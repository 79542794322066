import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'components/ui/Button';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './CloseButton.module.scss';

const CloseButton = ({ onClose, className, isHovering, color, ...props }) => {
    const handleMouseEnter = () => {
        isHovering(true);
    };

    const handleMouseLeave = () => {
        isHovering(false);
    };

    return (
        <>
            {onClose && (
                <div
                    className={styles.portalInner}
                    onMouseEnter={isHovering ? handleMouseEnter : null}
                    onMouseLeave={isHovering ? handleMouseLeave : null}
                >
                    <div className={cx(styles.root, styles.isOverlay)}>
                        <div className={styles.inner}>
                            <div
                                className={cx(styles.close, className)}
                                style={{
                                    color: `var(--colors-${color})`,
                                }}
                            >
                                <Button
                                    theme=""
                                    className={styles.button}
                                    onClick={onClose}
                                    gtm-id={props['gtm-id']}
                                    gtm-label={props['gtm-label']}
                                >
                                    <SvgIcon
                                        iconType="close"
                                        className={styles.icon}
                                    />
                                    <span className="sr-only">
                                        Close Overlay
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

CloseButton.propTypes = {
    onClose: PropTypes.func,
    className: PropTypes.string,
    isHovering: PropTypes.func,
    color: PropTypes.string,
    'gtm-id': PropTypes.string,
    'gtm-label': PropTypes.string,
};

CloseButton.defaultProps = {
    color: 'white',
};

export default CloseButton;
