import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';
import Link from 'components/ui/Link';

import Img from 'components/ui/Img';
import { getColor } from 'utils';

import styles from './CardLatest.module.scss';

const CardLatest = ({ eyebrow, headline, textColor, image, cta }) => (
    <Link.Entry className={styles.cardLinkWrap} entry={cta?.link}>
        <article
            className={styles.cardLatest}
            style={{ color: getColor(textColor) }}
        >
            <div className={styles.imageContainer}>
                {image?.file?.url && (
                    <Img
                        className={styles.image}
                        src={image.file.url}
                        fallbackImageWidth={1024}
                        alt={image.description || ''}
                        width={image.file.details?.image?.width}
                        height={image.file.details?.image?.height}
                        customSources={[
                            {
                                breakpoint: 1920,
                                src: image.file.url,
                                imageWidth: 1920 * 0.5,
                            },
                            {
                                breakpoint: 1440,
                                src: image.file.url,
                                imageWidth: 1440 * 0.5,
                            },
                            {
                                breakpoint: 1024,
                                src: image.file.url,
                                imageWidth: 1024 * 0.5,
                            },
                            {
                                breakpoint: 768,
                                src: image.file.url,
                                imageWidth: 768 * 0.75,
                            },
                            {
                                src: image.file.url,
                                imageWidth: 768,
                            },
                        ]}
                    />
                )}
            </div>
            <div className={styles.inner}>
                {eyebrow && (
                    <Text
                        baseTheme="labelSmall"
                        themes={{ xxLarge: 'labelLarge' }}
                        className={styles.eyebrow}
                        as="h5"
                    >
                        {eyebrow}
                    </Text>
                )}
                {headline && (
                    <Text
                        className={styles.headline}
                        baseTheme="bodyMedium"
                        themes={{
                            large: 'bodyMediumLarge',
                            xxLarge: 'bodyLarge',
                        }}
                        as="p"
                    >
                        {headline}
                    </Text>
                )}
            </div>
        </article>
    </Link.Entry>
);

CardLatest.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    textColor: PropTypes.string,
    cta: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }),
};

CardLatest.defaultProps = {
    textColor: 'blue',
};

export default CardLatest;
