import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { wait } from 'utils';

import Block from 'components/blocks/';
import Portal from 'components/ui/Portal';
import FlavorFinder from 'components/overlays/FlavorFinder';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import CloseButton from 'components/overlays/shared/CloseButton';
import CreamCheeseFlavorFinderBg from './CreamCheeseFlavorFinderBg';

import CreamCheeseFlavorProfiles from './CreamCheeseFlavorProfiles';

import { useBoolean, useMedia, useIntersection } from 'react-use';

import styles from './BlockCreamCheeseFlavorFinder.module.scss';

const routeName = 'products/cream-cheese/break-up';
const shareAnchor = 'quiz';

const overlayStates = {
    OPENING: 'OPENING',
    OPENED: 'OPENED',
    CLOSING: 'CLOSING',
    CLOSED: 'CLOSED',
};

const BlockCreamCheeseFlavorFinder = ({ twoColumnText }) => {
    const { OPENING, OPENED, CLOSING, CLOSED } = overlayStates;
    const blockRef = useRef();

    const [inView, setInView] = useState(false);

    const intersection = useIntersection(blockRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const isMediumQuery = useMedia('(min-width: 768px)');
    const hasHoverQuery = useMedia('(hover: hover)');

    const [isMedium, setIsMedium] = useBoolean(false); // use-media bug, need state reinforcement
    const [hasHover, setHasHover] = useBoolean(false); // use-media bug, need state reinforcement

    const [activeProductId, setActiveProductId] = useState('');

    useEffect(() => {
        setIsMedium(isMediumQuery);
    }, [isMediumQuery, setIsMedium]);

    useEffect(() => {
        setHasHover(hasHoverQuery);
    }, [hasHoverQuery, setHasHover]);

    useEffect(() => {
        setInView(intersection?.isIntersecting);
    }, [intersection, setInView]);

    const [overlayState, setOverlayState] = useState(CLOSED);

    useEffect(() => {
        let hashTimer;

        if (window.location.hash === '#quiz') {
            hashTimer = setTimeout(() => {
                blockRef.current.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 250);
        }

        return () => {
            clearTimeout(hashTimer);
        };
    }, []);

    const handleOpen = () => {
        setActiveProductId('');
        window.history.pushState(null, null, `/${routeName}#${shareAnchor}`);
        setOverlayState(OPENING);
    };

    const handleClose = useCallback(() => {
        window.history.pushState(null, null, `/${routeName}`);
        setOverlayState(overlayStates.CLOSING);
    }, []);

    const handleCloseDelayed = useCallback(() => {
        window.history.pushState(null, null, `/${routeName}`);
        // wait for scrollIntoView
        wait(250).then(() => {
            setOverlayState(overlayStates.CLOSING);
        });
    }, []);

    const handleKeyDown = useCallback(() => {
        if (event.keyCode === 27 && overlayState === OPENED) {
            handleClose();
        }
    }, [overlayState, handleClose, OPENED]);

    const handleAnimationEnd = () => {
        if (overlayState === CLOSING) {
            setOverlayState(CLOSED);
        }
        if (overlayState === OPENING) {
            setOverlayState(OPENED);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, [handleKeyDown]);

    const handleSendProductId = id => {
        setActiveProductId(id);
        handleCloseDelayed();
    };

    return (
        <>
            <article
                ref={blockRef}
                className={cx(styles.root, {
                    [styles.inView]: inView,
                })}
            >
                <div className={styles.innerAspect}>
                    <CreamCheeseFlavorFinderBg isMedium={isMedium} />
                    <div className={styles.innerContent}>
                        <div className={styles.content}>
                            <Text
                                as="h2"
                                className={styles.heading}
                                baseTheme="displayLarge2"
                            >
                                <span className="sr-only">
                                    Find Your Flavor Profile
                                </span>
                            </Text>
                            <Text
                                className={styles.body}
                                baseTheme="bodySmall"
                                themes={{ large: 'bodyMedium' }}
                            >
                                Can’t wait to meet your new Tillamook Cream
                                Cheese Spread? Swipe right to find true love.
                            </Text>
                            <Button
                                onClick={handleOpen}
                                className={styles.cta}
                                theme="filledBlueHoverCream-ultrabold"
                            >
                                Take the quiz
                            </Button>
                        </div>
                        {overlayState !== CLOSED && (
                            <Portal>
                                <div
                                    className={cx(styles.overlay, {
                                        [styles.isClosing]:
                                            overlayState === CLOSING,
                                    })}
                                    onAnimationEnd={handleAnimationEnd}
                                >
                                    <FlavorFinder
                                        isMedium={isMedium}
                                        hasHover={hasHover}
                                        sendProductId={handleSendProductId}
                                    />
                                </div>
                                <CloseButton
                                    color="blue"
                                    onClose={handleClose}
                                    className={cx(styles.overlayCloseButton, {
                                        [styles.isOpened]:
                                            overlayState === OPENED,
                                        [styles.isClosing]:
                                            overlayState === CLOSING,
                                    })}
                                />
                            </Portal>
                        )}
                    </div>
                </div>
            </article>
            <Block {...twoColumnText} contentTypeId="blockTwoColumnTextAlt" />
            <CreamCheeseFlavorProfiles showProductId={activeProductId} />
        </>
    );
};

BlockCreamCheeseFlavorFinder.propTypes = {
    twoColumnText: PropTypes.object,
};

export default BlockCreamCheeseFlavorFinder;
