import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useInterval, useWindowSize } from 'react-use';

import FlavorFinderBgSpinner from 'components/overlays/FlavorFinder/FlavorFinderBgSpinner';

import styles from './CreamCheeseFlavorFinderBg.module.scss';

const CreamCheeseFlavorFinderBg = ({ isMedium }) => {
    const ref = useRef();

    const { width: windowWidth } = useWindowSize();

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetHeight);
    }, [windowWidth, setWidth, setHeight]);

    const [constrainedPointsStored, setConstrainedPointsStored] = useState(
        false
    );
    const [constrainedPoints, setConstrainedPoints] = useState(false);
    const [isOdd, setIsOdd] = useState(false);
    const [webAnimationAPISupport, setWebAnimationAPISupport] = useState(false);

    const intervalDelay = 300;

    const emanate = () => {
        createParticle();
    };

    useInterval(
        () => {
            emanate();
        },
        webAnimationAPISupport ? intervalDelay : null
    );

    useEffect(() => {
        if (document.body.animate) {
            setWebAnimationAPISupport(true);
        }
    }, []);

    const generateCoords = useCallback(() => {
        const refWidth = width;
        const refHeight = height;

        const scale = isMedium ? 3 : 2;
        // generate points aroung a circle
        const radius = Math.max(refWidth / scale, refHeight / scale);
        const pointsArray = [];
        for (let degree = 0; degree < 360; degree++) {
            let radians = (degree * Math.PI) / 180;
            let x = radius * Math.cos(radians);
            let y = radius * Math.sin(radians);
            pointsArray.push({ x: x, y: y });
        }

        // constrain points
        const constrained = [];
        const maxVal = isMedium ? 30 : 12;
        const delta = Math.floor(pointsArray.length / maxVal);
        for (let i = 0; i < pointsArray.length; i = i + delta) {
            constrained.push(pointsArray[i]);
        }

        setConstrainedPointsStored(constrained);
        setConstrainedPoints(constrained);
    }, [isMedium, width, height]);

    useEffect(() => {
        generateCoords();
    }, [generateCoords]);

    useEffect(() => {
        generateCoords();
    }, [isMedium, generateCoords]);

    const createParticle = () => {
        // alternate x and heart icons evenly
        setIsOdd(!isOdd);
        const type = ['x', 'heart'][!isOdd ? 1 : 0];

        // create and append web animation api element <particle>
        const particle = document.createElement('particle');
        particle.classList.add(styles[type]);
        particle.classList.add(styles.particle);
        ref.current.appendChild(particle);

        const size = 60;
        particle.style.width = `${size}px`;
        particle.style.height = `${size}px`;

        // randomly pick destination
        // remove item from the array when used
        // cycle back to the original array when all items are used
        // should prevent icon build up and feel more natural
        const destination =
            constrainedPoints[
                Math.floor(Math.random() * constrainedPoints.length)
            ];

        if (constrainedPoints.length === 0) {
            setConstrainedPoints(constrainedPointsStored);
        } else {
            setConstrainedPoints(
                constrainedPoints.filter(item => item !== destination)
            );
        }

        if (destination?.x && destination?.y) {
            const animation = particle.animate(
                [
                    {
                        transform: `scale(0) translate(0px, 0px)`,
                    },
                    {
                        transform: `scale(${isMedium ? 4 : 1.5}) translate(${
                            destination?.x
                        }px, ${destination?.y}px)`,
                    },
                ],
                {
                    duration: isMedium ? 50000 : 10000,
                    easing: 'cubic-bezier(0, .9, .57, 1)',
                    delay: 0,
                }
            );

            animation.onfinish = () => {
                particle.remove();
            };
        } else {
            particle.remove();
        }
    };

    return (
        <div className={styles.root}>
            <FlavorFinderBgSpinner isSpinning renderSvgs={false} />
            <div ref={ref} className={styles.inner} />
        </div>
    );
};

CreamCheeseFlavorFinderBg.propTypes = {
    isMedium: PropTypes.bool,
};

export default CreamCheeseFlavorFinderBg;
