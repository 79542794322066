import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Button from 'components/ui/Button';
import Link from 'components/ui/Link';
import Stagger from 'components/ui/Stagger';

import styles from './CardLocation.module.scss';

const logoMap = {
    Market: 'market',
    Creamery: 'creamery',
};

const CardLocation = ({
    location,
    logo,
    address,
    linkToGoogleMaps,
    linkToReservation,
    hours,
    descriptionLine1,
    descriptionLine2,
    descriptionLine3,
    linkToLearnMore,
    linkToMenu,
    contactName,
    contactEmail,
    contactNumber,
}) => (
    <Stagger
        className={cx(styles.locationCard, {
            [styles.market]: logoMap[location] === 'market',
        })}
    >
        {location && !logo && (
            <Stagger.Child order={0} className={styles.logoWrapper}>
                <SvgIcon iconType={logoMap[location]} />
            </Stagger.Child>
        )}
        {logo?.file?.url && (
            <Stagger.Child order={0} className={styles.logoWrapper}>
                <img src={logo.file.url} />
            </Stagger.Child>
        )}
        {address && linkToGoogleMaps && (
            <Stagger.Child order={1} className={styles.address}>
                <Link href={linkToGoogleMaps}>
                    <Text
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyMedium' }}
                    >
                        {address}
                    </Text>
                </Link>
            </Stagger.Child>
        )}
        {linkToReservation && (
            <Stagger.Child order={2}>
                <Button
                    className={styles.reservationButton}
                    theme="outlinedBlue"
                    href={linkToReservation}
                >
                    Make A Reservation
                </Button>
            </Stagger.Child>
        )}
        <div className={styles.hoursWrapper}>
            {hours && (
                <Stagger.Child order={2}>
                    <Text className={styles.hoursLabel} baseTheme="labelLarge">
                        Hours
                    </Text>
                </Stagger.Child>
            )}
            <Stagger.Child order={2}>
                <ul className={styles.hoursList}>
                    {hours &&
                        hours.map((timeSpan, idx) => (
                            <li key={idx}>
                                <div className={styles.timeSpan}>
                                    <Text baseTheme="headingXSmall">
                                        {timeSpan.days}
                                    </Text>
                                    <Text baseTheme="headingXSmall">
                                        {timeSpan.hours}
                                    </Text>
                                </div>
                            </li>
                        ))}
                </ul>
            </Stagger.Child>
            <Stagger.Child order={2}>
                <Text baseTheme="bodyXSmall">
                    {descriptionLine1 && descriptionLine1}
                    {descriptionLine2 && (
                        <>
                            <br />
                            {descriptionLine2}
                        </>
                    )}
                    {descriptionLine3 && (
                        <>
                            <br />
                            {descriptionLine3}
                        </>
                    )}
                </Text>
            </Stagger.Child>
        </div>
        <Stagger.Child order={2} className={styles.contactWrapper}>
            {(contactName || contactNumber) && (
                <Text className={styles.contactLabel} baseTheme="labelLarge">
                    Contact
                </Text>
            )}

            {contactName && (
                <Text className={styles.contactName} baseTheme="headingXSmall">
                    {contactName}
                </Text>
            )}

            {contactEmail && (
                <Link
                    className={styles.contactEmail}
                    href={`mailto:${contactEmail}`}
                >
                    <Text baseTheme="headingXSmall">{contactEmail}</Text>
                </Link>
            )}
            {contactNumber && (
                <Link
                    className={styles.contactNumber}
                    href={`tel:${contactNumber}`}
                >
                    <Text baseTheme="headingXSmall">{contactNumber}</Text>
                </Link>
            )}
        </Stagger.Child>
        <Stagger.Child order={2}>
            <div className={styles.buttonWrapper}>
                <Button
                    className={styles.learnMoreLink}
                    theme="filledBlueHoverCream"
                    entry={linkToLearnMore}
                >
                    Learn More
                </Button>
                {linkToMenu?.assetDownloadLink && (
                    <Button
                        className={styles.menuButton}
                        theme="outlinedBlue"
                        href={linkToMenu.assetDownloadLink?.file?.url}
                        iconLeading="download"
                    >
                        {linkToMenu.text}
                    </Button>
                )}
            </div>
        </Stagger.Child>
    </Stagger>
);

CardLocation.propTypes = {
    address: PropTypes.string,
    contactEmail: PropTypes.string,
    contactName: PropTypes.string,
    contactNumber: PropTypes.string,
    descriptionLine1: PropTypes.string,
    descriptionLine2: PropTypes.string,
    descriptionLine3: PropTypes.string,
    hours: PropTypes.array,
    linkToGoogleMaps: PropTypes.string,
    linkToLearnMore: PropTypes.object,
    linkToMenu: PropTypes.object,
    linkToReservation: PropTypes.string,
    location: PropTypes.oneOf(['Market', 'Creamery']),
    logo: PropTypes.object,
};

CardLocation.defaultProps = {
    contactEmail: '',
    descriptionLine2: '',
    descriptionLine3: '',
    linkToReservation: '',
};

export default CardLocation;
