import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { webGLSupport } from 'utils';
import Portal from 'components/ui/Portal';
import SwirlStir from 'components/overlays/SwirlStir';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import CloseButton from 'components/overlays/shared/CloseButton';

import FallingFruit from './FallingFruit';
import {
    useInterval,
    useBoolean,
    useMedia,
    useDebounce,
    useIntersection,
} from 'react-use';

import styles from './BlockCreamerySwirlStir.module.scss';

const flavors = [
    {
        key: 'RASPBERRY_BLACKBERRY',
        label: 'Northwest blackberry & Raspberry',
        color1: '#D99FC9',
        color2: '#AF6CAC',
    },
    {
        key: 'STRAWBERRY_PLAIN',
        label: 'Oregon Strawberry & Plain',
        color1: '#F69FA2',
        color2: '#ED5371',
    },
    {
        key: 'BLUEBERRY_VANILLA',
        label: 'Oregon Blueberry & Vanilla',
        color1: '#AEBFDC',
        color2: '#8189C5',
    },
];

const SLIDE_DURATION = 1000 * 10;

const overlayStates = {
    OPENING: 'OPENING',
    OPENED: 'OPENED',
    CLOSING: 'CLOSING',
    CLOSED: 'CLOSED',
};

const Label = ({ isActive, isPaused, onPosition, label, onClick }) => {
    const ref = useRef();
    useEffect(() => {
        if (ref.current) {
            onPosition(ref.current.offsetLeft);
        }
    }, [onPosition]);

    return (
        <li
            ref={ref}
            className={cx(styles.flavorLabel, {
                [styles[`flavorLabel--isActive`]]: isActive,
                [styles[`flavorLabel--isPaused`]]: isPaused,
            })}
        >
            <button onClick={onClick} className={styles.flavorLabelButton}>
                {label}
            </button>
            <div className={styles.flavorLabelProgressBar}></div>
        </li>
    );
};

Label.propTypes = {
    isActive: PropTypes.bool,
    isPaused: PropTypes.bool,
    onPosition: PropTypes.func,
    onClick: PropTypes.func,
    label: PropTypes.string,
};

const BlockCreamerySwirlStir = ({ flushTop, flushBottom }) => {
    const { OPENING, OPENED, CLOSING, CLOSED } = overlayStates;
    const ref = useRef();
    const blockRef = useRef();

    const [inView, setInView] = useState(false);

    const intersection = useIntersection(blockRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const isMediumQuery = useMedia('(min-width: 768px)');
    const hasHoverQuery = useMedia('(hover: hover)');

    const [isMedium, setIsMedium] = useBoolean(false); // use-media bug, need state reinforcement
    const [hasHover, setHasHover] = useBoolean(false); // use-media bug, need state reinforcement
    const [isScrolling, setIsScrolling] = useBoolean(false);
    const [isInteracting, setIsPaused] = useBoolean(false);
    const [isCloseHover, setIsCloseHover] = useBoolean(false);
    const [hasWebGLSupport, setHasWebGLSupport] = useBoolean(false);

    const [scrollPositions, setScrollPositions] = useState(
        Array(flavors.length).fill(0)
    );

    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollPositionDebounced, setScrollPositionDebounced] = useState(0);

    useDebounce(
        () => {
            setScrollPositionDebounced(scrollPosition);
        },
        200,
        [scrollPosition]
    );

    useDebounce(
        () => {
            setIsPaused(false);
        },
        SLIDE_DURATION,
        [isInteracting]
    );

    useEffect(() => {
        setIsMedium(isMediumQuery);
    }, [isMediumQuery, setIsMedium]);

    useEffect(() => {
        setHasHover(hasHoverQuery);
    }, [hasHoverQuery, setHasHover]);

    useEffect(() => {
        setInView(intersection?.isIntersecting);
    }, [intersection, setInView]);

    useEffect(() => {
        const hasWebGL = webGLSupport();
        setHasWebGLSupport(hasWebGL === null ? false : true);
    }, [setHasWebGLSupport]);

    const [overlayState, setOverlayState] = useState(CLOSED);
    const [count, setCount] = useState(0);

    const isPaused =
        overlayState === OPENED || isScrolling || !inView || isInteracting;

    useInterval(
        () => {
            setCount((count + 1) % flavors.length);
        },
        isPaused ? null : SLIDE_DURATION // null pauses interval
    );

    useEffect(() => {
        let hashTimer;

        if (window.location.hash === '#swirl') {
            hashTimer = setTimeout(() => {
                blockRef.current.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 250);
        }

        return () => {
            clearTimeout(hashTimer);
        };
    }, []);

    const handleOpen = () => {
        window.history.pushState(null, null, `/creamery-collection#swirl`);
        setOverlayState(OPENING);
    };

    const handleClose = useCallback(() => {
        window.history.pushState(null, null, `/creamery-collection`);
        setOverlayState(overlayStates.CLOSING);
    }, []);

    const handleKeyDown = useCallback(() => {
        if (event.keyCode === 27 && overlayState === OPENED) {
            handleClose();
        }
    }, [overlayState, handleClose, OPENED]);

    const handleAnimationEnd = () => {
        if (overlayState === CLOSING) {
            setOverlayState(CLOSED);
            setIsCloseHover(false);
        }
        if (overlayState === OPENING) {
            setOverlayState(OPENED);
        }
    };

    useEffect(() => {
        ref.current.scroll(scrollPositions[count], 0);
    }, [scrollPositions, count]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, [handleKeyDown]);

    const handlePosition = useCallback(
        i => x => {
            scrollPositions[i] = x;
            setScrollPositions(scrollPositions);
        },
        [scrollPositions]
    );

    const handleClickLabel = i => () => {
        setIsPaused(true);
        setCount(i);
    };

    const handleScroll = () => {
        setScrollPosition(ref.current.scrollLeft);
        setIsScrolling(true);
    };

    useEffect(() => {
        setIsScrolling(false);

        const currentIndex = scrollPositions.findIndex(val => {
            return scrollPositionDebounced <= val;
        });

        setCount(currentIndex === -1 ? flavors.length - 1 : currentIndex);
    }, [setIsScrolling, scrollPositions, scrollPositionDebounced]);

    const handleCloseHover = isHovering => {
        setIsCloseHover(isHovering ? true : false);
    };

    return (
        <article
            style={{ '--slide-duration': `${SLIDE_DURATION}ms` }}
            ref={blockRef}
            className={cx(styles.wrapper, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <svg
                width="100%"
                height="100%"
                preserveAspectRatio="none"
                className={styles.background}
                viewBox="0 0 16 9"
                xmlns="http://www.w3.org/2000/svg"
                id="fill"
            >
                <polyline
                    className={styles.backgroundColor}
                    fill={flavors[count].color1}
                    strokeMiterlimit="10"
                    points="0,0 13.05,0 3.05,9 0,9"
                />
                <polyline
                    className={styles.backgroundColor}
                    fill={flavors[count].color2}
                    strokeMiterlimit="10"
                    points="16,0 13,0 3,9 16,9"
                />
            </svg>
            <div className={styles.innerAspect}>
                {overlayState !== OPENED && (
                    <FallingFruit
                        count={isMedium ? 30 : 8}
                        current={flavors[count].key}
                    />
                )}
                <div className={styles.innerContent}>
                    <div className={styles.content}>
                        <Text
                            className={styles.heading}
                            baseTheme="displayLarge"
                        >
                            Swirl <br />& Stir
                        </Text>
                        <Text
                            className={styles.body}
                            baseTheme="bodySmall"
                            themes={{ large: 'bodyMedium' }}
                        >
                            Get the best of both flavors by creating YOUR
                            perfect bite. Swirl awhile for a sweet treat at the
                            end.
                        </Text>
                        <Button
                            gtm-id="give-it-a-swirl-button"
                            onClick={handleOpen}
                            className={styles.cta}
                            theme="filledBlueHoverCream"
                            iconLeading="spoon"
                        >
                            Give it a swirl
                        </Button>
                    </div>
                    <ul
                        className={styles.flavorList}
                        ref={ref}
                        onScroll={handleScroll}
                    >
                        {flavors.map(({ label }, i) => {
                            return (
                                <Label
                                    key={i}
                                    idx={i}
                                    onPosition={handlePosition(i)}
                                    isActive={i === count}
                                    isPaused={isPaused}
                                    label={label}
                                    onClick={handleClickLabel(i)}
                                />
                            );
                        })}
                    </ul>
                    {overlayState !== CLOSED && (
                        <Portal>
                            <div
                                className={cx(styles.overlay, {
                                    [styles.isClosing]:
                                        overlayState === CLOSING,
                                })}
                                onAnimationEnd={handleAnimationEnd}
                            >
                                <SwirlStir
                                    initialId={count}
                                    isMedium={isMedium}
                                    hasHover={hasHover}
                                    isCloseHover={isCloseHover}
                                    hasWebGLSupport={hasWebGLSupport}
                                />
                            </div>
                            <CloseButton
                                onClose={handleClose}
                                isHovering={handleCloseHover}
                                gtm-id="swirl-close-button"
                                className={cx(styles.overlayCloseButton, {
                                    [styles.isOpened]: overlayState === OPENED,
                                    [styles.isClosing]:
                                        overlayState === CLOSING,
                                })}
                            />
                        </Portal>
                    )}
                </div>
            </div>
        </article>
    );
};

BlockCreamerySwirlStir.propTypes = {
    eyebrow: PropTypes.string,
    richHeadline: PropTypes.object,
    body: PropTypes.string.isRequired,
    sharpnessStory: PropTypes.object,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
};

BlockCreamerySwirlStir.defaultProps = {
    eyebrow: '',
    body: '',
    flushTop: true,
    flushBottom: true,
};

export default BlockCreamerySwirlStir;
