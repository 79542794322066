import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Img from 'components/ui/Img';
import Text from 'components/ui/Text';

import { easing, timing } from '../../animations';

import styles from './Card.module.scss';

const Card = ({ onClick, image, index, children, ...props }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = e => {
        setActiveIndex(index);
        onClick(e);
    };

    const label = typeof children === 'string' ? children : '';

    const contentVariants = {
        initial: { opacity: 0, scale: 1 },
        animateIn: {
            opacity: 1,
            transition: {
                duration: timing.base,
                ease: easing.opacity,
                delay: timing.base * 0.25,
            },
        },
        zoomOut: {
            scale: index === activeIndex ? 1 : 0.75,
            transition: { duration: timing.base, ease: easing.scale },
        },
        fadeOut: {
            opacity: 0,
            transition: { duration: timing.base, ease: easing.opacity },
        },
    };

    return (
        <button
            className={styles.root}
            onClick={handleClick}
            role="radio"
            aria-label={label}
            {...props}
        >
            <div className={styles.content}>
                <motion.div
                    variants={contentVariants}
                    initial="initial"
                    animate="animateIn"
                    exit={['zoomOut', 'fadeOut']}
                >
                    <Img
                        className={styles.image}
                        src={image}
                        fallbackImageWidth={500}
                        alt={image.description || label}
                    />
                    <Text
                        className={styles.label}
                        baseTheme="bodySmall"
                        themes={{
                            medium: 'headingSmall',
                            xLarge: 'headingMedium',
                        }}
                    >
                        {children}
                    </Text>
                </motion.div>
            </div>
        </button>
    );
};

Card.propTypes = {
    children: PropTypes.any,
    image: PropTypes.string,
    index: PropTypes.number,
    onClick: PropTypes.func,
};

export default Card;
