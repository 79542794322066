/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from 'react';
import styles from './ShredderCanvas.module.scss';
import { useMeasure } from 'react-use';

// performance might be effected by image count. remove or add here.
const shredImages = [
    '//images.ctfassets.net/j8tkpy1gjhi5/2cCY2kZGNMWe4SFqxrYnYO/80e1aac6ec2e0c866a54af4f22167b7c/shred-01.png?fm=webp&q=80',
    '//images.ctfassets.net/j8tkpy1gjhi5/27WCRakrlcHwp0Daw7g8A7/70c63d54b153ecc4afd8e6b58f705526/shred_02.png?fm=webp&q=80',
    '//images.ctfassets.net/j8tkpy1gjhi5/2lIekYyEDOgkwEYj1c1NlT/47f69284e446895b4d31c57df504c6e7/shred_03.png?fm=webp&q=80',
    '//images.ctfassets.net/j8tkpy1gjhi5/7emnsYZ1SEYKqtJEC6ODnA/1b7f60d314151d614929a5cd9ef7932f/shred_06.png?fm=webp&q=80',
    '//images.ctfassets.net/j8tkpy1gjhi5/26pnpNaM1i44rCJmBVbGds/a96e001ee11f485b077a3c8653846b13/shred_07.png?fm=webp&q=80',
];

function randomFloatInRange(min, max) {
    return min + Math.random() * (max - min);
}

const ShredderCanvas = ({
    shredInterval = 200,
    maxShreds = 150,
    minSize = 100,
    maxSize = 200,
    minSpeed = 1,
    maxSpeed = 5,
}) => {
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [images, setImages] = useState([]);
    const [ref, { width, height }] = useMeasure();
    const canvasRef = useRef();

    const preload = src =>
        new Promise((resolve, reject) => {
            const img = new Image();
            setImages(images => [...images, img]);
            img.onload = resolve;
            img.onerror = reject;
            img.src = src;
        });

    const preloadAll = srcs => Promise.all(srcs.map(preload));

    useEffect(() => {
        (async () => {
            if (await preloadAll(shredImages)) {
                setImagesLoaded(true);
            }
        })();
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        let animationFrameId;

        // cover the whole device screen to avoid resize resetting the animation
        // only resize canvas element, not animation
        const canvasWidth = screen.width;
        const canvasHeight = screen.height;

        const bufferCanvas = document.createElement('canvas');
        const bufferCanvasCtx = bufferCanvas.getContext('2d');
        bufferCanvasCtx.canvas.width = canvasWidth;
        bufferCanvasCtx.canvas.height = canvasHeight;

        let shredSpawnTimer = null;
        const shredArray = [];

        function Shred() {
            return {
                speed: randomFloatInRange(minSpeed, maxSpeed),
                width: randomFloatInRange(minSize, maxSize),
                height: randomFloatInRange(minSize, maxSize),
                x: Math.round(Math.random() * canvasWidth),
                y: -canvasHeight / 2,
                rotateSpeed: randomFloatInRange(4000, 10000),
            };
        }

        function addShred() {
            shredArray[shredArray.length] = new Shred();
            if (shredArray.length == maxShreds) clearInterval(shredSpawnTimer);
        }

        function clearCanvas() {
            bufferCanvasCtx.clearRect(
                0,
                0,
                bufferCanvasCtx.canvas.width,
                bufferCanvasCtx.canvas.height
            );
            context.clearRect(
                0,
                0,
                context.canvas.width,
                context.canvas.height
            );
        }

        function update() {
            let shred;
            const max = {
                x: canvasWidth,
                y: canvasHeight,
            };
            const min = {
                x: 0,
                y: 0,
            };
            let halfshred = {};
            const bounds = {
                x: max.x,
                y: max.y,
            };

            for (let i = 0; i < shredArray.length; i++) {
                shred = shredArray[i];
                halfshred.x = shred.width / 2;
                halfshred.y = shred.height / 2;

                bounds.x = max.x + halfshred.x;
                bounds.y += halfshred.y;

                // move along y.
                if (shred.y <= bounds.y) {
                    // within bounds: move.
                    shred.y += shred.speed;
                } else {
                    // out of bounds: reset.
                    shred.y = min.y - halfshred.y;
                }
            }
        }

        function draw(time) {
            context.save();
            clearCanvas();

            let shred;

            for (let i = 0; i < shredArray.length; i++) {
                shred = shredArray[i];

                let xPos = shred.x - shred.width / 2;
                let yPos = shred.y - shred.height / 2;

                bufferCanvasCtx.setTransform(1, 0, 0, 1, xPos, yPos);
                bufferCanvasCtx.rotate(time / shred.rotateSpeed);
                bufferCanvasCtx.drawImage(
                    images[i % images.length],
                    -shred.width / 2,
                    -shred.height / 2,
                    shred.width,
                    shred.height
                );
                bufferCanvasCtx.setTransform(1, 0, 0, 1, 0, 0);
            }

            context.drawImage(
                bufferCanvas,
                0,
                0,
                bufferCanvas.width,
                bufferCanvas.height
            );
            context.restore();
        }

        const render = time => {
            update();
            draw(time);
            animationFrameId = window.requestAnimationFrame(render);
        };

        // images must load before we can start drawing
        if (imagesLoaded) {
            shredSpawnTimer = setInterval(addShred, shredInterval);
            draw();
            render();
        }

        return () => {
            window.cancelAnimationFrame(animationFrameId);
        };
    }, [
        images,
        imagesLoaded,
        minSpeed,
        maxSpeed,
        minSize,
        maxSize,
        maxShreds,
        shredInterval,
    ]);

    return (
        <div ref={ref} className={styles.root}>
            <canvas
                ref={canvasRef}
                className={styles.canvas}
                width={width}
                height={height}
            />
        </div>
    );
};

export default ShredderCanvas;
