import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Carousel from 'components/ui/Carousel';
import styles from './CardGroupCarousel.module.scss';

const CardGroupCarousel = ({
    cardMap,
    cards,
    theme,
    className,
    navigationTheme,
}) => {
    const cleanCards = cards.filter(card => {
        const isRenderable =
            (cardMap[card.contentTypeId || 'default'] || cardMap['default']) !==
            null;

        if (!isRenderable) {
            console.warn(
                `Card is not renderable contentTypeId: ${
                    card.contentTypeId || 'Unknown draft'
                }`
            );
        }

        return isRenderable;
    });

    const renderCards = () => {
        return cleanCards.map((card, idx) => {
            const Card =
                cardMap[card.contentTypeId || 'default'] || cardMap['default'];

            if (Card == null) {
                console.warn(`Card Missing or Drafted`);
                return null;
            }

            return (
                <div
                    key={idx}
                    className={cx(styles.item, {
                        [styles.cardIconStat]:
                            card.contentTypeId === 'cardIconStat',
                        [styles.cardSmall]: card.contentTypeId === 'cardSmall',
                    })}
                >
                    <div className={styles.inner}>
                        <Card {...card} />
                    </div>
                </div>
            );
        });
    };

    return (
        <div
            className={cx(styles.root, className, {
                [styles.threeUpFull]: theme === 'Campaign',
                [styles.threeUpContained]:
                    cleanCards.length < 4 && theme !== 'Campaign',
                [styles.centered]: cleanCards.length < 3,
            })}
        >
            <Carousel
                className={styles.carousel}
                navigationTheme={navigationTheme}
                slideClassName={styles.slide}
            >
                {renderCards()}
            </Carousel>
        </div>
    );
};

CardGroupCarousel.propTypes = {
    className: PropTypes.string,
    cards: PropTypes.array.isRequired,
    cardMap: PropTypes.object,
    theme: PropTypes.string,
    navigationTheme: PropTypes.oneOf(['blue', 'cream']),
};

CardGroupCarousel.defaultProps = {
    className: null,
    theme: 'Latest',
};

export default CardGroupCarousel;
