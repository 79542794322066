import PropTypes from 'prop-types';
import React, { useContext, useRef, useEffect, useCallback } from 'react';
import { useIntersection } from 'react-use';

import { CarouselContext } from 'components/ui/Carousel';

const Slide = ({ children, className, index, root }) => {
    const ref = useRef(null);
    const { scrollToIndex, setActiveIndices } = useContext(CarouselContext);

    const intersection = useIntersection(ref, {
        root: root?.current,
        rootMargin: '0px',
        threshold: 1,
    });

    useEffect(() => {
        setActiveIndices(prevActiveIndices =>
            intersection?.isIntersecting
                ? [...prevActiveIndices, index]
                : prevActiveIndices.filter(idx => idx !== index)
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intersection]);

    const handleClick = useCallback(() => {
        scrollToIndex(index);
    }, [index, scrollToIndex]);

    return (
        <li ref={ref} className={className} onClick={handleClick} role="button">
            {children}
        </li>
    );
};

Slide.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    index: PropTypes.number.isRequired,
    root: PropTypes.shape({ current: PropTypes.object }),
};

export default Slide;
