import React from 'react';
import PropTypes from 'prop-types';

import Overlay from './Overlay';
import Card from './Card';

import SignUpNoOffer from './custom/SignUpNoOffer';
import CheddarbrationCard from './custom/CheddarbrationCard';
import ShredsTakeover from './custom/ShredsTakeover';

const customAlerts = {
    SignUpNoOffer: SignUpNoOffer,
    CheddarbrationCard: CheddarbrationCard,
    ShredsTakeover: ShredsTakeover,
};

const alertMap = {
    alertOverlay: Overlay,
    alertCard: Card,
};

const Alerts = props => {
    let RenderedAlert = alertMap[props.contentTypeId] || null;

    if (props.contentTypeId === 'alertCustom') {
        RenderedAlert = customAlerts[props.component] || null;
    }

    if (RenderedAlert) {
        return (
            // Portal or Tunnel will be rendered based on component
            <RenderedAlert {...props} />
        );
    }

    return null;
};

Alerts.propTypes = {
    contentTypeId: PropTypes.string.isRequired,
    component: PropTypes.string,
    id: PropTypes.string.isRequired,
};

export default Alerts;
