import React from 'react';
import PropTypes from 'prop-types';
import CardStory from 'components/cards/CardStory';

import styles from './StoryList.module.scss';

const StoryList = ({ stories }) => {
    if (stories?.length < 2) {
        console.warn('News block requires at least two articles.');

        return null;
    }

    return (
        <ul className={styles.storyList}>
            {stories.map((story, idx) => (
                <CardStory key={idx} {...story} />
            ))}
        </ul>
    );
};

StoryList.propTypes = {
    stories: PropTypes.array,
};

export default StoryList;
