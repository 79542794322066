import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Img from 'components/ui/Img';
import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Spinner from './Spinner';
import Graph from './Graph';

import styles from './BlockCommitmentChart.module.scss';

const BlockCommitmentChart = ({
    headline,
    bodyCopy,
    image,
    imageLarge,
    chartHeadline,
    standardsList,
    chartCopy,
    theme,
}) => {
    if (theme === 'Standards') {
        return (
            <div className={cx(styles.chartRow)}>
                <div className={styles.innerChart}>
                    <div className={styles.lockup}>
                        {headline && (
                            <Text
                                className={cx(
                                    styles.headline,
                                    styles.standardsHeadline
                                )}
                                baseTheme={
                                    theme === 'numbers'
                                        ? 'displayMedium'
                                        : 'headingLarge'
                                }
                                themes={
                                    theme === 'numbers'
                                        ? {
                                              large: 'displayLarge',
                                          }
                                        : {
                                              large: 'headingLarge',
                                          }
                                }
                            >
                                {headline}
                            </Text>
                        )}
                        <div className={cx(styles.iconWrapper)}>
                            <SvgIcon
                                className={styles.icon}
                                iconType={'arrowRight'}
                            />
                        </div>
                        {bodyCopy && (
                            <div className={styles.copyWrapper}>
                                <Text
                                    className={styles.bodyCopy}
                                    baseTheme={
                                        theme === 'numbers'
                                            ? 'bodyMedium'
                                            : 'bodySmall'
                                    }
                                    themes={
                                        theme === 'numbers'
                                            ? { large: 'bodyLarge' }
                                            : { large: 'bodyMedium' }
                                    }
                                >
                                    {bodyCopy}
                                </Text>
                            </div>
                        )}
                    </div>

                    <div className={styles.chartInner}>
                        <div className={styles.chart}>
                            {chartHeadline && (
                                <Text
                                    className={styles.chartHeadline}
                                    baseTheme={'displayXSmall'}
                                    themes={{
                                        large: 'displayXSmall',
                                    }}
                                >
                                    {chartHeadline}
                                </Text>
                            )}
                            {chartCopy && (
                                <Text
                                    className={styles.chartCopy}
                                    baseTheme={'bodySmall'}
                                    themes={{
                                        large: 'bodySmall',
                                    }}
                                >
                                    {chartCopy}
                                </Text>
                            )}
                            {standardsList && (
                                <div className={styles.standardsList}>
                                    {standardsList.map((standard, id) => {
                                        return (
                                            <div
                                                className={
                                                    styles.standardWrapper
                                                }
                                                key={id}
                                            >
                                                <div
                                                    className={
                                                        styles.standardIconWrapper
                                                    }
                                                >
                                                    <SvgIcon
                                                        className={
                                                            styles.standardIcon
                                                        }
                                                        iconType={'roundCheck'}
                                                    />
                                                </div>
                                                <Text
                                                    className={styles.standard}
                                                    baseTheme={'headingXSmall'}
                                                    themes={{
                                                        large: 'headingSmall',
                                                    }}
                                                >
                                                    {standard}
                                                </Text>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (theme === 'Graph1') {
        return <Graph headline={headline} bodyCopy={bodyCopy} theme={theme} />;
    }

    return (
        <div
            className={cx(styles.commitmentChart, {
                [styles.draggableChart]: theme === 'Draggable',
            })}
        >
            <div className={styles.contentWrapper}>
                <div className={styles.innerWrapper}>
                    <div className={styles.lockup}>
                        {headline && (
                            <Text
                                className={styles.headline}
                                baseTheme={'displaySmall'}
                                themes={{
                                    large: 'displayLarge',
                                }}
                            >
                                {headline}
                            </Text>
                        )}
                        <div className={cx(styles.iconWrapper)}>
                            <SvgIcon
                                className={styles.icon}
                                iconType={'arrowRight'}
                            />
                        </div>
                        {bodyCopy && (
                            <Text
                                className={styles.copy}
                                baseTheme={'bodySmall'}
                                themes={{
                                    large: 'bodyMedium',
                                }}
                            >
                                {bodyCopy}
                            </Text>
                        )}
                    </div>
                </div>
                {theme === 'Draggable' ? (
                    <Spinner className={styles.imgBlock}>
                        <Img
                            className={styles.img}
                            src={image?.file.url}
                            fallbackImageWidth={768}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: imageLarge?.file.url,
                                    imageWidth: 1420,
                                },
                                {
                                    src: image?.file.url,
                                    imageWidth: 1024,
                                },
                            ]}
                        />
                    </Spinner>
                ) : (
                    <div className={styles.imgBlock}>
                        <Img
                            className={styles.img}
                            src={image?.file.url}
                            fallbackImageWidth={768}
                            alt={image?.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: imageLarge?.file.url,
                                    imageWidth: 1420,
                                },
                                {
                                    src: image?.file.url,
                                    imageWidth: 1024,
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

BlockCommitmentChart.propTypes = {
    bodyCopy: PropTypes.string,
    headline: PropTypes.string,
    chartHeadline: PropTypes.string,
    chartCopy: PropTypes.string,
    standardsList: PropTypes.array,
    imageLarge: PropTypes.object,
    image: PropTypes.object,
    theme: PropTypes.oneOf([
        'Progress',
        'Draggable',
        'Standards',
        'Graph1',
        'Graph2',
    ]),
};

BlockCommitmentChart.defaultProps = {
    theme: 'Progress',
};

export default BlockCommitmentChart;
