import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FlavorPairings from '../shared/FlavorPairings';

import styles from './BlockProductPairings.module.scss';

const BlockProductPairings = props => {
    return (
        <div
            className={cx(styles.root, {
                [styles.flushTop]: props.flushTop,
                [styles.flushBottom]: props.flushBottom,
            })}
        >
            <div className={styles.inner}>
                <FlavorPairings {...props} />
            </div>
        </div>
    );
};

BlockProductPairings.propTypes = {
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default BlockProductPairings;
