import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';
import Button from 'components/ui/Button';
import Text from 'components/ui/Text';

import Img from 'components/ui/Img';

import styles from './BlockHowItsMade.module.scss';

const BlockHowItsMade = ({
    landscapeImage,
    portraitImage,
    cta,
    description,
}) => {
    useEffect(() => {
        if (!cta) {
            dispatchWarning('No CTA provided. CTA required for render.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (cta?.link && cta?.text) {
        return (
            <div className={styles.howItsMade}>
                <div className={styles.content}>
                    <div className={styles.icon}>
                        <SvgIcon iconType="howItsMade" />
                        <div className={styles.stampWrapper}>
                            <div className={styles.stamp}>
                                <SvgIcon iconType="stamp" />
                            </div>
                        </div>
                    </div>
                    <Text
                        className={styles.description}
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyLarge' }}
                    >
                        {description}
                    </Text>
                    <Button
                        iconLeading="compass"
                        theme="filledCream"
                        entry={cta.link}
                    >
                        {cta.text}
                    </Button>
                </div>
                <Img
                    className={styles.image}
                    src={portraitImage.file.url}
                    fallbackImageWidth={768}
                    alt={portraitImage.description || ''}
                    customSources={[
                        {
                            breakpoint: 768,
                            src: landscapeImage.file.url,
                            imageWidth: landscapeImage.file.details.image.width,
                        },
                        {
                            src: portraitImage.file.url,
                            imageWidth: portraitImage.file.details.image.width,
                        },
                    ]}
                />
            </div>
        );
    }

    return null;
};

BlockHowItsMade.propTypes = {
    landscapeImage: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }),
    portraitImage: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }),
    description: PropTypes.string,
    cta: PropTypes.shape({
        text: PropTypes.string.isRequired,
        link: PropTypes.object.isRequired,
    }),
};

BlockHowItsMade.defaultProps = {
    portraitImage: {
        file: {
            details: {
                image: {
                    width: 768,
                    height: 963,
                },
            },
            url: 'https://images.ctfassets.net/j8tkpy1gjhi5/29yhjrhuBq5mVhkS0OB1Nc/a8c311f3526577f9cf542a0fdce433b2/Mobile__1_.jpg',
        },
    },
    landscapeImage: {
        file: {
            details: {
                image: {
                    width: 2880,
                    height: 1800,
                },
            },
            url: 'https://images.ctfassets.net/j8tkpy1gjhi5/5bfHVkRaEiJM22Xz32ZZu5/95794a073e96d4b088f6759e0a1d6694/Desktop.jpg',
        },
    },
    description:
        'Taste the difference that can only be made with quality ingredients and time-honored tradition.',
};

export default BlockHowItsMade;
