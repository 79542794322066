import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useMedia, useClickAway } from 'react-use';
import { useWait } from 'hooks/useWait';
import { useRouter } from 'next/router';
import { localStorageSupport, saveToLocalStorage } from 'utils';

import Portal from 'components/ui/Portal';
import Card from './Card';

import styles from './ShredsTakeover.module.scss';

const ShredsTakeover = ({ id }) => {
    const router = useRouter();
    const wait = useWait();
    const innerRef = useRef(null);

    const isMediumQuery = useMedia('(min-width: 768px)');
    const [isOpen, toggleOpen] = useState(false);
    const [isVisible] = useState(true);

    useClickAway(innerRef, () => {
        handleClose();
    });

    function waitFrame() {
        return new Promise(resolve => {
            requestAnimationFrame(resolve);
        });
    }

    async function handleClose() {
        await saveToLocalStorage(id, 1);

        waitFrame().then(() => {
            toggleOpen(false);
        });
    }

    useEffect(() => {
        if (
            router.isReady &&
            localStorageSupport() &&
            !localStorage.getItem(id)
        ) {
            // Display alert IF alert id is not in local storage
            wait(1500).then(() => toggleOpen(true));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    function handleKeyDown(evt) {
        if (evt.keyCode === 27) {
            handleClose();
        }
    }

    function toggleEventListener(method) {
        document[`${method}EventListener`]('keydown', handleKeyDown);
    }

    useEffect(() => {
        toggleEventListener('add');

        return () => {
            toggleEventListener('remove');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {isOpen && (
                <Portal>
                    <div className={styles.overlay}>
                        <Card
                            onClose={handleClose}
                            isMediumQuery={isMediumQuery}
                            isVisible={isVisible}
                        />
                    </div>
                </Portal>
            )}
        </Fragment>
    );
};

ShredsTakeover.propTypes = {
    id: PropTypes.any,
};

export default ShredsTakeover;
