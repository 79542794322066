import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Block from 'components/blocks/';

import VideoPlayerYT from 'components/ui/VideoPlayerYT';

import styles from './BlockHeroVideo.module.scss';

const BlockHeroVideo = ({ video, callout, flushBottom }) => {
    useEffect(() => {
        if (!video) {
            dispatchWarning('No video provided. Video required for render.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    return (
        <div className={styles.root}>
            <div className={styles.inner}>
                <VideoPlayerYT {...video} heroVideo={true} />
            </div>
            {callout && (
                <div
                    className={cx(
                        styles.calloutWrapper,
                        flushBottom && styles.flushBottom
                    )}
                >
                    <Block {...callout} contentTypeId={'blockCallout'} />
                </div>
            )}
        </div>
    );
};

BlockHeroVideo.propTypes = {
    video: PropTypes.object.isRequired,
    callout: PropTypes.object,
    flushBottom: PropTypes.bool,
};

BlockHeroVideo.defaultProps = {
    callout: null,
    flushBottom: false,
};

export default BlockHeroVideo;
