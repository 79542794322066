import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getColor } from 'utils';

import Text from 'components/ui/Text';
import Icon from 'components/ui/Icon';
import SvgIcon from 'components/ui/SvgIcon';
import styles from './CareersGridRow.module.scss';

const iconMap = {
    heart: 'heart',
    checkBadge: 'checkBadge',
    leafPlant: 'leafPlant',
    heartHandshake: 'heartHandshake',
    trophy: 'trophy',
    gift: 'gift',
    fitness: 'fitness',
    calendar: 'calendar',
    health: 'health',
    notebooks: 'notebooks',
    tshirt: 'tshirt',
    butter: 'butter',
    people: 'people',
};

const CareersGridRow = ({ gridRow, theme }) => {
    const gridLength = gridRow.gridRow.length;
    return (
        <div className={styles.gridRow}>
            {gridRow &&
                gridRow.gridRow.map((item, id) => {
                    return (
                        <div
                            className={cx(
                                styles.gridItem,
                                {
                                    [styles.gridThreeItem]: gridLength === 3,
                                },
                                {
                                    [styles.miniGridItem]: theme === 'mini',
                                }
                            )}
                            key={id}
                            style={{
                                backgroundColor: getColor(item.bgColor),
                            }}
                        >
                            <div
                                className={cx(styles.lockup, {
                                    [styles.lockup__white]:
                                        item.bgColor == 'blueMedium',
                                    [styles.lockup__cream]:
                                        item.bgColor == 'blue',
                                })}
                            >
                                {item.icon && (
                                    <div className={styles.iconBox}>
                                        {item.iconType && (
                                            <div
                                                className={cx(
                                                    styles.iconWrapper,
                                                    styles[
                                                        `icon--${
                                                            iconMap[
                                                                item.iconType
                                                            ]
                                                        }`
                                                    ]
                                                )}
                                            >
                                                <Icon
                                                    src={
                                                        item?.icon?.icon?.file
                                                            ?.url
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}

                                {item.iconType && !item.icon && (
                                    <div className={styles.iconBox}>
                                        {item.iconType && (
                                            <div
                                                className={cx(
                                                    styles.iconWrapper,
                                                    styles[
                                                        `icon--${
                                                            iconMap[
                                                                item.iconType
                                                            ]
                                                        }`
                                                    ]
                                                )}
                                            >
                                                <SvgIcon
                                                    iconType={item.iconType}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                                {item.headline && (
                                    <Text
                                        className={cx(styles.headline)}
                                        baseTheme="labelLarge"
                                    >
                                        {item.headline}
                                    </Text>
                                )}

                                {item.copy && (
                                    <Text
                                        className={styles.copy}
                                        baseTheme={'bodySmall'}
                                        themes={{
                                            large: 'bodyMedium',
                                        }}
                                    >
                                        {item.copy}
                                    </Text>
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

CareersGridRow.propTypes = {
    gridRow: PropTypes.object,
    theme: PropTypes.string,
};

export default CareersGridRow;
