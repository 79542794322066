const iconMap = {
    Barn: 'barn',
    Butter: 'butter',
    Calendar: 'calendar',
    Chat: 'chat',
    'Cheese Loaf': 'cheese-loaf',
    Coffee: 'coffee',
    Cow: 'cow',
    Gift: 'gift',
    Hands: 'hands',
    Health: 'health',
    Heart: 'heart',
    Hills: 'hills',
    'Ice Cream Cone': 'ice-cream-cone',
    Learning: 'learning',
    'Milk Jug': 'milk-jug',
    'New Indicator': 'new-indicator',
    People: 'people',
    Quality: 'quality',
    Ribbons: 'ribbons',
    Shirt: 'shirt',
    'Small Batch': 'small-batch',
    Stewards: 'stewards',
    Team: 'team',
    Utensils: 'utensils',
    Volunteer: 'volunteer',
    Weights: 'weights',
    'Yogurt Cup': 'yogurt-cup',
};

export default iconMap;
