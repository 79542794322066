import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './UltraBoldHeading.module.scss';

const UltraBoldHeading = ({ headline, icon, as, ...props }) => {
    const richTextOverrides = {
        renderMark: {
            [MARKS.BOLD]: text => <em>{text}</em>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text as={as} className={styles.paragraph}>
                        {children}
                    </Text>
                );
            },
        },
    };

    if (!headline) {
        return null;
    }
    return (
        <Text {...props} as="div" className={styles.root}>
            {icon && (
                <div className={styles.icon}>
                    <SvgIcon iconType={icon} />
                </div>
            )}
            <RichText richText={headline} overrides={richTextOverrides} />
        </Text>
    );
};

UltraBoldHeading.defaultProps = {
    as: 'h1',
    baseTheme: 'displayMedium',
};

UltraBoldHeading.propTypes = {
    headline: PropTypes.object,
    icon: PropTypes.string,
    as: PropTypes.string,
    baseTheme: PropTypes.string,
    themes: PropTypes.object,
};

export default UltraBoldHeading;
