import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';

import styles from './CardTillamaps.module.scss';

const CardTillamaps = ({ headline, copy, cta, className, icon }) => {
    useEffect(() => {
        if (!headline) {
            dispatchWarning(
                'No headline provided. Headline required for render.'
            );
        }

        if (!cta?.link || !cta?.text) {
            dispatchWarning('No CTA provided. CTA required for render.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (headline && cta?.link && cta?.text) {
        return (
            <div className={cx(styles.card, className)}>
                <div className={styles.cardInner}>
                    {icon?.icon?.file?.url && (
                        <div className={styles.iconWrapper}>
                            <Icon src={icon.icon.file.url} />
                        </div>
                    )}
                    <Text className={styles.headline} baseTheme="headingMedium">
                        {headline}
                    </Text>
                    <Text className={styles.copy} baseTheme="bodyMedium">
                        {copy}
                    </Text>
                    <div className={styles.ctaWrapper}>
                        <Button theme="outlinedCream" entry={cta.link}>
                            {cta.text}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

CardTillamaps.propTypes = {
    headline: PropTypes.string.isRequired,
    copy: PropTypes.string,
    cta: PropTypes.shape({
        link: PropTypes.object.isRequired,
        text: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
    icon: PropTypes.object,
};

CardTillamaps.defaultProps = {
    className: '',
    icon: null,
};

export default CardTillamaps;
