import { useRouter } from 'next/router';
import querystate from 'querystate';

function useQueryState() {
    const router = useRouter();

    const state = querystate(
        typeof window === 'undefined'
            ? router.asPath.split('?')[1] || '?'
            : window?.location?.search,
        {
            autoApply: false,
        }
    );

    return [
        state.all(),
        obj => {
            state.set(obj);
            Object.keys(state.all()).forEach(key => {
                if (!obj[key]) {
                    state.remove(key);
                }
            });
            state.apply();
        },
    ];
}

export default useQueryState;
