import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { useTheme } from 'hooks/useTheme';
import Img from 'components/ui/Img';

import RichText, { BLOCKS } from 'components/ui/RichText';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';
import Button from 'components/ui/Button';

import styles from './CoverSlide.module.scss';

const richTextCopy = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodySmall',
                    }}
                    className={styles.copy}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const CoverSlide = ({
    eyebrow,
    headline,
    copy,
    portraitImage,
    landscapeImage,
    onClickStart,
    textColor,
}) => {
    const [globalTheme] = useTheme();

    const eyebrowColorMap = {
        default: {
            eyebrow: textColor,
            headline: textColor,
        },
        'makers-reserve': {
            eyebrow: 'makersReserveGoldAlt',
            headline: 'makersReserveOffBlack',
        },
    };

    return (
        <div
            style={{ '--textColor': `var(--colors-${textColor})` }}
            className={cx(styles.root, styles[`theme--${globalTheme}`])}
        >
            {landscapeImage?.file?.url &&
                landscapeImage?.file?.details?.image &&
                portraitImage?.file?.url &&
                portraitImage?.file?.details?.image && (
                    <div className={styles.image}>
                        <Img
                            src={portraitImage.file.url}
                            fallbackImageWidth={768}
                            alt={portraitImage.description || ''}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: landscapeImage.file.url,
                                    imageWidth: parseInt(1920 * 1.5),
                                },
                                {
                                    breakpoint: 1440,
                                    src: landscapeImage.file.url,
                                    imageWidth: parseInt(1440 * 1.5),
                                },
                                {
                                    breakpoint: 1024,
                                    src: landscapeImage.file.url,
                                    imageWidth: parseInt(1024 * 1.5, 10),
                                },
                                {
                                    breakpoint: 768,
                                    src: portraitImage.file.url,
                                    imageWidth: parseInt(768 * 1.5),
                                },
                                {
                                    src: portraitImage.file.url,
                                    imageWidth: parseInt(767 * 1.5),
                                },
                            ]}
                        />
                    </div>
                )}
            <div className={styles.inner}>
                <TextEyebrow
                    eyebrow={eyebrow}
                    headline={headline}
                    theme={globalTheme === 'default' ? 'ultrabold' : 'default'}
                    eyebrowColor={eyebrowColorMap[globalTheme]?.eyebrow}
                    headlineColor={eyebrowColorMap[globalTheme]?.headline}
                    icon="none"
                />
                <RichText richText={copy} overrides={richTextCopy} />
                <Button
                    className={styles.cta}
                    theme={
                        globalTheme === 'default'
                            ? 'filledBlue'
                            : 'filledGoldReserveCream'
                    }
                    onClick={onClickStart}
                >
                    See Pairings
                </Button>
            </div>
        </div>
    );
};

CoverSlide.defaultProps = {
    eyebrow: 'pairings',
};

CoverSlide.propTypes = {
    eyebrow: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    copy: PropTypes.string.isRequired,
    portraitImage: PropTypes.object.isRequired,
    landscapeImage: PropTypes.object.isRequired,
    onClickStart: PropTypes.func.isRequired,
    textColor: PropTypes.string.isRequired,
};

export default CoverSlide;
