import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import carouselContext from 'components/ui/CarouselProvider/CarouselContext';
import { MODE } from 'components/ui/CarouselProvider/carouselConstants';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import ButtonCircle from 'components/ui/ButtonCircle';
import Stagger from 'components/ui/Stagger';
import CarouselHeroSlide from './CarouselHeroSlide';

import styles from './BlockCarouselHero.module.scss';

const { PAUSED, PLAYING, STOPPED } = MODE;

const CarouselHero = () => {
    const cc = useContext(carouselContext);

    const dashLength = 90;
    const increment = dashLength / cc.timerDuration;
    const strokeDashoffset = dashLength - cc.seconds * increment;

    const [isShowInitial, setIsShowInitial] = useState(false);

    useEffect(() => {
        if (cc.activeIndex === 0 && !isShowInitial) {
            setIsShowInitial(true);
        }
    }, [cc.activeIndex, isShowInitial]);

    return (
        <>
            <section
                className={cx(styles.root, {
                    [styles.isContinuous]: cc.isContinuous,
                    [styles[PLAYING]]: cc.mode === PLAYING,
                    [styles[PAUSED]]: cc.mode === PAUSED,
                    [styles.isSwitching]: cc.isContinuous
                        ? cc.seconds === 0 && !cc.whileInterrupt
                        : cc.seconds === 0 && cc.mode !== STOPPED,
                })}
            >
                <div className={styles.inner}>
                    {cc.slides &&
                        cc.slides.map((slide, i) => {
                            return (
                                <CarouselHeroSlide
                                    key={i}
                                    isActive={cc.activeIndex === i}
                                    isPrev={cc.prevIndex === i}
                                    timerDuration={cc.timerDuration}
                                    isShowInitial={isShowInitial}
                                    {...slide}
                                />
                            );
                        })}
                    <div className={styles.column}>
                        <Stagger className={styles.ctaLockup}>
                            <Stagger.Child
                                order={5}
                                className={styles.buttonWrapper}
                            >
                                {cc.slides?.[cc.activeIndex]?.cta?.text &&
                                    cc.slides?.[cc.activeIndex]?.cta?.link && (
                                        <Button
                                            className={styles.cta}
                                            theme="filledOrangeCreamText"
                                            entry={
                                                cc.slides[cc.activeIndex].cta
                                                    .link
                                            }
                                        >
                                            {cc.slides[cc.activeIndex].cta.text}
                                        </Button>
                                    )}
                            </Stagger.Child>
                            <div className={styles.nav}>
                                <ButtonCircle
                                    onClick={cc.prevSlide}
                                    className={styles.navItem}
                                    theme="chevronLeftCream"
                                >
                                    <span className="sr-only">
                                        Previous Slide
                                    </span>
                                </ButtonCircle>
                                <Text
                                    baseTheme="labelSmall"
                                    className={styles.navCount}
                                >
                                    {`${cc.activeIndex + 1}/${cc.totalSlides}`}
                                </Text>
                                <ButtonCircle
                                    onClick={cc.nextSlide}
                                    className={styles.navItem}
                                    theme="chevronRightCream"
                                >
                                    <span className="sr-only">Next Slide</span>
                                    <span className={styles.navItemAnimation}>
                                        <svg
                                            width="31px"
                                            height="31px"
                                            viewBox="0 0 31 31"
                                        >
                                            <circle
                                                style={{
                                                    strokeDashoffset,
                                                }}
                                                className={styles.strokeCircle}
                                                fill="none"
                                                stroke="var(--colors-blue)"
                                                strokeWidth="2"
                                                cx="15.5"
                                                cy="15.5"
                                                r="14.5"
                                                strokeDasharray="90"
                                                strokeDashoffset="90"
                                                strokeMiterlimit="0"
                                            />
                                        </svg>
                                    </span>
                                </ButtonCircle>
                            </div>
                        </Stagger>
                    </div>
                </div>
            </section>
        </>
    );
};

CarouselHero.propTypes = {
    timerDuration: PropTypes.number,
    slides: PropTypes.array,
    className: PropTypes.string,
};

CarouselHero.defaultProps = {
    timerDuration: 7,
};

export default CarouselHero;
