import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const SearchContext = createContext(null);

export const SearchProvider = ({ children }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [termSearched, setTermSearched] = useState('');
    const [searchEnabled, setSearchEnabled] = useState(false);

    const store = useMemo(
        () => ({
            searchTerm: [searchTerm, setSearchTerm],
            searchEnabled: [searchEnabled, setSearchEnabled],
            termSearched: [termSearched, setTermSearched],
        }),
        [searchTerm, searchEnabled, termSearched]
    );

    return (
        <SearchContext.Provider value={store}>
            {children}
        </SearchContext.Provider>
    );
};

export default SearchProvider;

SearchProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
