import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Img from 'components/ui/Img';
import Text from 'components/ui/Text';
import RichText from 'components/ui/RichText';

import styles from './BlockTimeline.module.scss';

const TimelineItem = ({
    headline,
    copy,
    year,
    image,
    imageLarge,
    className,
}) => {
    return (
        <article className={cx(styles.timelineItem, className)}>
            <div className={styles.column}>
                {image?.file?.url &&
                    image?.file?.details?.image &&
                    imageLarge?.file?.url &&
                    imageLarge?.file?.details?.image && (
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            fallbackImageWidth={500}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: imageLarge.file.url,
                                    imageWidth:
                                        imageLarge.file.details.image.width,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: image.file.details.image.width,
                                },
                            ]}
                        />
                    )}
                {image?.file?.url &&
                    image?.file?.details?.image &&
                    !imageLarge && (
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            fallbackImageWidth={500}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    src: image.file.url,
                                    imageWidth: image.file.details.image.width,
                                },
                            ]}
                        />
                    )}
            </div>
            <div className={styles.column}>
                <div className={styles.text}>
                    <Text
                        as="h3"
                        baseTheme="headingSmall"
                        themes={{ large: 'headingMedium' }}
                        className={styles.headline}
                    >
                        {headline}
                        <div className={styles.year}>
                            <Text
                                as="h5"
                                baseTheme="labelLarge"
                                className={styles.yearInner}
                            >
                                {year}
                            </Text>
                        </div>
                    </Text>
                    <div className={styles.copy}>
                        <RichText richText={copy} />
                    </div>
                </div>
            </div>
        </article>
    );
};

TimelineItem.propTypes = {
    headline: PropTypes.string,
    copy: PropTypes.object,
    year: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    className: PropTypes.string,
};

export default TimelineItem;
