import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGet } from 'hooks/useFetch';

const Animation = ({ index, animationUrl }) => {
    const animationsContainerRef = useRef([]);
    const animationRef = useRef();
    const response = useGet(`https:${animationUrl}`);

    useEffect(() => {
        const name = `step${index}`;
        let lottie;
        const load = async () => {
            if (!response?.data) {
                return null;
            }
            // Lazy load animation library
            lottie = await import(
                /* webpackPrefetch: true, webpackChunkName: 'lottie-web' */ 'lottie-web'
            );

            animationRef.current = lottie.loadAnimation({
                container: animationsContainerRef.current, // the dom element
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: response.data, // the animation data
                name: name,
            });
        };

        load();

        return () => {
            animationRef?.current?.destroy(); // clean up
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response?.data]);

    return (
        <figure
            style={{ width: '100%', height: '100%' }}
            ref={animationsContainerRef}
        ></figure>
    );
};

Animation.propTypes = {
    index: PropTypes.number.isRequired,
    animationUrl: PropTypes.string.isRequired,
};

export default Animation;
