import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'components/ui/Button';
import Text from 'components/ui/Text';
import styles from './BlockFeaturedArticle.module.scss';
import Img from 'components/ui/Img';

const BlockFeaturedArticle = ({
    eyebrow,
    headline,
    image,
    imageLarge,
    cta,
    flushTop,
    flushBottom,
    className,
}) => {
    return (
        <section
            className={cx(styles.root, className, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.content}>
                    <div className={styles.background}>
                        {image?.file?.url && imageLarge?.file?.url && (
                            <Img
                                className={styles.image}
                                src={image.file.url}
                                alt={image.description || ''}
                                customSources={[
                                    {
                                        breakpoint: 1920,
                                        src: imageLarge.file.url,
                                        imageWidth: 1920 * 1.5,
                                    },
                                    {
                                        breakpoint: 1440,
                                        src: imageLarge.file.url,
                                        imageWidth: 1440 * 1.5,
                                    },
                                    {
                                        breakpoint: 1024,
                                        src: imageLarge.file.url,
                                        imageWidth: 1024 * 1.5,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: imageLarge.file.url,
                                        imageWidth: 768 * 1.5,
                                    },
                                    {
                                        src: image.file.url,
                                        imageWidth: 768,
                                    },
                                ]}
                            />
                        )}
                        {image?.file?.url && !imageLarge && (
                            <Img
                                className={styles.image}
                                src={image.file.url}
                                alt={image.description || ''}
                                customSources={[
                                    {
                                        breakpoint: 1920,
                                        src: image.file.url,
                                        imageWidth: 1920 * 1.5,
                                    },
                                    {
                                        breakpoint: 1440,
                                        src: image.file.url,
                                        imageWidth: 1440 * 1.5,
                                    },
                                    {
                                        breakpoint: 1024,
                                        src: image.file.url,
                                        imageWidth: 1024 * 1.5,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: image.file.url,
                                        imageWidth: 768 * 1.5,
                                    },
                                    {
                                        src: image.file.url,
                                        imageWidth: 768,
                                    },
                                ]}
                            />
                        )}
                    </div>
                    <div className={styles.foreground}>
                        <Text
                            className={styles.eyebrow}
                            baseTheme="labelSmall"
                            themes={{ large: 'labelLarge' }}
                        >
                            {eyebrow}
                        </Text>
                        <Text
                            className={styles.headline}
                            baseTheme="displaySmall"
                            themes={{ large: 'displayLarge' }}
                        >
                            {headline?.content?.map((line, i) => {
                                if (line.content[0].value === '') {
                                    return null;
                                }
                                return (
                                    <span className={styles.line} key={i}>
                                        {line.content[0].value}
                                    </span>
                                );
                            })}
                        </Text>
                        <Button
                            className={styles.cta}
                            theme="outlinedCream"
                            entry={cta?.link}
                        >
                            {cta?.text}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockFeaturedArticle.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    cta: PropTypes.shape({
        link: PropTypes.object,
        text: PropTypes.string,
        assetDownloadLink: PropTypes.bool,
    }),
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    className: PropTypes.string,
};

BlockFeaturedArticle.defaultProps = {
    flushTop: true,
    flushBottom: false,
};

export default BlockFeaturedArticle;
