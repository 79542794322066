import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { BLOCKS } from '@contentful/rich-text-types';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Stagger from 'components/ui/Stagger';
import RichText from 'components/ui/RichText';

import styles from './BlockTwoColumnText.module.scss';

const BlockTwoColumnText = ({
    eyebrow,
    richHeadline,
    bodyCopy,
    tip,
    cta,
    className,
    theme,
}) => {
    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        className={styles.paragraph}
                        baseTheme="bodyMedium"
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return (
                    <Text
                        as="h3"
                        className={styles.heading3}
                        baseTheme="headingSmall"
                        themes={{
                            medium:
                                theme === 'large'
                                    ? 'headingMedium'
                                    : 'headingLarge',
                        }}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };
    return (
        <section className={cx(styles.twoColumnTextFlex, className)}>
            <div className={styles.twoColumnText}>
                {richHeadline && (
                    <div
                        className={cx(styles.column, {
                            [styles.largeHeading]: theme === 'large',
                        })}
                    >
                        <Stagger className={styles.richEyebrow}>
                            {eyebrow && (
                                <Stagger.Child
                                    order={0}
                                    className={styles.eyebrow}
                                >
                                    <Text
                                        baseTheme={'labelSmall'}
                                        themes={{ medium: 'labelLarge' }}
                                        as="h3"
                                    >
                                        {eyebrow}
                                    </Text>
                                </Stagger.Child>
                            )}
                            <Stagger.Child
                                order={1}
                                className={styles.headline}
                            >
                                <Text
                                    as="h2"
                                    baseTheme={
                                        theme === 'large'
                                            ? 'displayLarge'
                                            : 'displaySmall'
                                    }
                                    themes={
                                        theme === 'small'
                                            ? {
                                                  medium: 'displayMedium',
                                              }
                                            : theme === 'large'
                                            ? {
                                                  large: 'displayXLarge',
                                              }
                                            : {
                                                  medium: 'displayLarge',
                                              }
                                    }
                                >
                                    {richHeadline.content.map((line, i) => {
                                        if (line.content[0].value === '') {
                                            return null;
                                        }
                                        return (
                                            <span
                                                className={styles.line}
                                                key={i}
                                            >
                                                {line.content[0].value}
                                            </span>
                                        );
                                    })}
                                </Text>
                            </Stagger.Child>
                            <Stagger.ArrowRight
                                theme="smallMobile"
                                order={2}
                                className={styles.iconWrapper}
                            />
                        </Stagger>
                    </div>
                )}
                <div
                    className={cx(styles.column, {
                        [styles.largeHeading]: theme === 'large',
                    })}
                >
                    <div className={styles.columnInner}>
                        {bodyCopy && (
                            <RichText
                                richText={bodyCopy}
                                overrides={richTextOverrides}
                            />
                        )}
                        {tip && (
                            <div className={styles.tillatipFlex}>
                                <div className={styles.tillatip}>
                                    <div className={styles.tillatipIcon}>
                                        <SvgIcon iconType="lightbulb" />
                                    </div>
                                    <Text
                                        className={styles.tillatipLabel}
                                        baseTheme="labelSmall"
                                    >
                                        Tilla Tip:
                                    </Text>
                                    <Text baseTheme="bodyXSmall"> {tip}</Text>
                                </div>
                            </div>
                        )}
                        {cta && (
                            <div className={styles.buttonWrapper}>
                                {cta.assetDownloadLink ? (
                                    <Button
                                        download
                                        className={styles.cta}
                                        theme="outlinedBlue"
                                        iconLeading="download"
                                        href={cta.assetDownloadLink.file?.url}
                                    >
                                        {cta.text}
                                    </Button>
                                ) : (
                                    <Button
                                        className={styles.cta}
                                        theme="outlinedBlue"
                                        entry={cta.link}
                                    >
                                        {cta.text}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockTwoColumnText.propTypes = {
    eyebrow: PropTypes.string,
    richHeadline: PropTypes.object.isRequired,
    bodyCopy: PropTypes.object,
    tip: PropTypes.string,
    cta: PropTypes.shape({
        link: PropTypes.object,
        text: PropTypes.string,
        assetDownloadLink: PropTypes.bool,
    }),
    className: PropTypes.string,
    theme: PropTypes.string,
};

BlockTwoColumnText.defaultProps = {
    theme: 'default',
};

export default BlockTwoColumnText;
