import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import Img from 'components/ui/Img';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import RichText from 'components/ui/RichText';
import UltraBoldHeading from 'components/ui/UltraBoldHeading';

import styles from './BlockHeroSplit.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    className={styles.description}
                    as="p"
                    baseTheme="headingXSmall"
                    themes={{ large: 'headingSmall' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockHeroSplit = ({
    headline,
    description,
    svgLockup,
    svgLockupSeoText,
    ctaButton,
    customHex,
    image,
    imageLarge,
    imageOnLeft,
    className,
}) => {
    return (
        <div
            className={cx(styles.root, className, {
                [styles.imageOnLeft]: imageOnLeft,
                [styles.hasDescription]: !!description,
            })}
        >
            <div className={styles.inner}>
                <div
                    className={styles.column}
                    style={{ backgroundColor: `#${customHex}` }}
                >
                    <div className={styles.aspect}>
                        <div className={styles.aspectInner}>
                            {headline &&
                                typeof headline === 'object' &&
                                !svgLockup && (
                                    <UltraBoldHeading
                                        baseTheme={'displayMedium'}
                                        themes={{
                                            medium: 'displayLarge',
                                            large: 'displayXLarge',
                                            xxxLarge: 'displayXXLarge',
                                        }}
                                        icon={'morningStar'}
                                        headline={headline}
                                    />
                                )}

                            {svgLockup && !headline && (
                                <div
                                    className={styles.svgLockup}
                                    style={{
                                        backgroundImage: `url(${svgLockup.file.url})`,
                                    }}
                                >
                                    {svgLockupSeoText && (
                                        <span className="sr-only">
                                            {svgLockupSeoText}
                                        </span>
                                    )}
                                </div>
                            )}
                            {description && (
                                <RichText
                                    overrides={richTextOverrides}
                                    richText={description}
                                />
                            )}
                            {(ctaButton?.cta?.link ||
                                ctaButton?.cta?.assetDownloadLink) && (
                                <div className={styles.ctaWrapper}>
                                    <Button
                                        className={styles.cta}
                                        theme={ctaButton.theme}
                                        entry={ctaButton.cta.link}
                                        iconLeading={ctaButton.iconType}
                                    >
                                        {ctaButton.cta.text}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.aspect}>
                        {image?.file?.url &&
                            image?.file?.details?.image &&
                            imageLarge?.file?.url &&
                            imageLarge?.file?.details?.image && (
                                <Img
                                    className={styles.image}
                                    src={image?.file.url}
                                    fallbackImageWidth={297 * 2}
                                    alt={image?.description || ''}
                                    customSources={[
                                        {
                                            breakpoint: 1920,
                                            src: imageLarge?.file.url,
                                            imageWidth: 1920 * 1.5,
                                        },
                                        {
                                            breakpoint: 1440,
                                            src: imageLarge?.file.url,
                                            imageWidth: 1440 * 1.5,
                                        },
                                        {
                                            breakpoint: 1024,
                                            src: imageLarge?.file.url,
                                            imageWidth: 1024 * 1.5,
                                        },
                                        {
                                            breakpoint: 768,
                                            src: imageLarge?.file.url,
                                            imageWidth: 720 * 2,
                                        },
                                        {
                                            src: image?.file.url,
                                            imageWidth: 297 * 2,
                                        },
                                    ]}
                                />
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

BlockHeroSplit.propTypes = {
    headline: PropTypes.object,
    description: PropTypes.object,
    svgLockup: PropTypes.object,
    svgLockupSeoText: PropTypes.string,
    customHex: PropTypes.string,
    ctaButton: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageOnLeft: PropTypes.bool,
    className: PropTypes.string,
};

BlockHeroSplit.defaultProps = {
    imageOnLeft: true,
    customHex: 'F6F4E0', // cream
    description: null,
};

export default BlockHeroSplit;
