import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useMedia, useBoolean } from 'react-use';

import CreamCheeseProfile from './CreamCheeseProfile';
import CreamCheeseProfileBackground from './CreamCheeseProfileBackground';

import { profiles } from '../cream-cheese-profiles';

import styles from './BlockCreamCheeseProfiles.module.scss';

const BlockCreamCheeseProfiles = ({ showProductId }) => {
    const isMediumQuery = useMedia('(min-width: 768px)');
    const [isMedium, setIsMedium] = useBoolean(false); // use-media bug, need state reinforcement

    const [activeProductId, setActiveProductId] = useState(false);

    const activeProduct = profiles.find(item => item.id === activeProductId);

    useEffect(() => {
        setIsMedium(isMediumQuery);
    }, [isMediumQuery, setIsMedium]);

    const handleInView = id => {
        setActiveProductId(id);
    };

    const scrollToProduct = useCallback(productId => {
        document.getElementById(productId)?.scrollIntoView();
    }, []);

    useEffect(() => {
        scrollToProduct(showProductId);
    }, [showProductId, scrollToProduct]);

    return (
        <div className={styles.root}>
            <CreamCheeseProfileBackground activeProduct={activeProduct} />
            {profiles?.map((profile, i) => {
                return (
                    <CreamCheeseProfile
                        key={i}
                        {...profile}
                        isInView={handleInView}
                        isMedium={isMedium}
                    />
                );
            })}
        </div>
    );
};

BlockCreamCheeseProfiles.propTypes = {
    showProductId: PropTypes.string,
};

export default BlockCreamCheeseProfiles;
