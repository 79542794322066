import React, { Fragment, useState, useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';

import Fuse from 'fuse.js';

import CardProduct from 'components/cards/CardProduct';
import CardNoResults from 'components/cards/CardNoResults';

import FilterBody from 'components/ui/FilterBody/';

import useQueryState from 'hooks/useQueryState';

import styles from './BlockProductList.module.scss';

const BlockProductList = ({
    products: dirtyProducts,
    theme,
    headline,
    eyebrow,
    useCategorySort,
}) => {
    const [state, setState] = useQueryState();

    const [search, setSearch] = useState('');

    const products = useMemo(() => {
        const ids = [];
        const dedupedProducts = dirtyProducts.filter(product => {
            if (ids.includes(product.id)) {
                console.warn(`Duplicate product "${product.displayName}"`);
                return false;
            }
            ids.push(product.id);
            return true;
        });

        return useCategorySort
            ? dedupedProducts.sort((a, b) => {
                  return a.order - b.order;
              })
            : dedupedProducts;
    }, [dirtyProducts, useCategorySort]);

    const searcher = useMemo(() => {
        return new Fuse(products, {
            keys: ['displayName', 'pageTitle', 'searchKeywords'],
            shouldSort: false,
        });
    }, [products]);

    // Run though all products to build a list of available filters
    const filters = useMemo(() => {
        return products.reduce((o, product) => {
            Object.entries(product.attributes || {}).forEach(([key, value]) => {
                const categoryAttribute =
                    product.category.attributes[key] || false;

                if (!categoryAttribute) {
                    return;
                }

                const options = categoryAttribute.options.filter(filter => {
                    if (categoryAttribute.type === 'multiple') {
                        return [...value].includes(`${filter.id}`);
                    } else {
                        return `${filter.id}` === `${value}`;
                    }
                });

                if (!options.length === 0) {
                    console.warn(
                        `Category attribute value of \`${value}\` not found attribute in ${key}: ${product.displayName}`
                    );
                    return;
                }

                const optionObj = options.reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});

                o[key] = {
                    ...categoryAttribute,
                    options: {
                        ...((o[key] && o[key].options) || {}),
                        ...optionObj,
                    },
                };
            });

            return o;
        }, {});
    }, [products]);

    const [filtered, setFiltered] = useState(state);

    const handleSearch = useCallback(evt => {
        setSearch(evt.target.value);
    }, []);

    const handleClear = () => {
        setSearch('');
        setFiltered({});
        setState({});
    };

    const productsSearched =
        search.length === 0
            ? products
            : searcher.search(search).map(product => product.item);

    const productsFiltered = productsSearched.filter(product => {
        if (Object.keys(state).length === 0) {
            return true;
        }
        return Object.keys(state).every(attrKey => {
            if (!product?.attributes) {
                return false;
            }
            const option = product.category?.attributes[attrKey]?.options.find(
                option => option.slug == state[attrKey]
            );
            if (Array.isArray(product.attributes[attrKey])) {
                return product.attributes[attrKey].includes(`${option?.id}`);
            }
            return `${product.attributes[attrKey]}` === `${option?.id}`;
        });
    });

    const handleChangeFilter = key => event => {
        const state = {
            ...filtered,
            [key]: event.currentTarget.value,
        };
        if (event.currentTarget.value === '') {
            delete state[key];
        }
        setState(state);
        setFiltered(state);
    };

    const isNoResults = productsFiltered.length === 0;

    return (
        <Fragment>
            <FilterBody
                onSearch={handleSearch}
                onClear={handleClear}
                showClear={
                    search.length > 0 || Object.keys(filtered).length > 0
                }
                theme={theme}
                headline={headline}
                eyebrow={eyebrow}
            >
                {Object.keys(filters).map(key => {
                    return Object.keys(filters[key].options).length >= 2 ? (
                        <FilterBody.Select
                            key={`${key}-select`}
                            filters={filters}
                            filtered={filtered}
                            keyValue={key}
                            label={filters[key].label}
                            value={filtered[key] || ''}
                            onChange={handleChangeFilter(key)}
                        />
                    ) : null;
                })}
            </FilterBody>
            <ul className={styles.productList}>
                {productsFiltered.map(product => {
                    if (!product.category || !product.slug) {
                        // These are an absolute must
                        return null;
                    }
                    return (
                        <li className={styles.listItem} key={product.id}>
                            <CardProduct
                                color={product.color}
                                category={product.category}
                                key={product.id}
                                eyebrow={product.eyebrow}
                                image={
                                    theme === 'foodservice'
                                        ? product.cardImage
                                        : product.image
                                }
                                displayName={product.displayName}
                                slug={product.slug}
                                theme={product.theme}
                            />
                        </li>
                    );
                })}
                <li key="end-card" className={styles.listItem}>
                    {isNoResults && <CardNoResults search={search} />}
                </li>
            </ul>
        </Fragment>
    );
};

BlockProductList.propTypes = {
    products: PropTypes.array,
    theme: PropTypes.string.isRequired,
    headline: PropTypes.string,
    eyebrow: PropTypes.string,
    useCategorySort: PropTypes.bool,
};

BlockProductList.defaultProps = {
    theme: 'default',
    products: [],
    useCategorySort: false,
};

export default BlockProductList;
