import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useIntersection } from 'react-use';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Img from 'components/ui/Img';

import styles from './CreamCheeseProfile.module.scss';

const CreamCheeseProfile = ({ id, label, detail, isInView, isMedium }) => {
    const inViewRef = useRef(null);

    const ratio = isMedium ? 0.6 : 0.4;

    const intersection = useIntersection(inViewRef, {
        root: null,
        rootMargin: '0px',
        threshold: ratio,
    });

    useEffect(() => {
        if (
            intersection &&
            intersection.isIntersecting &&
            intersection.intersectionRatio > ratio
        ) {
            isInView(id);
        }
    }, [intersection, isInView, ratio, id]);

    return (
        <div ref={inViewRef} className={styles.root} id={id}>
            <div className={styles.inner}>
                <div className={styles.column}>
                    <div className={styles.aspect}>
                        {detail.image && (
                            <Img
                                className={styles.image}
                                src={detail.image}
                                fallbackImageWidth={768}
                                alt={''}
                                customSources={[
                                    {
                                        breakpoint: 1440,
                                        src: detail.image,
                                        imageWidth: 900 * 1.5,
                                    },
                                    {
                                        breakpoint: 1024,
                                        src: detail.image,
                                        imageWidth: 700 * 1.5,
                                    },
                                    {
                                        src: detail.image,
                                        imageWidth: 375 * 1.5,
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.content}>
                        <Text
                            className={styles.label}
                            baseTheme="displaySmall"
                            themes={{
                                large: 'displayMedium',
                            }}
                            fixWidows
                        >
                            {label}
                        </Text>
                        <Text className={styles.info} baseTheme="labelSmall">
                            <span>
                                {detail.distance}
                                <span className={styles.pipe}>|</span>
                            </span>
                            <span>
                                {detail.occupation}
                                <span className={styles.pipe}>|</span>
                            </span>
                            <span>{detail.height}</span>
                        </Text>
                        <Text
                            className={styles.bio}
                            baseTheme="bodySmall"
                            themes={{ large: 'bodyMedium' }}
                        >
                            {detail.bio}
                        </Text>
                        <div className={styles.interests}>
                            <Text
                                className={styles.interestsLabel}
                                baseTheme="labelSmall"
                            >
                                Interests
                            </Text>
                            <div className={styles.interestsList}>
                                {detail.interests.map((item, i) => {
                                    return (
                                        <Text
                                            key={i}
                                            className={styles.interest}
                                            baseTheme="bodyXSmall"
                                        >
                                            <span>{item}</span>
                                        </Text>
                                    );
                                })}
                            </div>
                        </div>
                        {detail.ctaLink && (
                            <Button
                                className={styles.cta}
                                theme="filledBlueHoverCream-ultrabold"
                                href={detail.ctaLink}
                            >
                                Meet me on Tillamaps
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

CreamCheeseProfile.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    color: PropTypes.string,
    detail: PropTypes.object,
    isInView: PropTypes.func,
    isMedium: PropTypes.bool,
};

export default CreamCheeseProfile;
