import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import CountUp from 'react-countup';
import styles from './Graph.module.scss';

const Bar = ({ inView, i, chartItem }) => {
    const [countUp, setCountUp] = useState(false);
    const animateCount = number => {
        return (
            <CountUp
                end={number}
                duration={2}
                startOnMount={false}
                decimals={1}
                start={countUp ? 0 : null}
            >
                {({ countUpRef }) => (
                    <div className={styles.countUpHeadline}>
                        <span ref={countUpRef} />
                        <span>%</span>
                    </div>
                )}
            </CountUp>
        );
    };
    return (
        <div className={cx(styles.barWrapper, styles[`barWrapper${i + 1}`])}>
            <div
                className={cx(styles.chartBar, {
                    [styles.animate]: inView,
                })}
                style={{
                    '--animation-order': i + 1,
                }}
                onAnimationStart={() => setCountUp(true)}
            >
                <div className={styles.percentWrapper}>
                    <Text
                        className={styles.percentage}
                        baseTheme={'displayXSmall'}
                        themes={{
                            large: 'displaySmall',
                        }}
                    >
                        {animateCount(chartItem.percentage)}
                    </Text>
                </div>
            </div>
        </div>
    );
};

Bar.propTypes = {
    i: PropTypes.number,
    inView: PropTypes.bool,
    chartItem: PropTypes.object,
};
export default Bar;
