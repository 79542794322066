import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './BlockCommitmentHighlights.module.scss';
import TextEyebrow from 'components/ui/TextEyebrow';
import Highlight from './Highlight';
import { useIntersection } from 'react-use';

const BlockCommitmentHighlights = ({ eyebrow, headline, highlights }) => {
    const blockRef = useRef(null);
    const [inView, setInview] = useState(false);

    const intersection = useIntersection(blockRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    useEffect(() => {
        setInview(!!intersection?.isIntersecting);
    }, [intersection]);

    return (
        <div className={styles.highlightBlock}>
            <div className={styles.eyebrowBlock}>
                <TextEyebrow
                    className={styles.eyebrow}
                    eyebrow={eyebrow}
                    headline={headline}
                    icon="arrowRight"
                    theme="display"
                />
            </div>
            <div className={styles.highlights} ref={blockRef}>
                {highlights &&
                    highlights.map((highlight, id) => {
                        return (
                            <Highlight
                                key={id}
                                bodyText={highlight.bodyText}
                                headline={highlight.headline}
                                image={highlight.image}
                                inView={inView}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

BlockCommitmentHighlights.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string.isRequired,
    highlights: PropTypes.array,
    className: PropTypes.string,
};

export default BlockCommitmentHighlights;
