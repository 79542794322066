import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { widowKiller } from 'utils';

import TextThemed from './TextThemed';

import styles from './Text.module.scss';

const processNewlineBreaks = children => fixWidows => {
    const setLineBreak = (e, i, last) => {
        return i < children.split('\n').length - 1
            ? [e, <br key={e} />]
            : `${e}${last ? '' : ' '}`;
    };

    switch (typeof children) {
        case 'string':
            if (children?.trim() == '') {
                return null;
            }

            return (
                <Fragment key={children}>
                    {fixWidows
                        ? widowKiller(children)
                              .split('\n')
                              .map((item, i) =>
                                  setLineBreak(
                                      item,
                                      i,
                                      i + 1 === children.length
                                  )
                              )
                        : children
                              .split('\n')
                              .map((item, i) =>
                                  setLineBreak(
                                      item,
                                      i,
                                      i + 1 === children.length
                                  )
                              )}
                </Fragment>
            );
        case 'object':
            if (Array.isArray(children)) {
                return children.map((child, idx) => {
                    // Avoid adding double space before hyperlinked text
                    if (children[idx + 1]?.props?.href) {
                        return child;
                    }

                    return processNewlineBreaks(child)(fixWidows);
                });
            }
            return children;
        default:
            return children;
    }
};

const Text = ({
    as: Tag,
    baseTheme,
    themes,
    className,
    children,
    fixWidows,
    ...otherProps
}) => {
    if (children === '') {
        return null;
    }
    if (Array.isArray(children) && children.every(child => child === '')) {
        return null;
    }

    const childrenWithBreaks = processNewlineBreaks(children)(fixWidows);

    const themesArray = [];

    if (themes) {
        Object.keys(themes).map(key => {
            themesArray.push(styles[`${key}--${themes[key]}`]);
        });
    }

    return (
        <Tag
            {...otherProps}
            className={cx(
                styles[`base--${baseTheme}`] || '',
                ...themesArray,
                className
            )}
        >
            {childrenWithBreaks}
        </Tag>
    );
};

Text.propTypes = {
    as: PropTypes.string,
    baseTheme: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    fixWidows: PropTypes.bool,
    themes: PropTypes.object,
};

Text.defaultProps = {
    as: 'span',
    className: null,
    fixWidows: false,
};

Text.Theme = TextThemed;

export default Text;
