import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Block from 'components/blocks/';

import TextLockup from './TextLockup';

import styles from './BlockHeroTwoColumn.module.scss';

const BlockHeroTwoColumn = ({
    eyebrow,
    headline,
    richHeadline,
    bodyCopy,
    cta,
    ctaButton,
    image,
    imageLarge,
    theme,
    flushTop,
    icon,
    hasArrowIcon,
}) => {
    const textLockupProps = {
        eyebrow,
        headline,
        richHeadline,
        bodyCopy,
        cta,
        ctaButton,
        theme,
        hasArrowIcon,
        icon,
    };

    const snackImageProps = {
        image,
        imageLarge,
        priority: true,
        flushTop: true,
        flushBottom: true,
        fullWidth: true,
    };

    return (
        <section
            className={cx(styles.root, {
                [styles.reversed]: true,
                [styles.flushTop]: flushTop,
                [styles.hasRichHeadline]: richHeadline,
                [styles.holidayBlue]: theme === 'Holiday Blue',
                [styles.holidayRust]: theme === 'Holiday Rust',
                [styles.holidaySquash]: theme === 'Holiday Squash',
                [styles.tillamookBlue]: theme === 'Tillamook Blue',
            })}
        >
            <div className={styles.inner}>
                <div className={styles.column}>
                    <Block {...snackImageProps} contentTypeId="blockImage" />
                </div>
                <div className={styles.column}>
                    <div className={styles.columnInner}>
                        <TextLockup
                            {...textLockupProps}
                            className={styles.lockup}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockHeroTwoColumn.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    richHeadline: PropTypes.object,
    bodyCopy: PropTypes.string,
    cta: PropTypes.object,
    ctaButton: PropTypes.object,
    image: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    className: PropTypes.string,
    theme: PropTypes.oneOf([
        'Default',
        'Holiday Blue',
        'Holiday Rust',
        'Holiday Squash',
        'Tillamook Blue',
    ]),
    flushTop: PropTypes.bool,
    hasArrowIcon: PropTypes.bool,
    icon: PropTypes.object,
};

BlockHeroTwoColumn.defaultProps = {
    className: null,
    theme: 'Default',
};

export default BlockHeroTwoColumn;
