import React from 'react';
import PropTypes from 'prop-types';

import styles from './SharpnessSideBarLines.module.scss';

const SharpnessSideBarLines = ({ items }) => {
    return (
        <div className={styles.root} role="presentation">
            <div className={styles.lineVertical} />
            <div className={styles.lines}>
                {items?.map((item, i) => {
                    return <div key={i} className={styles.line} />;
                })}
            </div>
        </div>
    );
};

SharpnessSideBarLines.propTypes = {
    items: PropTypes.array,
};

export default SharpnessSideBarLines;
