import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import UltraBoldHeading from 'components/ui/UltraBoldHeading';

import styles from './BlockCategoryDetailIntro.module.scss';

const BlockCategoryDetailIntro = ({ headline, portraitImage }) => {
    useEffect(() => {
        if (!headline) {
            dispatchWarning(
                'No headline provided. Headline required for render.'
            );
        }

        if (!portraitImage) {
            dispatchWarning(
                'No portrait image provided. Portrait image required for render.'
            );
        }
    }, [headline, portraitImage]);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (headline && portraitImage) {
        return (
            <div className={styles.categoryDetailIntro}>
                <div className={styles.content}>
                    <div className={styles.headlineWrap}>
                        <div className={styles.headline}>
                            <UltraBoldHeading
                                baseTheme={'displayMedium'}
                                themes={{
                                    medium: 'displayLarge',
                                    large: 'displayXLarge',
                                    xxxLarge: 'displayXXLarge',
                                }}
                                icon={'morningStar'}
                                headline={headline}
                            />
                        </div>
                    </div>
                    <Img
                        key={portraitImage.file.url}
                        className={styles.image}
                        src={portraitImage.file.url}
                        fallbackImageWidth={768}
                        loading="eager"
                        alt={portraitImage.description || ''}
                        width={portraitImage.file.details?.image?.width}
                        height={portraitImage.file.details?.image?.height}
                        customSources={[
                            {
                                breakpoint: 768,
                                src: portraitImage.file.url,
                                imageWidth: 1640,
                            },
                            {
                                src: portraitImage.file.url,
                                imageWidth: 768,
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }

    return null;
};

BlockCategoryDetailIntro.propTypes = {
    headline: PropTypes.object,
    portraitImage: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default BlockCategoryDetailIntro;
