import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import kebabCase from 'lodash/kebabCase';

import Link from './';
import { BlockContext } from 'components/blocks';

export const getEntryLink = entry => {
    const { contentTypeId = 'unknown', slug } = entry;

    // handle asset downloads
    if (entry?.file?.url) {
        return { href: entry?.file?.url, download: true, target: undefined };
    }

    switch (contentTypeId) {
        case 'externalLink':
            return { href: entry.url, target: entry.target };
        case 'manualLink':
            return {
                href: `/${entry.as}`,
            };
        case 'recipe':
            return { href: `/recipes/${slug}` };
        case 'news':
            return { href: `/news/${slug}` };
        case 'faqCategory':
            return {
                href: `/faq/${entry.slug}`,
            };
        case 'faq':
            return {
                href: `/faq/${entry.category.slug}/${entry.slug}`,
            };
        case 'productCategory':
            return {
                href: `/products/${slug}`,
            };
        case 'product':
            return {
                href: `/products/${entry.category.slug}/${slug}`,
            };
        case 'foodServiceProductCategory':
            return {
                href: `/foodservice/products/${slug}`,
            };
        case 'foodServiceProduct':
            return {
                href: `/foodservice/products/${entry.category.slug}/${slug}`,
            };
        case 'allForFarmersStory':
            return {
                href: `/all-for-farmers/${slug}`,
            };
        default:
            return {
                href: slug === '/' ? '/' : `/${slug}`,
            };
    }
};

const EntryLink = props => {
    const blockContext = useContext(BlockContext);
    const router = useRouter();
    const trackingPath = kebabCase(router.asPath.split('?')[0]);

    if (!props.entry) {
        return <span className={props.className}>{props.children}</span>;
    }

    const trackingId = () => {
        const entryId = props.entry.id || '';
        if (blockContext) {
            return `${trackingPath}-${blockContext.substring(
                0,
                6
            )}-${entryId.substring(0, 6)}`;
        }

        return `${entryId.substring(0, 6)}`;
    };

    return (
        <Link
            {...props}
            {...getEntryLink(props.entry)}
            gtm-id={trackingId()}
            entry={undefined}
        />
    );
};

EntryLink.propTypes = {
    entry: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

EntryLink.defaultProps = {
    entry: null,
    children: null,
    onClick: null,
};

export default EntryLink;
