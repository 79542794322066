import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';
import Link from 'components/ui/Link';

import Img from 'components/ui/Img';

import { getReadableMonth } from 'utils';

import styles from './CardNewsDetail.module.scss';

const CardNewsDetail = ({ title, slug, cardImage, date }) => {
    const formattedDate = date ? new Date(date) : null;

    return (
        <article className={styles.listItem}>
            <Link className={styles.inner} href={`/news/${slug}`}>
                <figure className={styles.aspectBox}>
                    {cardImage?.file?.url && (
                        <Img
                            className={styles.image}
                            src={cardImage.file.url}
                            fallbackImageWidth={1030}
                            alt={cardImage.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: cardImage.file.url,
                                    imageWidth: 1030,
                                },
                                {
                                    src: cardImage.file.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </figure>
                <figcaption className={styles.textBlock}>
                    {formattedDate && (
                        <Text
                            baseTheme="bodySmall"
                            themes={{ medium: 'bodyMedium' }}
                            className={styles.date}
                            as="p"
                        >
                            {`${getReadableMonth(
                                formattedDate.getUTCMonth()
                            )} ${formattedDate.getUTCDate()}, ${formattedDate.getUTCFullYear()}`}
                        </Text>
                    )}
                    {title && (
                        <Text
                            baseTheme="headingSmall"
                            themes={{ medium: 'headingMedium' }}
                            className={styles.headline}
                            as="h3"
                        >
                            {title}
                        </Text>
                    )}
                </figcaption>
            </Link>
        </article>
    );
};

CardNewsDetail.propTypes = {
    cardImage: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    slug: PropTypes.string.isRequired,
    title: PropTypes.string,
    date: PropTypes.string,
};

CardNewsDetail.defaultProps = {
    cardImage: {},
    title: '',
    date: '',
};

export default CardNewsDetail;
