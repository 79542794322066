import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { BLOCKS } from '@contentful/rich-text-types';

import Text from 'components/ui/Text';
import Stagger from 'components/ui/Stagger';
import RichText from 'components/ui/RichText';
import CTA from 'components/ui/CTA';

import { useTheme } from 'hooks/useTheme';

import styles from './BlockTwoColumnTextAlt.module.scss';

const BlockTwoColumnTextAlt = ({
    eyebrow,
    richHeadline,
    bodyCopy,
    cta,
    className,
    removeArrow,
    theme,
}) => {
    const [globalTheme] = useTheme();
    const isMakersReserve = globalTheme === 'makers-reserve';

    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text.Theme
                        as="p"
                        config={{
                            'makers-reserve': {
                                baseTheme: 'bodyXSmall',
                                themes: { large: 'bodySmall' },
                            },
                            default: {
                                baseTheme: 'bodySmall',
                                themes: { large: 'bodyMedium' },
                            },
                        }}
                        fixWidows
                    >
                        {children}
                    </Text.Theme>
                );
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return (
                    <Text.Theme
                        as="h3"
                        className={styles.heading}
                        config={{
                            'makers-reserve': {
                                baseTheme: 'parkinsonSmall',
                                themes: { large: 'parkinsonMedium' },
                            },
                            default: {
                                baseTheme: 'headingSmall',
                                themes: { large: 'headingMedium' },
                            },
                        }}
                        fixWidows
                    >
                        {children}
                    </Text.Theme>
                );
            },
            [BLOCKS.HEADING_4]: (node, children) => {
                return (
                    <Text.Theme
                        as="h3"
                        config={{
                            default: {
                                baseTheme: 'bodyMedium',
                                themes: { large: 'bodyLarge' },
                            },
                        }}
                    >
                        {children}
                    </Text.Theme>
                );
            },
        },
    };

    return (
        <section
            className={cx(styles.root, styles[theme], className, {
                [styles.makersReserve]: isMakersReserve,
            })}
        >
            <div className={styles.inner}>
                {richHeadline && (
                    <div className={styles.column}>
                        <Stagger className={styles.richEyebrow}>
                            {eyebrow && (
                                <Stagger.Child order={0}>
                                    <Text.Theme
                                        config={{
                                            'makers-reserve': {
                                                baseTheme: 'labelSmallAlt',
                                                themes: {
                                                    large: 'labelMediumAlt',
                                                },
                                            },
                                            default: {
                                                baseTheme:
                                                    {
                                                        ultrabold: 'labelLarge',
                                                    }[theme] || 'labelSmall',
                                                themes: { large: 'labelLarge' },
                                            },
                                        }}
                                        as="h3"
                                    >
                                        {eyebrow}
                                    </Text.Theme>
                                </Stagger.Child>
                            )}
                            <Stagger.Child
                                order={1}
                                className={styles.headline}
                            >
                                <Text.Theme
                                    as="h2"
                                    config={{
                                        'makers-reserve': {
                                            baseTheme: 'parkinsonLarge',
                                            themes: {
                                                large: 'parkinsonXLarge',
                                            },
                                        },
                                        default: {
                                            baseTheme:
                                                {
                                                    ultrabold: 'displayXSmall',
                                                }[theme] || 'displayXSmall',
                                            themes: {
                                                small: {
                                                    large: 'headingMedium',
                                                },
                                                large: {
                                                    large: 'displayMedium',
                                                    xxLarge: 'displayXLarge',
                                                },
                                                ultrabold: {
                                                    large: 'displayMedium',
                                                },
                                            }[theme] || {
                                                large: 'displayMedium',
                                            },
                                        },
                                    }}
                                >
                                    {richHeadline.content.map((line, i) => {
                                        if (line.content[0].value === '') {
                                            return null;
                                        }
                                        return (
                                            <span
                                                className={styles.line}
                                                key={i}
                                            >
                                                {line.content[0].value}
                                            </span>
                                        );
                                    })}
                                </Text.Theme>
                            </Stagger.Child>
                            {!removeArrow && (
                                <Stagger.ArrowRight
                                    theme="smallMobile"
                                    order={2}
                                    className={styles.iconWrapper}
                                />
                            )}
                        </Stagger>
                    </div>
                )}
                <div className={styles.column}>
                    <div className={styles.columnInner}>
                        {bodyCopy && (
                            <RichText
                                richText={bodyCopy}
                                overrides={richTextOverrides}
                            />
                        )}
                        {/* Render from component slot */}
                        {React.isValidElement(cta) && (
                            <div className={styles.buttonWrapper}>{cta}</div>
                        )}
                        {/* Render from model */}
                        {!React.isValidElement(cta) &&
                            (cta?.link || cta?.assetDownloadLink) && (
                                <div className={styles.buttonWrapper}>
                                    <CTA
                                        className={styles.cta}
                                        theme="outlinedBlue"
                                        iconLeading={
                                            cta.assetDownloadLink
                                                ? 'download'
                                                : null
                                        }
                                        cta={cta}
                                    >
                                        {cta.text}
                                    </CTA>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockTwoColumnTextAlt.propTypes = {
    eyebrow: PropTypes.string,
    richHeadline: PropTypes.object.isRequired,
    bodyCopy: PropTypes.object,
    cta: PropTypes.oneOf([
        PropTypes.node,
        PropTypes.shape({
            link: PropTypes.object,
            text: PropTypes.string,
            assetDownloadLink: PropTypes.bool,
        }),
    ]),
    className: PropTypes.string,
    theme: PropTypes.string,
    removeArrow: PropTypes.bool,
};

BlockTwoColumnTextAlt.defaultProps = {
    theme: 'default',
    removeArrow: false,
};

export default BlockTwoColumnTextAlt;
