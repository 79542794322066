import React, { useEffect, useState, useRef, Fragment } from 'react';

import dynamic from 'next/dynamic';
import { Suspense } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';

import { localStorageSupport, saveToLocalStorage } from 'utils';
import { useWait } from 'hooks/useWait';

import Portal from 'components/ui/Portal';

import styles from './Card.module.scss';

const CardExperiment = ({ id }) => {
    const router = useRouter();
    const wait = useWait();

    const alert = {
        source: 'Email_Alert_NoOffer',
        Component: dynamic(() => import('./variants/General'), {
            suspense: true,
        }),
    };

    const Component = alert.Component;

    const innerRef = useRef(null);

    const [isOpen, toggleOpen] = useState(false);
    const [isVisible, toggleVisibility] = useState(true);

    useClickAway(innerRef, () => {
        handleClose();
    });

    function waitFrame() {
        return new Promise(resolve => {
            requestAnimationFrame(resolve);
        });
    }

    async function handleClose() {
        // Save CMS alert id to local storage to avoid rendering alert again
        await saveToLocalStorage(id, 1);
        toggleVisibility(false);

        waitFrame().then(() => {
            toggleOpen(false);
        });
    }

    // Check local storage for the CMS id of this alert
    useEffect(() => {
        if (router.query?.banner && localStorageSupport()) {
            localStorage.removeItem(id);
        }
        if (
            router.isReady &&
            localStorageSupport() &&
            !localStorage.getItem(id)
        ) {
            // Display alert IF alert id is not in local storage
            wait(3500).then(() => toggleOpen(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    function handleKeyDown(evt) {
        if (evt.keyCode === 27) {
            handleClose();
        }
    }

    function toggleEventListener(method) {
        document[`${method}EventListener`]('keydown', handleKeyDown);
    }

    useEffect(() => {
        toggleEventListener('add');

        return () => {
            toggleEventListener('remove');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {isOpen && Component && (
                <Portal>
                    <div className={styles.overlay}>
                        <Suspense fallback={`Loading...`}>
                            <Component
                                source={alert.source}
                                onClose={handleClose}
                                isVisible={isVisible}
                            />
                        </Suspense>
                    </div>
                </Portal>
            )}
        </Fragment>
    );
};

CardExperiment.propTypes = {
    contentTypeId: PropTypes.string.isRequired,
    icon: PropTypes.string,
    icon2: PropTypes.object,
    headline: PropTypes.object.isRequired,
    bodyCopy: PropTypes.object.isRequired,
    ctaButton: PropTypes.shape({
        cta: PropTypes.shape({
            link: PropTypes.object.isRequired,
            text: PropTypes.string,
        }),
        iconType: PropTypes.string,
        theme: PropTypes.string,
    }),
    image: PropTypes.object,
    id: PropTypes.string.isRequired,
};

CardExperiment.defaultProps = {
    image: null,
    ctaButton: null,
};

export default CardExperiment;
