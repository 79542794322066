import { createClient } from 'contentful';
import getConfig from 'next/config';
import resolveResponse from 'utils/contentful-resolve-response';
import { useMemo } from 'react';
import jsonpack from 'jsonpack';

const config = getConfig();

export const ClientNoLinkResolve = () => {
    return createClient({
        resolveLinks: false,
        space: config?.publicRuntimeConfig?.contentful?.space,
        accessToken: config?.publicRuntimeConfig?.contentful?.token,
        environment: config?.publicRuntimeConfig?.contentful?.env,
        host: config?.publicRuntimeConfig?.contentful?.host,
    });
};

export const Client = () => {
    return createClient({
        space: config?.publicRuntimeConfig?.contentful?.space,
        accessToken: config?.publicRuntimeConfig?.contentful?.token,
        environment: config?.publicRuntimeConfig?.contentful?.env,
        host: config?.publicRuntimeConfig?.contentful?.host,
    });
};

export async function fetchFarmersMapMarkers() {
    const results = await ClientNoLinkResolve().getEntries({
        content_type: 'mapAllForFarmersDetail',
        limit: 500,
        include: 6,
    });
    return cleanResponse(results);
}

export async function fetchNewsBySlug(slug) {
    if (slug) {
        const results = await ClientNoLinkResolve().getEntries({
            content_type: 'news',
            'fields.slug': slug,
            include: 6,
        });
        return cleanResponse(results, { keepTimestamps: true });
    }
}

export async function fetchRecipeBySlug(slug) {
    if (slug) {
        const results = await ClientNoLinkResolve().getEntries({
            content_type: 'recipe',
            'fields.slug': slug,
            include: 6,
        });
        return cleanResponse(results);
    }

    return null;
}

export async function fetchAllIcons() {
    const results = await ClientNoLinkResolve().getAssets({
        'metadata.tags.sys.id[all]': 'icon',
        limit: 500,
    });
    return cleanResponse(results);
}

export async function fetchFarmerBySlug(slug) {
    if (slug) {
        const results = await ClientNoLinkResolve().getEntries({
            content_type: 'allForFarmersStory',
            'fields.slug': slug,
            include: 6,
        });
        return cleanResponse(results);
    }

    return null;
}

export async function fetchAllFAQs() {
    const results = await ClientNoLinkResolve().getEntries({
        content_type: 'faq',
        order: 'fields.order',
        limit: 500,
        include: 6,
    });
    return cleanResponse(results);
}

export async function fetchAllRedirects() {
    const results = await ClientNoLinkResolve().getEntries({
        content_type: 'redirect',
        limit: 500,
        include: 1,
    });
    return cleanResponse(results);
}

export async function fetchAllRecipes() {
    const results = await ClientNoLinkResolve().getEntries({
        content_type: 'recipe',
        order: '-sys.createdAt',
        limit: 500,
        include: 6,
    });
    return cleanResponse(results);
}

export async function fetchAllRestaurants() {
    const results = await Client().getEntries({
        content_type: 'restaurant',
        limit: 500,
        include: 6,
    });
    return cleanResponse(results);
}

export async function fetchResturantsByCoordinates(coordinates) {
    const results = await Client().getEntries({
        include: 5,
        limit: 300,
        content_type: 'restaurant',
        'fields.hide[ne]': true,
        'fields.coordinates[within]': coordinates,
    });
    return cleanResponse(results);
}

export async function fetchCommitmentPages() {
    const results = await ClientNoLinkResolve().getEntries({
        content_type: 'commitmentPages',
        include: 6,
    });
    return cleanResponse(results);
}

export async function fetchAllNews() {
    const results = await ClientNoLinkResolve().getEntries({
        content_type: 'news',
        order: '-sys.createdAt',
        limit: 500,
        include: 6,
    });
    return cleanResponse(results);
}

export async function fetchSiteConfig() {
    const results = await ClientNoLinkResolve().getEntries({
        content_type: 'siteConfig',
        include: 4,
    });
    return cleanResponse(results);
}

export async function fetchPrerenderPages() {
    const results = await ClientNoLinkResolve().getEntries({
        content_type: 'page',
        // 'fields.preRender': 'true',
        include: 1,
    });
    return cleanResponse(results);
}

export async function fetchPrerenderProducts() {
    const results = await Client().getEntries({
        content_type: 'product',
        include: 2,
    });

    return cleanResponse(results);
}

export async function fetchPrerenderFoodServiceProducts() {
    const results = await Client().getEntries({
        content_type: 'foodServiceProduct',
        include: 2,
    });

    return cleanResponse(results);
}

export async function fetchPrerenderFoodServiceCategory() {
    const results = await Client().getEntries({
        content_type: 'foodServiceProductCategory',
        include: 1,
    });

    return cleanResponse(results);
}

export async function fetchPrerenderCategories() {
    const results = await Client().getEntries({
        content_type: 'productCategory',
        include: 1,
    });

    return cleanResponse(results);
}

export async function fetchPrerenderNews() {
    const results = await Client().getEntries({
        content_type: 'news',
        include: 1,
    });

    return cleanResponse(results);
}

export async function fetchPrerenderRecipes() {
    const results = await Client().getEntries({
        content_type: 'recipe',
        include: 1,
    });

    return cleanResponse(results);
}

export async function fetchPage(slug) {
    if (slug) {
        const results = await ClientNoLinkResolve().getEntries({
            content_type: 'page',
            'fields.slug': slug,
            include: 7,
        });
        return cleanResponse(results);
    }

    return null;
}

export async function fetchProductDetailPageBySlug(slug) {
    if (slug) {
        const results = await ClientNoLinkResolve().getEntries({
            content_type: 'product',
            'fields.slug': slug,
            include: 5,
        });
        return cleanResponse(results);
    }
}

export async function fetchProductByTillamapsID(id) {
    if (id) {
        const results = await ClientNoLinkResolve().getEntries({
            content_type: 'product',
            'fields.tillamapsID': id,
            include: 2,
        });
        return cleanResponse(results);
    }
}

export async function fetchProducts() {
    return ClientNoLinkResolve().getEntries({
        content_type: 'product',
        'fields.discontinued[ne]': true,
        limit: 500,
        include: 5,
    });
}

export async function fetchProductsLinked({ showDiscontinued = false }) {
    return Client().getEntries({
        content_type: 'product',
        order: 'fields.order',
        'fields.discontinued[ne]': !showDiscontinued,
        limit: 500,
        include: 5,
    });
}

export async function fetchRestaurantById(id) {
    if (id) {
        const response = await Client().getEntries({
            'sys.id': id,
            include: 5,
        });

        return response;
    }

    return null;
}

export async function fetchProductCategories() {
    return ClientNoLinkResolve().getEntries({
        content_type: 'productCategory',
        limit: 500,
        include: 5,
    });
}

export async function fetchFAQCategories() {
    return ClientNoLinkResolve().getEntries({
        content_type: 'faqCategory',
        limit: 500,
        include: 5,
    });
}

export async function fetchCategoryProducts(slug) {
    if (slug) {
        const category = await ClientNoLinkResolve().getEntries({
            content_type: 'productCategory',
            'fields.slug': slug,
            include: 1,
        });
        const categoryClean = cleanResponse(category);

        const products = await ClientNoLinkResolve().getEntries({
            content_type: 'product',
            'fields.discontinued[ne]': true,
            links_to_entry: categoryClean.items[0]?.sys?.id,
            order: 'fields.order',
            include: 1,
        });
        return {
            category,
            products,
        };
    }

    return null;
}

export async function fetchEntryById(id) {
    if (id) {
        const response = await ClientNoLinkResolve().getEntries({
            'sys.id': id,
            include: 5,
        });

        return response;
    }

    return null;
}

/**
 *
 *
 * @returns {Promise<Object>}
 *
 */

export async function fetchFoodServiceProductDetailPageBySlug(slug) {
    if (slug) {
        const results = await ClientNoLinkResolve().getEntries({
            content_type: 'foodServiceProduct',
            'fields.slug': slug,
            include: 5,
        });
        return cleanResponse(results);
    }
}

/**
 *
 *
 * @returns {Promise<Object>}
 *
 */

export async function fetchFoodServiceCategoryProducts(slug) {
    if (slug) {
        const category = await ClientNoLinkResolve().getEntries({
            content_type: 'foodServiceProductCategory',
            'fields.slug': slug,
            include: 1,
        });
        const categoryClean = cleanResponse(category);

        const products = await ClientNoLinkResolve().getEntries({
            content_type: 'foodServiceProduct',
            links_to_entry: categoryClean.items[0]?.sys?.id,
            order: 'fields.order',
            include: 1,
        });

        return {
            category,
            products,
        };
    }

    return null;
}

export function resolveEntryLink(item, response) {
    try {
        if (item?.sys?.type === 'Link') {
            return response.includes[item.sys.linkType].find(entry => {
                return entry.sys.id === item.sys.id;
            });
        }
        return [];
    } catch (e) {
        return false;
    }
}

export function resolveEntryLinks(items, response) {
    if (Array.isArray(items)) {
        return items.map(item => {
            return response.includes[item.sys.linkType].find(entry => {
                return entry.sys.id === item.sys.id;
            });
        });
    }
    return [];
}

export const useNormalizedContentfulResponse = response => {
    return useMemo(() => {
        return resolveResponse(
            typeof response === 'string' ? jsonpack.unpack(response) : response
        );
    }, [response]);
};

/**
 * Cleans the Contentful response item of unuseful data
 * @param {Object}      response A brute Contentful REST API response
 * @param {Object}      opts
 * @returns {Object}    A Contentful REST API response
 *
 */
const cleanResponse = (response, opts = {}) => {
    response.items?.map(item => cleanEntrySys(item, opts));
    response.includes?.Entry?.map(item => cleanEntrySys(item, opts));
    response.includes?.Asset?.map(item => cleanEntrySys(item, opts));
    return response;
};

/**
 * Cleans the Contentful response item of unuseful data
 * @param {Object}      item A Contentful REST API response item
 * @param {Object}      opts
 * @param {bool}        opts.keepTimestamps skips deletion of the TS keys
 * @returns {Object}    A Contentful REST API response item
 *
 */
export const cleanEntrySys = (item, opts = {}) => {
    if (!opts?.keepTimestamps) {
        delete item.sys.createdAt;
        delete item.sys.updatedAt;
    }
    delete item.sys.revision;
    delete item.sys.environment;
    delete item.sys.locale;
    delete item.sys.space;
    return item;
};
