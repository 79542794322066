import qs from 'qs';

const getOptions = (listId, email, fields) => {
    const body = {
        g: listId,
        email,
        ...fields,
    };

    return {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: qs.stringify(body),
    };
};

const fetchUrl = 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe';

export async function subscribe(email, fields) {
    const options = getOptions('XuwZvd', email, fields);

    const response = fetch(fetchUrl, options)
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            return data;
        })
        .catch(error => {
            console.error('Error:', error);
            return error;
        });

    return response;
}

export async function retrieveIceCreamCoupon(email) {
    const options = getOptions('UhdBZY', email);

    const response = fetch(fetchUrl, options)
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            return data;
        })
        .catch(error => {
            console.error('Error:', error);
            return error;
        });

    return response;
}

export const trackExternalLink = url => {
    _learnq.push([
        'track',
        'External Link',
        {
            'Page URL': url,
        },
    ]);
};

export const trackPageView = url => {
    _learnq.push([
        'track',
        'Page View',
        {
            'Page URL': url,
        },
    ]);
};

// modified to remove sign_up_forms from https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VVip4F

export const loadKlaviyoScript = siteId => `
(function () {
    var a = '${siteId}';
    if (
        window.klaviyoModulesObject &&
        (window.klaviyoModulesObject.companyId != a ||
            !window.klaviyoModulesObject.serverSideRendered)
    ) {
        console.warn(
            'Cannot load klaviyo.js for different accounts on the same site. Skipping account "' +
                a +
                '". Active account is "' +
                window.klaviyoModulesObject.companyId +
                '"'
        );
        return;
    }
    window._learnq = window._learnq || [];
    window.__klKey = window.__klKey || a;
    if (!window.klaviyoModulesObject) {
        window._learnq.push(['account', a]);
        Object.defineProperty(window, 'klaviyoModulesObject', {
            value: {
                companyId: a,
                loadTime: new Date(),
                loadedModules: {},
                loadedCss: {},
                serverSideRendered: true,
            },
            enumerable: false,
        });
    }
    var b = {};

    function c(a) {
        if (b[a]) return;
        var c = document.createElement('script');
        c.type = 'text/javascript';
        c.async = true;
        c.src = a;
        document.head.appendChild(c);
        b[a] = true;
    }

    function d(a) {
        var b = document.createElement('link');
        b.rel = 'stylesheet';
        b.href = a;
        document.head.appendChild(b);
    }

    function e() {
        var a = document.createElement('script');
        return 'noModule' in a;
    }

    function f() {
        try {
            new Function('import("")');
            return true;
        } catch (a) {
            return false;
        }
    }
    var g = JSON.parse(
        '{"static": {"js": ["https://static.klaviyo.com/onsite/js/fender_analytics.1c0f92f6328d1dc71a80.js", "https://static.klaviyo.com/onsite/js/sharedUtils.144831da2e7415b0df39.js", "https://static.klaviyo.com/onsite/js/static.116ac02df54931fc96cc.js"]}}'
    );
    if (e() || f())
        g = JSON.parse(
            '{"static": {"js": ["https://static.klaviyo.com/onsite/js/fender_analytics.5f2fb493b401008d6d00.js", "https://static.klaviyo.com/onsite/js/sharedUtils.dd98b94b5c4d56c81873.js", "https://static.klaviyo.com/onsite/js/static.9ec4d0f227c7232d0746.js"]}}'
        );
    for (var h in g)
        if (g.hasOwnProperty(h)) {
            var i = g[h];
            for (var j = 0; j < i.js.length; j += 1)
                if (!window.klaviyoModulesObject.loadedModules[i.js[j]]) {
                    c(i.js[j]);
                    window.klaviyoModulesObject.loadedModules[i.js[j]] =
                        new Date().toISOString();
                }
            if (i.css)
                if (!window.klaviyoModulesObject.loadedCss[i.css]) {
                    d(i.css);
                    window.klaviyoModulesObject.loadedCss[i.css] =
                        new Date().toISOString();
                }
        }
})();
`;
