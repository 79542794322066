import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';

import Img from 'components/ui/Img';

import styles from './CardDownload.module.scss';

const CardDownload = ({ headline, cta, image }) => {
    useEffect(() => {
        if (!headline) {
            dispatchWarning(
                'No headline provided. Headline required for render.'
            );
        }

        if (!image) {
            dispatchWarning('No image provided. Image required for render.');
        }

        if (!cta?.link || !cta?.text) {
            dispatchWarning('No CTA provided. CTA required for render.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (headline && cta && image) {
        return (
            <div className={cx(styles.downloadCard)}>
                <div className={cx(styles.downloadCardInner)}>
                    <div className={styles.imageWrapper}>
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            fallbackImageWidth={732}
                            alt={image.description || ''}
                        />
                    </div>
                    <div className={styles.lockUp}>
                        <Text
                            className={styles.headline}
                            baseTheme="headingSmall"
                        >
                            {headline}
                        </Text>
                        <div className={styles.ctaWrapper}>
                            <Button
                                className={styles.cta}
                                theme="outlinedBlue"
                                download={true}
                                href={cta.assetDownloadLink.file.url}
                                iconLeading="download"
                            >
                                download
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

CardDownload.propTypes = {
    headline: PropTypes.string.isRequired,
    cta: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default CardDownload;
