import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Link from 'components/ui/Link';
import SvgIcon from 'components/ui/SvgIcon';
import RichText from 'components/ui/RichText';

import { BLOCKS } from '@contentful/rich-text-types';

import styles from './CardSmall.module.scss';

const iconMap = {
    Milk: 'milkJar',
    Berries: 'berries',
    Nuts: 'nuts',
};

const CardSmall = ({ icon, copy, theme, cta }) => {
    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        className={styles.paragraph}
                        baseTheme={
                            theme === 'Large Icon' ? 'bodyLarge' : 'bodyMedium'
                        }
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    return (
        <Link.Entry className={styles.cardLinkWrap} entry={cta?.link}>
            <article
                className={cx(styles.cardSmall, {
                    [styles.largeIcon]: theme === 'Large Icon',
                })}
            >
                <div className={styles.inner}>
                    {icon && (
                        <div className={cx(styles.icon, styles[iconMap[icon]])}>
                            <SvgIcon iconType={iconMap[icon]} />
                        </div>
                    )}
                    {copy && (
                        <div className={styles.copy}>
                            <RichText
                                richText={copy}
                                overrides={richTextOverrides}
                            />
                        </div>
                    )}
                </div>
            </article>
        </Link.Entry>
    );
};

CardSmall.propTypes = {
    icon: PropTypes.string,
    stat: PropTypes.string,
    copy: PropTypes.object,
    cta: PropTypes.object,
    theme: PropTypes.oneOf(['Default', 'Large Icon']),
};

CardSmall.defaultProps = {
    cta: null,
    theme: 'Default',
};

export default CardSmall;
