import React, { useMemo, createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'components/ui/Link';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';
import Award from 'components/ui/Award';
import { getColor } from 'utils';

import { useIntersection, useMeasure } from 'react-use';

import Img from 'components/ui/Img';
import { checkColorContrast } from '@tillamook/brand/lib/colors';
import { useTheme } from 'hooks/useTheme';

import styles from './CardProduct.module.scss';

const CardProduct = ({
    category,
    color,
    displayName,
    eyebrow,
    featured,
    image,
    slug,
    award,
}) => {
    const isDark = useMemo(() => checkColorContrast(color) < 2, [color]);
    const ref = createRef();
    const [theme] = useTheme();
    const [cardRef, { width }] = useMeasure();

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const [visible, setVisibility] = useState(false);

    useEffect(() => {
        intersection && intersection.isIntersecting
            ? setVisibility(true)
            : setVisibility(false);
    }, [intersection, intersection?.isIntersecting]);

    if (!category) {
        return null;
    }

    return (
        <Link
            as={`/products/${category.slug}/${slug}`}
            href={'/products/[category]/[product]'}
            className={styles.cardLinkWrap}
        >
            <article
                ref={ref}
                style={{
                    '--card-background-color': getColor(color),
                    '--card-width': `${width}px`,
                }}
                className={cx(styles.card, styles[`theme--${theme}`])}
            >
                <div
                    ref={cardRef}
                    className={cx(styles.cardInner, {
                        [styles[`cardInner--dark-text`]]: isDark, // WCAG Contrast Level AAA large text
                    })}
                >
                    <div className={styles.cardContent}>
                        {featured && (
                            <SvgIcon
                                className={styles.featured}
                                iconType="featuredProduct"
                            />
                        )}
                        {image?.file?.url && (
                            <div
                                className={cx(styles.imageContainer, {
                                    [styles[`imageContainer--portrait`]]:
                                        image.file?.details?.image?.height >
                                        image.file?.details?.image?.width,
                                })}
                            >
                                <figure
                                    className={cx(styles.image, {
                                        [styles.isVisible]: visible,
                                    })}
                                >
                                    <Img
                                        src={image.file.url}
                                        fallbackImageWidth={450}
                                        alt={displayName}
                                        customSources={[
                                            {
                                                breakpoint: 1440,
                                                src: image.file.url,
                                                imageWidth: 450,
                                            },
                                            {
                                                breakpoint: 1024,
                                                src: image.file.url,
                                                imageWidth: 300,
                                            },
                                            {
                                                breakpoint: 768,
                                                src: image.file.url,
                                                imageWidth: 400,
                                            },
                                            {
                                                src: image.file.url,
                                                imageWidth: 240,
                                            },
                                        ]}
                                    />
                                    {theme == 'makers-reserve' && award && (
                                        <figure className={styles.award}>
                                            <Award richText={award} />
                                        </figure>
                                    )}
                                </figure>
                            </div>
                        )}
                        <div className={styles.textGroup}>
                            <Text.Theme
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'labelSmallAlt',
                                        themes: {
                                            xxLarge: 'labelMediumAlt',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'labelSmall',
                                    },
                                }}
                                className={styles.eyebrow}
                            >
                                {eyebrow}
                            </Text.Theme>
                            <Text.Theme
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'parkinsonSmall',
                                        themes: {
                                            xxLarge: 'parkinsonMedium',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'bodyLarge',
                                        themes: {
                                            large: 'bodyMedium',
                                            xxLarge: 'bodyLarge',
                                        },
                                    },
                                }}
                                className={styles.cta}
                            >
                                {displayName}
                            </Text.Theme>
                        </div>
                    </div>
                </div>
            </article>
        </Link>
    );
};

CardProduct.propTypes = {
    category: PropTypes.object.isRequired,
    color: PropTypes.string,
    displayName: PropTypes.string,
    eyebrow: PropTypes.string,
    featured: PropTypes.bool.isRequired,
    image: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }),
    slug: PropTypes.string.isRequired,
    colorTheme: PropTypes.oneOf(['default']),
    theme: PropTypes.oneOf(['default', 'makers-reserve']),
    award: PropTypes.object,
};

CardProduct.defaultProps = {
    color: 'blue',
    theme: 'default',
    displayName: '',
    eyebrow: '',
    featured: false,
    colorTheme: 'default',
};

export default CardProduct;
