import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const ThemeContext = createContext({
    theme: 'default',
    setTheme: null,
});

export const useTheme = () => {
    const { theme, setTheme } = useContext(ThemeContext);
    return [theme?.toLowerCase(), setTheme];
};

export const ThemeProvider = ({ theme: renderedTheme, children }) => {
    const [theme, setTheme] = useState(renderedTheme);

    useEffect(() => {
        setTheme(renderedTheme);
    }, [renderedTheme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

ThemeProvider.propTypes = {
    theme: PropTypes.string,
    children: PropTypes.object.isRequired,
};

ThemeProvider.defaultProps = {};
