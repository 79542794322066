import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useInterval } from 'react-use';

import FlavorFinderIcon from '../FlavorFinderIcon';

import styles from './FlavorFinderCardSwiper.module.scss';

const pulsateScaleStart = 'scale(1.1)';
const pulsateScaleEnd = 'scale(1)';

const FlavorFinderVoteButton = ({
    hint,
    willBePicked,
    willNotBePicked,
    type,
    onClick,
}) => {
    const innerRef = useRef(null);

    // use useEffect and intervals with css transition instead of animation keyframes
    // because animation keyframes do not resolve to the nearest end point when cancelled
    useEffect(() => {
        if (hint) {
            innerRef.current.style.transform = pulsateScaleStart;
        } else {
            innerRef.current.style.transform = pulsateScaleEnd;
        }
    }, [hint]);
    useInterval(
        () => {
            innerRef.current.style.transform = pulsateScaleEnd;
        },
        hint && !willBePicked ? 150 : null
    );
    useInterval(
        () => {
            innerRef.current.style.transform = pulsateScaleStart;
        },
        hint && !willBePicked ? 300 : null
    );

    return (
        <button
            className={cx(styles.button, {
                [styles.buttonYes]: type === 'Yes',
                [styles.buttonNo]: type === 'No',
                [styles.willBePicked]: willBePicked,
                [styles.willNotBePicked]: willNotBePicked,
            })}
            onClick={onClick}
        >
            <div ref={innerRef} className={styles.buttonInner}>
                <div className={styles.buttonIcon}>
                    {type === 'Yes' && <FlavorFinderIcon type="heart" />}
                    {type === 'No' && <FlavorFinderIcon type="x" />}
                </div>
            </div>
        </button>
    );
};

FlavorFinderVoteButton.propTypes = {
    hint: PropTypes.bool,
    isDragging: PropTypes.bool,
    willBePicked: PropTypes.bool,
    willNotBePicked: PropTypes.bool,
    type: PropTypes.oneOf(['Yes', 'No']),
    onClick: PropTypes.func,
};

FlavorFinderVoteButton.defaultProps = {
    type: 'Yes',
};

export default FlavorFinderVoteButton;
