import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './CardNoResults.module.scss';

const CardNoResults = ({ search }) => {
    return (
        <li className={styles.root}>
            <Text className={styles.text} as="h3" baseTheme="headingLarge">
                Sorry, we couldn’t find any results{' '}
                {search ? (
                    <>
                        for&nbsp;<q>{search}</q>
                    </>
                ) : null}
            </Text>
        </li>
    );
};

CardNoResults.propTypes = {
    search: PropTypes.string,
};

export default CardNoResults;
