import client from './client';

export default {
    subscribe: data => {
        return client.post('/klaviyo/subscribe', data);
    },
    retrieveIceCreamCoupon: data => {
        return client.post('/klaviyo/retrieve-ice-cream-coupon', data);
    },
    campaignCreameryQrSubmitForm: data => {
        return client.post('/klaviyo/campaign-creamery-qr-submit-form', data);
    },
    campaignCreameryQrCheckExpiration: data => {
        return client.post(
            '/klaviyo/campaign-creamery-qr-check-expiration',
            data
        );
    },
    campaignCreameryQrClaimVoucher: data => {
        return client.post('/klaviyo/campaign-creamery-qr-claim-voucher', data);
    },
    retrievePromotion: data => {
        return client.post('/klaviyo/retrieve-promotion', data);
    },
    printPromotion: data => {
        return client.post('/klaviyo/print-promotion', data);
    },
    retrieveNewsletterSubscriptionStatus: data => {
        return client.post(
            '/klaviyo/retrieve-newsletter-subscription-status',
            data
        );
    },
    trackEvent: data => {
        return client.post('/klaviyo/track-event', data);
    },
};
