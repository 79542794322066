import React from 'react';
import { useRouter } from 'next/router';

import Img from 'components/ui/Img';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';

import { usePreviousAsPath } from 'hooks/usePreviousAsPath';

import styles from './NotFound.module.scss';

const Error = () => {
    const router = useRouter();
    const previousAsPath = usePreviousAsPath();

    return (
        <div className={styles.container}>
            <Text
                className={styles.headline}
                as="h1"
                baseTheme="displaySmall"
                themes={{ medium: 'displayLarge' }}
            >
                Well, this isn’t grate
            </Text>
            <Text
                className={styles.copy}
                as="p"
                baseTheme="bodyMedium"
                themes={{ medium: 'bodyLarge' }}
            >
                We can’t seem to find the page you’re looking for. Sorry about
                that!
            </Text>
            {previousAsPath ? (
                <Button theme="filledBlue" onClick={() => router.back()}>
                    Back
                </Button>
            ) : (
                <Button theme="filledBlue" href="/">
                    Home
                </Button>
            )}
            <figure className={styles.imageWrapper}>
                <Img
                    src="https://images.ctfassets.net/j8tkpy1gjhi5/3jkzmNp6SF4bsl2liTNiRA/7ae0f7a8be5008608cdfe1764594cf81/DIGITAL-shreds-side---KO_1.png"
                    fallbackImageWidth={1274}
                    alt="Cheese Shreds"
                />
            </figure>
        </div>
    );
};

export default Error;
