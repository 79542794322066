import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGeolocation } from 'react-use';
import mapboxClient from '@mapbox/mapbox-sdk';
import mapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

export const LocationContext = createContext({
    locationFallback: {},
    isLoaded: false,
});

const MAPBOX_TOKEN =
    'pk.eyJ1IjoiaGF1c2xhIiwiYSI6ImNrbHN0dHRzNjA1d24yd25xZWhka2xmMm8ifQ.C3nu0o6XmETb877Vj6tjfQ';

export const useLocation = () => {
    const { locationFallback, isLoaded: fallbackLoaded } =
        useContext(LocationContext);

    const [location, setLocation] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const geolocation = useGeolocation();

    useEffect(() => {
        if (fallbackLoaded && geolocation.loading === true) {
            setLocation(locationFallback);
        }
        const getReverseGeocoding = async () => {
            await new Promise(resolve => {
                if (geolocation.loading === false && !isLoaded) {
                    resolve();
                }
            });
            const geocoded = await mapboxGeocoding(
                mapboxClient({ accessToken: MAPBOX_TOKEN })
            )
                .reverseGeocode({
                    query: [geolocation.longitude, geolocation.latitude],
                    types: ['postcode'],
                })
                .send();

            const match = geocoded.body.features[0];
            const stateResult = match?.context[2];
            const stateDisplay = stateResult?.short_code
                ? stateResult?.short_code.split('-')[1]
                : stateResult?.text;

            setLocation(
                geolocation.latitude
                    ? {
                          postalCode: match?.text,
                          coordinates: match?.center,
                          city: match?.context[0].text,
                          state: stateDisplay,
                          country: match?.context[3].short_code,
                          accuracy: 'precise',
                      }
                    : locationFallback
            );

            setIsLoaded(true);
        };

        if (fallbackLoaded) {
            getReverseGeocoding();
        }
    }, [fallbackLoaded, geolocation, isLoaded, locationFallback]);

    return [location, setLocation];
};

export const LocationProvider = ({ location: renderedLocation, children }) => {
    const [locationFallback, setLocationFallback] = useState(renderedLocation);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (renderedLocation) {
            const {
                viewerCity: city,
                viewerCountry: country,
                viewerCountryRegion: state,
                viewerLat: latitude,
                viewerLong: longitude,
            } = renderedLocation;

            setLocationFallback({
                city,
                country,
                state,
                coordinates: [longitude, latitude],
                accuracy: 'fallback',
            });

            setIsLoaded(true);
        }
    }, [renderedLocation]);

    return (
        <LocationContext.Provider value={{ locationFallback, isLoaded }}>
            {children}
        </LocationContext.Provider>
    );
};

LocationProvider.propTypes = {
    children: PropTypes.object.isRequired,
    location: PropTypes.shape({
        viewerCity: PropTypes.string,
        viewerCountry: PropTypes.string,
        viewerCountryRegion: PropTypes.string,
        viewerLat: PropTypes.string,
        viewerLong: PropTypes.string,
    }),
};

LocationProvider.defaultProps = {};
