import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import SwirlShare from '../SwirlShare';

import Img from 'components/ui/Img';

import styles from './SwirlPayoff.module.scss';

const flavorNames = {
    0: {
        0: [
            {
                headline: `BERRY PICKIN’ <br />GOOD`,
                subheadline: `Not too sweet, not too tart. <br />It’s just the way you like.`,
            },
            {
                headline: `BERRY PARADISE`,
                subheadline: `You went for an even mix, <br />but like things on the sweeter side.`,
            },
        ],
        1: [
            {
                headline: `RIPE WITH ENVY`,
                subheadline: `You like having options, and this way <br />you get the best of both worlds.`,
            },
        ],
        2: [
            {
                headline: `BLASTBERRY GURU`,
                subheadline: `You’re a natural swirlmaster. <br />You craft the perfect bite every time.`,
            },
            {
                headline: `BERRY BLEND GENIUS`,
                subheadline: `You’re into perfectly-balanced pairings <br />that turn into perfect little bites.`,
            },
        ],
    },
    1: {
        0: [
            {
                headline: `SWIZZLEBERRY TWIST`,
                subheadline: `Two flavors, one spoon. <br />The perfect bite is in your hands.`,
            },
            {
                headline: `BERRY PARADISE`,
                subheadline: `You went for an even mix, <br />but like things on the sweeter side.`,
            },
            {
                headline: `BERRYPLAIN-TASTIC`,
                subheadline: `You don’t want to pick sides. <br />You’ll savor them.`,
            },
        ],
        1: [
            {
                headline: `SNACKBERRYMATIC`,
                subheadline: `You like the best of both worlds. <br />Here’s to doubling-down. `,
            },
        ],
        2: [
            {
                headline: `STRAWBERRYLAZA`,
                subheadline: `You make every bite your <br />own mini snack masterpiece.`,
            },
            {
                headline: `SAVORBERRY SWIRL`,
                subheadline: `Don’t stop, can’t stop swirlin’. <br />You’re on your way to a more satisfying snack.`,
            },
        ],
    },
    2: {
        0: [
            {
                headline: `BLUEBERRYATIC`,
                subheadline: `No more this or that. <br />Enjoy the best of both worlds.`,
            },
            {
                headline: `LULUBERRY BLAST`,
                subheadline: `Not too tart. Not too sweet. <br />You made it just right.`,
            },
        ],
        1: [
            {
                headline: `BIZZLEBERRY`,
                subheadline: `Turns out you’re a match with both flavors. <br />And that’s the way it should be.`,
            },
        ],
        2: [
            {
                headline: `BLOOMOON`,
                subheadline: `Whoa! You’re a natural swirlmaster. <br />You like to choose your own flavor adventure.`,
            },
            {
                headline: `BLUEDREAM CREAM`,
                subheadline: `What a mix. You’re about to enter a <br />world of flavor possibilities.`,
            },
        ],
    },
};

const flavorMap = {
    0: {
        flavorName: {
            0: flavorNames[0][0][
                Math.floor(Math.random() * flavorNames[0][0].length)
            ],
            1: flavorNames[0][1][
                Math.floor(Math.random() * flavorNames[0][1].length)
            ],
            2: flavorNames[0][2][
                Math.floor(Math.random() * flavorNames[0][2].length)
            ],
        },
        image: '/images/creamery-collection/northwest-raspberry-and-blackberry.png',
        imageLarge:
            '/images/creamery-collection/northwest-raspberry-and-blackberry-large.png',
    },
    1: {
        flavorName: {
            0: flavorNames[1][0][
                Math.floor(Math.random() * flavorNames[1][0].length)
            ],
            1: flavorNames[1][1][
                Math.floor(Math.random() * flavorNames[1][1].length)
            ],
            2: flavorNames[1][2][
                Math.floor(Math.random() * flavorNames[1][2].length)
            ],
        },
        image: '/images/creamery-collection/oregon-strawberry-and-plain.png',
        imageLarge:
            '/images/creamery-collection/oregon-strawberry-and-plain-large.png',
    },
    2: {
        flavorName: {
            0: flavorNames[2][0][
                Math.floor(Math.random() * flavorNames[2][0].length)
            ],
            1: flavorNames[2][1][
                Math.floor(Math.random() * flavorNames[2][1].length)
            ],
            2: flavorNames[2][2][
                Math.floor(Math.random() * flavorNames[2][2].length)
            ],
        },
        image: '/images/creamery-collection/oregon-blueberry-and-vanilla.png',
        imageLarge:
            '/images/creamery-collection/oregon-blueberry-and-vanilla-large.png',
    },
};

const SwirlPayoff = ({ show, activeId, tryAgain, movementScore }) => {
    const [internalId, setInternalId] = useState(0);

    const handleTryAgain = () => {
        tryAgain();
    };

    useEffect(() => {
        if (show) {
            setInternalId(activeId);
        }
    }, [show, activeId]);

    return (
        <div
            gtm-id="swirl-payoff"
            className={cx(styles.root, {
                [styles.show]: show,
            })}
        >
            <div className={styles.content}>
                <Text
                    className={styles.eyebrow}
                    baseTheme="labelSmall"
                    themes={{ large: 'labelLarge' }}
                >
                    Your Perfect Bite Is:
                </Text>
                <Text
                    className={styles.headline}
                    baseTheme="displaySmall"
                    themes={{ large: 'displayMedium' }}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: flavorMap[internalId]?.flavorName?.[
                                movementScore
                            ]?.headline,
                        }}
                    />
                </Text>
                <Text
                    className={styles.subheadline}
                    baseTheme="bodyMedium"
                    themes={{ large: 'bodyLarge' }}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: flavorMap[internalId]?.flavorName?.[
                                movementScore
                            ]?.subheadline,
                        }}
                    />
                </Text>
                <Button
                    href="https://ppod.io/s/7HMGcb5UYKI26G06"
                    target="_blank"
                    className={styles.coupon}
                    theme="filledBlue"
                    gtm-id="swirl-claim-your-coupon"
                >
                    Claim your coupon
                </Button>
                <Button
                    gtm-id="swirl-retry-button"
                    onClick={handleTryAgain}
                    className={styles.tryAgain}
                    theme=""
                >
                    <span className={styles.tryAgainSvg}>
                        <svg viewBox="0 0 25 22">
                            <path
                                d="M24.5 9.42855C24.5 14.6274 20.624 18.8572 15.86 18.8572H7.13744L8.37872 20.2118C8.7536 20.6209 8.7536 21.2841 8.37872 21.6932C8.00432 22.1023 7.39568 22.1023 7.02128 21.6932L4.14128 18.5503C3.7664 18.1412 3.7664 17.478 4.14128 17.0689L7.02128 13.926C7.39568 13.5169 8.00432 13.5169 8.37872 13.926C8.7536 14.3351 8.7536 14.9983 8.37872 15.4074L7.13744 16.762H15.86C19.5661 16.762 22.58 13.4729 22.58 9.42855C22.58 8.85026 23.0101 8.38092 23.54 8.38092C24.0699 8.38092 24.5 8.85026 24.5 9.42855ZM9.14 5.23803H17.8626L16.6213 6.59261C16.2464 7.00171 16.2464 7.66487 16.6213 8.07397C16.9933 8.48045 17.6024 8.48568 17.9787 8.07397L20.8587 4.93107C21.2336 4.52197 21.2336 3.85882 20.8587 3.44972L17.9787 0.306825C17.6043 -0.102275 16.9957 -0.102275 16.6213 0.306825C16.2464 0.715925 16.2464 1.37908 16.6213 1.78818L17.8626 3.14276H9.14C4.376 3.14276 0.5 7.37258 0.5 12.5714C0.5 13.1497 0.93008 13.6191 1.46 13.6191C1.98992 13.6191 2.42 13.1497 2.42 12.5714C2.42 8.52759 5.4344 5.23803 9.14 5.23803Z"
                                fill="currentColor"
                            />
                        </svg>
                    </span>
                    <span className={styles.tryAgainText}>Try Again</span>
                </Button>
            </div>
            <div className={styles.imageWrap}>
                <Img
                    className={styles.image}
                    src={flavorMap[internalId]?.image}
                    fallbackImageWidth={768}
                    alt={''}
                    customSources={[
                        {
                            breakpoint: 1024,
                            src: flavorMap[internalId]?.imageLarge,
                            imageWidth: 700 * 1.5,
                        },
                        {
                            breakpoint: 768,
                            src: flavorMap[internalId]?.imageLarge,
                            imageWidth: 600 * 1.5,
                        },
                        {
                            src: flavorMap[internalId]?.image,
                            imageWidth: 375 * 1.5,
                        },
                    ]}
                />
            </div>
            <SwirlShare show={show} />
        </div>
    );
};

SwirlPayoff.propTypes = {
    show: PropTypes.bool,
    activeId: PropTypes.oneOf([0, 1, 2]),
    tryAgain: PropTypes.func,
    movementScore: PropTypes.oneOf([0, 1, 2]),
};

export default SwirlPayoff;
