import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useIntersection, usePrevious } from 'react-use';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Stagger from 'components/ui/Stagger';

import defaultImages from './default';
import Slide from './Slide';

import styles from './BlockWhereToBuy.module.scss';

import useDocumentVisibility from 'hooks/useDocumentVisibility';

const animationStates = {
    IDLE: 'idle',
    ENTER: 'enter',
    PLAYING: 'playing',
    PAUSED: 'paused',
};

const BlockWhereToBuy = ({ bodyCopy, images, lastBlock }) => {
    const ref = useRef(null);

    const [isInView, setIsInView] = useState(false);
    const [animationState, setAnimationState] = useState(animationStates.IDLE);

    const previousAnimationState = usePrevious(animationState);
    const documentVisibility = useDocumentVisibility();

    const items = (
        images?.length >= 5 ? [...images] : [...defaultImages]
    ).reverse();

    // We initialize at four because the first item in the array needs to be in position three at the start of the animation
    const [activeIndex, setActiveIndex] = useState(4);

    useEffect(() => {
        if (animationState !== animationStates.PLAYING) {
            return;
        }

        const interval = setInterval(() => {
            setActiveIndex(prevActivIndex => {
                return prevActivIndex < items.length - 1
                    ? prevActivIndex + 1
                    : 0;
            });
        }, 3000);

        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationState]);

    useEffect(() => {
        if (isInView) {
            // Previously IDLE - need to enter
            if (previousAnimationState === animationStates.IDLE) {
                setAnimationState(animationStates.ENTER);

                // Set a timeout to trigger the playing state
                setTimeout(() => {
                    setAnimationState(animationStates.PLAYING);
                }, 1200);
            }

            // Previously PAUSED - need to play animation now
            if (previousAnimationState === animationStates.PAUSED) {
                setAnimationState(animationStates.PLAYING);
            }
        } else {
            if (previousAnimationState === animationStates.PLAYING) {
                setAnimationState(animationStates.PAUSED);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInView]);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    });

    useEffect(() => {
        intersection?.isIntersecting && documentVisibility === 'visible'
            ? setIsInView(true)
            : setIsInView(false);
    }, [intersection, documentVisibility]);

    return (
        <div
            ref={ref}
            className={cx(styles.root, {
                [styles.lastBlock]: lastBlock,
                [styles.onEnter]: animationState === animationStates.ENTER,
                [styles.afterEnter]:
                    animationState === animationStates.PLAYING ||
                    animationState === animationStates.PAUSED,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.lockup}>
                    <Stagger className={styles.lockupInner}>
                        <div className={styles.icon} />
                        <Stagger.Child order={0}>
                            <Text.Theme
                                className={styles.heading}
                                config={{
                                    default: {
                                        baseTheme: 'displayMedium',
                                        themes: {
                                            large: 'displayXXLarge',
                                        },
                                    },
                                }}
                                as="h2"
                            >
                                Find it
                                <br />
                                near
                                <br />
                                <strong>you</strong>
                            </Text.Theme>
                        </Stagger.Child>
                        <Stagger.Child order={1}>
                            <Text.Theme
                                className={styles.bodyCopy}
                                as="p"
                                config={{
                                    default: {
                                        baseTheme: 'bodyXSmall',
                                        themes: {
                                            large: 'bodyMedium',
                                        },
                                    },
                                }}
                            >
                                {bodyCopy}
                            </Text.Theme>
                        </Stagger.Child>
                        <Stagger.Child className={styles.linkWrapper} order={2}>
                            <Button
                                className={styles.link}
                                href="/where-to-buy"
                                theme="filledCreamHoverWhite-ultrabold"
                            >
                                Where to buy
                            </Button>
                        </Stagger.Child>
                    </Stagger>
                </div>
                <div className={styles.track}>
                    {items?.map((item, index) => {
                        const slot = (index + activeIndex) % items.length;

                        return (
                            <Slide
                                key={index}
                                slot={slot}
                                animationState={animationState}
                                {...item}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

BlockWhereToBuy.propTypes = {
    bodyCopy: PropTypes.string,
    images: PropTypes.array,
    lastBlock: PropTypes.bool,
};

BlockWhereToBuy.defaultProps = {
    bodyCopy:
        'From bold cheddar to extra creamy ice cream, find Tillamook near you',
    images: [],
};

export default BlockWhereToBuy;
