import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/future/image';
import ContentfulImg from 'components/globals/ContentfulImg';

const Img = ({
    alt,
    className,
    customSources,
    fallbackImageWidth,
    height,
    loading,
    priority,
    sizes,
    src,
    width,
    ...rest
}) => {
    if (src.includes('downloads.ctfassets.net')) {
        console.error(
            `This following image is over 20mg and bypassing Contentful's Image API.: \n ${src} \n`
        );
        return null;
    }

    if (src.includes('images.ctfassets.net')) {
        return (
            <ContentfulImg
                alt={alt}
                className={className}
                customSources={customSources}
                fallbackImageWidth={fallbackImageWidth}
                loading={loading}
                priority={priority}
                src={src}
                height={height}
                width={width}
                {...rest}
            />
        );
    }
    return (
        <NextImage
            className={className}
            src={src}
            alt={alt}
            height={height}
            width={width}
            sizes={sizes}
            loading={priority ? 'eager' : 'lazy'}
            fetchpriority={priority ? 'high' : 'low'}
            priority={priority}
            {...rest}
        />
    );
};

Img.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    customSources: PropTypes.array,
    draggable: PropTypes.bool,
    fallbackImageWidth: PropTypes.number,
    height: PropTypes.number,
    loading: PropTypes.oneOf(['eager', 'lazy']),
    priority: PropTypes.bool,
    src: PropTypes.string.isRequired,
    sizes: PropTypes.string,
    width: PropTypes.number,
};

Img.defaultProps = {
    alt: '',
    className: null,
    customSources: null,
    draggable: false,
    fallbackImageWidth: 1280,
    loading: 'lazy',
    priority: false,
    sizes: '100vw',
    src: '',
};

export default memo(Img);
