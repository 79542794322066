import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';

import styles from './CardFanStoryShare.module.scss';

const colorMap = {
    Orange: 'orange',
    Blue: 'blue',
};

const iconMap = {
    Message: 'messageBubbles',
    Heart: 'heart',
};

const CTAButton = ({ cta, theme, iconType }) => (
    <Button
        className={styles.cta}
        theme={theme}
        href={cta.link.url}
        iconLeading={iconType}
    >
        {cta.text}
    </Button>
);

CTAButton.propTypes = {
    cta: PropTypes.shape({
        link: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }),
        text: PropTypes.string,
    }),
    iconType: PropTypes.string,
    theme: PropTypes.string,
};

CTAButton.defaultProps = {
    iconType: null,
};

const CardFanStoryShare = ({
    icon,
    backgroundColor,
    headline,
    description,
    ctaButton,
}) => {
    return (
        <article
            className={cx(styles.root, [
                styles[`${colorMap[backgroundColor]}Background`],
            ])}
        >
            <div className={styles.iconWrapper}>
                <SvgIcon iconType={iconMap[icon]} />
            </div>
            <Text
                className={styles.headline}
                baseTheme="headingMedium"
                themes={{ medium: 'headingLarge' }}
            >
                {headline}
            </Text>
            <Text
                className={styles.description}
                baseTheme="bodySmall"
                themes={{ medium: 'bodyMedium' }}
            >
                {description}
            </Text>
            <CTAButton {...ctaButton} />
        </article>
    );
};

CardFanStoryShare.propTypes = {
    icon: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    ctaButton: PropTypes.object.isRequired,
};

export default CardFanStoryShare;
