import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CardTillamaps from 'components/cards/CardTillamaps';
import Grid from 'components/ui/Grid';

import styles from './BlockFoodServiceContactForm.module.scss';

const BlockFoodServiceContactForm = ({
    card,
    formId,
    flushTop,
    flushBottom,
}) => {
    const [wrapperHeight, updateWrapperHeight] = useState(0);

    useEffect(() => {
        const handleMessage = msg => {
            if (msg?.origin === `https://${formId}.paperform.co`) {
                try {
                    const parsedData = JSON.parse(msg?.data || '{}') || null;
                    const height = parsedData?.height || null;

                    if (height) {
                        updateWrapperHeight(height);
                    }
                } catch (e) {}
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [formId]);

    return (
        <div
            className={cx(styles.contact, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <Grid>
                <div className={styles.contactInner}>
                    <div
                        style={{ height: wrapperHeight, width: '100%' }}
                        className={styles.iframeWrapper}
                    >
                        <iframe
                            scrolling="no"
                            frameBorder="0"
                            width="100%"
                            src={`https://${formId}.paperform.co?embed=1&inline=1`}
                            height="100%"
                        ></iframe>
                    </div>
                </div>
                <CardTillamaps {...card} />
            </Grid>
        </div>
    );
};

BlockFoodServiceContactForm.propTypes = {
    card: PropTypes.object.isRequired,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    formId: PropTypes.string.isRequired,
};

BlockFoodServiceContactForm.defaultProps = {
    flushTop: false,
    flushBottom: false,
};

export default BlockFoodServiceContactForm;
