import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'components/ui/Button';
import Text from 'components/ui/Text';
import Img from 'components/ui/Img';

import styles from './BlockFeaturedLink.module.scss';

const BlockFeaturedLink = ({
    image,
    imageLarge,
    flushTop,
    flushBottom,
    eyebrow,
    headline,
    cta,
}) => {
    return (
        <div
            className={cx(styles.container, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.background}>
                    <Img
                        className={styles.image}
                        src={image?.file.url}
                        fallbackImageWidth={732}
                        customSources={[
                            {
                                breakpoint: 768,
                                src: imageLarge?.file.url,
                                imageWidth: 1420,
                            },
                            {
                                src: image?.file.url,
                                imageWidth: 732,
                            },
                        ]}
                    />
                </div>
                <div className={styles.foreground}>
                    <Text
                        className={styles.eyebrow}
                        baseTheme="labelSmall"
                        themes={{ large: 'labelLarge' }}
                    >
                        {eyebrow}
                    </Text>
                    <Text
                        className={styles.headline}
                        baseTheme="headingLarge"
                        themes={{ large: 'headingXLarge' }}
                    >
                        {headline}
                    </Text>
                    <Button
                        className={styles.button}
                        theme="outlinedCream"
                        entry={cta?.link}
                    >
                        {cta?.text}
                    </Button>
                </div>
            </div>
        </div>
    );
};

BlockFeaturedLink.propTypes = {
    image: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    cta: PropTypes.shape({
        link: PropTypes.object,
        text: PropTypes.string,
    }),
    headline: PropTypes.string.isRequired,
    eyebrow: PropTypes.string,
};

BlockFeaturedLink.defaultProps = {
    flushTop: false,
    flushBottom: false,
};

export default BlockFeaturedLink;
