import React, { useState, useEffect, Fragment } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import Portal from 'components/ui/Portal';
import Text from 'components/ui/Text';
import Link from 'components/ui/Link';
import RichText from 'components/ui/RichText';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import SvgIcon from 'components/ui/SvgIcon';

import { getEntryLink } from 'components/ui/Link/EntryLink';

import { localStorageSupport, saveToLocalStorage } from 'utils';

import AlertSvgIcon from '../AlertSvgIcon';
import iconMap from '../AlertSvgIcon/iconMap';

import styles from './Card.module.scss';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h3"
                    className={styles.headline}
                    baseTheme="displayXSmall"
                    themes={{ large: 'displaySmall' }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.bodyCopy}
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodyMedium',
                    }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const Card = ({ icon, icon2, headline, bodyCopy, ctaButton, id }) => {
    const [isOpen, toggleOpen] = useState(false);
    const [isVisible, toggleVisibility] = useState(false);
    const router = useRouter();

    function wait(ms) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    // Check local storage for the CMS id of this alert
    useEffect(() => {
        if (localStorageSupport() && !localStorage.getItem(id)) {
            // Display alert IF alert id is not in local storage
            toggleOpen(true);
            toggleVisibility(true);
        }
    }, [id]);

    function handleClose() {
        // Save CMS alert id to local storage to avoid rendering alert again
        localStorage.setItem(id, 1);
        toggleVisibility(false);

        // Transition card off before render
        wait(600).then(() => {
            toggleOpen(false);
        });
    }

    const handleCTAClick = async e => {
        if (e) {
            e.preventDefault();
            await saveToLocalStorage(id, 1);
            handleRouting();
        }
    };

    const handleRouting = () => {
        const { link } = ctaButton?.cta;

        if (link) {
            const { href, as } = getEntryLink(link);

            // internal link
            if (as) {
                router.push(href, as);
            } else {
                window.location = href;
            }
        }
    };

    return (
        <Fragment>
            {isOpen && (
                <Portal>
                    <div
                        className={cx(
                            styles.root,
                            !isVisible && styles.isClosing
                        )}
                    >
                        <div className={styles.card}>
                            <Button
                                theme=""
                                onClick={handleClose}
                                className={styles.closeButton}
                            >
                                <div className={styles.closeIconBuffer}>
                                    <div className={styles.closeIconWrapper}>
                                        <SvgIcon iconType="close" />
                                    </div>
                                </div>
                            </Button>
                            {icon2?.icon?.file?.url && (
                                <div className={styles.icon}>
                                    <Icon src={icon2?.icon?.file?.url} />
                                </div>
                            )}
                            {iconMap[icon] && !icon2?.icon?.file?.url && (
                                <div className={styles.icon}>
                                    <AlertSvgIcon iconSrc={iconMap[icon]} />
                                </div>
                            )}
                            <div className={styles.headlineWrapper}>
                                <RichText
                                    richText={headline}
                                    overrides={headlineOverrides}
                                />
                            </div>
                            <div className={styles.bodyCopyWrapper}>
                                <RichText
                                    richText={bodyCopy}
                                    overrides={copyOverrides}
                                />
                            </div>
                            {ctaButton?.cta?.link && (
                                <Button
                                    theme="filledBlueHoverWhite-ultrabold"
                                    entry={ctaButton.cta.link}
                                    onClick={handleCTAClick}
                                >
                                    <Text>{ctaButton.cta.text}</Text>
                                </Button>
                            )}
                        </div>
                    </div>
                </Portal>
            )}
        </Fragment>
    );
};

Card.propTypes = {
    icon: PropTypes.string,
    icon2: PropTypes.object,
    headline: PropTypes.object.isRequired,
    bodyCopy: PropTypes.object.isRequired,
    ctaButton: PropTypes.shape({
        cta: PropTypes.shape({
            link: PropTypes.object.isRequired,
            text: PropTypes.string,
        }),
        iconType: PropTypes.string,
        theme: PropTypes.string,
    }),
    id: PropTypes.string.isRequired,
};

Card.defaultProps = {
    ctaButton: null,
};

export default Card;
