import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Img from 'components/ui/Img';

import styles from './Image.module.scss';

const Image = ({ image, isTop, isMiddle, isBottom, isPrev, isForward }) => {
    return (
        <figure
            className={cx(styles.root, {
                [styles.top]: isTop,
                [styles.middle]: isMiddle,
                [styles.bottom]: isBottom,
                [styles.prev]: isPrev,
                [styles.isForward]: isForward,
            })}
        >
            {image.file?.url && image.file?.details?.image && (
                <Img
                    className={styles.image}
                    src={image.file.url}
                    fallbackImageWidth={278 * 2}
                    alt={image.description || ''}
                    customSources={[
                        {
                            breakpoint: 1024,
                            src: image.file.url,
                            imageWidth: 700 * 2,
                        },
                        {
                            breakpoint: 768,
                            src: image.file.url,
                            imageWidth: 600 * 2,
                        },
                        {
                            src: image.file.url,
                            imageWidth: 278 * 2,
                        },
                    ]}
                />
            )}
        </figure>
    );
};

Image.propTypes = {
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    isTop: PropTypes.bool,
    isMiddle: PropTypes.bool,
    isBottom: PropTypes.bool,
    isPrev: PropTypes.bool,
    isForward: PropTypes.bool,
};

Image.defaultProps = {};

export default Image;
