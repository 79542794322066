import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { getColor } from 'utils';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';
import Stagger from 'components/ui/Stagger';
import RichText from 'components/ui/RichText';
import CTA from 'components/ui/CTA';
import Link from 'components/ui/Link';

import styles from './TextEyebrow.module.scss';

const Eyebrow = ({ children, theme }) => {
    return (
        <Text.Theme
            config={{
                'makers-reserve': {
                    baseTheme: 'labelSmallAlt',
                    themes: { medium: 'labelMediumAlt' },
                },
                default: {
                    baseTheme:
                        theme === 'display' ? 'labelSmall' : 'labelLarge',
                    themes: { large: 'labelLarge' },
                },
            }}
            className={cx(styles.innerHeadline, {
                [styles.innerHeadlineDisplay]: theme === 'display',
            })}
            as="h3"
        >
            {children}
        </Text.Theme>
    );
};

Eyebrow.propTypes = {
    theme: PropTypes.oneOf([
        'normal',
        'display',
        'masthead',
        'displaySmall',
        'displaySmallToLarge1',
        'displayLarge',
        'ultrabold',
    ]),
    children: PropTypes.any,
};

Eyebrow.defaultProps = {
    theme: 'normal',
};

const Headline = ({ children, theme, headlineAs, fixWidows }) => {
    const textThemeConfig = {
        'makers-reserve': {
            baseTheme: 'parkinsonMedium',
            themes: { medium: 'parkinsonLarge' },
        },
        default: {
            baseTheme:
                {
                    displaySmallToLarge1: 'displaySmall',
                    displaySmall: 'displayXSmall',
                    masthead: 'displaySmall',
                    display: 'displayXSmall',
                    displayLarge: 'displayLarge',
                    ultrabold: 'displaySmall',
                }[theme] || 'headingMedium',
            themes: {
                displaySmallToLarge1: {
                    large: 'displayLarge',
                },
                displaySmall: {
                    xLarge: 'displayMedium',
                },
                masthead: { large: 'displayLarge' },
                display: { large: 'displayLarge' },
                displayLarge: {
                    large: 'displayXLarge',
                },
                ultrabold: {
                    large: 'displayMedium',
                },
            }[theme] || { large: 'headingLarge' },
        },
    };

    const richTextHeadlineOverrides = {
        // This headline should always come in as a paragraph from the CMS.
        // It is not possible to restrict input to H2.
        renderMark: {
            [MARKS.BOLD]: text => <em>{text}</em>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text.Theme
                        config={textThemeConfig}
                        as={headlineAs}
                        className={styles.paragraph}
                    >
                        {children}
                    </Text.Theme>
                );
            },
            // Override for rBST link in product category index
            [INLINES.HYPERLINK]: node => {
                return (
                    <Link
                        target="_self"
                        href={node.data.uri}
                        className={styles.richTextLink}
                    >
                        {node.content[0].value}
                    </Link>
                );
            },
        },
    };

    if (typeof children === 'object') {
        return (
            <RichText
                overrides={richTextHeadlineOverrides}
                richText={children}
            />
        );
    }

    return (
        <Text.Theme
            config={textThemeConfig}
            as={headlineAs}
            fixWidows={fixWidows}
        >
            {children}
        </Text.Theme>
    );
};

Headline.propTypes = {
    theme: PropTypes.oneOf([
        'normal',
        'display',
        'masthead',
        'displaySmall',
        'displaySmallToLarge1',
        'displayLarge',
        'ultrabold',
    ]),
    headlineAs: PropTypes.string,
    children: PropTypes.any,
    fixWidows: PropTypes.bool,
};

Headline.defaultProps = {
    theme: 'normal',
    fixWidows: true,
};

const TextEyebrow = ({
    theme,
    eyebrow,
    headline,
    richHeadline,
    className,
    icon,
    globalTheme,
    eyebrowColor,
    headlineColor,
    iconColor,
    cta,
    ctaButton,
    fixWidows,
    disableAnimation,
}) => {
    return (
        <Stagger
            className={cx(
                styles.root,
                className,
                styles[theme],
                styles[`theme--${globalTheme}`]
            )}
            style={{
                '--eyebrow': eyebrowColor && getColor(eyebrowColor),
                '--headline': headlineColor && getColor(headlineColor),
                '--icon': iconColor && getColor(iconColor),
            }}
        >
            {eyebrow && (
                <Stagger.Child
                    order={0}
                    className={styles.innerTextWrapper}
                    style={{
                        color: 'var(--eyebrow)',
                    }}
                    animation={disableAnimation ? 'disabled' : 'fadeUp'}
                >
                    <Eyebrow theme={theme}>{eyebrow}</Eyebrow>
                </Stagger.Child>
            )}
            {(headline || richHeadline) && (
                <Stagger.Child
                    order={1}
                    as="h2"
                    className={cx(styles.innerTextWrapper, styles.headline)}
                    style={{
                        color: 'var(--headline)',
                    }}
                    animation={disableAnimation ? 'disabled' : 'fadeUp'}
                >
                    <Headline
                        headlineAs={'span'}
                        theme={theme}
                        fixWidows={fixWidows}
                    >
                        {richHeadline || headline}
                    </Headline>
                </Stagger.Child>
            )}
            {icon !== 'none' && icon === 'arrowRight' && (
                <Stagger.ArrowRight
                    order={2}
                    style={{
                        color: 'var(--icon)',
                    }}
                    animation={disableAnimation ? 'disabled' : 'pop'}
                    theme={
                        {
                            displaySmallToLarge1: 'large',
                            display: 'smallMobile',
                        }[theme] || 'small'
                    }
                    className={styles.iconWrapper}
                />
            )}
            {icon !== 'none' && icon !== 'arrowRight' && (
                <Stagger.Child
                    order={2}
                    style={{
                        color: 'var(--icon)',
                    }}
                    animation={disableAnimation ? 'disabled' : 'fadeUp'}
                    className={cx(styles.iconWrapper, {
                        [styles.iconWrapperDisplay]: theme === 'display',
                    })}
                >
                    <div className={styles.icon}>
                        <SvgIcon iconType={icon} styles={cx(styles.icon)} />
                    </div>
                </Stagger.Child>
            )}
            {(cta?.link || cta?.assetDownloadLink) && !ctaButton && (
                <Stagger.Child
                    order={2}
                    className={styles.ctaWrapper}
                    animation={disableAnimation ? 'disabled' : 'fadeUp'}
                >
                    <CTA className={styles.cta} theme={cta.theme} cta={cta}>
                        {cta.text}
                    </CTA>
                </Stagger.Child>
            )}
            {(ctaButton?.cta?.link || ctaButton?.cta?.assetDownloadLink) && (
                <Stagger.Child
                    order={2}
                    className={styles.ctaWrapper}
                    animation={disableAnimation ? 'disabled' : 'fadeUp'}
                >
                    <CTA
                        className={styles.cta}
                        theme={ctaButton.theme}
                        cta={ctaButton.cta}
                        iconLeading={ctaButton.iconType}
                    >
                        {ctaButton.cta.text}
                    </CTA>
                </Stagger.Child>
            )}
        </Stagger>
    );
};

TextEyebrow.propTypes = {
    className: PropTypes.string,
    cta: PropTypes.object,
    ctaButton: PropTypes.object,
    disableAnimation: PropTypes.bool,
    eyebrow: PropTypes.string,
    eyebrowColor: PropTypes.string,
    fixWidows: PropTypes.bool,
    globalTheme: PropTypes.string,
    headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headlineAs: PropTypes.string,
    headlineColor: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    richHeadline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    theme: PropTypes.oneOf([
        'normal',
        'display',
        'masthead',
        'displaySmall',
        'displaySmallToLarge1',
        'displayLarge',
        'ultrabold',
    ]),
};

TextEyebrow.defaultProps = {
    theme: 'normal',
    icon: 'arrowRight',
    headlineAs: 'h2',
    globalTheme: null,
    cta: null,
    fixWidows: true,
    disableAnimation: false,
};

export default TextEyebrow;
