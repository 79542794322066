import { localStorageSupport } from 'utils';
import hash from 'object-hash';
export const event = (eventName, data = {}) => {
    window.dataLayer && window.dataLayer.push({ event: eventName, ...data });
};

export const eventOnce = (eventName, data = {}) => {
    if (localStorageSupport()) {
        if (localStorage.getItem(`${eventName}-${hash(data)}`) === null) {
            event(eventName, data);
            localStorage.setItem(`${eventName}-${hash(data)}`, 'true');
        }
    } else {
        event(eventName, data);
    }
};

export const pageView = url => {
    event('pageview', { url });
};
