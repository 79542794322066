import React from 'react';
import PropTypes from 'prop-types';
import Img from 'components/ui/Img';
import cx from 'classnames';

import { useTheme } from 'hooks/useTheme';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';

import image from './images/image.png';

import styles from './BlockProductFinder.module.scss';

const BlockProductFinder = ({ copy }) => {
    const [globalTheme] = useTheme();

    return (
        <section
            className={cx(styles.root, {
                [styles['theme--makers-reserve']]:
                    globalTheme === 'makers-reserve',
            })}
        >
            <div className={styles.inner}>
                <div className={styles.lockup}>
                    <div className={styles.icon}></div>
                    <Text.Theme
                        className={styles.heading}
                        config={{
                            default: {
                                baseTheme: 'displayMedium',
                                themes: {
                                    large: 'displayXXLarge',
                                },
                            },
                        }}
                        as="h2"
                    >
                        Find it
                        <br />
                        near
                        <br />
                        <strong>you</strong>
                    </Text.Theme>
                    <Text.Theme
                        className={styles.copy}
                        as="p"
                        config={{
                            default: {
                                baseTheme: 'bodyXSmall',
                                themes: {
                                    large: 'bodyMedium',
                                },
                            },
                        }}
                    >
                        {copy}
                    </Text.Theme>
                    <Button
                        href="/where-to-buy"
                        theme="filledCreamHoverWhite-ultrabold"
                    >
                        Where to buy
                    </Button>
                </div>
                <Img
                    className={styles.image}
                    src={image.src}
                    width={1317}
                    height={664}
                    sizes="(max-width: 1024px) 100vw, 50vw"
                    alt="Various Products"
                />
            </div>
        </section>
    );
};

BlockProductFinder.propTypes = {
    copy: PropTypes.string,
    title: PropTypes.string,
};

BlockProductFinder.defaultProps = {
    title: 'Template',
    copy: 'From bold cheddar to extra creamy ice cream, find Tillamook near you',
};

export default BlockProductFinder;
