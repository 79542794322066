import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import { useTheme } from 'hooks/useTheme';

import Img from 'components/ui/Img';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Stagger from 'components/ui/Stagger';
import RichText from 'components/ui/RichText';

import styles from './BlockPromotional.module.scss';

const BlockPromotional = ({
    className,
    copy,
    cta,
    eyebrow,
    headline,
    image,
    imageLarge,
    flushTop,
    flushBottom,
    lastBlock,
}) => {
    const [globalTheme] = useTheme();
    const isMakersReserve = globalTheme === 'makers-reserve';

    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        baseTheme="bodyXSmall"
                        themes={{
                            large: 'bodySmall',
                        }}
                        fixWidows
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    const renderImage = () => {
        const imageSource = [
            {
                src: image?.file?.url,
                imageWidth: 500,
            },
        ];

        let sources = [...imageSource];

        if (imageLarge?.file?.url) {
            const imageLargeSources = [
                {
                    breakpoint: 1440,
                    src: imageLarge.file.url,
                    imageWidth: 1440 * 1.5,
                },
                {
                    breakpoint: 1024,
                    src: imageLarge.file.url,
                    imageWidth: 1440,
                },
                {
                    breakpoint: 768,
                    src: imageLarge.file.url,
                    imageWidth: 900,
                },
            ];

            sources = [...imageLargeSources, ...imageSource];
        }

        return (
            <div className={styles.imageAspect}>
                {image?.file?.url && (
                    <Img
                        className={styles.image}
                        src={image.file.url}
                        fallbackImageWidth={732}
                        alt={image.description || ''}
                        customSources={sources}
                    />
                )}
            </div>
        );
    };

    return (
        <section
            className={cx(styles.root, className, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.lastBlock]: lastBlock && !flushBottom,
                [styles.makersReserve]: isMakersReserve,
            })}
        >
            <div className={styles.inner}>
                {renderImage()}

                <Stagger className={styles.content}>
                    {eyebrow && (
                        <Stagger.Child order={0} className={styles.eyebrow}>
                            <Text.Theme
                                className={styles.eyebrowText}
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'labelSmallAlt',
                                        themes: {
                                            large: 'labelMediumAlt',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'labelSmall',
                                        themes: {
                                            large: 'labelLarge',
                                        },
                                    },
                                }}
                                as="p"
                            >
                                {eyebrow}
                            </Text.Theme>
                        </Stagger.Child>
                    )}
                    {headline && (
                        <Stagger.Child order={1} className={styles.headline}>
                            <Text.Theme
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'parkinsonMedium',
                                        themes: {
                                            large: 'parkinsonLarge',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'displaySmall',
                                        themes: {
                                            large: 'displayMedium',
                                            xxLarge: 'displayLarge',
                                        },
                                    },
                                }}
                                as="h2"
                            >
                                {headline.content.map((line, i) => {
                                    if (line.content[0].value === '') {
                                        return null;
                                    }
                                    return (
                                        <span className={styles.line} key={i}>
                                            {line.content[0].value}
                                        </span>
                                    );
                                })}
                            </Text.Theme>
                        </Stagger.Child>
                    )}
                    {copy && (
                        <Stagger.Child order={2} className={styles.copy}>
                            <RichText
                                richText={copy}
                                overrides={richTextOverrides}
                            />
                        </Stagger.Child>
                    )}
                    {cta?.text && cta?.link && (
                        <Stagger.Child order={3} className={styles.cta}>
                            <Button
                                theme={
                                    {
                                        'makers-reserve':
                                            'filledGoldReserveCream',
                                        default: 'filledCreamDark',
                                    }[globalTheme || 'default']
                                }
                                entry={cta.link}
                            >
                                {cta.text}
                            </Button>
                        </Stagger.Child>
                    )}
                </Stagger>
            </div>
        </section>
    );
};

BlockPromotional.propTypes = {
    className: PropTypes.string,
    copy: PropTypes.object,
    cta: PropTypes.shape({
        assetDownloadLink: PropTypes.object,
        link: PropTypes.shape({
            contentTypeId: PropTypes.string,
        }),
        text: PropTypes.string,
    }),
    eyebrow: PropTypes.string,
    headline: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    lastBlock: PropTypes.bool,
};

BlockPromotional.defaultProps = {
    flushTop: false,
    flushBottom: false,
    lastBlock: false,
};

export default BlockPromotional;
