import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Block from 'components/blocks/';
import styles from './BlockRecommendedProducts.module.scss';

const BlockRecommendedProducts = ({ products, flushTop, flushBottom }) => {
    if (!products || products.length < 2) {
        return null;
    }
    return (
        <div
            className={cx(styles.recommended, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <Block
                theme="Recommended"
                cards={[
                    {
                        contentTypeId: 'callout',
                        richHeadline: {
                            nodeType: 'document',
                            content: [
                                {
                                    nodeType: 'paragraph',
                                    content: [
                                        {
                                            nodeType: 'text',
                                            value: 'More products you’ll love',
                                            marks: [],
                                        },
                                    ],
                                },
                            ],
                        },
                        icon: 'morningStar',
                        theme: 'Recommended',
                    },
                    ...products.map(product => ({
                        ...product,
                        title: product.displayName,
                        image: product.image,
                    })),
                ]}
                contentTypeId="blockCardGroup"
            />
        </div>
    );
};

BlockRecommendedProducts.propTypes = {
    products: PropTypes.array.isRequired,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

BlockRecommendedProducts.defaultProps = { flushTop: false, flushBottom: false };

export default BlockRecommendedProducts;
