import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CardCampaign from 'components/cards/CardCampaign';
import CardRecipe from 'components/cards/CardRecipe';
import CardProduct from 'components/cards/CardProduct';
import CardLeadership from 'components/cards/CardLeadership';
import CardEvent from 'components/cards/CardEvent';
import CardDownload from 'components/cards/CardDownload';
import CardLatest from 'components/cards/CardLatest';
import CardNewsDetail from 'components/cards/CardNewsDetail';
import CardIconStat from 'components/cards/CardIconStat';
import CardSmall from 'components/cards/CardSmall';

import Button from 'components/ui/Button';
import SvgIcon from 'components/ui/SvgIcon';
import TextEyebrow from 'components/ui/TextEyebrow';

import Block from 'components/blocks/';

import CardGroupCarousel from './CardGroupCarousel';

import styles from './BlockCardGroup.module.scss';

const cardMap = {
    recipe: CardRecipe,
    cardCampaign: CardCampaign,
    product: CardProduct,
    cardLeadership: CardLeadership,
    event: CardEvent,
    cardDownload: CardDownload,
    callout: props => <Block {...props} contentTypeId={'blockCallout'} />,
    cardLatest: CardLatest,
    news: CardNewsDetail,
    cardIconStat: CardIconStat,
    cardSmall: CardSmall,
    default: null,
};

const BlockCardGroup = ({
    theme,
    eyebrow,
    headline,
    cards,
    cta,
    flushTop,
    hasArrowIcon,
    flushBottom,
    navigationTheme,
}) => {
    const themeConfig = {
        'Full Width': {
            className: 'fullWidth',
            icon: hasArrowIcon ? 'arrowRight' : 'none',
            buttonTheme: 'outlinedBlue',
            eyebrowTheme: 'display',
        },
        Featured: {
            className: 'featured',
            icon: hasArrowIcon ? 'arrowRight' : 'none',
            buttonTheme: 'outlinedCream',
            eyebrowTheme: 'display',
        },
        Default: {
            className: 'default',
            icon: hasArrowIcon ? 'arrowRight' : 'none',
            buttonTheme: 'outlinedBlue',
            eyebrowTheme: 'display',
        },
        Leadership: {
            className: 'leadership',
            icon: hasArrowIcon ? 'arrowRight' : 'none',
            buttonTheme: 'outlinedBlue',
            eyebrowTheme: 'display',
        },
        Latest: {
            className: 'latest',
            icon: hasArrowIcon ? 'arrowRight' : 'none',
            buttonTheme: 'outlinedBlue',
            eyebrowTheme: 'display',
        },
        Events: {
            className: 'events',
            icon: hasArrowIcon ? 'arrowRight' : 'none',
            buttonTheme: 'outlinedBlue',
            eyebrowTheme: 'display',
        },
        Campaign: {
            className: 'campaign',
            icon: hasArrowIcon ? 'arrowRight' : 'none',
            buttonTheme: 'outlinedBlue',
            eyebrowTheme: 'display',
        },
        Recommended: {
            className: 'recommended',
            icon: hasArrowIcon ? 'arrowRight' : 'none',
            buttonTheme: 'outlinedBlue',
            eyebrowTheme: 'display',
        },
        Download: {
            className: 'download',
            icon: hasArrowIcon ? 'arrowRight' : 'none',
            buttonTheme: 'outlinedBlue',
            eyebrowTheme: 'display',
        },
        Ultrabold: {
            className: 'ultrabold',
            icon: 'none',
            buttonTheme: 'outlinedBlue',
            eyebrowTheme: 'ultrabold',
        },
    };

    useEffect(() => {
        if (!cards) {
            console.warn('No cards provided. Three cards required for render.');
        } else {
            if (cards.length < 2) {
                console.warn('At least two cards required for render.');
            }
        }
    }, [cards]);

    if (cards) {
        return (
            <section
                className={cx(
                    styles.cardGroup,
                    styles[themeConfig[theme].className],
                    {
                        [styles.flushTop]: flushTop,
                        [styles.flushBottom]: flushBottom,
                    }
                )}
            >
                {theme === 'Featured' && (
                    <div className={styles.featuredBg} role="presentation">
                        <SvgIcon iconType="tillamapsBarn" />
                    </div>
                )}

                {headline && (
                    <TextEyebrow
                        className={styles.eyebrow}
                        eyebrow={eyebrow}
                        headline={headline}
                        icon={themeConfig[theme].icon}
                        theme={themeConfig[theme].eyebrowTheme}
                    />
                )}

                <CardGroupCarousel
                    cardMap={cardMap}
                    cards={cards}
                    theme={theme}
                    navigationTheme={navigationTheme}
                />

                {cta?.text && cta?.link && (
                    <Button
                        className={styles.cta}
                        theme={themeConfig[theme].buttonTheme}
                        entry={cta.link}
                    >
                        {cta.text}
                    </Button>
                )}
            </section>
        );
    }

    return null;
};

BlockCardGroup.propTypes = {
    theme: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    hasArrowIcon: PropTypes.bool,
    cards: PropTypes.array.isRequired,
    cta: PropTypes.object,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    navigationTheme: PropTypes.oneOf(['blue', 'cream']),
};

BlockCardGroup.defaultProps = {
    eyebrow: '',
    headline: '',
    cta: null,
    flushTop: false,
    theme: 'Default',
    flushBottom: false,
    hasArrowIcon: true,
};

export default BlockCardGroup;
