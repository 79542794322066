import React from 'react';
import PropTypes from 'prop-types';

import CarouselProvider from 'components/ui/CarouselProvider';
import CarouselShowcaseWithCard from './CarouselShowcaseWithCard';

const BlockCarouselShowcaseWithCard = ({
    slides,
    timerDuration,
    isContinuous,
    flushTop,
    flushBottom,
}) => {
    return (
        <CarouselProvider
            slides={slides}
            timerDuration={timerDuration}
            isContinuous={isContinuous}
        >
            <CarouselShowcaseWithCard
                flushTop={flushTop}
                flushBottom={flushBottom}
            />
        </CarouselProvider>
    );
};

BlockCarouselShowcaseWithCard.propTypes = {
    className: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
    isContinuous: PropTypes.bool,
};

BlockCarouselShowcaseWithCard.defaultProps = {
    imageOnLeft: false,
    timerDuration: 7,
    isContinuous: false,
    flushTop: false,
    flushBottom: false,
};

export default BlockCarouselShowcaseWithCard;
