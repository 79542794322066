import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Link from 'components/ui/Link';

// TODO: Update Event Card Link to contain slug when events pages have been created

import Img from 'components/ui/Img';

import styles from './CardEvent.module.scss';

const CardRecipe = ({
    headline,
    slug,
    location,
    image,
    imageLarge,
    time,
    date,
}) => {
    console.warn(`Need to add slug (${slug}) to CardEvent component`);
    return (
        <article className={cx(styles.event, {})}>
            <Link href="/events" className={styles.inner}>
                <div className={styles.inner}>
                    {image?.file?.url && image?.file?.details?.image && (
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            fallbackImageWidth={500}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: imageLarge.file.url,
                                    imageWidth:
                                        imageLarge.file.details.image.width,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: image.file.details.image.width,
                                },
                            ]}
                        />
                    )}
                </div>
                <div className={styles.infoWrapper}>
                    <div className={styles.infoLockup}>
                        <Text
                            className={styles.dateLabel}
                            baseTheme="labelSmall"
                        >
                            {date}
                        </Text>
                        <Text
                            className={styles.separator}
                            baseTheme="labelSmall"
                        >
                            |
                        </Text>
                        <Text
                            className={styles.timeLabel}
                            baseTheme="labelSmall"
                        >
                            {time}
                        </Text>
                    </div>
                    <Text
                        className={styles.headline}
                        baseTheme="headingSmall"
                        themes={{ xxLarge: 'headingMedium' }}
                        as="h5"
                    >
                        {headline}
                    </Text>
                    <Text
                        className={styles.location}
                        baseTheme="bodySmall"
                        as="h6"
                    >
                        {location}
                    </Text>
                </div>
            </Link>
        </article>
    );
};

CardRecipe.propTypes = {
    headline: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    imageLarge: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
};

export default CardRecipe;
