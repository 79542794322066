const footerProps = {
    membershipHeadline: 'Subscribe to the Newsletter',
    emailPlaceholder: 'Enter your email',
    emailErrorMessage: 'Email is invalid',
    emailSuccessMessage: 'Thank You!',
    socialLinks: [],
    linkGroups: [],
    bCorpInfo: {
        text: 'Tillamook is a Certified B Corp',
    },
};

export default footerProps;
