import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import RichText from 'components/ui/RichText';

import Img from 'components/ui/Img';

import styles from './BlockSimpleImageParagraph.module.scss';

const BlockSimpleImageParagraph = ({
    image,
    richCopy,
    flushTop,
    flushBottom,
    className,
}) => {
    return (
        <section
            className={cx(styles.blockSimpleImageParagraph, className, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                {image?.file?.url && (
                    <Img
                        className={styles.image}
                        src={image.file.url}
                        fallbackImageWidth={500}
                        alt={image.description || ''}
                        customSources={[
                            {
                                src: image.file.url,
                                imageWidth: 500,
                            },
                        ]}
                    />
                )}
                {richCopy && (
                    <div className={styles.copy}>
                        <RichText richText={richCopy} />
                    </div>
                )}
            </div>
        </section>
    );
};

BlockSimpleImageParagraph.propTypes = {
    richCopy: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    className: PropTypes.string,
};

BlockSimpleImageParagraph.defaultProps = {
    flushTop: true,
    flushBottom: true,
};

export default BlockSimpleImageParagraph;
