import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';
import ButtonCircle from 'components/ui/ButtonCircle';

import Tab from './Tab';

import { useIntersection } from 'react-use';
import useScrollDirection from 'hooks/useScrollDirection';

import styles from './BlockTabs.module.scss';

const BlockTabs = ({ eyebrow, headline, tabs, className }) => {
    const ref = useRef(null);
    const refSticky = useRef(null);
    const contentRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [previousId, setPreviousId] = useState('');
    const [activeId, setActiveId] = useState(tabs?.[0].hashSlug || 0);

    const scrollDirection = useScrollDirection();

    const intersection = useIntersection(refSticky, {
        rootMargin: '0px',
        threshold: 1,
    });

    useEffect(() => {
        if (intersection && intersection.intersectionRatio < 1) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    }, [intersection]);

    const handleItemClick = e => {
        setPreviousId(activeId);

        const id = e.currentTarget.getAttribute('data-id');
        setActiveId(id);
        setIsOpen(false);
        scrollToContent();
    };

    const scrollToContent = () => {
        contentRef.current.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const handleOpenClick = () => {
        setIsOpen(true);
    };

    const handleCloseClick = () => {
        setIsOpen(false);
    };

    const handleOutsideClick = event => {
        if (ref?.current && !ref?.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        toggleEventListener('add');
        return () => {
            toggleEventListener('remove');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleEventListener = method => {
        document[`${method}EventListener`]('click', handleOutsideClick);
    };

    useEffect(() => {
        if (scrollDirection === 'down' || scrollDirection === 'up') {
            isOpen && setIsOpen(false);
        }
    }, [isOpen, scrollDirection]);

    return (
        <section ref={ref} className={cx(styles.blockTabs, className)}>
            {headline && (
                <TextEyebrow
                    className={styles.textEyebrow}
                    eyebrow={eyebrow ? eyebrow : ''}
                    headline={headline}
                    theme="display"
                />
            )}
            {tabs && (
                <div
                    ref={refSticky}
                    className={cx(styles.tabsNav, {
                        [styles.isOpen]: isOpen,
                        [styles.isSticky]: isSticky,
                        [styles.isFlushTop]: scrollDirection === 'down',
                    })}
                >
                    <div className={styles.tabsNavAbsInner}>
                        <div
                            className={styles.tabsNavAbsInnerBack}
                            onClick={handleCloseClick}
                        />
                        <div
                            className={styles.tabsNavHeader}
                            onClick={handleOpenClick}
                        >
                            <Text
                                as="span"
                                baseTheme="labelLarge"
                                className={styles.label}
                            >
                                {activeId}
                            </Text>
                            <ButtonCircle
                                className={styles.openButton}
                                onClick={handleCloseClick}
                                theme="chevronDownCreamDark"
                            />
                        </div>
                        <div className={styles.tabsNavInner}>
                            {tabs.map((tab, i) => {
                                return (
                                    <div
                                        className={cx(styles.tabLink, {
                                            [styles.isActive]:
                                                activeId === tab.hashSlug,
                                        })}
                                        onClick={handleItemClick}
                                        data-id={tab.hashSlug || ''}
                                        key={i}
                                    >
                                        <Text
                                            as="div"
                                            baseTheme="labelLarge"
                                            className={styles.label}
                                        >
                                            {tab.label || ''}
                                        </Text>
                                    </div>
                                );
                            })}
                            <ButtonCircle
                                className={styles.closeButton}
                                onClick={handleCloseClick}
                                theme="chevronDownCreamDark"
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.blockTabsInner}>
                <div
                    ref={contentRef}
                    className={styles.blockTabsAnchor}
                    role="presentation"
                />
                <section className={cx(styles.tabs, className)}>
                    {tabs &&
                        tabs.map((tab, i) => (
                            <Tab
                                {...tab}
                                isActive={activeId === tab.hashSlug}
                                isPrevious={previousId === tab.hashSlug}
                                key={i}
                            />
                        ))}
                </section>
            </div>
        </section>
    );
};

BlockTabs.propTypes = {
    headline: PropTypes.string,
    eyebrow: PropTypes.string,
    tabs: PropTypes.array,
    className: PropTypes.string,
};

export default BlockTabs;
