import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Stagger from 'components/ui/Stagger';

import styles from './BlockTwoColumnGrid.module.scss';

const BlockTwoColumnGrid = ({
    eyebrow,
    headline,
    body,
    cta,
    image,
    imagePosition,
    flushTop,
    flushBottom,
}) => {
    const richTextBodyOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text as="p" baseTheme="bodyMedium">
                        {children}
                    </Text>
                );
            },
        },
    };

    const richTextHeadlineOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="h3"
                        baseTheme="displaySmall"
                        themes={{ xLarge: 'displayMedium' }}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    return (
        <div
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.imageRight]: imagePosition.toLowerCase() === 'right',
            })}
        >
            <Img
                className={styles.image}
                src={image?.file?.url}
                fallbackImageWidth={768}
            />
            <Stagger className={styles.card}>
                {eyebrow && (
                    <Stagger.Child className={styles.eyebrow} order={0}>
                        <Text as="p" baseTheme="labelLarge">
                            {eyebrow}
                        </Text>
                    </Stagger.Child>
                )}
                {headline && (
                    <Stagger.Child className={styles.headline} order={0}>
                        {typeof headline === 'string' ? (
                            <Text
                                as="h3"
                                baseTheme="displaySmall"
                                themes={{ xLarge: 'displayMedium' }}
                            >
                                {headline}
                            </Text>
                        ) : (
                            <RichText
                                overrides={richTextHeadlineOverrides}
                                richText={headline}
                            />
                        )}
                    </Stagger.Child>
                )}
                {body && (
                    <Stagger.Child className={styles.body} order={1}>
                        {typeof body === 'string' ? (
                            <Text as="p" baseTheme="bodyMedium">
                                {body}
                            </Text>
                        ) : (
                            <RichText
                                overrides={richTextBodyOverrides}
                                richText={body}
                            />
                        )}
                    </Stagger.Child>
                )}
                {cta?.text && cta?.link && (
                    <Stagger.Child className={styles.cta} order={1}>
                        <Button
                            theme="filledBlueHoverWhite-ultrabold"
                            entry={cta.link}
                        >
                            {cta.text}
                        </Button>
                    </Stagger.Child>
                )}
            </Stagger>
        </div>
    );
};

BlockTwoColumnGrid.defaultProps = {
    imagePosition: 'left',
};

BlockTwoColumnGrid.propTypes = {
    body: PropTypes.object.isRequired,
    cta: PropTypes.object.isRequired,
    eyebrow: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    headline: PropTypes.object.isRequired,
    image: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    imagePosition: PropTypes.oneOf(['Left', 'Right']),
};

export default BlockTwoColumnGrid;
