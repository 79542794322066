import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { validateEmail } from 'utils';

import * as klaviyo from 'utils/klaviyo';

import Text from 'components/ui/Text';

import Icon from 'components/ui/Icon';

import Button from 'components/ui/Button';
import Img from 'components/ui/Img';

import styles from './BlockGlobalNewsletter.module.scss';

const image = {
    file: {
        url: '//images.ctfassets.net/j8tkpy1gjhi5/759y3fBwG6ugQMjbe1bJVf/5c08ede4ff94527c65abb79ce2379339/newsletter-mobile.png',
        details: {
            image: { width: 355, height: 285 },
        },
    },
};

const imageLarge = {
    file: {
        url: '//images.ctfassets.net/j8tkpy1gjhi5/1a1dwsXml5mGkO2QkYAliL/d589de753f305f6f1e966c0d149574a7/newsletter.png',
        details: {
            image: { width: 624, height: 624 },
        },
    },
};

const emailPlaceholder = 'Subscribe to the Newsletter';
const emailErrorMessage = 'Email is invalid';
const emailSuccessMessage = 'Subscribed!';

const RETURN_KEYCODE = 13;

const BlockGlobalNewsletter = ({ flushTop, className }) => {
    const [formEmailValue, setFormEmailValue] = useState('');
    const [formEmailError, setFormEmailError] = useState(false);
    const [formEmailSuccess, setFormEmailSuccess] = useState(false);
    const emailInput = useRef(null);

    const handleSubmit = async () => {
        if (formEmailValue && validateEmail(formEmailValue)) {
            setFormEmailSuccess(true);
            setFormEmailValue('');

            // 1. Run identify first with $email before determineSegmentation for tracking
            window._learnq.push([
                'identify',
                {
                    // Change the line below to dynamically print the user's email.
                    $email: formEmailValue,
                },
            ]);

            // 2. Subscribe email to Klaviyo
            await klaviyo.subscribe(formEmailValue, {
                $fields: '$source',
                $source: 'Newsletter',
            });
        } else {
            setFormEmailError('Invalid Email');
            setFormEmailSuccess(false);
        }
    };

    const handleInput = e => {
        setFormEmailError(false);
        setFormEmailValue(e.currentTarget.value);
    };

    const handleInputKeyDown = e => {
        if (e.keyCode === RETURN_KEYCODE) {
            handleSubmit(e.currentTarget);
        }
    };

    return (
        <article
            className={cx(styles.root, className, {
                [styles.flushTop]: flushTop,
                [styles.error]: formEmailError,
            })}
        >
            <div className={styles.newsLetterInner}>
                <div className={styles.columns}>
                    <div className={styles.column}>
                        <div className={styles.imageWrap}>
                            {image && imageLarge && (
                                <Img
                                    className={styles.image}
                                    src={image.file.url}
                                    fallbackImageWidth={732 * 2}
                                    width={image.file.details?.image?.width}
                                    height={image.file.details?.image?.height}
                                    customSources={[
                                        {
                                            breakpoint: 768,
                                            src: imageLarge.file.url,
                                            imageWidth: 909 * 1.5,
                                        },
                                        {
                                            src: image.file.url,
                                            imageWidth: 732 * 2,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.emblemLockup}>
                            <Icon
                                className={styles.emblemIcon}
                                src="https://images.ctfassets.net/j8tkpy1gjhi5/67sKYNrn3ltIa9KovSefYM/94e322bc19de73a3550221efd1537aab/Heart.svg"
                            />
                        </div>
                        <div className={styles.lockup}>
                            <Text
                                className={styles.headline}
                                baseTheme="displayXSmall"
                                themes={{
                                    large: 'displaySmall',
                                    xLarge: 'displayMedium',
                                }}
                            >
                                {`Join the 
                                Mailing List`}
                            </Text>
                            <Text
                                className={styles.copy}
                                baseTheme="bodySmall"
                                themes={{
                                    xLarge: 'bodyMedium',
                                }}
                            >
                                Subscribe to get personalized updates, offers,
                                early access to new products and more.
                            </Text>
                            <div className={styles.inputLockupWrap}>
                                <div
                                    className={cx(styles.inputLockup, {
                                        [styles.inputSuccess]: formEmailSuccess,
                                    })}
                                >
                                    <Text
                                        className={styles.inputWrap}
                                        baseTheme="bodyMedium"
                                    >
                                        <input
                                            ref={emailInput}
                                            onChange={handleInput}
                                            onKeyDown={handleInputKeyDown}
                                            value={formEmailValue}
                                            className={styles.input}
                                            type="email"
                                            placeholder={
                                                formEmailSuccess
                                                    ? emailSuccessMessage
                                                    : emailPlaceholder
                                            }
                                        />
                                    </Text>
                                </div>
                                <Button
                                    onClick={handleSubmit}
                                    type="submit"
                                    theme="filledBlueHoverCream"
                                    disabled={formEmailSuccess}
                                    className={styles.submitButton}
                                >
                                    Subscribe
                                </Button>
                                <div className={styles.joinError}>
                                    {formEmailError && (
                                        <Text
                                            className={styles.joinErrorInner}
                                            baseTheme="bodyXSmall"
                                        >
                                            {emailErrorMessage}
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
};

BlockGlobalNewsletter.propTypes = {
    flushTop: PropTypes.bool,
    className: PropTypes.string,
    image: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }),
};

BlockGlobalNewsletter.defaultProps = {
    className: null,
};

export default BlockGlobalNewsletter;
