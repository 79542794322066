import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useIntersection, usePrevious } from 'react-use';
import Img from 'components/ui/Img';

import styles from './SlideShow.module.scss';

const SlideShow = ({ slides, dotsOnLeft }) => {
    const [activeIndex, setActiveIndex] = useState(slides.length - 1);
    const [transitioningIndex, setTransitioningIndex] = useState(null);
    const [isInView, setIsInView] = useState(false);

    const previousActiveIndex = usePrevious(activeIndex);
    const ref = useRef(null);
    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const handlePlay = () => {
        setActiveIndex(prevActivIndex => {
            return prevActivIndex < slides.length - 1 ? prevActivIndex + 1 : 0;
        });
    };

    useEffect(() => {
        setIsInView(intersection?.isIntersecting);
    }, [intersection]);

    useEffect(() => {
        if (isInView) {
            handlePlay();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInView]);

    useEffect(() => {
        setTransitioningIndex(previousActiveIndex);
        setTimeout(() => setTransitioningIndex(null), 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex]);

    useEffect(() => {
        if (!isInView) {
            return;
        }

        const interval = setInterval(() => {
            handlePlay();
        }, 10000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInView, activeIndex]);

    return (
        <div ref={ref} className={styles.root}>
            <div className={cx(styles.track, { [styles.paused]: !isInView })}>
                {slides.map((slide, index) => {
                    return (
                        <div
                            key={index}
                            className={cx(styles.slide, {
                                [styles.active]:
                                    activeIndex === index ||
                                    transitioningIndex === index,
                            })}
                        >
                            <Img
                                className={styles.image}
                                src={slide.image.file.url}
                                customSources={[
                                    {
                                        breakpoint: 1440,
                                        src: slide.imageLarge.file.url,
                                        imageWidth: 1440 * 1.5,
                                    },
                                    {
                                        breakpoint: 1024,
                                        src: slide.imageLarge.file.url,
                                        imageWidth: 1024 * 1.5,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: slide.imageLarge.file.url,
                                        imageWidth: 768 * 1.5,
                                    },
                                    {
                                        src: slide.image.file.url,
                                        imageWidth: 768,
                                    },
                                ]}
                            />
                        </div>
                    );
                })}
            </div>
            <div
                className={cx(styles.dots, {
                    [styles.dotsOnLeft]: dotsOnLeft,
                })}
            >
                {slides.map((_, index) => {
                    return (
                        <button
                            key={index}
                            className={cx(styles.dot, {
                                [styles.active]: index === activeIndex,
                            })}
                            onClick={() => setActiveIndex(index)}
                        />
                    );
                })}
            </div>
        </div>
    );
};

SlideShow.propTypes = {
    slides: PropTypes.array.isRequired,
    dotsOnLeft: PropTypes.bool,
};

export default SlideShow;
