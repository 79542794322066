import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const AlertSvgIcon = ({ className, iconSrc, height, width }) => {
    return (
        <span
            className={cx('svg-icon', className)}
            style={{
                width,
                height,
            }}
        >
            <img src={`/images/icons/${iconSrc}.svg`} />
        </span>
    );
};

AlertSvgIcon.defaultProps = {
    tag: 'span',
};

AlertSvgIcon.propTypes = {
    className: PropTypes.string,
    height: PropTypes.string,
    iconSrc: PropTypes.string.isRequired,
    tag: PropTypes.string,
    width: PropTypes.string,
};

export default AlertSvgIcon;
