import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import debug from 'debug';

import Debug from './Debug';

import BlockCategoryDetailIntro from './BlockCategoryDetailIntro';
import BlockCommitmentIntro from './BlockCommitmentIntro';
import BlockFeaturedArticle from './BlockFeaturedArticle';
import BlockFullWidthMedia from './BlockFullWidthMedia';
import BlockHeader from './BlockHeader';
import BlockHero from './BlockHero';
import BlockHeroVideo from './BlockHeroVideo';
import BlockHeroTwoColumn from './BlockHeroTwoColumn';
import BlockHeroSplit from './BlockHeroSplit';
import BlockMissing from './BlockMissing';
import BlockProductList from './BlockProductList';
import BlockFoodServiceProductList from './BlockFoodServiceProductList';
import BlockRichText from './BlockRichText';
import BlockCarouselHero from './BlockCarouselHero';
import BlockSharpnessStoryProxy from './BlockSharpnessStoryProxy';
import BlockDonationForm from './BlockDonationForm';
import BlockCallout from './BlockCallout';
import BlockCreamerySwirlStir from './BlockCreamerySwirlStir';
import BlockCreamCheeseFlavorFinder from './BlockCreamCheeseFlavorFinder';
import BlockProductHero from './BlockProductHero';
import BlockAbout from './BlockAbout';
import BlockGrid from './BlockGrid';
import BlockVideo from './BlockVideo';
import BlockVideoCarousel from './BlockVideoCarousel';
import BlockFeaturedLink from './BlockFeaturedLink';
import BlockImage from './BlockImage';
import BlockSlideShow from './BlockSlideShow';
import BlockHighlight from './BlockHighlight';
import BlockHowItsMade from './BlockHowItsMade';
import BlockGlobal from './BlockGlobal';
import BlockNews from './BlockNews';
import BlockSharpnessStory from './BlockSharpnessStory';
import BlockCardGroup from './BlockCardGroup';
import BlockTwoColumnText from './BlockTwoColumnText';
import BlockTwoColumnTextAlt from './BlockTwoColumnTextAlt';
import BlockTwoColumnImage from './BlockTwoColumnImage';
import BlockTwoColumnGrid from './BlockTwoColumnGrid';
import BlockCarouselShowcase from './BlockCarouselShowcase';
import BlockTestimonial from './BlockTestimonial';
import BlockTabs from './BlockTabs';
import BlockLocation from './BlockLocation';
import BlockCommitmentChart from './BlockCommitmentChart';
import BlockCommitmentHighlights from './BlockCommitmentHighlights';
import BlockAccordion from './BlockAccordion';
import BlockTimeline from './BlockTimeline';
import BlockIssueBriefGoals from './BlockIssueBriefGoals';
import BlockContactForm2 from './BlockContactForm2';
import BlockSimpleImageParagraph from './BlockSimpleImageParagraph';
import BlockAnchorProductList from './BlockAnchorProductList';
import BlockCareersGrid from './BlockCareersGrid';
import BlockCTAGroup from './BlockCTAGroup';
import BlockCardTrioInline from './BlockCardTrioInline';
import BlockTwoColumnRichText from './BlockTwoColumnRichText';
import BlockDownloadList from './BlockDownloadList';
import BlockCardSwipe from './BlockCardSwipe';
import BlockLinkList from './BlockLinkList';
import BlockCardTrioSmall from './BlockCardTrioSmall';
import BlockEyebrow from './BlockEyebrow';
import BlockDownloadableResources from './BlockDownloadableResources';
import BlockAudio from './BlockAudio';
import BlockFanStory from './BlockFanStory';
import BlockFanStoryShare from './BlockFanStoryShare';
import BlockShopPromotional from './BlockShopPromotional';
import BlockSimpleProductList from './BlockSimpleProductList';
import BlockPromotional from './BlockPromotional';
import BlockProductFinder from './BlockProductFinder';
import BlockRecommendedProducts from './BlockRecommendedProducts';
import BlockPaperForm from './BlockPaperForm';
import BlockCarouselShowcaseWithCard from './BlockCarouselShowcaseWithCard';
import BlockFlipDeck from './BlockFlipDeck';
import BlockDeckSlider from './BlockDeckSlider';
import BlockProductSharpnessStory from './BlockProductSharpnessStory';
import BlockProductPairings from './BlockProductPairings';
import BlockProductPairingsSlides from './BlockProductPairingsSlides';
import BlockPromotionPod from './BlockPromotionPod';
import BlockFoodServiceContactForm from './BlockFoodServiceContactForm';
import BlockSimpleCards from './BlockSimpleCards';
import BlockSmallMediaWithText from './BlockSmallMediaWithText';
import BlockWhereToBuy from './BlockWhereToBuy';
import BlockTwoColumnCarousel from './BlockTwoColumnCarousel';

export const BlockContext = createContext();

export const blockMap = {
    blockCategoryDetailIntro: BlockCategoryDetailIntro,
    blockCommitmentIntro: BlockCommitmentIntro,
    blockFeaturedArticle: BlockFeaturedArticle,
    blockFullWidthMedia: BlockFullWidthMedia,
    blockHeader: BlockHeader,
    blockHero: BlockHero,
    blockHeroTwoColumn: BlockHeroTwoColumn,
    blockHeroSplit: BlockHeroSplit,
    blockProducts: BlockProductList,
    blockFoodserviceProducts: BlockFoodServiceProductList,
    blockRichText: BlockRichText,
    blockCarouselHero: BlockCarouselHero,
    blockSharpnessStoryProxy: BlockSharpnessStoryProxy,
    blockDonationForm: BlockDonationForm,
    blockHeroVideo: BlockHeroVideo,
    blockCreamCheeseFlavorFinder: BlockCreamCheeseFlavorFinder,
    blockCreamerySwirlStir: BlockCreamerySwirlStir,
    blockProductHero: BlockProductHero,
    blockAbout: BlockAbout,
    blockGrid: BlockGrid,
    blockVideo: BlockVideo,
    blockVideoCarousel: BlockVideoCarousel,
    blockFeaturedLink: BlockFeaturedLink,
    blockImage: BlockImage,
    blockImageOffsetContent: BlockSlideShow,
    blockHighlight: BlockHighlight,
    blockHowItsMade: BlockHowItsMade,
    blockGlobal: BlockGlobal,
    blockNews: BlockNews,
    blockSharpnessStory: BlockSharpnessStory,
    blockCardGroup: BlockCardGroup,
    blockTwoColumnText: BlockTwoColumnText,
    blockTwoColumnTextAlt: BlockTwoColumnTextAlt,
    blockTwoColumnImage: BlockTwoColumnImage,
    blockTwoColumnGrid: BlockTwoColumnGrid,
    blockCarouselShowcase: BlockCarouselShowcase,
    blockTestimonial: BlockTestimonial,
    blockTabs: BlockTabs,
    blockLocation: BlockLocation,
    blockCommitmentChart: BlockCommitmentChart,
    blockCommitmentHighlights: BlockCommitmentHighlights,
    blockAccordion: BlockAccordion,
    blockTimeline: BlockTimeline,
    blockIssueBriefGoals: BlockIssueBriefGoals,
    blockContactForm2: BlockContactForm2,
    blockSimpleImageParagraph: BlockSimpleImageParagraph,
    blockAnchorProductList: BlockAnchorProductList,
    blockCareersGrid: BlockCareersGrid,
    blockCtaGroup: BlockCTAGroup,
    blockCardTrioInline: BlockCardTrioInline,
    blockTwoColumnRichText: BlockTwoColumnRichText,
    blockDownloadList: BlockDownloadList,
    blockCardSwipe: BlockCardSwipe,
    blockLinkList: BlockLinkList,
    blockCardTrioSmall: BlockCardTrioSmall,
    blockCardGroupSimple: BlockCardGroup,
    blockEyebrow: BlockEyebrow,
    blockDownloadableResources: BlockDownloadableResources,
    blockAudio: BlockAudio,
    blockFanStory: BlockFanStory,
    blockFanStoryShare: BlockFanStoryShare,
    blockShopPromotional: BlockShopPromotional,
    blockSimpleProducts: BlockSimpleProductList,
    blockPromotional: BlockPromotional,
    blockProductFinder: BlockProductFinder,
    blockRecommendedProducts: BlockRecommendedProducts,
    blockPaperform: BlockPaperForm,
    blockCarouselShowcaseWithCard: BlockCarouselShowcaseWithCard,
    blockFlipDeck: BlockFlipDeck,
    blockCallout: BlockCallout,
    blockDeckSlider: BlockDeckSlider,
    blockProductSharpnessStory: BlockProductSharpnessStory,
    blockProductPairings: BlockProductPairings,
    blockProductPairingsSlides: BlockProductPairingsSlides,
    blockPromotionPod: BlockPromotionPod,
    blockFoodServiceContactForm: BlockFoodServiceContactForm,
    blockSimpleCards: BlockSimpleCards,
    blockSmallMediaWithText: BlockSmallMediaWithText,
    blockWhereToBuy: BlockWhereToBuy,
    blockTwoColumnCarousel: BlockTwoColumnCarousel,
    default: BlockMissing,
};

export function getBlockComponent(contentTypeId) {
    return blockMap[contentTypeId] || blockMap.default;
}

const Block = props => {
    const BlockComponent = getBlockComponent(props.contentTypeId);
    const d = debug('Blocks');

    // Strip hash in case they were inadvertently added to CMS
    const sanitizedAnchorId = props.anchorId
        ? props.anchorId.replace(/#/g, '')
        : null;

    return (
        <BlockContext.Provider value={props.id}>
            <Debug {...props} debug={props.topLevel ? d.enabled : false}>
                <span
                    id={sanitizedAnchorId ? `${sanitizedAnchorId}` : null}
                    data-contenttypeid={`${props.contentTypeId}`}
                >
                    <BlockComponent {...props} />
                </span>
            </Debug>
        </BlockContext.Provider>
    );
};

Block.defaultProps = {
    topLevel: false,
};

Block.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    topLevel: PropTypes.bool,
    firstBlock: PropTypes.bool,
    lastBlock: PropTypes.bool,
    contentTypeId: PropTypes.string,
    anchorId: PropTypes.string,
};

export const renderBlocks = blocks => {
    const cleanBlocks = blocks.filter(block => {
        const isRenderable =
            !!block.contentTypeId && (blockMap[block.contentTypeId] || false);

        if (!isRenderable) {
            console.warn(
                `Block cannot be rendered – contentTypeId: ${
                    block.contentTypeId || 'Unknown draft'
                }`
            );
        }

        return isRenderable;
    });

    return cleanBlocks.map((blockProps, i) => (
        <Block
            key={`block-${blockProps.id}`}
            topLevel={true}
            firstBlock={i === 0}
            lastBlock={i + 1 === cleanBlocks.length}
            {...blockProps}
        />
    ));
};

export default Block;
