import React from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';

import styles from './CreamCheeseProfileBackground.module.scss';

// Framer Motion has a bug with animating css var colors
// https://github.com/framer/motion/issues/546

const spring = {
    type: 'spring',
    damping: 60,
    stiffness: 160,
};

const CreamCheeseProfileBackground = ({ activeProduct }) => {
    return (
        <motion.div
            className={styles.root}
            animate={{
                backgroundColor: activeProduct?.color || '',
            }}
            transition={spring}
        />
    );
};

CreamCheeseProfileBackground.propTypes = {
    activeProduct: PropTypes.object,
};

export default CreamCheeseProfileBackground;
