import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import TextEyebrow from 'components/ui/TextEyebrow';
import styles from './BlockDownloadList.module.scss';
import SvgIcon from 'components/ui/SvgIcon';

const CTAButton = ({ cta, theme, iconType }) =>
    cta.assetDownloadLink ? (
        <Button
            download
            theme=""
            className={styles.cta}
            href={cta.assetDownloadLink.file?.url}
        >
            <span className={styles.ctaDownload}>
                <Text className={styles.ctaText} baseTheme="bodyMedium">
                    {cta.text}
                </Text>
                <span className={styles.ctaIconGroup}>
                    <span className={styles.ctaIcon}>
                        <SvgIcon iconType="download" />
                    </span>
                    <Text
                        className={styles.ctaTextDownload}
                        baseTheme="labelLarge"
                    >
                        Download
                    </Text>
                </span>
            </span>
        </Button>
    ) : (
        <Button
            className={styles.cta}
            theme={theme}
            entry={cta.link}
            iconLeading={iconType}
        >
            {cta.text}
        </Button>
    );

CTAButton.propTypes = {
    cta: PropTypes.shape({
        assetDownloadLink: PropTypes.object,
        link: PropTypes.shape({
            contentTypeId: PropTypes.string,
        }),
        text: PropTypes.string,
    }),
    iconType: PropTypes.string,
    theme: PropTypes.string,
};

CTAButton.defaultProps = {
    iconType: null,
};

const BlockDownloadList = ({ headline, downloads, className }) => {
    return (
        <section className={cx(styles.blockDownloadList, className)}>
            <div className={styles.inner}>
                <div className={styles.eyebrow}>
                    <TextEyebrow
                        headline={headline}
                        theme="display"
                        fixWidows={false}
                    />
                </div>
                <ul className={styles.list}>
                    {downloads?.map(
                        (button, i) =>
                            button.cta && (
                                <li className={styles.listItem} key={i}>
                                    <CTAButton {...button} />
                                </li>
                            )
                    )}
                </ul>
            </div>
        </section>
    );
};

BlockDownloadList.propTypes = {
    headline: PropTypes.string,
    downloads: PropTypes.array,
    className: PropTypes.string,
};

export default BlockDownloadList;
