import React, { createContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntersection, useMeasure } from 'react-use';
import cx from 'classnames';

import Button from 'components/ui/Button';
import SvgIcon from 'components/ui/SvgIcon';

import Slide from './Slide';
import styles from './Carousel.module.scss';

export const CarouselContext = createContext({
    activeIndices: [],
    setActiveIndices: () => {},
    hasOverflow: true,
    isBeginning: true,
    isEnd: false,
    scrollToIndex: () => {},
});

const Carousel = ({
    children,
    className,
    hasNavigation,
    navigationTheme,
    slideClassName,
}) => {
    const [scrollLeft, setScrollLeft] = useState(0);
    const [activeIndices, setActiveIndices] = useState([]);
    const [isInView, setIsInView] = useState(false);
    const [hasOverflow, setHasOverflow] = useState(true);

    const ref = useRef(null);
    const containerRef = useRef(null);
    const trackRef = useRef(null);

    const [widthRef, { width: containerWidth }] = useMeasure();
    const trackWidth = trackRef?.current?.scrollWidth;
    const slideWith = trackWidth / children.length;

    const isBeginning = Math.floor(scrollLeft) === 0;
    const isEnd = Math.ceil(containerWidth + scrollLeft) >= trackWidth;

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const handleNavigation = direction => {
        const scroll = {
            prev: slideWith * -1,
            next: slideWith,
        };

        containerRef.current.scrollBy({
            left: scroll[direction],
        });
    };

    const handleContainerScroll = () => {
        setScrollLeft(containerRef?.current?.scrollLeft);
    };

    const scrollToIndex = index => {
        const scrollLeft = index * slideWith;

        containerRef.current.scrollTo({
            left: scrollLeft,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        containerRef?.current && containerRef.current.scrollTo({ left: 0 });
    }, [containerRef]);

    useEffect(() => {
        if (intersection?.isIntersecting) {
            setIsInView(true);
        }
    }, [intersection]);

    useEffect(() => {
        setHasOverflow(trackWidth > Math.ceil(containerWidth));
    }, [containerWidth, trackWidth]);

    useEffect(() => {
        const container = containerRef.current;

        container.addEventListener('scroll', handleContainerScroll);

        return () =>
            container.removeEventListener('scroll', handleContainerScroll);
    }, [containerRef]);

    return (
        <CarouselContext.Provider
            value={{
                activeIndices,
                hasOverflow,
                isBeginning,
                isEnd,
                setActiveIndices,
                scrollToIndex,
            }}
        >
            <section
                ref={ref}
                className={cx(styles.root, className, {
                    [styles.hasOverflow]: hasOverflow,
                    [styles.isInView]: isInView,
                })}
            >
                <div ref={containerRef} className={styles.container}>
                    <div ref={widthRef}>
                        <ul ref={trackRef} className={styles.track}>
                            {React.Children.map(children, (child, index) => {
                                return (
                                    <Slide
                                        root={containerRef}
                                        key={child.key}
                                        index={index}
                                        className={cx(
                                            styles.slide,
                                            slideClassName
                                        )}
                                    >
                                        {React.cloneElement(child)}
                                    </Slide>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                {hasNavigation && (
                    <div
                        className={cx(
                            styles.navigation,
                            styles[navigationTheme]
                        )}
                    >
                        <Button
                            theme="none"
                            className={cx(styles.button, styles.prev)}
                            onClick={() => handleNavigation('prev')}
                            disabled={isBeginning}
                        >
                            <SvgIcon
                                className={styles.icon}
                                iconType="chevronLeft"
                            />
                        </Button>
                        <Button
                            theme="none"
                            className={cx(styles.button, styles.next)}
                            onClick={() => handleNavigation('next')}
                            disabled={isEnd}
                        >
                            <SvgIcon
                                className={styles.icon}
                                iconType="chevronRight"
                            />
                        </Button>
                    </div>
                )}
            </section>
        </CarouselContext.Provider>
    );
};

Carousel.defaultProps = {
    hasNavigation: true,
    navigationTheme: 'blue',
};

Carousel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    navigationTheme: PropTypes.oneOf(['blue', 'cream']),
    slideClassName: PropTypes.string,
    hasNavigation: PropTypes.bool,
};

export default Carousel;
