import React from 'react';
import PropTypes from 'prop-types';

import CarouselProvider from 'components/ui/CarouselProvider';
import CarouselHero from './CarouselHero';

const BlockCarouselHero = ({
    slides,
    timerDuration,
    isContinuous,
    shopCallout,
}) => {
    return (
        <CarouselProvider
            slides={slides}
            timerDuration={timerDuration}
            isContinuous={isContinuous}
        >
            <CarouselHero shopCallout={shopCallout} />
        </CarouselProvider>
    );
};

BlockCarouselHero.propTypes = {
    timerDuration: PropTypes.number,
    slides: PropTypes.array,
    shopCallout: PropTypes.object,
    isContinuous: PropTypes.bool,
};

BlockCarouselHero.defaultProps = {
    timerDuration: 7,
    isContinuous: false,
};

export default BlockCarouselHero;
