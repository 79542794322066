import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CardTestimonial from 'components/cards/CardTestimonial';

import styles from './BlockTestimonial.module.scss';

const colorMap = {
    Blue: 'blue',
    White: 'white',
    Default: 'none',
};

const themeMap = {
    Default: 'default',
    Commitments: 'commitments',
};

const BlockTestimonial = ({
    testimonial,
    backgroundColor,
    theme,
    flushTop,
}) => {
    return (
        <div
            className={cx(styles.blockTestimonial, {
                [styles.blueBackground]: colorMap[backgroundColor] === 'blue',
                [styles.whiteBackground]: colorMap[backgroundColor] === 'white',
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.blockTestimonialInner}>
                <CardTestimonial
                    className={styles.testimonial}
                    {...testimonial}
                    theme={themeMap[theme]}
                    backgroundColor={colorMap[backgroundColor]}
                />
            </div>
        </div>
    );
};

BlockTestimonial.propTypes = {
    testimonial: PropTypes.object.isRequired,
    secondaryTestimonial: PropTypes.object,
    backgroundColor: PropTypes.oneOf(['Default', 'Blue', 'White']),
    theme: PropTypes.oneOf(['Default', 'Commitments']),
    flushTop: PropTypes.bool,
};

BlockTestimonial.defaultProps = {
    backgroundColor: 'Default',
    theme: 'Default',
    flushTop: true,
};

export default BlockTestimonial;
