import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { BLOCKS } from '@contentful/rich-text-types';
import { useClickAway, usePrevious } from 'react-use';

import Img from 'components/ui/Img';
import { localStorageSupport, saveToLocalStorage } from 'utils';

import Portal from 'components/ui/Portal';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import RichText from 'components/ui/RichText';
import Icon from 'components/ui/Icon';
import SvgIcon from 'components/ui/SvgIcon';

import AlertSvgIcon from '../AlertSvgIcon';
import iconMap from '../AlertSvgIcon/iconMap';

import styles from './Overlay.module.scss';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.HEADING_3]: (node, children) => {
            return (
                <Text
                    as="h3"
                    className={styles.headline}
                    baseTheme="displayXSmall"
                    themes={{
                        large: 'displaySmall',
                    }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h3"
                    className={styles.headline}
                    baseTheme="displayXSmall"
                    themes={{
                        large: 'displaySmall',
                    }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.bodyCopy}
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodyMedium',
                    }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const Overlay = ({
    image,
    imageLarge,
    headline,
    bodyCopy,
    id,
    ctaButton,
    icon,
    icon2,
}) => {
    const overlayRef = useRef(null);
    const innerRef = useRef(null);

    const router = useRouter();
    const routerPrev = usePrevious(router);

    const [isOpen, toggleOpen] = useState(false);
    const [isVisible, toggleVisibility] = useState(false);

    useClickAway(innerRef, () => {
        handleClose();
    });
    function wait(ms) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    function waitFrame() {
        return new Promise(resolve => {
            requestAnimationFrame(resolve);
        });
    }

    async function handleClose() {
        // Save CMS alert id to local storage to avoid rendering alert again
        await saveToLocalStorage(id, 1);
        toggleVisibility(false);
        clearAllBodyScrollLocks();

        waitFrame().then(() => {
            toggleOpen(false);
        });
    }

    function handleBodyScroll() {
        if (overlayRef.current) {
            disableBodyScroll(overlayRef.current, {
                allowTouchMove: el => el.firstChild,
            });

            // Transition the overlay on
            toggleVisibility(true);

            return;
        }

        // gentle pause to ensure overlay ref exists
        wait(10).then(() => {
            handleBodyScroll();
        });
    }

    useEffect(() => {
        const handleStart = () => {
            clearAllBodyScrollLocks();
        };

        router.events.on('routeChangeStart', handleStart);

        return () => {
            router.events.off('routeChangeStart', handleStart);
        };
    }, [router]);

    // Check local storage for the CMS id of this alert
    useEffect(() => {
        if (localStorageSupport() && !localStorage.getItem(id)) {
            // Display alert IF alert id is not in local storage
            toggleOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (routerPrev?.asPath && routerPrev?.asPath !== router.asPath) {
            // Changed routes close
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router, routerPrev]);

    function handleKeyDown(evt) {
        if (evt.keyCode === 27) {
            handleClose();
        }
    }

    function toggleEventListener(method) {
        document[`${method}EventListener`]('keydown', handleKeyDown);
    }

    useEffect(() => {
        isOpen && handleBodyScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        toggleEventListener('add');

        return () => {
            toggleEventListener('remove');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {isOpen && (
                <Portal>
                    <div
                        ref={overlayRef}
                        className={cx(styles.root, {
                            [styles.hasImage]: image?.file?.url,
                            [styles.isVisible]: isVisible,
                        })}
                    >
                        <div className={styles.overlay}>
                            <div ref={innerRef} className={styles.overlayInner}>
                                <button
                                    onClick={handleClose}
                                    className={styles.closeButton}
                                >
                                    <div className={styles.closeIconWrapper}>
                                        <SvgIcon iconType="close" />
                                    </div>
                                </button>
                                {image?.file?.url && (
                                    <figure
                                        className={cx(
                                            styles.aspectBox,
                                            styles.imageAspectBox,
                                            styles.imageSmall
                                        )}
                                    >
                                        <Img
                                            className={styles.image}
                                            src={image.file.url}
                                            fallbackImageWidth={480}
                                            alt={image.description || ''}
                                            customSources={[
                                                {
                                                    breakpoint: 1024,
                                                    imageWidth: 750,
                                                },
                                                {
                                                    imageWidth: 480,
                                                },
                                            ]}
                                            priority
                                        />
                                    </figure>
                                )}
                                {imageLarge?.file?.url && (
                                    <figure
                                        className={cx(
                                            styles.aspectBox,
                                            styles.imageAspectBox,
                                            styles.imageLarge
                                        )}
                                    >
                                        <Img
                                            className={styles.image}
                                            src={imageLarge.file.url}
                                            fallbackImageWidth={480}
                                            alt={imageLarge.description || ''}
                                            customSources={[
                                                {
                                                    breakpoint: 1024,
                                                    imageWidth: 750,
                                                },
                                                {
                                                    imageWidth: 480,
                                                },
                                            ]}
                                            priority
                                        />
                                    </figure>
                                )}
                                <div className={styles.aspectBox}>
                                    <div className={styles.content}>
                                        <div className={styles.innerContent}>
                                            {icon2?.icon?.file?.url && (
                                                <div
                                                    className={
                                                        styles.iconWrapper
                                                    }
                                                >
                                                    <Icon
                                                        src={
                                                            icon2.icon?.file
                                                                ?.url
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {iconMap[icon] &&
                                                !icon2?.icon?.file?.url && (
                                                    <div
                                                        className={
                                                            styles.iconWrapper
                                                        }
                                                    >
                                                        <AlertSvgIcon
                                                            iconSrc={
                                                                iconMap[icon]
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            <div
                                                className={
                                                    styles.headlineWrapper
                                                }
                                            >
                                                <RichText
                                                    richText={headline}
                                                    overrides={
                                                        headlineOverrides
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.bodyCopyWrapper
                                                }
                                            >
                                                <RichText
                                                    richText={bodyCopy}
                                                    overrides={copyOverrides}
                                                />
                                            </div>
                                            {ctaButton?.cta?.link && (
                                                <Button
                                                    className={styles.cta}
                                                    theme="filledBlueHoverWhite-ultrabold"
                                                    entry={ctaButton.cta?.link}
                                                    iconLeading={
                                                        ctaButton.iconType
                                                    }
                                                >
                                                    {ctaButton.cta?.text}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </Fragment>
    );
};

Overlay.propTypes = {
    contentTypeId: PropTypes.string.isRequired,
    icon: PropTypes.string,
    icon2: PropTypes.object,
    headline: PropTypes.object.isRequired,
    bodyCopy: PropTypes.object.isRequired,
    ctaButton: PropTypes.shape({
        cta: PropTypes.shape({
            link: PropTypes.object.isRequired,
            text: PropTypes.string,
        }),
        iconType: PropTypes.string,
    }),
    image: PropTypes.object,
    imageLarge: PropTypes.object,
    id: PropTypes.string.isRequired,
};

Overlay.defaultProps = {
    image: null,
    ctaButton: null,
};

export default Overlay;
