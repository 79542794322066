import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { motion, useMotionValue, useAnimation } from 'framer-motion';

import { wait } from 'utils';

import Text from 'components/ui/Text';
import Img from 'components/ui/Img';

import styles from './FlavorFinderCardSwiper.module.scss';

const boxShadowStyle = `-1.9rem 2.9rem 5rem rgba(0, 30, 96, 0.35)`;

const FlavorFinderCard = ({
    isTop,
    isMiddle,
    isBottom,
    image,
    text,
    isInteractive,
    onVote,
    onDrag,
    onDragEnd,
    onHintYes,
    onHintNo,
    onYes,
    onNo,
    forceYes,
    forceNo,
    isMedium,
}) => {
    const cardRef = useRef(null);

    const x = useMotionValue(0);
    const controls = useAnimation();

    const [isYes, setIsYes] = useState(false);
    const [isNo, setIsNo] = useState(false);
    const [isConstrained, setIsConstrained] = useState(true);
    const [direction, setDirection] = useState();
    const [velocity, setVelocity] = useState();

    // determine direction of swipe based on velocity
    const getDirection = () => {
        return velocity >= 1 ? 'right' : velocity <= -1 ? 'left' : undefined;
    };

    const getTrajectory = () => {
        setVelocity(x.getVelocity());
        setDirection(getDirection());
    };

    const handleDrag = () => {
        onDrag();
        getTrajectory();
    };

    const handleDragEnd = () => {
        onDragEnd();

        const calcDistance = dir => {
            const windowWidth = window.innerWidth;
            const childWidth = cardRef.current.offsetWidth;

            return dir === 'left'
                ? -windowWidth / 2 - childWidth / 2 - 50
                : windowWidth / 2 + childWidth / 2 + 50;
        };

        // flicked with velocity
        if (direction && Math.abs(velocity) > 500 && !isYes && !isNo) {
            setIsConstrained(false);

            controls.start({
                x: calcDistance(direction),
                transition: { duration: 0.2 },
            });

            onVote(direction === 'left' ? 'No' : 'Yes');
        }

        // dragged over threshhold for yes or no
        if (isYes) {
            onVote('Yes');
            setIsConstrained(false);
            controls.start({
                x: calcDistance('right'),
                transition: { duration: 0.5 },
            });
        }

        if (isNo) {
            onVote('No');
            setIsConstrained(false);
            controls.start({
                x: calcDistance('left'),
                transition: { duration: 0.5 },
            });
        }

        wait(500).then(() => {
            setIsConstrained(true);
        });
    };

    useEffect(
        () =>
            x.onChange(latest => {
                if (cardRef && cardRef.current) {
                    const dropThreshhold = isMedium
                        ? cardRef.current.offsetWidth / 2
                        : cardRef.current.offsetWidth / 3;

                    const hintThreshhold = 5;

                    if (onHintYes && onHintNo) {
                        if (latest > hintThreshhold) {
                            onHintYes(true);
                            onHintNo(false);
                        } else if (latest < -hintThreshhold) {
                            onHintNo(true);
                            onHintYes(false);
                        } else {
                            onHintYes(false);
                            onHintNo(false);
                        }
                    }

                    if (latest > dropThreshhold) {
                        setIsYes(true);
                    } else if (latest < -dropThreshhold) {
                        setIsNo(true);
                    } else {
                        setIsYes(false);
                        setIsNo(false);
                    }
                }
            }),
        [x, onHintYes, onHintNo, setIsYes, setIsNo, isMedium]
    );

    useEffect(() => {
        if (onYes && onNo) {
            onYes(isYes);
            onNo(isNo);
        }
    }, [isYes, isNo, onYes, onNo]);

    useEffect(() => {
        isInteractive &&
            controls.start({
                x: 0,
                y: 0,
                opacity: 1,
            });
    }, [isInteractive, controls]);

    return (
        <div
            className={cx(styles.card, {
                [styles.isTop]: isTop,
                [styles.isMiddle]: isMiddle,
                [styles.isBottom]: isBottom,
                [styles.forceYes]: forceYes,
                [styles.forceNo]: forceNo,
                [styles.isLeaving]: !isConstrained,
            })}
        >
            <motion.div
                className={styles.cardInner}
                animate={controls}
                dragConstraints={
                    isConstrained && { left: 0, right: 0, top: 0, bottom: 0 }
                }
                dragElastic={1}
                ref={cardRef}
                style={{ x }}
                onDrag={handleDrag}
                onDragEnd={handleDragEnd}
                whileTap={{
                    scale: isTop ? 1.08 : 1,
                    boxShadow: isTop ? boxShadowStyle : 'none',
                }}
                whileHover={{
                    scale: isTop ? 1.08 : 1,
                    boxShadow: isTop ? boxShadowStyle : 'none',
                }}
                drag={isTop}
            >
                <Img
                    className={styles.cardImage}
                    src={image}
                    fallbackImageWidth={278 * 2}
                    alt={''}
                    draggable
                    customSources={[
                        {
                            breakpoint: 1024,
                            src: image,
                            imageWidth: 700 * 2,
                        },
                        {
                            breakpoint: 768,
                            src: image,
                            imageWidth: 600 * 2,
                        },
                        {
                            src: image,
                            imageWidth: 278 * 2,
                        },
                    ]}
                />
                {text && (
                    <Text className={styles.cardText} baseTheme="displayLarge">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: text,
                            }}
                        />
                    </Text>
                )}
            </motion.div>
        </div>
    );
};

FlavorFinderCard.propTypes = {
    isTop: PropTypes.bool,
    isMiddle: PropTypes.bool,
    isBottom: PropTypes.bool,
    text: PropTypes.string,
    image: PropTypes.string,
    isInteractive: PropTypes.bool,
    onVote: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    onHintYes: PropTypes.func,
    onHintNo: PropTypes.func,
    onYes: PropTypes.func,
    onNo: PropTypes.func,
    forceYes: PropTypes.bool,
    forceNo: PropTypes.bool,
    isMedium: PropTypes.bool,
};

export default FlavorFinderCard;
