import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';

const ButtonCircle = ({ theme, children, ...props }) => {
    const iconKey = {
        chevronRightBlueDark: 'chevronRight',
        chevronRightBlue: 'chevronRight',
        chevronLeftBlue: 'chevronLeft',
        chevronDownBlue: 'chevronDown',
        chevronRightCream: 'chevronRight',
        chevronLeftCream: 'chevronLeft',
        chevronDownCream: 'chevronDown',
        chevronRightCreamDark: 'chevronRight',
        chevronRightMakersReserveGold: 'chevronRight',
        chevronLeftCreamDark: 'chevronLeft',
        chevronDownCreamDark: 'chevronDown',
        chevronRightWhite: 'chevronRight',
        chevronLeftWhite: 'chevronLeft',
        playVideoBlue: 'playVideo',
        playVideoCream: 'playVideo',
        arrowRightBlue: 'arrowRight',
        arrowLeftBlue: 'arrowLeft',
        arrowRightCream: 'arrowRight',
        arrowLeftCream: 'arrowLeft',
        arrowRightRoundedBlue: 'arrowRightRounded',
        plusCream: 'plus',
        plusMakersReserveGold: 'plus',
        minusCream: 'minus',
        minusMakersReserveGold: 'minus',
    };
    return (
        <Button circleTheme={theme} circleIcon={iconKey[theme]} {...props}>
            {children}
        </Button>
    );
};

ButtonCircle.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.string,
};

export default ButtonCircle;
