import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';

import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './BlockRichText.module.scss';

const BlockRichText = ({ richText }) => {
    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyMedium' }}
                        className={styles.bodyText}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    return (
        <div className={styles.richTextBlock}>
            <RichText richText={richText} overrides={richTextOverrides} />
        </div>
    );
};

BlockRichText.propTypes = {
    richText: PropTypes.object.isRequired,
};

export default BlockRichText;
