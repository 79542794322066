import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Location from './Location';
import styles from './BlockLocation.module.scss';

const BlockLocation = ({ location, theme, className }) => {
    return (
        <section
            className={cx(styles.blockLocation, className, {
                [styles.bgWhite]: theme === 'White Background',
            })}
        >
            <div className={styles.blockLocationInner}>
                <Location {...location} />
            </div>
        </section>
    );
};

BlockLocation.propTypes = {
    theme: PropTypes.oneOf(['White Background', 'Transparent Background']),
    location: PropTypes.object,
    className: PropTypes.string,
};

BlockLocation.defaultProps = {
    theme: 'Transparent Background',
};

export default BlockLocation;
