import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';
import cx from 'classnames';

import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './BlockPaperForm.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.HEADING_4]: (node, children) => {
            return (
                <Text
                    as="h4"
                    className={styles.richTextHeading}
                    baseTheme="headingSmall"
                    themes={{ medium: 'headingMedium' }}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.richTextParagraph}
                    baseTheme="bodyXSmall"
                    themes={{ medium: 'bodyMedium' }}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.UL_LIST]: (node, children) => {
            return <ul className={styles.richTextUnorderedList}>{children}</ul>;
        },
    },
};

const BlockPaperForm = ({ richText, formId, flushBottom, flushTop }) => {
    const [wrapperHeight, updateWrapperHeight] = useState(0);

    useEffect(() => {
        const handleMessage = msg => {
            if (msg?.origin === `https://${formId}.paperform.co`) {
                try {
                    const parsedData = JSON.parse(msg?.data || '{}') || null;
                    const height = parsedData?.height || null;

                    if (height) {
                        updateWrapperHeight(height + 100);
                    }
                } catch (e) {}
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [formId]);

    if (formId) {
        return (
            <div
                className={cx(styles.root, {
                    [styles.flushTop]: flushTop,
                    [styles.flushBottom]: flushBottom,
                })}
            >
                {richText && (
                    <div className={styles.richText}>
                        <RichText
                            richText={richText}
                            overrides={richTextOverrides}
                        />
                    </div>
                )}
                <div
                    style={{ height: wrapperHeight, width: '100%' }}
                    className={cx(
                        styles.iframeWrapper,
                        richText && styles.richTextSpacer
                    )}
                >
                    <iframe
                        scrolling="no"
                        frameBorder="0"
                        width="100%"
                        src={`https://${formId}.paperform.co?embed=1&inline=1`}
                        height="100%"
                    ></iframe>
                </div>
            </div>
        );
    }

    return null;
};

BlockPaperForm.propTypes = {
    formId: PropTypes.string.isRequired,
    richText: PropTypes.object,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

BlockPaperForm.defaultProps = {
    richText: null,
    flushBottom: false,
    flushTop: false,
};

export default BlockPaperForm;
