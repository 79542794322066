import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';
import Link from 'components/ui/Link';

import ButtonCircle from 'components/ui/ButtonCircle';

import Img from 'components/ui/Img';
import { getColor } from 'utils';

import styles from './CardCampaign.module.scss';

const CardCampaign = ({
    headline,
    description,
    image,
    backgroundColor,
    color,
    link,
}) => (
    <Link.Entry entry={link}>
        <article
            className={styles.campaign}
            style={{
                backgroundColor: getColor(backgroundColor),
                color: getColor(color),
            }}
        >
            <div className={styles.imageContainer}>
                <Img
                    className={styles.image}
                    src={image.file.url}
                    fallbackImageWidth={250}
                    alt={image.description || ''}
                    customSources={[
                        {
                            src: image.file.url,
                            imageWidth: 250,
                        },
                    ]}
                />
            </div>
            <Text
                baseTheme="displayXSmall"
                themes={{ large: 'displaySmall' }}
                className={styles.headline}
            >
                {headline}
            </Text>
            <Text
                className={styles.description}
                baseTheme="bodyMedium"
                themes={{ xLarge: 'bodyLarge' }}
            >
                {description}
            </Text>
            <ButtonCircle theme="chevronRightCream" />
        </article>
    </Link.Entry>
);

CardCampaign.propTypes = {
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }),
    }).isRequired,
    link: PropTypes.object.isRequired,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
};

CardCampaign.defaultProps = {
    backgroundColor: 'orange',
    color: 'cream',
};

export default CardCampaign;
