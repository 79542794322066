export const data = [
    {
        prompt: 'First things first, what is your…',
        question: 'Dream Vacation?',
        color: '#FFCECC',
        cards: [
            {
                flavor: 'Strawberry',
                image: '/images/cream-cheese-campaign/card-0-0.jpg',
                text: 'Anywhere<br />tropical',
            },
            {
                flavor: 'Cheddar',
                image: '/images/cream-cheese-campaign/card-0-1.jpg',
                text: 'Snowboarding in<br />Whistler',
            },
            {
                flavor: 'Veggie',
                image: '/images/cream-cheese-campaign/card-0-2.jpg',
                text: 'Camping in<br />Yosemite',
            },
            {
                flavor: 'Chive',
                image: '/images/cream-cheese-campaign/card-0-3.jpg',
                text: 'West Coast<br />road trip',
            },
            {
                flavor: 'Original',
                image: '/images/cream-cheese-campaign/card-0-4.jpg',
                text: `Chillin' at home`,
            },
        ],
    },
    {
        prompt: 'Nice. Now help us understand your…',
        question: `Favorite dessert?`,
        color: '#FFE0A6',
        cards: [
            {
                flavor: 'Strawberry',
                image: '/images/cream-cheese-campaign/card-1-0.jpg',
                text: 'Strawberry<br />shortcake',
            },
            {
                flavor: 'Cheddar',
                image: '/images/cream-cheese-campaign/card-1-1.jpg',
                text: 'Not a fan of<br />sweets',
            },
            {
                flavor: 'Veggie',
                image: '/images/cream-cheese-campaign/card-1-2.jpg',
                text: 'Chocolate Chip<br />Cookies',
            },
            {
                flavor: 'Chive',
                image: '/images/cream-cheese-campaign/card-1-3.jpg',
                text: 'Carrot cake<br />with extra<br />frosting',
            },
            {
                flavor: 'Original',
                image: '/images/cream-cheese-campaign/card-1-4.jpg',
                text: `Apple pie à la<br />mode`,
            },
        ],
    },
    {
        prompt: 'Great! Now, are you interested in&nbsp;any…',
        question: `Hobbies?`,
        color: '#B8EBB2',
        cards: [
            {
                flavor: 'Strawberry',
                image: '/images/cream-cheese-campaign/card-2-0.jpg',
                text: 'Cake<br />decorating',
            },
            {
                flavor: 'Cheddar',
                image: '/images/cream-cheese-campaign/card-2-1.jpg',
                text: 'Blogging',
            },
            {
                flavor: 'Veggie',
                image: '/images/cream-cheese-campaign/card-2-2.jpg',
                text: 'Biking',
            },
            {
                flavor: 'Chive',
                image: '/images/cream-cheese-campaign/card-2-3.jpg',
                text: 'Gardening',
            },
            {
                flavor: 'Original',
                image: '/images/cream-cheese-campaign/card-2-4.jpg',
                text: 'Surfing',
            },
        ],
    },
    {
        prompt: 'Excellent! What would be your idea of a perfect…',
        question: `First Date?`,
        color: '#BFE1D0',
        cards: [
            {
                flavor: 'Strawberry',
                image: '/images/cream-cheese-campaign/card-3-0.jpg',
                text: 'Hiking',
            },
            {
                flavor: 'Cheddar',
                image: '/images/cream-cheese-campaign/card-3-1.jpg',
                text: 'Karaoke',
            },
            {
                flavor: 'Veggie',
                image: '/images/cream-cheese-campaign/card-3-2.jpg',
                text: 'Wine Tasting',
            },
            {
                flavor: 'Chive',
                image: '/images/cream-cheese-campaign/card-3-3.jpg',
                text: `The Farmer’s<br />Market`,
            },
            {
                flavor: 'Original',
                image: '/images/cream-cheese-campaign/card-3-4.jpg',
                text: 'Dinner and a<br />movie',
            },
        ],
    },
    {
        prompt:
            'Perfect. Now, what do you consider to be your all-time favorite…',
        question: `Music Genre?`,
        color: '#A1E1FF',
        cards: [
            {
                flavor: 'Strawberry',
                image: '/images/cream-cheese-campaign/card-4-0.jpg',
                text: 'Pop',
            },
            {
                flavor: 'Cheddar',
                image: '/images/cream-cheese-campaign/card-4-1.jpg',
                text: 'Punk Rock',
            },
            {
                flavor: 'Veggie',
                image: '/images/cream-cheese-campaign/card-4-2.jpg',
                text: `‘90s R&B`,
            },
            {
                flavor: 'Chive',
                image: '/images/cream-cheese-campaign/card-4-3.jpg',
                text: 'Reggae',
            },
            {
                flavor: 'Original',
                image: '/images/cream-cheese-campaign/card-4-4.jpg',
                text: 'Blues',
            },
        ],
    },
];
