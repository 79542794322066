import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import Img from 'components/ui/Img';

import styles from './CardLeadership.module.scss';

const CardLeadership = ({ name, position, image }) => (
    <article className={styles.leadership}>
        <div className={styles.imageContainer}>
            <Img
                className={styles.image}
                src={image.file.url}
                fallbackImageWidth={480}
                alt={image.description || ''}
                width={image?.file.details?.image?.width}
                height={image?.file.details?.image?.height}
                customSources={[{ src: image.file.url, imageWidth: 480 }]}
            />
        </div>
        <Text
            baseTheme="headingMedium"
            themes={{ large: 'headingLarge' }}
            className={styles.name}
            as="h5"
        >
            {name}
        </Text>
        <Text
            className={styles.position}
            baseTheme="labelSmall"
            themes={{ large: 'labelLarge' }}
            as="p"
        >
            {position}
        </Text>
    </article>
);

CardLeadership.propTypes = {
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            url: PropTypes.string.isRequired,
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
        }),
    }).isRequired,
};

export default CardLeadership;
