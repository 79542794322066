import React, { useRef, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

import SvgIcon from 'components/ui/SvgIcon';

import useMediaQuery from 'hooks/useMediaQuery';
import { useIntersection } from 'react-use';

import styles from './Stagger.module.scss';

const ArrowRight = ({
    animation,
    className,
    delay,
    duration,
    order,
    theme,
    ...props
}) => {
    return (
        <div
            role="presentation"
            className={cx(
                styles.child,
                styles.arrowRight,
                className,
                styles[animation],
                styles[theme]
            )}
            style={{
                animationDelay: `${order * delay}s`,
                animationDuration: `${duration}s`,
            }}
            {...props}
        >
            <SvgIcon iconType="arrowRight" />
        </div>
    );
};

ArrowRight.propTypes = {
    animation: PropTypes.oneOf(['pop', 'disabled']),
    children: PropTypes.node,
    className: PropTypes.string,
    delay: PropTypes.number,
    duration: PropTypes.number,
    order: PropTypes.number.isRequired,
    theme: PropTypes.oneOf(['smallMobile', 'small', 'large']),
};

ArrowRight.defaultProps = {
    animation: 'pop',
    delay: 0.18,
    duration: 0.3,
    order: 0,
    theme: 'large',
};

const Child = ({
    animation,
    children,
    className,
    delay,
    duration,
    order,

    as: Tag,
    ...props
}) => {
    return (
        <Tag
            className={cx(styles.child, className, styles[animation])}
            style={{
                animationDelay: `${order * delay}s`,
                animationDuration: `${duration}s`,
            }}
            {...props}
        >
            {children}
        </Tag>
    );
};

Child.propTypes = {
    animation: PropTypes.oneOf(['fadeUp', 'disabled']),
    children: PropTypes.node,
    className: PropTypes.string,
    delay: PropTypes.number,
    duration: PropTypes.number,
    order: PropTypes.number.isRequired,
    as: PropTypes.string,
};

Child.defaultProps = {
    animation: 'fadeUp',
    delay: 0.18,
    duration: 0.3,
    as: 'div',
};

const Stagger = ({ children, className, threshold, ...props }) => {
    const ref = useRef(null);
    const [isInView, setIsInView] = useState(false);

    const isMedium = useMediaQuery('(min-width: 768px)');
    const isLarge = useMediaQuery('(min-width: 1024px)');
    const isXLarge = useMediaQuery('(min-width: 1440px)');

    const rootMargin = isMedium
        ? isLarge
            ? isXLarge
                ? '-250px'
                : '-200px'
            : '-125px'
        : '-50px';

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin,
        threshold,
    });

    useEffect(() => {
        if (intersection && intersection.isIntersecting) {
            setIsInView(true);
        }
    }, [intersection]);

    return (
        <div
            ref={ref}
            className={cx(styles.stagger, className, {
                [styles.isInView]: isInView,
            })}
            {...props}
        >
            {children}
        </div>
    );
};

Stagger.ArrowRight = ArrowRight;
Stagger.Child = Child;

Stagger.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    threshold: PropTypes.number,
};

Stagger.defaultProps = {
    className: null,
    threshold: 0,
};

export default Stagger;
