import axios from 'axios';

export const getOrigin = req => {
    return req && req.headers
        ? `${req.secure ? 'https' : 'http'}://${req.headers.host}`
        : document.location.origin;
};

export const api = (url, config, req) => {
    url = `${getOrigin(req)}${url}`;
    if (req && req.headers && req.headers.cookie) {
        return axios({
            ...config,
            url,
            headers: {
                cookie: req.headers.cookie,
            },
        });
    }
    return axios({
        ...config,
        url,
    });
};

export const allowedSentryDomains = [
    /https?:\/\/((preview|staging|www)\.)?tillamook\.com/,
    /https?:\/\/((.*)\.)?vercel\.app/,
];

export const validateEmail = email => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const validateZipcode = zipcode => {
    const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return re.test(zipcode);
};

export const isValidContentfulImage = image => {
    return image && image.file && image.file.url;
};

export const getFileExtension = (filename = '') => {
    return filename.split('.').pop();
};

export const isUtilityURL = (url = '') => {
    const re = /^(mailto:|tel:)/i;
    return re.test(url);
};

export const isExternalURL = (url = '') => {
    const re = /^https?:\/\/|^\/\//i;
    return re.test(url);
};

export const ensureProtocol = (url = '') => {
    return url.startsWith('//') ? `https:${url}` : url;
};

export function widowKiller(text, offset = 2) {
    if (!text) {
        return text;
    }
    const textArray = text.toString().split(' ');
    if (textArray.length <= offset) {
        return textArray.join(' ');
    }
    textArray.push(textArray.splice(-offset, offset).join('\u00A0'));
    return textArray.join(' ');
}

export function detectTouch(cb) {
    // Idea: https://stackoverflow.com/a/30303898/167378

    var lastTouchTime = 0;
    var isTouch = true;

    function mouseDetected() {
        // filter emulated events coming from touch events
        if (Date.now() - lastTouchTime < 500) {
            return;
        }
        if (isTouch) {
            isTouch = false;
            cb(false);
        }
    }

    function touchDetected() {
        lastTouchTime = Date.now();
        if (!isTouch) {
            isTouch = true;
            cb(true);
        }
    }

    document.addEventListener('touchstart', touchDetected, true);
    document.addEventListener('mousemove', mouseDetected, true);

    cb(isTouch);
}

export function wait(ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

export function waitFrame() {
    return new Promise(resolve => {
        requestAnimationFrame(resolve);
    });
}

export function webGLSupport() {
    try {
        var canvas = document.createElement('canvas');
        return (
            !!window.WebGLRenderingContext &&
            (canvas.getContext('webgl') ||
                canvas.getContext('experimental-webgl'))
        );
    } catch (e) {
        return false;
    }
}

export function localStorageSupport() {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

export function sessionStorageSupport() {
    const test = 'test';
    try {
        sessionStorage.setItem(test, test);
        sessionStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

export function isHex(color) {
    return /^#([0-9A-F]{3}){1,2}$/i.test(color);
}

export function getColor(color) {
    return isHex(color) ? color : `var(--colors-${color})`;
}

export function getReadableMonth(idx) {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    return months[idx];
}

export function getMonthNums() {
    let nums = [];

    for (var num = 1; num <= 12; num++) {
        nums.push(num < 10 ? `0${num}` : `${num}`);
    }

    return nums;
}

export function getDayNums() {
    let nums = [];

    for (var num = 1; num <= 31; num++) {
        nums.push(num < 10 ? `0${num}` : `${num}`);
    }

    return nums;
}

export function getYears() {
    let years = [];
    const currentYear = new Date().getUTCFullYear();

    // Opt for 120 years to include the oldest people in the world
    for (var year = currentYear; year >= currentYear - 120; year--) {
        years.push(`${year}`);
    }

    return years;
}

export function saveToLocalStorage(id, value) {
    return localStorage.setItem(id, value);
}

export function saveToSessionStorage(id, value) {
    return sessionStorage.setItem(id, value);
}

const epsilon = 1e-6,
    { abs } = Math;

export function bezier(x1, y1, x2, y2) {
    function bezier(x1, x2) {
        const a = 1 - 3 * x2 + 3 * x1,
            b = 3 * x2 - 6 * x1,
            c = 3 * x1;
        return t => ((a * t + b) * t + c) * t;
    }
    function bezierDerivate(x1, x2) {
        const a = 3 - 9 * x2 + 9 * x1,
            b = 6 * x2 - 12 * x1,
            c = 3 * x1;
        return t => (a * t + b) * t + c;
    }
    function solve(f, fp, y) {
        let x = y;
        for (let i = 0; i < 100; i++) {
            const delta = (y - f(x)) / (fp(x) || epsilon),
                absd = abs(delta);
            x += absd < 0.1 ? delta : (0.1 * delta) / absd;
            if (absd < 1e-9) break;
        }
        return x < 0 ? 0 : x > 1 ? 1 : x;
    }
    return x1 === y1 && x2 === y2 // useless(?) shortcut when y===x
        ? x => x
        : x => bezier(y1, y2)(solve(bezier(x1, x2), bezierDerivate(x1, x2), x));
}

function custom(x1, y1, x2, y2, dir) {
    x1 = +x1;
    y1 = +y1;
    x2 = +x2;
    y2 = +y2;
    if (x1 < 0 || x1 > 1 || x2 < 0 || x2 > 1)
        throw new RangeError('Parameter out of range');

    const b = bezier(x1, y1, x2, y2),
        _out = t => 1 - b(1 - t),
        _inout = t => ((t *= 2) < 1 ? b(t) : 2 - b(2 - t)) / 2,
        f = dir === 'out' ? _out : dir === 'inout' ? _inout : b;
    f.points = (x1, y1, x2, y2) => custom(x1, y1, x2, y2, dir);
    return f;
}

export const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];
    return inst => {
        for (const ref of filteredRefs) {
            if (typeof ref === 'function') {
                ref(inst);
            } else if (ref) {
                ref.current = inst;
            }
        }
    };
};

export const scrollToOffset = (el, offset, behavior = 'auto') => {
    if (!el) {
        return;
    }
    const y = el.getBoundingClientRect().top + window.pageYOffset + offset;

    window.pageYOffset > el.getBoundingClientRect().top + window.pageYOffset &&
        window.scrollTo({ top: y, behavior: behavior });
};

export const unique = array => {
    const set = new Set(array);
    return [...set];
};
