import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import SocialLink from 'components/globals/Footer/SocialLink';
import Icon from 'components/ui/Icon';

import styles from './EndState.module.scss';

const EndState = ({ headline, body, socialLinks, onReset }) => {
    return (
        <div className={styles.root}>
            <Icon
                className={styles.icon}
                src="https://images.ctfassets.net/j8tkpy1gjhi5/66jv9aNXjx2KIf7uaDJonP/23b6a8ac56ae79a7b9649471fd9163c6/Checkmark.svg"
            />
            <Text
                className={styles.headline}
                as="h2"
                baseTheme="displaySmall"
                themes={{ medium: 'displayLarge' }}
            >
                {headline}
            </Text>
            <Text
                className={styles.body}
                baseTheme="bodyMedium"
                themes={{ medium: 'bodyLarge' }}
            >
                {body}
            </Text>
            <Button
                theme="filledBlueHoverCream-ultrabold"
                className={styles.resetButton}
                onClick={onReset}
            >
                Start Over
            </Button>
            <div className={styles.social}>
                {socialLinks &&
                    socialLinks.length &&
                    socialLinks.map((data, i) => {
                        return (
                            <SocialLink
                                key={i}
                                href={data.url}
                                platform={data.platform}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

EndState.propTypes = {
    body: PropTypes.string,
    headline: PropTypes.string,
    onReset: PropTypes.func,
    socialLinks: PropTypes.array,
};

export default EndState;
