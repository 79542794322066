import React from 'react';
import PropTypes from 'prop-types';

import CarouselProvider from 'components/ui/CarouselProvider';
import FlipDeck from './FlipDeck';

const BlockFlipDeck = ({
    slides,
    timerDuration,
    isContinuous,
    flushTop,
    flushBottom,
}) => {
    return (
        <CarouselProvider
            slides={slides}
            timerDuration={timerDuration}
            isContinuous={isContinuous}
        >
            <FlipDeck flushTop={flushTop} flushBottom={flushBottom} />
        </CarouselProvider>
    );
};

BlockFlipDeck.propTypes = {
    className: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
    isContinuous: PropTypes.bool,
};

BlockFlipDeck.defaultProps = {
    imageOnLeft: false,
    timerDuration: 7,
    isContinuous: false,
    flushTop: false,
    flushBottom: false,
};

export default BlockFlipDeck;
