import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import styles from './Debug.module.scss';

const Debug = props => {
    const [debug, setDebug] = useState(false);

    useEffect(() => {
        setDebug(props.debug);
    }, [props.debug]);

    const handleClickLogProps = () => {
        console.info(`contentTypeId: ${props.contentTypeId}`, props);
    };

    if (!debug) {
        return props.children;
    }

    return (
        <div className={styles.root}>
            {props.children}
            <div className={styles.panel}>
                <div className={styles.panelInner}>
                    <h3>
                        {startCase(props.contentTypeId).replace(
                            'Block ',
                            'Block - '
                        )}
                    </h3>
                    <button
                        className={styles.button}
                        onClick={handleClickLogProps}
                    >
                        Log Props
                    </button>
                    <a
                        href={`https://app.contentful.com/spaces/j8tkpy1gjhi5/entries/${props.id}`}
                        target="edit"
                        className={styles.button}
                    >
                        Edit
                    </a>
                </div>
            </div>
        </div>
    );
};

Debug.defaultProps = {
    debug: false,
};

Debug.propTypes = {
    debug: PropTypes.bool.isRequired,
    contentTypeId: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    id: PropTypes.string,
};

export default Debug;
