import { useState } from 'react';
import { useForm } from 'react-hook-form';

import * as klaviyo from 'utils/klaviyo';
import api from 'services/api';

const formStates = Object.freeze({
    INITIAL: 'INITIAL',
    SUBMITTING: 'SUBMITTING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    SUBSCRIBED: 'SUBSCRIBED',
});

const DEFAULT = 'DEFAULT';
const EVENT_NAME = 'Subscribed';
const EVENT_PROPERTIES = { name: 'Campaign_Cheddarbration_BOGO_Bug_22' };

const useEmailSignUp = source => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const [formState, setFormState] = useState(formStates.INITIAL);

    const isError = formState === formStates.ERROR;
    const isSuccess = formState === formStates.SUCCESS;
    const isSubmitting = formState === formStates.SUBMITTING;
    const isSubscribed = formState === formStates.SUBSCRIBED;

    const onError = (errors, e) => {
        console.log(errors, e);
    };

    const onSubmit = async formData => {
        if (isSubmitting) {
            return;
        }

        setFormState(formStates.SUBMITTING);

        const formDataClean = Object.keys(formData).reduce((obj, key) => {
            if (obj[key] === DEFAULT) {
                delete obj[key];
            }
            return obj;
        }, formData);

        if (formDataClean.email_address) {
            let formFields = {
                $fields:
                    '$source, email_address, mail_address, mail_city, mail_state, mail_zip',

                //custom fields
                mail_address: formDataClean.street_address,
                mail_city: formDataClean.city,
                mail_state: formDataClean.state,
                mail_zip: formDataClean.zip,
            };

            const { data } =
                await api.klaviyo.retrieveNewsletterSubscriptionStatus({
                    email: formDataClean.email_address,
                });
            console.log('data', data);
            if (data?.responseCode) {
                // Already subscribed: update information, not source
                if (data.responseCode === formStates.SUBSCRIBED) {
                    // formFields['$source'] = data.source;
                    return setFormState(formStates.SUBSCRIBED);
                } else if (data.responseCode === formStates.SUCCESS) {
                    // Not subscribed: update information and source
                    formFields['$source'] = source;
                } else {
                    return setFormState(formStates.ERROR);
                }
            } else {
                return setFormState(formStates.ERROR);
            }

            const response = await klaviyo.subscribe(
                formDataClean.email_address,
                {
                    ...formFields,
                }
            );

            if (response?.success) {
                const trackEventResponse = await api.klaviyo.trackEvent({
                    event: EVENT_NAME,
                    EVENT_PROPERTIES,
                    properties: {
                        name: source,
                        previously_subscribed:
                            data.responseCode === formStates.SUBSCRIBED,
                    },
                    customerProperties: {
                        email: formDataClean.email_address,
                        ...formFields,
                    },
                });

                if (trackEventResponse?.data?.success) {
                    setFormState(formStates.SUCCESS);
                } else {
                    setFormState(formStates.ERROR);
                }
            } else {
                setFormState(formStates.ERROR);
            }
        } else {
            setFormState(formStates.ERROR);
        }
    };

    return {
        isError,
        isSuccess,
        isSubmitting,
        isSubscribed,
        register,
        watch,

        onSubmit: handleSubmit(onSubmit, onError),
        errors,
    };
};

export default useEmailSignUp;
