import PropTypes from 'prop-types';
import React from 'react';

import cx from 'classnames';

import Text from 'components/ui/Text';

import Button from 'components/ui/Button';
import Link from 'components/ui/Link';

import SvgIcon from 'components/ui/SvgIcon';

import styles from './Banner.module.scss';

const DEFAULT = 'DEFAULT';

const Banner = ({
    icon,
    title,
    copy,
    copy2,
    isExpanded,
    isClosing,
    isSuccess,
    isSubmitting,
    isSubscribed,
    register,
    errors,
    onSubmit,
    onClose,
    selectValues,
    watchedValues,
    innerRef,
}) => {
    return (
        <div
            ref={innerRef}
            onAnimationEnd={e => {
                if (/^Banner_close/i.test(e.animationName)) {
                    onClose();
                }
            }}
            className={cx(styles.root, {
                [styles.isExpanded]: isExpanded && !isClosing,
                [styles.isSuccess]: isSuccess,
                [styles.isClosing]: isClosing && isExpanded,
            })}
        >
            <div className={styles.bannerCopy}>
                {icon}
                <div className={styles.headlineWrapper}>
                    <Text
                        baseTheme={'displayXSmall'}
                        themes={{
                            medium: 'displayMedium',
                        }}
                        fixWidows
                    >
                        {title}
                    </Text>
                </div>
                <div className={styles.bodyCopyWrapper}>
                    <Text
                        as="p"
                        baseTheme={'bodyXSmall'}
                        themes={{ medium: 'bodySmall' }}
                        fixWidows
                    >
                        {copy}
                    </Text>
                    <Text
                        as="p"
                        baseTheme={'bodyXSmall'}
                        themes={{ medium: 'bodySmall' }}
                        fixWidows
                    >
                        {copy2}
                    </Text>
                </div>
            </div>
            <form onSubmit={onSubmit} className={styles.form}>
                <div className={styles.inputLockup}>
                    <Text htmlFor="email" as="label" baseTheme="labelSmall">
                        {`Email Address*`}
                    </Text>
                    <Text className={styles.inputWrap} baseTheme="bodyMedium">
                        <input
                            id="email"
                            {...register('email_address', {
                                required: true,
                                pattern:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            className={cx(styles.input, {
                                [styles.inputError]:
                                    errors.email_address || isSubscribed,
                            })}
                            type="email"
                            placeholder={'john@tillamook.com'}
                        />
                    </Text>
                </div>
                <div className={styles.inputLockup}>
                    <Text htmlFor="street" as="label" baseTheme="labelSmall">
                        {`Street Address*`}
                    </Text>
                    <Text className={styles.inputWrap} baseTheme="bodyMedium">
                        <input
                            id="street"
                            {...register('street_address', {
                                required: true,
                            })}
                            className={cx(styles.input, {
                                [styles.inputError]: errors.street_address,
                            })}
                            type="text"
                            placeholder={'123 Main Street'}
                        />
                    </Text>
                </div>
                <div className={styles.location}>
                    <div className={styles.inputLockup}>
                        <Text htmlFor="city" as="label" baseTheme="labelSmall">
                            {`City*`}
                        </Text>
                        <Text
                            className={styles.inputWrap}
                            baseTheme="bodyMedium"
                        >
                            <input
                                id="city"
                                {...register('city', {
                                    required: true,
                                })}
                                className={cx(styles.input, {
                                    [styles.inputError]: errors.city,
                                })}
                                type="text"
                                placeholder={'Tillamook'}
                            />
                        </Text>
                    </div>
                    <div className={styles.state}>
                        <div className={styles.inputLockup}>
                            <Text
                                htmlFor="state"
                                as="label"
                                baseTheme="labelSmall"
                            >
                                {`State*`}
                            </Text>
                            <Text baseTheme="bodyMedium">
                                <select
                                    id="state"
                                    {...register('state', {
                                        required: true,
                                        validate: value => value !== DEFAULT,
                                    })}
                                    defaultValue={DEFAULT}
                                    className={cx(styles.select, {
                                        [styles.inputError]: errors.state,
                                        [styles.isDefault]:
                                            selectValues[
                                                watchedValues.state
                                            ] === DEFAULT,
                                    })}
                                >
                                    <option value={DEFAULT} disabled hidden>
                                        State
                                    </option>
                                    <option value="AL">AL</option>
                                    <option value="AK">AK</option>
                                    <option value="AZ">AZ</option>
                                    <option value="AR">AR</option>
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DE">DE</option>
                                    <option value="DC">DC</option>
                                    <option value="FL">FL</option>
                                    <option value="GA">GE</option>
                                    <option value="HI">HI</option>
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>
                                    <option value="IA">IA</option>
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="ME">ME</option>
                                    <option value="MD">MD</option>
                                    <option value="MA">MA</option>
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MS">MS</option>
                                    <option value="MO">MO</option>
                                    <option value="MT">MT</option>
                                    <option value="NE">NE</option>
                                    <option value="NV">NV</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>
                                    <option value="NY">NY</option>
                                    <option value="NC">NC</option>
                                    <option value="ND">ND</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VT">VT</option>
                                    <option value="VA">VA</option>
                                    <option value="WA">WA</option>
                                    <option value="WV">WV</option>
                                    <option value="WI">WI</option>
                                    <option value="WY">WY</option>
                                </select>
                            </Text>
                        </div>
                        <div className={styles.inputLockup}>
                            <Text
                                htmlFor="zip"
                                as="label"
                                baseTheme="labelSmall"
                            >
                                {`Zip*`}
                            </Text>
                            <Text baseTheme="bodyMedium">
                                <input
                                    id="zip"
                                    {...register('zip', {
                                        required: true,
                                        pattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                                    })}
                                    type="text"
                                    pattern="[0-9]*"
                                    placeholder="97141"
                                    className={cx(styles.input, {
                                        [styles.inputError]: errors.zip,
                                    })}
                                />
                            </Text>
                        </div>
                    </div>
                </div>
                <Text baseTheme="bodyXSmall" className={styles.checkbox}>
                    <label
                        className={cx(styles.checkbox, {
                            [styles['checkbox-error']]: errors.terms,
                        })}
                    >
                        I agree to receive emails for marketing purposes.
                        Tillamook can use my information in accordance with
                        their{' '}
                        <Link
                            href="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy.
                        </Link>
                        <input
                            type="checkbox"
                            {...register('terms', {
                                required: true,
                            })}
                        />
                        <span className={styles.checkmark} />
                    </label>
                </Text>
                <div
                    className={cx(styles.submitButtonWrap, {
                        [styles['submitButtonWrap__isLoading']]: isSubmitting,
                    })}
                >
                    <Button
                        type="submit"
                        theme="filledBlueHoverCream-ultrabold"
                        className={styles.submitButton}
                    >
                        Subscribe
                    </Button>
                </div>
                <div className={styles.disclaimer}>
                    <Text baseTheme="bodyXSmall">
                        *Indicates required field
                    </Text>
                </div>
                <div className={styles.joinError}>
                    <Text
                        className={styles.joinErrorInner}
                        baseTheme="bodyXSmall"
                    >
                        {errors.email_address && 'Email is invalid'}
                        {isSubscribed && (
                            <>Looks like you&rsquo;re already subscribed</>
                        )}
                    </Text>
                </div>
            </form>

            <Button theme="" onClick={onClose} className={styles.closeButton}>
                <div className={styles.closeIconBuffer}>
                    <div className={styles.closeIconWrapper}>
                        <SvgIcon iconType="close" />
                    </div>
                </div>
            </Button>
        </div>
    );
};

Banner.propTypes = {
    title: PropTypes.any,
    copy: PropTypes.any,
    copy2: PropTypes.any,
    icon: PropTypes.any,
    isExpanded: PropTypes.any,
    isClosing: PropTypes.any,
    isSuccess: PropTypes.any,
    isSubmitting: PropTypes.any,
    isSubscribed: PropTypes.any,
    register: PropTypes.any,
    errors: PropTypes.any,
    onSubmit: PropTypes.any,
    onClose: PropTypes.any,
    watchedValues: PropTypes.any,
    selectValues: PropTypes.any,
    innerRef: PropTypes.any,
};

export default Banner;
