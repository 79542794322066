import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CTA from 'components/ui/CTA';
import TextEyebrow from 'components/ui/TextEyebrow';
import Text from 'components/ui/Text';
import AccordionItem from './AccordionItem';

import styles from './BlockAccordion.module.scss';

const BlockAccordion = ({
    accordionItems,
    bodyCopy,
    cta,
    eyebrow,
    groups,
    headline,
    theme,
}) => {
    return (
        <div
            className={cx(styles.blockAccordionFlex, {
                [styles.themeFAQ]: theme === 'FAQ',
            })}
        >
            <div className={styles.blockAccordion}>
                <div className={styles.textBlock}>
                    {theme === 'FAQ' ? (
                        <Fragment>
                            {headline && (
                                <div
                                    className={cx(
                                        styles.eyebrow,
                                        styles.headline
                                    )}
                                >
                                    <TextEyebrow
                                        eyebrow={eyebrow}
                                        headline={headline}
                                        theme="display"
                                    />
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {headline && (
                                <Text
                                    baseTheme="headingMedium"
                                    themes={{ large: 'headingLarge' }}
                                    className={styles.headline}
                                    as="h3"
                                >
                                    {headline}
                                </Text>
                            )}
                            {cta && (cta.link || cta.assetDownloadLink) && (
                                <CTA
                                    theme="outlinedBlue"
                                    cta={cta}
                                    className={styles.cta}
                                >
                                    {cta.text}
                                </CTA>
                            )}
                        </Fragment>
                    )}
                </div>
                {accordionItems && (
                    <div className={styles.accordionItems}>
                        {accordionItems.map((item, id) => {
                            return <AccordionItem {...item} key={id} />;
                        })}
                    </div>
                )}
                {groups?.map((groupItem, i) => {
                    return (
                        <Fragment key={i}>
                            {groupItem.items && (
                                <div className={styles.accordionItems}>
                                    {groupItem.headline && (
                                        <Text
                                            as="div"
                                            className={styles.groupHeadline}
                                            baseTheme="headingSmall"
                                        >
                                            {groupItem.headline}
                                        </Text>
                                    )}
                                    {groupItem.items.map((item, id) => {
                                        return (
                                            <AccordionItem {...item} key={id} />
                                        );
                                    })}
                                </div>
                            )}
                        </Fragment>
                    );
                })}

                {theme === 'FAQ'
                    ? cta &&
                      (cta.link || cta.assetDownloadLink) && (
                          <CTA
                              className={styles.cta}
                              theme="outlinedBlue"
                              cta={cta}
                          >
                              {cta.text}
                          </CTA>
                      )
                    : bodyCopy && (
                          <div className={styles.bodyCopyBlock}>
                              <Text
                                  baseTheme="bodyXSmall"
                                  themes={{ large: 'bodyMedium' }}
                                  className={styles.bodyCopy}
                              >
                                  {bodyCopy}
                              </Text>
                          </div>
                      )}
            </div>
        </div>
    );
};

BlockAccordion.propTypes = {
    accordionItems: PropTypes.array,
    bodyCopy: PropTypes.string,
    cta: PropTypes.object,
    eyebrow: PropTypes.string,
    groups: PropTypes.array,
    headline: PropTypes.string,
    theme: PropTypes.oneOf(['Default', 'FAQ']),
};

BlockAccordion.defaultProps = {
    accordionItems: undefined,
    bodyCopy: '',
    cta: {
        assetDownloadLink: undefined,
        link: undefined,
        text: '',
    },
    eyebrow: '',
    groups: undefined,
    headline: '',
    theme: 'Default',
};

export default BlockAccordion;
