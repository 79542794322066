import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SvgIcon from 'components/ui/SvgIcon';
import Button from 'components/ui/Button';
import ButtonCircle from 'components/ui/ButtonCircle';
import Text from 'components/ui/Text';
import Video from 'components/ui/Video';
import Portal from 'components/ui/Portal';
import Overlay from 'components/ui/Portal/Overlay';
import Stagger from 'components/ui/Stagger';
import UltraBoldHeading from 'components/ui/UltraBoldHeading';
import { CarouselContext } from 'components/ui/Carousel';

import Img from 'components/ui/Img';

import styles from './VideoPlayerYT.module.scss';

const textStyleDefault = {
    baseTheme: 'headingMedium2',
    themes: { large: 'displayLarge' },
};

const textStyleEmbeddedEntry = {
    baseTheme: 'headingLarge',
    themes: {
        medium: 'headingMedium',
        large: 'headingLarge',
        xxLarge: 'headingXLarge',
    },
};

const VideoPlayerYT = ({
    className,
    eyebrow,
    headline,
    youtubeVideoId,
    image,
    imageLarge,
    index,
    videoTextureMp4,
    videoTextureWebm,
    videoTexturePoster,
    embeddedEntry,
    heroVideo,
    isActive,
    richTextHeadline,
    richTextHeadlineSize,
    richTextHeadlineColor,
    richTextHeadlineIcon,
    centerPlayButton,
    threshold,
}) => {
    const { activeIndices } = useContext(CarouselContext);

    const [isOpen, setIsOpen] = useState(false);

    const playButtonTheme =
        richTextHeadline && richTextHeadlineColor !== 'cream'
            ? 'playVideoBlue'
            : 'playVideoCream';

    const isInCarousel = activeIndices.length !== 0;

    const handlePlay = () => {
        if (activeIndices.includes(index) || !isInCarousel) {
            setIsOpen(true);
        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const headlineSizes = {
        small: {
            baseTheme: 'displaySmall',
            themes: {
                medium: 'displayLarge',
                large: 'displayXLarge',
                xxxLarge: 'displayXXLarge',
            },
        },
        medium: {
            baseTheme: 'displayMedium',
            themes: {
                medium: 'displayLarge',
                large: 'displayXLarge',
                xxxLarge: 'displayXXLarge',
            },
        },
    };

    return (
        <>
            <div
                onClick={handlePlay}
                className={cx(styles.videoPlayerYT, className, {
                    [styles.isOpen]: isOpen,
                    [styles.embeddedEntry]: embeddedEntry,
                    [styles.heroVideo]: heroVideo,
                    [styles.isActive]: isActive,
                    [styles.isCentered]: richTextHeadline || centerPlayButton,
                    [styles.centerPlayButton]: centerPlayButton,
                })}
            >
                <div className={styles.background}>
                    {videoTextureMp4?.file?.url &&
                    videoTextureWebm?.file?.url ? (
                        <Video
                            className={styles.image}
                            playState={isOpen ? 'pause' : 'play'}
                            poster={videoTexturePoster?.file?.url}
                            threshold={threshold}
                            sources={[
                                videoTextureMp4.file.url,
                                videoTextureWebm.file.url,
                            ]}
                        />
                    ) : image?.file?.url && imageLarge?.file?.url ? (
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            fallbackImageWidth={732}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: imageLarge.file.url,
                                    imageWidth: 1420,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: 732,
                                },
                            ]}
                        />
                    ) : null}
                </div>
                <Stagger className={styles.foreground} threshold={0.2}>
                    {eyebrow && (
                        <Stagger.Child order={0} className={styles.eyebrow}>
                            <Text
                                baseTheme="labelSmall"
                                themes={{ large: 'labelLarge' }}
                            >
                                {eyebrow}
                            </Text>
                        </Stagger.Child>
                    )}
                    {richTextHeadline ? (
                        <UltraBoldHeading
                            className={styles.headlineSandwich}
                            baseTheme={
                                headlineSizes[richTextHeadlineSize].baseTheme
                            }
                            themes={{
                                medium: headlineSizes[richTextHeadlineSize]
                                    .themes.medium,
                                large: headlineSizes[richTextHeadlineSize]
                                    .themes.large,
                                xxxLarge:
                                    headlineSizes[richTextHeadlineSize].themes
                                        .xxxLarge,
                            }}
                            style={{
                                color: `var(--colors-${richTextHeadlineColor})`,
                            }}
                            icon={
                                richTextHeadlineIcon === 'morningStar'
                                    ? 'morningStar'
                                    : null
                            }
                            headline={richTextHeadline}
                        />
                    ) : (
                        <>
                            {!embeddedEntry && (
                                <Stagger.Child
                                    order={1}
                                    className={styles.headline}
                                >
                                    <Text {...textStyleDefault}>
                                        {headline}
                                    </Text>
                                </Stagger.Child>
                            )}
                            {embeddedEntry && (
                                <Stagger.Child
                                    order={1}
                                    className={styles.headline}
                                >
                                    <Text {...textStyleEmbeddedEntry}>
                                        {headline}
                                    </Text>
                                </Stagger.Child>
                            )}
                        </>
                    )}
                    <Stagger.Child order={2} className={styles.playVideo}>
                        <ButtonCircle theme={playButtonTheme}>
                            <div className="sr-only">
                                Open Video Overlay - {headline}
                            </div>
                        </ButtonCircle>
                    </Stagger.Child>
                </Stagger>
            </div>
            <Portal>
                <Overlay isOpen={isOpen} handleClose={handleClose}>
                    <div
                        className={cx(styles.videoOverlay, {
                            [styles.isOpen]: isOpen,
                        })}
                    >
                        <div
                            className={styles.videoOverlayBackdrop}
                            onClick={handleClose}
                            role="presentation"
                        />
                        <div
                            className={styles.videoOverlayClose}
                            onClick={handleClose}
                        >
                            <Button
                                className={styles.videoOverlayCloseIcon}
                                theme="close"
                                onClick={handleClose}
                            >
                                <SvgIcon iconType="close" />
                                <span className="sr-only">
                                    Close Video Overlay - {headline}
                                </span>
                            </Button>
                        </div>
                        <div className={styles.videoOverlayInnerWrap}>
                            <div
                                className={cx(
                                    styles.videoOverlayBackdrop,
                                    styles.transparent
                                )}
                                onClick={handleClose}
                                role="presentation"
                            />
                            <div className={styles.videoOverlayInner}>
                                {isOpen && (
                                    <iframe
                                        allow="autoplay *; fullscreen *"
                                        allowFullScreen
                                        frameBorder="0"
                                        className={styles.iFrame}
                                        src={`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1&origin=https://www.tillamook.com/`}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Overlay>
            </Portal>
        </>
    );
};

VideoPlayerYT.propTypes = {
    className: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    youtubeVideoId: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    index: PropTypes.number,
    videoTextureMp4: PropTypes.object,
    videoTextureWebm: PropTypes.object,
    videoTexturePoster: PropTypes.object,
    embeddedEntry: PropTypes.bool,
    heroVideo: PropTypes.bool,
    isActive: PropTypes.bool,
    controls: PropTypes.object,
    richTextHeadline: PropTypes.object,
    richTextHeadlineSize: PropTypes.string,
    richTextHeadlineColor: PropTypes.string,
    richTextHeadlineIcon: PropTypes.string,
    centerPlayButton: PropTypes.bool,
    threshold: PropTypes.number,
};

VideoPlayerYT.defaultProps = {
    embeddedEntry: false,
    heroVideo: false,
    isActive: true,
    controls: { current: {} },
    richTextHeadline: null,
    richTextHeadlineSize: 'medium',
    richTextHeadlineColor: 'cream',
    richTextHeadlineIcon: 'none',
    centerPlayButton: false,
    threshold: 1,
};

export default VideoPlayerYT;
