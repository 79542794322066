import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { INLINES } from '@contentful/rich-text-types';

import Img from 'components/ui/Img';
import Button from 'components/ui/Button';
import Link from 'components/ui/Link';
import Stagger from 'components/ui/Stagger';
import Icon from 'components/ui/Icon';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import styles from './BlockProductHero.module.scss';
import RichText from 'components/ui/RichText';

const BlockProductHero = ({
    blockEyebrow,
    blockHeadline,
    cta,
    ctas,
    eyebrow,
    flushBottom,
    flushTop,
    headline,
    image,
    imageLarge,
    richText,
    paragraph,
    textOnRight,
    theme,
    eyebrowColor,
    headlineColor,
    ctaTheme,
    hideCta,
    hideArrow,
    icon,
}) => {
    const richTextOverrides = {
        renderNode: {
            [INLINES.HYPERLINK]: node => {
                return (
                    <Link href={node.data.uri}>
                        <b>{node.content[0].value}</b>
                    </Link>
                );
            },
        },
        renderText: text =>
            text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    };

    if (image?.file?.url && imageLarge?.file?.url) {
        return (
            <div
                className={cx(styles.root, {
                    [styles.featuredTheme]: theme === 'Featured',
                    [styles.displayTheme]: theme === 'Display',
                    [styles.hasCTA]: cta && cta.link,
                    [styles.flushTop]: flushTop,
                    [styles.flushBottom]: flushBottom,
                    [styles.textOnRight]: textOnRight,
                })}
                style={{
                    '--eyebrow-color': `var(--colors-${eyebrowColor})`,
                    '--headline-color': `var(--colors-${headlineColor})`,
                }}
            >
                <div className={styles.inner}>
                    {blockEyebrow && blockHeadline && theme !== 'Display' && (
                        <TextEyebrow
                            eyebrow={blockEyebrow}
                            headline={blockHeadline}
                            className={styles.eyebrowBlock}
                            theme="display"
                        />
                    )}
                    <Link.Entry
                        className={styles.contentWrapper}
                        entry={hideCta && cta?.link ? cta.link : ''}
                    >
                        <div className={styles.innerWrapperInner}>
                            <div className={styles.heroImgBlock}>
                                <Img
                                    className={styles.img}
                                    src={image.file.url}
                                    fallbackImageWidth={768}
                                    alt={image.description || ''}
                                    width={image.file.details?.image?.width}
                                    height={image.file.details?.image?.height}
                                    customSources={[
                                        {
                                            breakpoint: 768,
                                            src: imageLarge.file.url,
                                            imageWidth:
                                                theme === 'Display'
                                                    ? 2560
                                                    : 1420,
                                        },
                                        {
                                            src: image.file.url,
                                            imageWidth: 768,
                                        },
                                    ]}
                                />
                            </div>
                            <div className={styles.innerWrapper}>
                                <Stagger className={styles.textBlock}>
                                    {icon?.icon?.file?.url && (
                                        <Stagger.Child
                                            order={0}
                                            className={styles.mainIcon}
                                        >
                                            <Icon src={icon.icon.file.url} />
                                        </Stagger.Child>
                                    )}
                                    {eyebrow && (
                                        <Stagger.Child
                                            order={1}
                                            className={styles.eyebrow}
                                        >
                                            <Text
                                                baseTheme={'labelSmall'}
                                                themes={{
                                                    large: 'labelLarge',
                                                }}
                                                as="h3"
                                            >
                                                {eyebrow}
                                            </Text>
                                        </Stagger.Child>
                                    )}
                                    <Stagger.Child
                                        order={2}
                                        className={styles.headlineWrapper}
                                    >
                                        <Text
                                            baseTheme={
                                                theme === 'Display' ||
                                                theme === 'Featured'
                                                    ? 'displaySmall'
                                                    : 'displayXSmall'
                                            }
                                            themes={{
                                                large: 'displayMedium',
                                                xxLarge:
                                                    theme === 'Display'
                                                        ? 'displayLargeFluid'
                                                        : null,
                                            }}
                                            className={styles.headline}
                                        >
                                            {headline}
                                        </Text>
                                    </Stagger.Child>
                                    <div
                                        className={cx(
                                            styles.innerEyebrow,
                                            styles.eyebrowWrapper,
                                            {
                                                [styles.featuredLogo]:
                                                    theme === 'Featured',
                                            }
                                        )}
                                    >
                                        {richText && (
                                            <Stagger.Child order={3}>
                                                <div
                                                    className={styles.richText}
                                                >
                                                    <RichText
                                                        richText={richText}
                                                        overrides={
                                                            richTextOverrides
                                                        }
                                                    />
                                                </div>
                                            </Stagger.Child>
                                        )}
                                        {!richText && paragraph && (
                                            <Stagger.Child order={3}>
                                                <Text
                                                    baseTheme={'bodySmall'}
                                                    className={
                                                        styles.innerHeadline
                                                    }
                                                    as="h3"
                                                >
                                                    {paragraph}
                                                </Text>
                                            </Stagger.Child>
                                        )}
                                        {!hideArrow && (
                                            <Stagger.ArrowRight
                                                order={paragraph ? 4 : 3}
                                                className={cx(
                                                    styles.iconWrapper,
                                                    styles.heroWrapper
                                                )}
                                            />
                                        )}
                                        {!hideCta && (
                                            <Stagger.Child
                                                order={paragraph ? 5 : 4}
                                                className={cx(
                                                    styles.buttonWrapper,
                                                    {
                                                        [styles.multiButton]:
                                                            ctas?.length,
                                                    }
                                                )}
                                            >
                                                {ctas?.length ? (
                                                    <>
                                                        {ctas?.map(
                                                            (cta, idx) => (
                                                                <Button
                                                                    key={idx}
                                                                    entry={
                                                                        cta.link
                                                                    }
                                                                    className={
                                                                        styles.cta
                                                                    }
                                                                    theme={
                                                                        idx ===
                                                                        0
                                                                            ? ctaTheme
                                                                            : 'filledCream'
                                                                    }
                                                                >
                                                                    {cta.text}
                                                                </Button>
                                                            )
                                                        )}
                                                    </>
                                                ) : (
                                                    <Button
                                                        entry={cta.link}
                                                        className={styles.cta}
                                                        theme={
                                                            theme === 'Default'
                                                                ? 'filledCream'
                                                                : ctaTheme
                                                        }
                                                    >
                                                        {cta.text}
                                                    </Button>
                                                )}
                                            </Stagger.Child>
                                        )}
                                    </div>
                                </Stagger>
                            </div>
                        </div>
                    </Link.Entry>
                </div>
            </div>
        );
    }

    return null;
};

BlockProductHero.propTypes = {
    blockEyebrow: PropTypes.string,
    blockHeadline: PropTypes.string,
    cta: PropTypes.object,
    ctaTheme: PropTypes.oneOf(['outlinedCream', 'outlinedReserve']),
    ctas: PropTypes.array,
    eyebrow: PropTypes.string,
    eyebrowColor: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    headline: PropTypes.string,
    headlineColor: PropTypes.string,
    hideArrow: PropTypes.bool,
    hideCta: PropTypes.bool,
    icon: PropTypes.object,
    image: PropTypes.object,
    imageLarge: PropTypes.object,
    paragraph: PropTypes.string,
    richText: PropTypes.object,
    textOnRight: PropTypes.bool,
    theme: PropTypes.oneOf(['Featured', 'Default', 'Display']),
};

BlockProductHero.defaultProps = {
    flushBottom: false,
    flushTop: false,
    textOnRight: false,
    theme: 'Default',
    ctas: [],
    eyebrowColor: 'cream',
    headlineColor: 'cream',
    ctaTheme: 'outlinedCream',
    hideCta: false,
    icon: null,
};

export default BlockProductHero;
