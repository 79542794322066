/* eslint-disable @next/next/no-img-element */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import Text from 'components/ui/Text';
import RichText from 'components/ui/RichText';
import Button from 'components/ui/Button';

import styles from './Description.module.scss';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h3"
                    baseTheme="displaySmall"
                    themes={{ large: 'displayMedium' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const bodyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodySmall"
                    themes={{ large: 'bodyMedium' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const Description = ({
    isActive,
    isPrev,
    headline,
    svgIcon,
    eyebrow,
    bodyCopy,
    cta,
    dirNext,
}) => {
    return (
        <div
            className={cx(styles.description, {
                [styles.isActive]: isActive,
                [styles.isPrev]: isPrev,
                [styles.dirNext]: dirNext,
            })}
        >
            {svgIcon?.file?.url && (
                <div className={styles.icon} role="presentation">
                    <img src={svgIcon.file.url} />
                </div>
            )}
            {eyebrow && (
                <div className={styles.eyebrow}>
                    <Text as="p" baseTheme="labelLarge">
                        {eyebrow}
                    </Text>
                </div>
            )}
            {headline && (
                <div className={styles.headline}>
                    <RichText
                        richText={headline}
                        overrides={headlineOverrides}
                    />
                </div>
            )}
            {bodyCopy && (
                <div className={styles.bodyCopy}>
                    <RichText richText={bodyCopy} overrides={bodyOverrides} />
                </div>
            )}
            {cta?.text && cta?.link && (
                <Button className={styles.cta} entry={cta.link}>
                    {cta.text}
                </Button>
            )}
        </div>
    );
};

Description.propTypes = {
    isActive: PropTypes.bool,
    isPrev: PropTypes.bool,
    timerDuration: PropTypes.number,
    eyebrow: PropTypes.string,
    headline: PropTypes.object.isRequired,
    svgIcon: PropTypes.object,
    bodyCopy: PropTypes.object.isRequired,
    cta: PropTypes.object.isRequired,
    dirNext: PropTypes.bool,
};

export default Description;
