import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { AnimatePresence, motion } from 'framer-motion';
import Icon from 'components/ui/Icon';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';

import { cleanupContentfulData } from 'utils/contentful-resolve-response';
import { scrollToOffset } from 'utils';

import ProductsNav from './ProductsNav';
import ProductCode from './ProductCode';
import Form from './Form';
import EndState from './EndState';
import Accordion from './Accordion';

import CTA from './Form/CTA';
import Card from './ProductsNav/Card';

import styles from './BlockContactForm.module.scss';

import { easing, timing } from './animations';

const BlockContactForm2 = ({ productCategories, socialLinks, lastBlock }) => {
    const rootRef = useRef(null);

    const [contactReason, setContactReason] = useState(null);
    const [activeStep, setActiveStep] = useState(-1);
    const [stepsLength, setStepsLength] = useState(0);
    const [formState, setFormState] = useState({});

    const initialLoad = activeStep === -1;
    const endStep = activeStep === 4;

    const handleReset = () => {
        setActiveStep(0);
        setFormState({});
        setContactReason(null);
        scrollToOffset(rootRef?.current, -100, 'smooth');
    };

    useEffect(() => {
        if (activeStep === 0) {
            handleReset();
        } else if (endStep) {
            scrollToOffset(rootRef?.current, -100, 'smooth');
        } else {
            scrollToOffset(rootRef?.current, -10, 'smooth');
        }
    }, [activeStep]);

    const handleUpdateForm = state => {
        setFormState({ ...formState, ...state });
    };

    const config = [
        {
            title: 'Ask a product question',
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/2ahiGuxWji1oYwHcKhNUa3/ffcff03a0094d4bf8c5d3e06209e1942/Babyloaf.svg',
            },
            steps: [
                {
                    title: 'Ask a product question',
                },
                {
                    title:
                        formState.product && activeStep !== 1
                            ? `${formState.product?.pageTitle} ${formState.size?.size}`
                            : 'Pick a product',
                    render: () => (
                        <ProductsNav
                            productCategories={cleanupContentfulData(
                                productCategories
                            )}
                            tabIndex={activeStep < stepsLength - 1 ? 0 : -1}
                            onUpdate={state => (
                                handleUpdateForm(state),
                                setActiveStep(stepsLength - 1)
                            )}
                            additionalCategory={() => (
                                <Card
                                    image="https://images.ctfassets.net/j8tkpy1gjhi5/S8K7B60gCYxh2d5A8vuGC/bda280fb8622e31d4c6907b183dfa44d/Contact-Us-General-Question.png"
                                    onClick={state => (
                                        handleUpdateForm(state),
                                        setActiveStep(stepsLength - 1)
                                    )}
                                >
                                    General Question
                                </Card>
                            )}
                        />
                    ),
                },
                {
                    title: 'Tell us the rest',
                    render: () => (
                        <Form
                            formState={formState}
                            setActiveStep={setActiveStep}
                        />
                    ),
                },
            ],
        },

        {
            title: 'Share product feedback or a concern',
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/47RUou8NWFzm0S2z91h9Q/65b386953b1d8399d1de624840dda6de/ConsumerQuote.svg',
            },

            steps: [
                {
                    title: 'Share product feedback',
                },
                {
                    title:
                        formState.product && activeStep !== 1
                            ? `${formState.product?.pageTitle} ${formState.size?.size}`
                            : 'Pick a product',
                    render: () => (
                        <ProductsNav
                            productCategories={cleanupContentfulData(
                                productCategories
                            )}
                            tabIndex={activeStep < stepsLength - 2 ? 0 : -1}
                            onUpdate={state => (
                                handleUpdateForm(state),
                                setActiveStep(stepsLength - 2)
                            )}
                        />
                    ),
                },
                {
                    title: 'Product Code',
                    render: () => (
                        <ProductCode
                            setActiveStep={setActiveStep}
                            onUpdate={handleUpdateForm}
                            formState={formState}
                            tabIndex={activeStep < stepsLength - 1 ? 0 : -1}
                        />
                    ),
                },
                {
                    title: 'Tell us the rest',
                    render: () => (
                        <Form
                            formState={formState}
                            setActiveStep={setActiveStep}
                        />
                    ),
                },
            ],
        },
        {
            title: 'Ask about visiting us',
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/5TehcyTSFfL6Byhl6NciLB/a3f6039bd45ab5edd1c059ac0cdfe095/Oregon.svg',
            },

            steps: [
                {
                    title: 'Ask about visiting us',
                },
                {
                    title: 'Tell us more',
                    render: () => (
                        <Form
                            formState={formState}
                            setActiveStep={setActiveStep}
                            cta={() => (
                                <CTA
                                    headline="Get more info about visiting Tillamook."
                                    theme="visitUs"
                                />
                            )}
                        />
                    ),
                },
            ],
        },

        {
            title: 'Get support for our online shop',
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/7JxKd0Bl4yQEqJgAq5IPqE/33b6c874c7eddace11cd35bfdab3afd8/Storefront.svg',
            },

            steps: [
                { title: 'Get support for our online shop' },
                {
                    title: 'Tell us more',
                    render: () => (
                        <Form
                            formState={formState}
                            setActiveStep={setActiveStep}
                            defaultMessage="Please share your order number if you have it!"
                        />
                    ),
                },
            ],
        },
        {
            title: 'Send a compliment',
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/67sKYNrn3ltIa9KovSefYM/94e322bc19de73a3550221efd1537aab/Heart.svg',
            },
            steps: [
                { title: 'Send a compliment' },
                {
                    title: 'Tell us more',
                    render: () => (
                        <Form
                            formState={formState}
                            setActiveStep={setActiveStep}
                            cta={() => (
                                <CTA
                                    headline="We’d love to hear from you."
                                    theme="compliment"
                                    socialLinks={socialLinks}
                                />
                            )}
                            defaultMessage="If you want to compliment a specific product, we’d love to know which one and where you found it!"
                        />
                    ),
                },
            ],
        },
        {
            title: 'Something else',
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/1GNzhmiFMZ4NI9ctT3CQC3/c9f9c62985d398fa2aece1d2c75ce7aa/Quote.svg',
            },

            steps: [
                { title: 'something else' },
                {
                    title: 'Tell us more',
                    render: () => (
                        <Form
                            formState={formState}
                            setActiveStep={setActiveStep}
                        />
                    ),
                },
            ],
        },
    ];

    useEffect(() => {
        setStepsLength(config[contactReason]?.steps?.length);
        setFormState({
            ...formState,
            contactReason: config[contactReason]?.title,
        });

        contactReason !== null && setActiveStep(1);
    }, [contactReason]);

    const renderEndState = () => {
        const endStateBody =
            'We’re on it! A Tillamook team member will reach out as soon as possible.';
        const endStateHeadline = 'thank you';

        return (
            <EndState
                headline={endStateHeadline}
                socialLinks={socialLinks}
                body={endStateBody}
                onReset={handleReset}
            />
        );
    };

    const contactReasonsVariants = {
        initial: {
            clipPath: `inset(0% 0% 100% 0%)`,
        },
        animate: {
            clipPath: `inset(0% 0% 0% 0%)`,
            transition: {
                duration: timing.base * 1.25,
                delay: initialLoad ? timing.base / 4 : timing.base,
                ease: easing.height,
            },
        },
        exit: {
            height: 0,
            transition: { duration: timing.base, ease: easing.height },
        },
    };

    const renderContactReasons = () => (
        <motion.div
            role="radiogroup"
            aria-label="Contact reasons"
            className={styles.grid}
            variants={contactReasonsVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            {config.map(({ title, icon }, i) => {
                const contentVariants = {
                    initial: { opacity: 1, scale: 1 },
                    animateIn: {
                        opacity: 1,
                        transition: {
                            duration: timing.base,
                            ease: easing.opacity,
                        },
                    },
                    zoomOut: {
                        scale: i === contactReason ? 1 : 0.75,
                        transition: {
                            duration: timing.base,
                            ease: easing.scale,
                        },
                    },
                    fadeOut: {
                        opacity: i === contactReason ? 1 : 0,
                        transition: {
                            duration: timing.base,
                            ease: easing.opacity,
                        },
                    },
                };

                return (
                    <button
                        className={styles.card}
                        key={i}
                        tabIndex={0}
                        role="radio"
                        aria-label={title}
                        onClick={() => setContactReason(i)}
                    >
                        <motion.div
                            className={styles.content}
                            variants={contentVariants}
                            initial="initial"
                            animate="animateIn"
                            exit={['zoomOut', 'fadeOut']}
                        >
                            <Icon className={styles.icon} src={icon?.src} />
                            <Text
                                className={styles.label}
                                baseTheme="bodySmall"
                                themes={{ medium: 'headingMedium' }}
                            >
                                {title}
                            </Text>
                        </motion.div>
                    </button>
                );
            })}
        </motion.div>
    );

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={publicRuntimeConfig.recaptchaSiteKey}
        >
            <div
                ref={rootRef}
                className={cx(styles.root, {
                    [styles.lastBlock]: lastBlock,
                })}
            >
                <AnimatePresence>
                    {activeStep <= 0
                        ? renderContactReasons()
                        : config[contactReason]?.steps?.map(
                              (item, i) =>
                                  i <= activeStep &&
                                  !endStep && (
                                      <Accordion
                                          key={i}
                                          title={item.title}
                                          index={i}
                                          activeStep={activeStep}
                                          setActive={setActiveStep}
                                          pagination={`${i + 1}/${stepsLength}`}
                                      >
                                          {item.render && item?.render()}
                                      </Accordion>
                                  )
                          )}
                </AnimatePresence>

                <Button
                    theme="filledCreamHoverWhite-ultrabold"
                    className={cx(styles.resetButton, {
                        [styles.isVisible]: activeStep > 0 && !endStep,
                    })}
                    onClick={handleReset}
                    iconLeading="startOver"
                >
                    Start Over
                </Button>

                {endStep && renderEndState()}
            </div>
        </GoogleReCaptchaProvider>
    );
};

BlockContactForm2.propTypes = {
    productCategories: PropTypes.array,
    socialLinks: PropTypes.array,
    lastBlock: PropTypes.bool,
};

export default BlockContactForm2;
