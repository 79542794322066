import { useState, useEffect } from 'react';
import axios from 'axios';

export const useFetch = options => {
    const [response, setResponse] = useState();
    useEffect(() => {
        const load = async () => {
            const response = await axios(options);
            setResponse(response);
        };
        load();
    }, [options.url]);

    return response;
};

export const useGet = (url, options = {}) => {
    options.method = 'get';
    options.url = url;
    return useFetch(options);
};
export const usePost = (url, options = {}) => {
    options.method = 'post';
    options.url = url;
    return useFetch(options);
};
export const useDelete = (url, options = {}) => {
    options.method = 'delete';
    options.url = url;
    return useFetch(options);
};
export const usePut = (url, options = {}) => {
    options.method = 'put';
    options.url = url;
    return useFetch(options);
};
export const usePatch = (url, options = {}) => {
    options.method = 'patch';
    options.url = url;
    return useFetch(options);
};
export const useHead = (url, options = {}) => {
    options.method = 'head';
    options.url = url;
    return useFetch(options);
};
export const useOptions = (url, options = {}) => {
    options.method = 'options';
    options.url = url;
    return useFetch(options);
};
