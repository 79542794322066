import React from 'react';
import PropTypes from 'prop-types';

import CarouselProvider from 'components/ui/CarouselProvider';
import CarouselShowcase from './CarouselShowcase';

const BlockCarouselShowcase = ({ slides, timerDuration, isContinuous }) => {
    return (
        <CarouselProvider
            slides={slides}
            timerDuration={timerDuration}
            isContinuous={isContinuous}
        >
            <CarouselShowcase />
        </CarouselProvider>
    );
};

BlockCarouselShowcase.propTypes = {
    className: PropTypes.string,
    imageOnLeft: PropTypes.bool,
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
    isContinuous: PropTypes.bool,
};

BlockCarouselShowcase.defaultProps = {
    imageOnLeft: false,
    timerDuration: 7,
    isContinuous: false,
};

export default BlockCarouselShowcase;
