import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Portal from 'components/ui/Portal';
import TextEyebrow from 'components/ui/TextEyebrow';
import Button from 'components/ui/Button';
import CTA from 'components/ui/CTA';
import RichText from 'components/ui/RichText';

import SharpnessStory from 'components/overlays/SharpnessStory';
import SharpnessCloseButton from 'components/overlays/SharpnessStory/SharpnessCloseButton';
import Block from 'components/blocks';
import Img from 'components/ui/Img';

import { useTheme } from 'hooks/useTheme';

import styles from './BlockSharpnessStory.module.scss';

const eyebrowColorMap = {
    'makers-reserve': {
        eyebrow: 'makersReserveGoldAlt',
        icon: 'makersReserveGoldAlt',
        headline: 'makersReserveBlack',
    },
};

const BlockSharpnessStory = ({
    eyebrow,
    richHeadline,
    richCopy,
    sharpnessStory,
    sharpnessStoryPage,
    image,
    imageLarge,
    flushTop,
    flushBottom,
    ctaButton,
    textColor,
    hasArrowIcon,
}) => {
    const [isOpen, setOpen] = useState(false);
    const [isReveal, setIsReveal] = useState(false);
    const [globalTheme] = useTheme();

    const handleOpen = () => {
        window.history.pushState(null, null, `/${sharpnessStoryPage.slug}`);
        setOpen(true);
        setTimeout(() => {
            window.requestAnimationFrame(() => {
                setIsReveal(true);
            });
        }, 0);
    };

    const handleClose = useCallback(() => {
        window.history.pushState(
            null,
            null,
            `/${sharpnessStory.closeLink.slug}`
        );
        setIsReveal(false);
    }, [sharpnessStory]);

    const handleKeyDown = useCallback(() => {
        if (event.keyCode === 27) {
            handleClose();
        }
    }, [handleClose]);

    const onCloseTransitionEnd = e => {
        if (e.propertyName === 'transform' && !isReveal) setOpen(false);
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, [handleKeyDown]);

    const renderCTA = () => {
        return (
            <>
                {(ctaButton?.cta?.link ||
                    ctaButton?.cta?.assetDownloadLink) && (
                    <CTA
                        className={styles.cta}
                        theme={ctaButton.theme}
                        cta={ctaButton.cta}
                        iconLeading={ctaButton.iconType}
                    >
                        {ctaButton.cta.text}
                    </CTA>
                )}
                {sharpnessStory && (
                    <Button
                        onClick={handleOpen}
                        className={styles.cta}
                        theme={
                            globalTheme === 'makers-reserve'
                                ? 'filledGoldReserveCreamWithBlackHover'
                                : 'outlinedCream'
                        }
                        iconLeading="compass"
                    >
                        Explore
                    </Button>
                )}
            </>
        );
    };

    const renderInnerContent = () => {
        return (
            <>
                <div className={styles.innerAspect}>
                    <div className={styles.aspectBox}>
                        {image?.file?.url && (
                            <Img
                                className={styles.image}
                                src={image.file.url}
                                fallbackImageWidth={1280}
                                alt={image.description || ''}
                                width={image.file.details?.image?.width}
                                height={image.file.details?.image?.height}
                                customSources={[
                                    {
                                        breakpoint: 1024,
                                        src: imageLarge?.file?.url
                                            ? imageLarge.file.url
                                            : image.file.url,
                                        imageWidth: 1440,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: imageLarge?.file?.url
                                            ? imageLarge.file.url
                                            : image.file.url,
                                        imageWidth: 1080,
                                    },
                                    {
                                        src: image.file.url,
                                        imageWidth: 1280,
                                    },
                                ]}
                            />
                        )}
                    </div>
                    <div className={styles.innerContent}>
                        <div className={styles.block}>
                            <div
                                className={styles.content}
                                style={{ color: `var(--colors-${textColor})` }}
                            >
                                {richHeadline && (
                                    <TextEyebrow
                                        icon={
                                            globalTheme === 'makers-reserve' ||
                                            !hasArrowIcon
                                                ? 'none'
                                                : 'arrowRight'
                                        }
                                        className={styles.eyebrow}
                                        eyebrow={eyebrow}
                                        richHeadline={richHeadline}
                                        theme={
                                            globalTheme === 'makers-reserve'
                                                ? 'makers-reserve'
                                                : 'displaySmallToLarge1'
                                        }
                                        globalTheme={globalTheme}
                                        eyebrowColor={
                                            eyebrowColorMap[globalTheme]
                                                ?.eyebrow
                                        }
                                        headlineColor={
                                            eyebrowColorMap[globalTheme]
                                                ?.headline
                                        }
                                        iconColor={
                                            eyebrowColorMap[globalTheme]?.icon
                                        }
                                    />
                                )}
                                {richCopy && (
                                    <div className={styles.richCopyWrapper}>
                                        <RichText richText={richCopy} />
                                    </div>
                                )}
                                {renderCTA()}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <article
            className={cx(styles.root, [styles[`theme--${globalTheme}`]], {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                {globalTheme !== 'makers-reserve' && renderInnerContent()}
                {globalTheme === 'makers-reserve' && (
                    <>
                        <Block
                            eyebrow={eyebrow}
                            richHeadline={richHeadline}
                            bodyCopy={richCopy}
                            cta={
                                <Button
                                    onClick={handleOpen}
                                    theme={'filledGoldReserveCream'}
                                    iconLeading="compass"
                                >
                                    Explore
                                </Button>
                            }
                            className={''}
                            contentTypeId={'blockTwoColumnTextAlt'}
                        />
                    </>
                )}
            </div>
            {isOpen && sharpnessStory && (
                <Portal>
                    <div
                        className={cx(styles.overlay, {
                            [styles.isReveal]: isReveal,
                        })}
                        onTransitionEnd={onCloseTransitionEnd}
                    >
                        <SharpnessStory {...sharpnessStory} />
                    </div>
                    <SharpnessCloseButton
                        onClose={handleClose}
                        className={cx(styles.overlayCloseButton, {
                            [styles.isReveal]: isReveal,
                        })}
                    />
                </Portal>
            )}
            {globalTheme === 'makers-reserve' && image?.file?.url && (
                <Img
                    className={styles.inlineImage}
                    src={image.file.url}
                    fallbackImageWidth={1280}
                    alt={image.description || ''}
                    customSources={[
                        {
                            breakpoint: 1024,
                            src: imageLarge?.file?.url
                                ? imageLarge.file.url
                                : image.file.url,
                            imageWidth: 1440,
                        },
                        {
                            src: image.file.url,
                            imageWidth: 1280,
                        },
                    ]}
                />
            )}
        </article>
    );
};

BlockSharpnessStory.propTypes = {
    eyebrow: PropTypes.string,
    richHeadline: PropTypes.object,
    richCopy: PropTypes.object,
    sharpnessStory: PropTypes.object,
    sharpnessStoryPage: PropTypes.object,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    ctaButton: PropTypes.object,
    textColor: PropTypes.oneOf(['cream', 'blue']),
    hasArrowIcon: PropTypes.bool,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
};

BlockSharpnessStory.defaultProps = {
    eyebrow: '',
    richCopy: null,
    flushTop: true,
    flushBottom: true,
    textColor: 'cream',
    hasArrowIcon: true,
};

export default BlockSharpnessStory;
