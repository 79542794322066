import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './SharpnessStoryItem.module.scss';

const SharpnessHeadlineMakersReserve = ({ headline, year }) => {
    return (
        <div>
            <div className={styles.makersSharpnessYearGraphic}>
                <svg
                    width="27"
                    height="9"
                    viewBox="0 0 27 9"
                    fill="none"
                    role="presentation"
                >
                    <line
                        x1="1"
                        y1="-1"
                        x2="17"
                        y2="-1"
                        transform="matrix(-1 0 0 1 27 2.5)"
                        stroke="#9F7C45"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                    <line
                        x1="1"
                        y1="-1"
                        x2="26"
                        y2="-1"
                        transform="matrix(-1 0 0 1 27 8.5)"
                        stroke="#9F7C45"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                </svg>
                <Text
                    className={styles.makersSharpnessYear}
                    baseTheme="labelLargeAlt"
                >
                    {year}
                </Text>
                <svg
                    width="27"
                    height="9"
                    viewBox="0 0 27 9"
                    fill="none"
                    role="presentation"
                >
                    <line
                        x1="1"
                        y1="1.5"
                        x2="17"
                        y2="1.5"
                        stroke="#9F7C45"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                    <line
                        x1="1"
                        y1="7.5"
                        x2="26"
                        y2="7.5"
                        stroke="#9F7C45"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                </svg>
            </div>
            <Text
                className={styles.headline}
                baseTheme="parkinsonMedium"
                themes={{ large: 'parkinsonLarge' }}
            >
                {headline ? headline : ''}
            </Text>
        </div>
    );
};

SharpnessHeadlineMakersReserve.propTypes = {
    year: PropTypes.number,
    headline: PropTypes.string,
};

export default SharpnessHeadlineMakersReserve;
