import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useWindowSize } from 'react-use';

import { data } from '../cream-cheese-finder-data';

import Text from 'components/ui/Text';

import styles from './FlavorFinderPrompt.module.scss';

const FlavorFinderPrompt = ({
    isPrompt,
    isInteractive,
    isSwitching,
    isPayoff,
    cardSet,
}) => {
    const questionRef = useRef(null);
    const [isTallQuestion, setIsTallQuestion] = useState(false);

    const { width: windowWidth } = useWindowSize();

    useEffect(() => {
        if (questionRef && questionRef.current) {
            const height = questionRef.current.offsetHeight;
            setIsTallQuestion(height > 78);
        }
    }, [windowWidth, questionRef, isTallQuestion, isPrompt]);

    return (
        <div
            className={cx(styles.root, {
                [styles.isPrompt]: isPrompt,
                [styles.isInteractive]: isInteractive,
                [styles.isSwitching]: isSwitching,
                [styles.isPayoff]: isPayoff,
                [styles.isTallQuestion]: isTallQuestion,
            })}
        >
            <Text className={styles.leadIn} baseTheme="bodyMedium">
                <Text className={styles.leadInCount} baseTheme="labelLarge">
                    {`${cardSet + 1}/${data.length}`}
                </Text>
                <span
                    dangerouslySetInnerHTML={{
                        __html: data[cardSet]?.prompt,
                    }}
                />
            </Text>
            <div className={styles.question}>
                <Text className={styles.questionText} baseTheme="displaySmall">
                    <span
                        ref={questionRef}
                        className={styles.questionTextInner}
                    >
                        {data[cardSet]?.question}
                    </span>
                </Text>
                <Text className={styles.suggestion} baseTheme="bodyMedium">
                    Swipe the cards left/right or tap the buttons to find your
                    flavor match
                </Text>
            </div>
        </div>
    );
};

FlavorFinderPrompt.propTypes = {
    isPrompt: PropTypes.bool,
    isInteractive: PropTypes.bool,
    isSwitching: PropTypes.bool,
    isPayoff: PropTypes.bool,
    cardSet: PropTypes.number,
};

export default FlavorFinderPrompt;
