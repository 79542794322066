import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Img from 'components/ui/Img';

import styles from './Highlight.module.scss';

const Highlight = ({ bodyText, headline, image, inView }) => {
    return (
        <div className={styles.highlight}>
            <div
                className={cx(styles.imageContainer, {
                    [styles.imageContainer__animated]: inView,
                })}
            >
                <Img
                    className={styles.image}
                    src={image.file.url}
                    fallbackImageWidth={600}
                    alt={image.description || ''}
                    customSources={[
                        { breakpoint: 768, imageWidth: 600 },
                        { breakpoint: 320, imageWidth: 600 },
                    ]}
                />
            </div>
            <Text
                baseTheme="headingLarge"
                themes={{ large: 'headingXLarge' }}
                className={styles.headline}
                as="h5"
            >
                {headline}
            </Text>
            <Text
                className={styles.bodyText}
                baseTheme="bodyMedium"
                themes={{ large: 'bodyLarge' }}
                as="p"
            >
                {bodyText}
            </Text>
        </div>
    );
};

Highlight.propTypes = {
    bodyText: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    inView: PropTypes.bool,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            url: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

export default Highlight;
