import React from 'react';
import PropTypes from 'prop-types';

const BorderDivotSvg1px = ({ className, color, side }) => {
    return (
        <>
            {side === 'top' && (
                <svg
                    className={className}
                    width="52.7px"
                    height="16px"
                    viewBox="0 0 52.7 16"
                >
                    <polyline
                        fill="none"
                        stroke={color}
                        strokeMiterlimit="10"
                        points="52.7,0.5 41.3,0.5 26.4,14.8 11.4,0.5 0,0.5 "
                    />
                </svg>
            )}
            {side === 'right' && (
                <svg
                    className={className}
                    width="16px"
                    height="52.7px"
                    viewBox="0 0 16 52.7"
                >
                    <polyline
                        fill="none"
                        stroke={color}
                        strokeMiterlimit="10"
                        points="15.5,52.7 15.5,41.3 1.2,26.4 15.5,11.4 15.5,0 "
                    />
                </svg>
            )}
            {side === 'bottom' && (
                <svg
                    className={className}
                    width="52.7px"
                    height="16px"
                    viewBox="0 0 52.7 16"
                >
                    <polyline
                        fill="none"
                        stroke={color}
                        strokeMiterlimit="10"
                        points="0,15.5 11.4,15.5 26.4,1.2 41.3,15.5 52.7,15.5 "
                    />
                </svg>
            )}
            {side === 'left' && (
                <svg
                    className={className}
                    width="16px"
                    height="52.7px"
                    viewBox="0 0 16 52.7"
                >
                    <polyline
                        fill="none"
                        stroke={color}
                        strokeMiterlimit="10"
                        points="0.5,0 0.5,11.4 14.8,26.4 0.5,41.3 0.5,52.7 "
                    />
                </svg>
            )}
        </>
    );
};

BorderDivotSvg1px.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

export default BorderDivotSvg1px;
