import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Img from 'components/ui/Img';
import Text from 'components/ui/Text';
import Stagger from 'components/ui/Stagger';

import styles from './CarouselHeroSlide.module.scss';

const CarouselHeroSlide = ({
    eyebrow,
    richHeadline,
    image,
    imageLarge,
    className,
    isActive,
    isPrev,
    isShowInitial,
    isShifting,
    timerDuration,
    shopCallout,
}) => {
    const [transitionEnded, setTransitionEnded] = useState(false);

    const handleTransitionEnd = () => {
        setTransitionEnded(true);
    };

    useEffect(() => {
        setTransitionEnded(false);
    }, [isActive]);

    return (
        <article
            className={cx(styles.root, className, {
                [styles.isActive]: isActive,
                [styles.isPrev]: !transitionEnded && isPrev,
                [styles.isShifting]: isShifting,
                [styles.isShowInitial]: isShowInitial,
                [styles.hasShopCallout]: shopCallout,
            })}
        >
            <div className={styles.column}>
                <div className={styles.imageWrapper}>
                    <div
                        className={styles.imageWrapperInner}
                        style={{
                            transitionDuration: `${timerDuration + 3}s`,
                        }}
                    >
                        {image?.file?.url &&
                            image?.file?.details?.image &&
                            imageLarge?.file?.url &&
                            imageLarge?.file?.details?.image && (
                                <Img
                                    className={styles.image}
                                    src={image.file.url}
                                    fallbackImageWidth={640}
                                    alt={image.description || ''}
                                    customSources={[
                                        {
                                            breakpoint: 768,
                                            src: imageLarge.file.url,
                                            imageWidth: 1440,
                                        },
                                        {
                                            src: image.file.url,
                                            imageWidth: 640,
                                        },
                                    ]}
                                />
                            )}
                    </div>
                </div>
            </div>
            <div className={styles.column}>
                {richHeadline && (
                    <Stagger className={styles.textLockup}>
                        <div className={styles.eyebrowLockup}>
                            {eyebrow && (
                                <Stagger.Child
                                    order={0}
                                    className={styles.eyebrowWrap}
                                >
                                    <Text
                                        className={styles.eyebrow}
                                        baseTheme={'labelSmall'}
                                        themes={{ medium: 'labelLarge' }}
                                        as="h3"
                                    >
                                        {eyebrow}
                                    </Text>
                                </Stagger.Child>
                            )}
                            <Stagger.Child
                                order={1}
                                className={styles.headline}
                            >
                                {richHeadline.content.map((line, i) => {
                                    if (line.content[0].value === '') {
                                        return null;
                                    }
                                    return (
                                        <div
                                            key={i}
                                            className={styles.headlineLineWrap}
                                            onTransitionEnd={
                                                isPrev
                                                    ? handleTransitionEnd
                                                    : null
                                            }
                                        >
                                            <Text
                                                className={styles.headlineLine}
                                                as="h2"
                                                baseTheme="displaySmall"
                                                themes={{
                                                    large: 'displayMedium',
                                                    xxLarge: 'displayLarge',
                                                }}
                                            >
                                                {line.content[0].value}
                                            </Text>
                                        </div>
                                    );
                                })}
                            </Stagger.Child>
                        </div>
                    </Stagger>
                )}
            </div>
        </article>
    );
};

CarouselHeroSlide.propTypes = {
    eyebrow: PropTypes.string,
    richHeadline: PropTypes.object.isRequired,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    className: PropTypes.string,
    isActive: PropTypes.bool,
    isPrev: PropTypes.bool,
    isShowInitial: PropTypes.bool,
    isShifting: PropTypes.bool,
    timerDuration: PropTypes.number,
    shopCallout: PropTypes.object,
};

export default CarouselHeroSlide;
