import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { BLOCKS } from '@contentful/rich-text-types';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import CTA from 'components/ui/CTA';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Stagger from 'components/ui/Stagger';

import styles from './BlockTwoColumnImage.module.scss';
import RichHeadline from './RichHeadline';

const BlockTwoColumnImage = ({
    headline,
    richHeadline,
    bodyCopy,
    richText,
    theme,
    leftImage,
    className,
    image,
    lastBlock,
    cta,
    ctas,
    flushTop,
    flushBottom,
    hasArrowIcon,
}) => {
    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyMedium' }}
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.UL_LIST]: (node, children) => {
                return <ul className={styles.richUnorderedList}>{children}</ul>;
            },
        },
    };

    return (
        <article
            className={cx(styles.blockTwoColumnFlex, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.lastBlock]: lastBlock && !flushBottom,
            })}
        >
            <div
                className={cx(
                    styles.blockTwoColumn,
                    className,
                    styles[`theme--${theme}`],
                    {
                        [styles.blockTwoColumnReversed]: leftImage,
                    }
                )}
            >
                <div className={styles.column}>
                    {image?.file?.url && (
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            fallbackImageWidth={500}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: image.file.url,
                                    imageWidth: 1024,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: 732,
                                },
                            ]}
                        />
                    )}
                </div>
                <div className={styles.column}>
                    <Stagger className={styles.lockup}>
                        {headline && !richHeadline && (
                            <Stagger.Child
                                order={0}
                                className={styles.headline}
                            >
                                <Text
                                    baseTheme="displayXSmall"
                                    themes={{
                                        large: 'displayMedium',
                                    }}
                                >
                                    {headline}
                                </Text>
                            </Stagger.Child>
                        )}
                        {richHeadline && (
                            <Stagger.Child
                                order={0}
                                className={styles.richHeadline}
                            >
                                <RichHeadline richText={richHeadline} />
                            </Stagger.Child>
                        )}
                        {hasArrowIcon && (
                            <Stagger.ArrowRight
                                order={1}
                                theme="small"
                                className={styles.iconWrapper}
                            />
                        )}
                        {bodyCopy && !richText && (
                            <Stagger.Child order={2} className={styles.copy}>
                                <Text
                                    baseTheme="bodySmall"
                                    themes={{
                                        large: 'bodyMedium',
                                    }}
                                    fixWidows
                                >
                                    {bodyCopy}
                                </Text>
                            </Stagger.Child>
                        )}
                        {richText && (
                            <Stagger.Child order={2} className={styles.copy}>
                                <RichText
                                    richText={richText}
                                    overrides={richTextOverrides}
                                />
                            </Stagger.Child>
                        )}
                        {ctas?.length ? (
                            <Stagger.Child
                                order={3}
                                className={cx(styles.buttonWrapper, {
                                    [styles.multiButton]: ctas?.length,
                                })}
                            >
                                {ctas?.map((cta, idx) => (
                                    <Button
                                        key={idx}
                                        entry={cta.link}
                                        className={styles.cta}
                                        theme={
                                            idx === 0
                                                ? 'outlinedBlue'
                                                : 'filledBlue'
                                        }
                                    >
                                        {cta.text}
                                    </Button>
                                ))}
                            </Stagger.Child>
                        ) : (
                            <>
                                {cta && (
                                    <Stagger.Child
                                        order={3}
                                        className={styles.buttonWrapper}
                                    >
                                        {cta.contentTypeId === 'ctaButton' && (
                                            <CTA {...cta}>{cta?.cta?.text}</CTA>
                                        )}

                                        {cta.contentTypeId === 'cta' && (
                                            <>
                                                {cta.assetDownloadLink ? (
                                                    <Button
                                                        download
                                                        className={styles.cta}
                                                        theme="outlinedBlue"
                                                        iconLeading="download"
                                                        href={
                                                            cta
                                                                .assetDownloadLink
                                                                .file?.url
                                                        }
                                                    >
                                                        {cta.text}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className={styles.cta}
                                                        theme="outlinedBlue"
                                                        entry={cta.link}
                                                    >
                                                        {cta.text}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </Stagger.Child>
                                )}
                            </>
                        )}
                    </Stagger>
                </div>
            </div>
        </article>
    );
};

BlockTwoColumnImage.propTypes = {
    richHeadline: PropTypes.object,
    headline: PropTypes.string,
    bodyCopy: PropTypes.string,
    richText: PropTypes.object,
    leftImage: PropTypes.bool,
    image: PropTypes.object,
    cta: PropTypes.shape({
        link: PropTypes.object,
        text: PropTypes.string,
        assetDownloadLink: PropTypes.object,
        contentTypeId: PropTypes.string,
        cta: PropTypes.shape({ text: PropTypes.string }),
    }),
    ctas: PropTypes.array,
    className: PropTypes.string,
    theme: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    hasArrowIcon: PropTypes.bool,
    lastBlock: PropTypes.bool,
};

BlockTwoColumnImage.defaultProps = {
    className: null,
    leftImage: false,
    hasArrowIcon: true,
    lastBlock: false,
    ctas: [],
};

export default BlockTwoColumnImage;
