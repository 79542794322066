import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';

import styles from './BlockAudio.module.scss';

const BlockAudio = ({ sources }) => {
    const playerRef = useRef(null);

    const [playerIsReady, togglePlayerReady] = useState(false);
    const [isPlaying, toggleIsPlaying] = useState(false);
    const [playPercent, updatePlayPercent] = useState(0);

    const handlePlay = () => {
        if (playerRef.current) {
            try {
                if (!isPlaying) {
                    playerRef.current.play();
                    toggleIsPlaying(true);
                } else {
                    playerRef.current.pause();
                    toggleIsPlaying(false);
                }
            } catch (e) {
                console.warn(e);
            }
        }
    };

    const formatDuration = timeInSeconds => {
        if (timeInSeconds) {
            // convert to minutes and round to upper bound
            if (timeInSeconds > 60) {
                // Whole minutes
                const minutes = Math.floor(timeInSeconds / 60);

                // Remainder seconds
                const seconds = timeInSeconds - minutes * 60;

                return `${minutes}:${seconds}`;
            }

            // round seconds to the upper bound
            return `00:${Math.round(timeInSeconds)}`;
        }
    };

    const handleTimeUpdate = evt => {
        const { currentTime, duration } = evt.target;

        updatePlayPercent((currentTime / duration) * 100);
    };

    const handleLoadedMetadata = () => {
        // Enable play button when metadata is loaded
        // Last event fired via Safari iOS

        if (!playerIsReady) {
            togglePlayerReady(true);
        }
    };

    if (!sources?.length) {
        return null;
    }

    return (
        <div className={styles.root}>
            <audio
                preload="metadata"
                ref={playerRef}
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleTimeUpdate}
            >
                {sources?.map((src, idx) => (
                    <source key={idx} src={src.file.url} />
                ))}
            </audio>
            <div className={styles.player}>
                <Button
                    disabled={!playerIsReady}
                    theme="none"
                    onClick={handlePlay}
                >
                    <div className={styles.audioButton}>
                        <div
                            className={cx(styles.iconWrapper, {
                                [styles.pauseIcon]: isPlaying,
                                [styles.playIcon]: !isPlaying,
                            })}
                        >
                            <SvgIcon
                                iconType={
                                    isPlaying ? 'audioPause' : 'audioPlay'
                                }
                            />
                        </div>
                    </div>
                </Button>
                <div className={styles.progressBar}>
                    <div className={styles.innerProgressBar}>
                        <div className={styles.playLine}>
                            <div
                                className={styles.innerPlayLine}
                                style={{
                                    transform: `translateX(-${
                                        100 - playPercent
                                    }%)`,
                                }}
                            ></div>
                        </div>
                        <div className={styles.durationLine} />
                    </div>
                </div>
                <div className={styles.duration}>
                    <Text baseTheme="bodyXSmall">
                        {playerRef?.current?.duration
                            ? formatDuration(playerRef.current.duration)
                            : '00:00'}
                    </Text>
                </div>
            </div>
        </div>
    );
};

BlockAudio.propTypes = {
    sources: PropTypes.array.isRequired,
};

export default BlockAudio;
