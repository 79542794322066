import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { motion } from 'framer-motion';

import Button from 'components/ui/Button';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './Spinner.module.scss';

const Spinner = ({ children, className }) => {
    const constraintsRef = useRef(null);
    const [rotation, setRotation] = useState(0);

    const onDrag = (event, info) => {
        setRotation(rotation + info.delta.x);
    };

    return (
        <motion.div className={styles.spinnerWrapper} ref={constraintsRef}>
            <motion.div
                className={styles.image}
                drag="x"
                dragElastic={0}
                onDrag={onDrag}
                dragMomentum={false}
                dragConstraints={constraintsRef}
            >
                <motion.div
                    className={className}
                    animate={{ rotate: rotation }}
                    transition={{
                        ease: 'easeOut',
                        duration: 1.5,
                    }}
                >
                    {children}
                </motion.div>
                <div className={styles.buttonWrapper}>
                    <Button theme="filledOrange" className={styles.button}>
                        <span
                            className={cx(styles.buttonIcon, styles.arrowLeft)}
                        >
                            <SvgIcon iconType="arrowLeft" />
                        </span>
                        Drag to Explore
                        <span
                            className={cx(styles.buttonIcon, styles.arrowRight)}
                        >
                            <SvgIcon iconType="arrowRight" />
                        </span>
                    </Button>
                </div>
            </motion.div>
        </motion.div>
    );
};

Spinner.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Spinner;
