import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import Link from 'components/ui/Link';

import styles from './BubbleButton.module.scss';

const BubbleButton = props => {
    const buttonProps = {
        ...props,
    };

    delete buttonProps.iconSlot;

    const containsLink = buttonProps.entry || buttonProps.href;
    const Tag = containsLink ? 'span' : 'button';

    const renderShell = () => (
        <div className={props?.className}>
            <Tag
                {...buttonProps}
                className={cx(
                    styles.root,
                    props.iconSlot && styles.hasIcon,
                    styles[`theme--${props.theme}`]
                )}
            >
                <span>{props.children}</span>
                <svg
                    viewBox="0 0 22 16"
                    fill="none"
                    className={styles.highlight}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        opacity="0.95"
                        d="M20.9232 3.37132L20.9575 3.29765L20.9711 3.21752C21.0481 2.76489 20.9741 2.33645 20.733 1.97135C20.5007 1.61958 20.1502 1.38234 19.771 1.22236C19.0254 0.907852 18.0038 0.822548 16.9161 0.871035C14.7177 0.969029 11.9442 1.63058 9.77232 2.56464C7.37014 3.59774 4.88966 5.65072 3.13142 7.77578C2.25014 8.84093 1.52994 9.9484 1.09127 10.9838C0.66139 11.9984 0.458059 13.0486 0.776067 13.9307C0.946436 14.4033 1.19705 14.819 1.59552 15.0471C2.02268 15.2917 2.47652 15.2429 2.86389 15.0902C3.23873 14.9424 3.61378 14.6761 3.9767 14.369C4.34593 14.0566 4.73933 13.6699 5.14958 13.2474C5.43405 12.9544 5.72866 12.6425 6.03355 12.3198C7.5083 10.7589 9.22349 8.94344 11.1953 7.77239C13.2868 6.93161 14.9475 6.47367 16.3028 6.09998C16.756 5.97502 17.175 5.85948 17.5645 5.7422C19.1038 5.27874 20.276 4.76151 20.9232 3.37132Z"
                        fill="var(--highlight)"
                        strokeWidth="1.2"
                    />
                </svg>
                <div className={styles.iconSlot}>{props.iconSlot}</div>
            </Tag>
        </div>
    );

    const LinkComponent = buttonProps.entry ? Link?.Entry || Link : Link;

    return containsLink ? (
        <LinkComponent
            {...props}
            href={buttonProps.href}
            entry={buttonProps.entry}
            className={cx(
                props?.className,
                styles.isLink,
                buttonProps.className && buttonProps.className,
                {
                    [styles.disabled]: buttonProps.disabled,
                }
            )}
        >
            {renderShell()}
        </LinkComponent>
    ) : (
        renderShell()
    );
};

BubbleButton.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
    iconSlot: PropTypes.any,
    theme: PropTypes.string,
};

export default BubbleButton;
