import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import BlockProductFinder from '../BlockProductFinder';
import BlockGlobalNewsletter from './BlockGlobalNewsletter';

import styles from './BlockGlobal.module.scss';

const blockMap = {
    Newsletter: BlockGlobalNewsletter,
    Tillamaps: BlockProductFinder,
};

const BlockGlobal = ({ block, flushTop, className }) => {
    if (block && blockMap[block]) {
        const BlockRendered = blockMap[block];

        return (
            <section className={cx(styles.blockGlobal, className)}>
                <BlockRendered flushTop={flushTop} />
            </section>
        );
    }

    return null;
};

BlockGlobal.propTypes = {
    block: PropTypes.oneOf(['Tillamaps', 'Newsletter']).isRequired,
    flushTop: PropTypes.bool,
    className: PropTypes.string,
};

BlockGlobal.defaultProps = {
    flushTop: false,
};

export default BlockGlobal;
