import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useMediaQuery from 'hooks/useMediaQuery';

import Link from 'components/ui/Link';
import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';

import { useWindowSize } from 'react-use';

import styles from './Footer.module.scss';

const FooterSectionBlock = ({
    dataIndex,
    headline,
    routes,
    mobileOpen,
    onClick,
}) => {
    const isMobile = useMediaQuery('(max-width: 767px)');
    const [height, setHeight] = useState(0);
    const heightRef = useRef(null);
    const { width } = useWindowSize();

    const getHeight = () => {
        setHeight(heightRef.current.offsetHeight);
    };

    useEffect(() => {
        getHeight();
    }, []);

    useEffect(() => {
        if (isMobile) {
            getHeight();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    return (
        <div
            className={cx(styles.footerSectionBlock, {
                [styles.mobileOpen]: mobileOpen,
            })}
        >
            <div
                className={styles.blockHeader}
                data-index={dataIndex}
                onClick={onClick}
            >
                <Text
                    as="h3"
                    className={styles.blockHeadline}
                    baseTheme="labelSmall"
                    themes={{
                        xxLarge: 'labelLarge',
                    }}
                >
                    {headline}
                </Text>
                <SvgIcon
                    className={styles.blockHeaderIcon}
                    iconType="arrowDown"
                />
            </div>
            <div
                className={styles.blockList}
                style={{
                    height: !isMobile ? 'auto' : mobileOpen ? `${height}px` : 0,
                }}
            >
                <ul className={styles.blockListInner} ref={heightRef}>
                    {routes.map((route, idx) => (
                        <li className={styles.blockListItem} key={idx}>
                            <Text
                                baseTheme="bodySmall"
                                themes={{
                                    xxLarge: 'bodyMedium',
                                }}
                            >
                                <Link.Entry
                                    className={styles.link}
                                    entry={route.link}
                                >
                                    {route.text}
                                </Link.Entry>
                            </Text>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

FooterSectionBlock.propTypes = {
    dataIndex: PropTypes.number.isRequired,
    headline: PropTypes.string.isRequired,
    routes: PropTypes.array.isRequired,
    mobileOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default FooterSectionBlock;
