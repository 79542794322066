import React, { useEffect, useState, useRef, Fragment } from 'react';

import { Suspense } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';

import { localStorageSupport, saveToLocalStorage } from 'utils';
import { useWait } from 'hooks/useWait';

import Portal from 'components/ui/Portal';
import Card from './Card';
import icons from './icons';

import styles from './Card.module.scss';

const Alert = ({ id }) => {
    const router = useRouter();
    const wait = useWait();

    const innerRef = useRef(null);

    const [isOpen, toggleOpen] = useState(false);
    const [isVisible, toggleVisibility] = useState(true);

    useClickAway(innerRef, () => {
        handleClose();
    });

    function waitFrame() {
        return new Promise(resolve => {
            requestAnimationFrame(resolve);
        });
    }

    async function handleClose() {
        // Save CMS alert id to local storage to avoid rendering alert again
        await saveToLocalStorage(id, 1);
        toggleVisibility(false);

        waitFrame().then(() => {
            toggleOpen(false);
        });
    }

    // Check local storage for the CMS id of this alert
    useEffect(() => {
        if (router.query?.banner && localStorageSupport()) {
            localStorage.removeItem(id);
        }
        if (
            router.isReady &&
            localStorageSupport() &&
            !localStorage.getItem(id)
        ) {
            // Display alert IF alert id is not in local storage
            wait(3500).then(() => toggleOpen(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    function handleKeyDown(evt) {
        if (evt.keyCode === 27) {
            handleClose();
        }
    }

    function toggleEventListener(method) {
        document[`${method}EventListener`]('keydown', handleKeyDown);
    }

    useEffect(() => {
        toggleEventListener('add');

        return () => {
            toggleEventListener('remove');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {isOpen && (
                <Portal>
                    <div className={styles.overlay}>
                        <Suspense fallback={`Loading...`}>
                            <Card
                                icon={
                                    <div
                                        className={styles.icon}
                                        dangerouslySetInnerHTML={{
                                            __html: icons.cheese,
                                        }}
                                    />
                                }
                                title={<>Feed your inbox and save</>}
                                copy={
                                    <>
                                        Join our newsletter and get a buy one
                                        get one free coupon good for any 8oz
                                        Tillamook Cheese. Plus get our best
                                        recipes and more.
                                    </>
                                }
                                successIcon={
                                    <div
                                        className={styles.icon}
                                        dangerouslySetInnerHTML={{
                                            __html: icons.checkmark,
                                        }}
                                    />
                                }
                                successTitle={
                                    <>
                                        You’re
                                        <br />
                                        all set
                                    </>
                                }
                                successCopy={
                                    <>
                                        Thanks for joining! Look out for your
                                        <br /> Tillamook coupon in your mailbox.
                                    </>
                                }
                                source="Campaign_Cheddarbration_BOGO_Bug_22"
                                onClose={handleClose}
                                isVisible={isVisible}
                            />
                        </Suspense>
                    </div>
                </Portal>
            )}
        </Fragment>
    );
};

Alert.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Alert;
