import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';

import { easing, timing } from '../../animations';

import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ label, className, onClick, isHidden, tabIndex }) => {
    const buttonVariants = {
        initial: { opacity: 0, scale: 0.85 },
        animate: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: timing.base * 1,
                delay: timing.base * 1,
                ease: easing.scale,
            },
        },
        exit: {
            opacity: 0,
            scale: 0.85,
            transition: {
                duration: timing.base * 1,
                delay: timing.base * 0.1,

                ease: easing.scale,
            },
        },
    };

    return (
        <AnimatePresence>
            <motion.button
                key={label}
                className={cx(styles.root, className, {
                    [styles.isHidden]: isHidden,
                })}
                variants={buttonVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                onClick={onClick}
                tabIndex={tabIndex}
            >
                <SvgIcon className={styles.icon} iconType="chevronLeft" />{' '}
                <Text
                    className={styles.label}
                    baseTheme="bodySmall"
                    themes={{ medium: 'bodyMedium' }}
                >
                    {label}
                </Text>
            </motion.button>
        </AnimatePresence>
    );
};

Breadcrumbs.propTypes = {
    className: PropTypes.string,
    isHidden: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    step: PropTypes.number,
    tabIndex: PropTypes.number,
};

export default Breadcrumbs;
