import { useState, useEffect } from 'react';

export function useAnimation(duration) {
    const [progress, setProgress] = useState(0);
    const [startTime, setStartTime] = useState(Date.now());
    const play = () => setStartTime(Date.now());
    useEffect(() => {
        let queuedFrame;
        const frame = () => {
            const now = Date.now() - startTime;
            if (now < duration) queuedFrame = requestAnimationFrame(frame);
            setProgress(Math.min(1, now / duration));
        };
        frame();
        return () => cancelAnimationFrame(queuedFrame);
    }, [startTime, duration]);
    return [progress, play];
}
