import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useMount, useUnmount } from 'react-use';
import Script from 'next/script';
import Button from 'components/ui/Button';

const Xola = ({ cta }) => {
    const [isMounted, setMounted] = useState(false);
    useMount(() => {
        if (window.xola) {
            window.xola.initialized = false;
            window.xola.init();
        } else {
            setMounted(true);
        }
    });

    useUnmount(() => {
        if (window.xola) {
            window.xola.initialized = false;
        }
    });

    return (
        <>
            <Button
                className={cx(
                    `xola-${cta.link.type || 'checkout'}`,
                    'xola-custom'
                )}
                data-button-id={cta.link.xolaButtonId}
                theme={cta.theme}
            >
                {cta.text}
            </Button>
            {isMounted && (
                <Script id="xola-loader" src="https://xola.com/checkout.js" />
            )}
        </>
    );
};

Xola.propTypes = {
    cta: PropTypes.object,
};

export default Xola;
