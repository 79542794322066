import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Button from 'components/ui/Button';
import Stagger from 'components/ui/Stagger';

import Img from 'components/ui/Img';

import styles from './BlockAbout.module.scss';

const BlockAbout = ({
    eyebrow,
    headline,
    cta,
    landscapeImage,
    portraitImage,
    theme,
    flushTop,
    flushBottom,
    fullWidth,
    className,
}) => {
    useEffect(() => {
        if (!headline) {
            dispatchWarning(
                'No headline provided. Headline required for render.'
            );
        }

        if (!cta) {
            dispatchWarning('No CTA provided. CTA required for render.');
        }

        if (!landscapeImage) {
            dispatchWarning(
                'No landscape image provided. Landscape image required for render.'
            );
        }

        if (!portraitImage) {
            dispatchWarning(
                'No portrait image provided. Portrait image required for render.'
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (
        headline &&
        cta?.link &&
        cta?.text &&
        landscapeImage.file?.url &&
        landscapeImage.file?.details?.image &&
        portraitImage.file?.url &&
        portraitImage.file?.details?.image
    ) {
        return (
            <div
                className={cx(styles.about, className, {
                    [styles.display]: theme === 'Display',
                    [styles.flushTop]: flushTop,
                    [styles.flushBottom]: flushBottom,
                    [styles.fullWidth]: fullWidth,
                })}
            >
                <div className={styles.inner}>
                    <div className={styles.imageBoxOuter}>
                        <div className={styles.imageBoxInner}>
                            <Img
                                className={styles.image}
                                src={portraitImage.file.url}
                                fallbackImageWidth={768}
                                alt={portraitImage.description || ''}
                                customSources={[
                                    {
                                        breakpoint: 1920,
                                        src: landscapeImage.file.url,
                                        imageWidth: 1920 * 1.5,
                                    },
                                    {
                                        breakpoint: 1440,
                                        src: landscapeImage.file.url,
                                        imageWidth: 1440 * 1.5,
                                    },
                                    {
                                        breakpoint: 1024,
                                        src: landscapeImage.file.url,
                                        imageWidth: 1024 * 1.5,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: landscapeImage.file.url,
                                        imageWidth: 768 * 1.5,
                                    },
                                    {
                                        src: portraitImage.file.url,
                                        imageWidth: 767 * 1.5,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <Stagger className={styles.contentWrapper}>
                        {theme === 'Default' && (
                            <Stagger.Child
                                order={0}
                                className={styles.iconWrapper}
                            >
                                <SvgIcon iconType="sailboat" />
                            </Stagger.Child>
                        )}
                        {eyebrow && (
                            <Stagger.Child order={0} className={styles.eyebrow}>
                                <Text
                                    baseTheme="labelSmall"
                                    themes={{ large: 'labelLarge' }}
                                >
                                    {eyebrow}
                                </Text>
                            </Stagger.Child>
                        )}
                        <Stagger.Child order={1} className={styles.headline}>
                            <Text
                                baseTheme={
                                    theme === 'Display'
                                        ? 'displayMedium'
                                        : 'displaySmall'
                                }
                                themes={{
                                    large: 'displayLarge',
                                    xxLarge:
                                        theme === 'Display'
                                            ? 'displayLargeFluid'
                                            : null,
                                }}
                                as="h2"
                            >
                                {headline}
                            </Text>
                        </Stagger.Child>
                        {theme === 'Display' && (
                            <Stagger.ArrowRight
                                order={2}
                                className={cx(
                                    styles.iconWrapper,
                                    styles.iconWrapperDisplay
                                )}
                            />
                        )}
                        <Stagger.Child order={theme === 'Display' ? 3 : 2}>
                            <Button theme="outlinedCream" entry={cta.link}>
                                {cta.text}
                            </Button>
                        </Stagger.Child>
                    </Stagger>
                </div>
            </div>
        );
    }

    return null;
};

BlockAbout.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    cta: PropTypes.shape({
        link: PropTypes.object.isRequired,
        text: PropTypes.string.isRequired,
    }).isRequired,
    landscapeImage: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }).isRequired,
            }),
            url: PropTypes.string,
        }),
    }),
    portraitImage: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    theme: PropTypes.oneOf(['Default', 'Display']),
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
};

BlockAbout.defaultProps = {
    theme: 'Default',
};

export default BlockAbout;
