import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Stagger from 'components/ui/Stagger';
import CTA from 'components/ui/CTA';
import Divot from 'components/globals/Divot';
import TextEyebrow from 'components/ui/TextEyebrow';
import Text from 'components/ui/Text';

import styles from '../BlockCallout.module.scss';

const themeSettingsMap = {
    'Blue Background': {
        background: 'blueMedium',
        headline: 'cream',
        eyebrow: 'cream',
        body: 'cream',
        icon: 'cream',
        cta: 'outlinedCream',
    },
    'Cream Background': {
        background: 'creamDark',
        headline: 'blue',
        eyebrow: 'blue',
        body: 'blue',
        icon: 'blue',
        cta: 'outlinedCream',
    },
    default: {
        background: 'blueMedium',
        headline: 'cream',
        eyebrow: 'cream',
        body: 'cream',
        icon: 'cream',
        cta: 'outlinedCream',
    },
    'makers-reserve': {
        background: 'creamDark',
        headline: 'makersReserveBlack',
        eyebrow: 'makersReserveGoldAlt',
        body: 'makersReserveBlack',
        icon: 'makersReserveGoldAlt',
        cta: 'outlinedCream',
    },
};

const Banner = ({
    eyebrow,
    richHeadline,
    copy,
    colorTheme: colorThemeOriginal,
    className,
    icon,
    hasNotch,
    hasArrowIcon,
    cta,
    ctaButton,
    buttonComponentSlot,
    globalTheme,
    ctaOnly,
}) => {
    const colorTheme =
        globalTheme === 'makers-reserve' ||
        globalTheme === 'makers-reserve-sharpness'
            ? 'makers-reserve'
            : colorThemeOriginal;

    return (
        <article
            className={cx(
                styles.calloutWrapperFlex,
                styles.calloutWrapper,
                styles[globalTheme],
                className,
                styles.banner,
                {
                    [styles.hasNotch]: hasNotch,
                    [styles.withCopy]: copy,
                }
            )}
        >
            <div
                className={styles.calloutWrapper}
                style={{
                    backgroundColor: `var(--colors-${themeSettingsMap[colorTheme]?.background})`,
                }}
            >
                {hasNotch && (
                    <Divot theme={themeSettingsMap[colorTheme]?.background} />
                )}
                <div
                    className={styles.callout}
                    style={{
                        '--eyebrow': `var(--colors-${themeSettingsMap[colorTheme]?.eyebrow})`,
                        '--headline': `var(--colors-${themeSettingsMap[colorTheme]?.headline})`,
                        '--icon': `var(--colors-${themeSettingsMap[colorTheme]?.icon})`,
                        '--body': `var(--colors-${themeSettingsMap[colorTheme]?.body})`,
                    }}
                >
                    <div className={styles.inner}>
                        {richHeadline && !ctaOnly && (
                            <div className={styles.section}>
                                <TextEyebrow
                                    className={styles.eyebrow}
                                    eyebrow={eyebrow ? eyebrow : ''}
                                    richHeadline={richHeadline}
                                    icon={
                                        buttonComponentSlot ||
                                        cta ||
                                        ctaButton ||
                                        !hasArrowIcon
                                            ? 'none'
                                            : icon
                                    }
                                    theme="normal"
                                    cta={
                                        cta && !buttonComponentSlot
                                            ? {
                                                  ...cta,
                                                  theme: themeSettingsMap[
                                                      colorTheme
                                                  ]?.cta,
                                              }
                                            : null
                                    }
                                    ctaButton={ctaButton}
                                />
                                {buttonComponentSlot && (
                                    <div className={styles.buttonComponentSlot}>
                                        {buttonComponentSlot}
                                    </div>
                                )}
                            </div>
                        )}
                        {copy && !ctaOnly && (
                            <div
                                className={styles.section}
                                style={{
                                    color: `var(--body)`,
                                }}
                            >
                                <Text.Theme
                                    config={{
                                        'makers-reserve': {
                                            baseTheme: 'bodySmall',
                                            themes: {
                                                large: 'bodyMedium',
                                                xLarge: 'bodyLarge',
                                            },
                                        },
                                        default: {
                                            baseTheme: 'bodySmall',
                                            themes: { large: 'bodyMedium' },
                                        },
                                    }}
                                    className={styles.copy}
                                >
                                    {copy}
                                </Text.Theme>
                            </div>
                        )}
                        <Stagger>
                            {ctaOnly &&
                                (cta?.link || cta?.assetDownloadLink) &&
                                !ctaButton && (
                                    <Stagger.Child order={0}>
                                        <CTA
                                            className={styles.cta}
                                            theme={cta.theme}
                                            cta={cta}
                                        >
                                            {cta.text}
                                        </CTA>
                                    </Stagger.Child>
                                )}
                            {ctaOnly &&
                                (ctaButton?.cta?.link ||
                                    ctaButton?.cta?.assetDownloadLink) && (
                                    <Stagger.Child order={0}>
                                        <CTA
                                            className={styles.cta}
                                            theme={ctaButton.theme}
                                            cta={ctaButton.cta}
                                            iconLeading={ctaButton.iconType}
                                        >
                                            {ctaButton.cta.text}
                                        </CTA>
                                    </Stagger.Child>
                                )}
                        </Stagger>
                    </div>
                </div>
            </div>
        </article>
    );
};

Banner.propTypes = {
    className: PropTypes.string,
    eyebrow: PropTypes.string,
    richHeadline: PropTypes.object,
    icon: PropTypes.string,
    copy: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    cta: PropTypes.object,
    ctaButton: PropTypes.object,
    hasNotch: PropTypes.bool,
    hasArrowIcon: PropTypes.bool,
    theme: PropTypes.string,
    globalTheme: PropTypes.string,
    colorTheme: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    buttonComponentSlot: PropTypes.node,
    ctaOnly: PropTypes.bool,
};

Banner.defaultProps = {
    hasNotch: false,
    theme: 'Card',
    colorTheme: 'Blue Background',
    cta: null,
    ctaOnly: false,
    hasArrowIcon: true,
};

export default Banner;
