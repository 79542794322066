import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { wait } from 'utils';

import Button from 'components/ui/Button';
import Text from 'components/ui/Text';

import Img from 'components/ui/Img';
import SwirlTimer from './SwirlTimer';
import SwirlPayoff from './SwirlPayoff';

const ImageSwirler = dynamic(() => import('./ImageSwirler'), {
    ssr: false,
});

import styles from './SwirlStir.module.scss';

const image0 = '/images/creamery-collection/bkg0.jpg';
const image1 = '/images/creamery-collection/bkg1.jpg';
const image2 = '/images/creamery-collection/bkg2.jpg';

const image0Large = '/images/creamery-collection/bkg0-large.jpg';
const image1Large = '/images/creamery-collection/bkg1-large.jpg';
const image2Large = '/images/creamery-collection/bkg2-large.jpg';

const encouragements = [
    'You got this',
    'Swirl like <br />you mean it',
    'Great blending',
    'This looks delicious',
    'Mix it up',
    'A gift is near in sight',
    'Faster! Faster!',
    'How creamy does <br />this look',
];

const loadingImages = {
    0: {
        small: image0,
        large: image0Large,
    },
    1: {
        small: image1,
        large: image1Large,
    },
    2: {
        small: image2,
        large: image2Large,
    },
};

const SwirlStir = ({
    initialId,
    isMedium,
    hasHover,
    isCloseHover,
    hasWebGLSupport,
    className,
}) => {
    const bodyLockRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSwirling, setIsSwirling] = useState(false);
    const [isInteractive, setIsInteractive] = useState(false);
    const [isPayoff, setIsPayoff] = useState(false);
    const [activeId, setActiveId] = useState(initialId);
    const [encouragement, setEncouragement] = useState('');
    const [encouragementsStore, setEncouragementsStore] =
        useState(encouragements);
    const [showEncouragement, setShowEncouragement] = useState(false);
    const [movementScore, setMovementScore] = useState(0);

    const swirlBgImage0 = isMedium ? image0Large : image0;
    const swirlBgImage1 = isMedium ? image1Large : image1;
    const swirlBgImage2 = isMedium ? image2Large : image2;

    const flavors = [
        {
            id: 0,
            name: 'Northwest Raspberry & Blackberry',
            image: swirlBgImage0,
        },
        {
            id: 1,
            name: 'Oregon Strawberry & Plain',
            image: swirlBgImage1,
        },
        {
            id: 2,
            name: 'Oregon Blueberry & Vanilla',
            image: swirlBgImage2,
        },
    ];

    const handleIsReady = () => {
        setIsLoading(false);

        wait(250).then(() => {
            setIsPlaying(true);
            setIsInteractive(true);
        });
    };

    useEffect(() => {
        disableBodyScroll(bodyLockRef.current);
        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const handlePointerDown = useCallback(() => {
        if (isInteractive) {
            setIsSwirling(true);
        }
    }, [isInteractive]);

    const handleTimerEnded = () => {
        setEncouragementsStore(encouragements);
        setIsInteractive(false);
        setIsPayoff(true);
    };

    const handleTryAgain = () => {
        setActiveId(activeId + 1 > 2 ? 0 : activeId + 1);
        setIsPayoff(false);
        setIsSwirling(false);
        setIsInteractive(true);
    };

    const handleTimerMessage = () => {
        const randomItem =
            encouragementsStore[
                Math.floor(Math.random() * encouragementsStore.length)
            ];

        setEncouragementsStore(
            encouragementsStore.filter(item => item !== randomItem)
        );

        setEncouragement(randomItem);
        setShowEncouragement(true);

        wait(2000)
            .then(() => {
                setShowEncouragement(false);
            })
            .then(() => {
                wait(500).then(() => {
                    setEncouragement('');
                });
            });
    };

    const handleMovementScore = score => {
        setMovementScore(score);
    };

    return (
        <section
            ref={bodyLockRef}
            className={cx(styles.root, className, {
                [styles.isLoading]: isLoading,
                [styles.isPlaying]: isPlaying,
                [styles.isInteractive]: isInteractive,
                [styles.isSwirling]: isSwirling,
                [styles.isPayoff]: isPayoff,
                [styles.showEncouragement]: showEncouragement,
            })}
        >
            <div
                gtm-id="swirl-interactive-overlay"
                className={styles.interactive}
                onPointerDown={handlePointerDown}
            >
                {hasWebGLSupport && (
                    <ImageSwirler
                        isInteractive={isInteractive}
                        isSwirling={isSwirling}
                        isPayoff={isPayoff}
                        isMobile={!isMedium}
                        activeId={activeId}
                        flavors={flavors}
                        isReady={handleIsReady}
                        isCloseHover={isCloseHover}
                        sendMovementScore={handleMovementScore}
                    />
                )}

                <Img
                    className={styles.loadingImage}
                    src={loadingImages[activeId].small}
                    fallbackImageWidth={768}
                    alt={''}
                    customSources={[
                        {
                            breakpoint: 1440,
                            src: loadingImages[activeId].large,
                            imageWidth: 1440,
                        },
                        {
                            breakpoint: 1024,
                            src: loadingImages[activeId].large,
                            imageWidth: 1024,
                        },
                        {
                            src: loadingImages[activeId].small,
                            imageWidth: 375,
                        },
                    ]}
                />
                <SwirlTimer
                    show={isInteractive && !isPayoff}
                    start={isSwirling}
                    ended={handleTimerEnded}
                    sendMessage={handleTimerMessage}
                />
                <SwirlPayoff
                    show={isPayoff}
                    activeId={activeId}
                    tryAgain={handleTryAgain}
                    movementScore={movementScore}
                />
                <div className={styles.encouragement}>
                    <span
                        className={styles.encouragementCopy}
                        dangerouslySetInnerHTML={{
                            __html: encouragement,
                        }}
                    />
                </div>
                <div className={styles.prompt}>
                    <Text className={styles.promptLabel} baseTheme="labelLarge">
                        {hasHover ? 'Click' : 'Tap'} & Drag
                    </Text>
                    <Text
                        as="p"
                        className={styles.promptCopy}
                        baseTheme="bodyMedium"
                        themes={{
                            large: 'bodyXLarge',
                        }}
                    >
                        Blend both flavors to become an original swirlmaster.
                        It’s easy: click and twirl for the perfect swirl. Reach
                        the end for a sweet offer off your next Tillamook®
                        product purchase.
                    </Text>
                </div>
                {!hasWebGLSupport && (
                    <div className={styles.error}>
                        <Text
                            className={styles.promptLabel}
                            baseTheme="labelLarge"
                        >
                            WebGL unsupported
                        </Text>
                        <Text
                            as="p"
                            className={styles.promptCopy}
                            baseTheme="bodyMedium"
                            themes={{
                                large: 'bodyXLarge',
                            }}
                        >
                            Sorry! WebGL is unsupported in this browser. This
                            experience requires WebGL.
                        </Text>
                        <Button
                            href="https://ppod.io/s/7HMGcb5UYKI26G06"
                            target="_blank"
                            className={styles.coupon}
                            theme="filledBlue"
                            gtm-id="swirl-claim-your-coupon"
                        >
                            Claim your coupon
                        </Button>
                    </div>
                )}
            </div>
        </section>
    );
};

SwirlStir.propTypes = {
    initialId: PropTypes.number,
    isMedium: PropTypes.bool,
    hasHover: PropTypes.bool,
    isCloseHover: PropTypes.bool,
    hasWebGLSupport: PropTypes.bool,
    className: PropTypes.string,
};

export default SwirlStir;
