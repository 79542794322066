/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import api from 'services/api';

import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';

import useQueryState from 'hooks/useQueryState';

import styles from './BlockPromotionPod.module.scss';

const pageStates = Object.freeze({
    SUCCESS: 'SUCCESS',
    INITIAL: 'INITIAL',
    REQUESTING: 'REQUESTING',
    ERROR: 'ERROR',
    EXPIRED: 'EXPIRED',
    CLAIMED: 'CLAIMED',
});

const pageHeadlines = Object.freeze({
    EXPIRED: 'This offer has already expired',
    ERROR: 'This offer has already expired',
    CLAIMED: 'This coupon has been redeemed',
});

const iconMap = {
    present: 'present',
};

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h3"
                    baseTheme="displayXSmall"
                    themes={{ large: 'displayLarge' }}
                    className={styles.headline}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockPromotionPod = ({
    promotionPodId,
    headline,
    copy,
    icon,
    klaviyoPropertyId,
    klaviyoPropertyValue,
    flushBottom,
    flushTop,
}) => {
    const [pageState, setPageState] = useState(pageStates.INITIAL);
    const [frameDims, setFrameDims] = useState({
        width: '300px',
        height: '300px',
    });
    const [QS, setQS] = useState('');

    const [queryState] = useQueryState();

    const frame = useRef();

    const isError = pageState === pageStates.ERROR;
    const isSuccess = pageState === pageStates.SUCCESS;
    const isExpired = pageState === pageStates.EXPIRED;
    const isInitial = pageState === pageStates.INITIAL;
    const isRequesting = pageState === pageStates.REQUESTING;
    const isClaimed = pageState === pageStates.CLAIMED;

    const frameUrl = `https://banner2.promotionpod.com/banner/${promotionPodId}/`;

    const pageMessages = {
        EXPIRED:
            'Looks like you’ve already used this link to redeem your printed coupon.',
        ERROR: 'Looks like you’ve already used this link to redeem your printed coupon.',
        CLAIMED:
            'Find a store near you on Tillamaps and show your printed coupon to the cashier at checkout. Enjoy!',
        SUCCESS:
            copy ||
            'Before you click, make sure you’re prepared to print. This coupon will expire after one use.',
    };

    const markCouponAsPrinted = async () => {
        // Mark as printed in Klaviyo
        const { data } = await api.klaviyo.printPromotion({
            email: queryState.email,
            propertyId: klaviyoPropertyId,
            propertyValue: klaviyoPropertyValue,
        });

        if (data?.responseCode) {
            setPageState(data.responseCode);
        } else {
            // Safe to mark as claimed because we already know they printed
            setPageState(pageState.CLAIMED);
        }
    };

    useEffect(() => {
        const checkEmail = async email => {
            setPageState(pageStates.REQUESTING);

            if (email && klaviyoPropertyId && klaviyoPropertyValue) {
                const { data } = await api.klaviyo.retrievePromotion({
                    email: email,
                    propertyId: klaviyoPropertyId,
                    propertyValue: klaviyoPropertyValue,
                });

                if (data?.responseCode) {
                    setPageState(data.responseCode);
                } else {
                    setPageState(pageStates.ERROR);
                }
            } else {
                setPageState(pageStates.ERROR);
            }
        };
        if (pageState === pageStates.INITIAL) {
            checkEmail(queryState.email);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSuccess) {
            var propod = window.propod || {};

            if (!propod.handleMessageRequest) {
                propod.handleMessageRequest = async function (message) {
                    var origin = message.origin;
                    if (origin.indexOf('promotionpod.com') === -1) {
                        return;
                    }
                    var data = message.data;
                    if (
                        typeof data !== 'string' ||
                        data.indexOf('propod:') != 0
                    ) {
                        return;
                    }
                    if (data.indexOf('propod:embed:banner:') !== -1) {
                        if (data.indexOf('dimensions:') !== -1) {
                            var prt = data
                                .replace('propod:embed:banner:', '')
                                .split(':');
                            var dim = prt[2].split('x');

                            setFrameDims({
                                width: `${dim[0]}px`,
                                height: `${dim[1]}px`,
                            });
                        }
                        return;
                    }

                    if (
                        data.indexOf('propod:print:printComplete:true') !== -1
                    ) {
                        // Printed!!!
                        return markCouponAsPrinted();
                    }
                };
                if (window.addEventListener) {
                    window.addEventListener(
                        'message',
                        function (m) {
                            propod.handleMessageRequest(m);
                        },
                        false
                    );
                } else {
                    window.attachEvent('onmessage', function (m) {
                        propod.handleMessageRequest(m);
                    });
                }
            }

            const p = window.location.search ? '&' : '';
            setQS(
                window.location.search.substring(1) +
                    p +
                    'cb=' +
                    new Date().getTime() +
                    '&ref=' +
                    encodeURIComponent(window.location)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <div
            className={cx(styles.root, {
                [styles.flushBottom]: flushBottom,
                [styles.flushTop]: flushTop,
            })}
        >
            {(isInitial || isRequesting) && (
                <div className={styles.loadingMessage}>
                    <Text
                        as="p"
                        baseTheme="bodyMedium"
                        themes={{ large: 'bodyLarge' }}
                        className={styles.loadingMessage}
                    >
                        Checking user email...{isRequesting && `one moment...`}
                    </Text>
                </div>
            )}
            {icon &&
                iconMap[icon] &&
                (isError || isExpired || isSuccess || isClaimed) && (
                    <img
                        className={styles.icon}
                        src={`/images/icons/${iconMap[icon]}.svg`}
                    />
                )}
            {isSuccess && (
                <RichText richText={headline} overrides={richTextOverrides} />
            )}
            {pageHeadlines[pageState] && (
                <Text
                    as="h3"
                    baseTheme="displayXSmall"
                    themes={{ large: 'displayLarge' }}
                    className={styles.headline}
                >
                    {pageHeadlines[pageState]}
                </Text>
            )}
            {pageMessages[pageState] && (
                <Text
                    as="p"
                    baseTheme="bodyMedium"
                    themes={{ large: 'bodyLarge' }}
                    className={styles.description}
                >
                    {pageMessages[pageState]}
                </Text>
            )}
            {(isExpired || isError || isClaimed) && (
                <div className={styles.actions}>
                    <Button
                        className={styles.leadingButton}
                        theme="filledBlue"
                        iconLeading="compass"
                        href="/products"
                    >
                        Browse Products
                    </Button>
                    <Button
                        href="https://www.tillamook.com/tillamaps"
                        theme="outlinedBlue"
                        iconLeading="compass"
                    >
                        Explore tillamaps
                    </Button>
                </div>
            )}
            {isSuccess && (
                <div
                    id={`propodEmbedContainer${promotionPodId}`}
                    className={`propodBannerEmbed ${styles.banner}`}
                >
                    <iframe
                        ref={frame}
                        allowTransparency="true"
                        style={{ ...frameDims }}
                        frameBorder="0"
                        scrolling="no"
                        id={`propodIframe${promotionPodId}`}
                        allow="geolocation 'self' https://banner2.promotionpod.com"
                        src={
                            frameUrl.indexOf('?') === -1
                                ? frameUrl + '?' + QS
                                : frameUrl + '&' + QS
                        }
                    ></iframe>
                </div>
            )}
        </div>
    );
};

BlockPromotionPod.propTypes = {
    promotionPodId: PropTypes.string.isRequired,
    headline: PropTypes.object.isRequired,
    copy: PropTypes.string,
    icon: PropTypes.string,
    klaviyoPropertyId: PropTypes.string.isRequired,
    klaviyoPropertyValue: PropTypes.string.isRequired,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
};

BlockPromotionPod.defaultProps = {
    icon: '',
    flushBottom: false,
    flushTop: false,
};

export default BlockPromotionPod;
