import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Img from 'components/ui/Img';

import styles from './Slide.module.scss';

const Slide = ({
    image,
    imageLarge,
    className,
    isActive,
    isPrev,
    timerDuration,
}) => {
    return (
        <article
            className={cx(styles.slide, className, {
                [styles.isActive]: isActive,
                [styles.isPrev]: isPrev,
            })}
        >
            <div className={styles.slideInner}>
                <div className={styles.imageColumn}>
                    <div className={styles.imageWrapper}>
                        <div
                            className={styles.imageWrapperInner}
                            style={{
                                transitionDuration: `${timerDuration + 3}s`,
                            }}
                        >
                            {image.file?.url &&
                                image.file?.details?.image &&
                                imageLarge.file?.url &&
                                imageLarge.file?.details?.image && (
                                    <Img
                                        className={styles.image}
                                        src={image.file.url}
                                        fallbackImageWidth={297 * 2}
                                        alt={image.description || ''}
                                        customSources={[
                                            {
                                                breakpoint: 2560,
                                                src: imageLarge.file.url,
                                                imageWidth: 2560 * 1.5,
                                            },
                                            {
                                                breakpoint: 1920,
                                                src: imageLarge.file.url,
                                                imageWidth: 1920 * 1.5,
                                            },
                                            {
                                                breakpoint: 1440,
                                                src: imageLarge.file.url,
                                                imageWidth: 1440 * 1.5,
                                            },
                                            {
                                                breakpoint: 1024,
                                                src: imageLarge.file.url,
                                                imageWidth: 1024 * 1.5,
                                            },
                                            {
                                                breakpoint: 768,
                                                src: image.file.url,
                                                imageWidth: 768 * 1.5,
                                            },
                                            {
                                                src: image.file.url,
                                                imageWidth: 297 * 2,
                                            },
                                        ]}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
};

Slide.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.object.isRequired,
    bodyCopy: PropTypes.object.isRequired,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    className: PropTypes.string,
    isActive: PropTypes.bool,
    isPrev: PropTypes.bool,
    timerDuration: PropTypes.number,
    cta: PropTypes.object.isRequired,
};

export default Slide;
