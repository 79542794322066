import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';

import styles from './CardCTA.module.scss';

const CardCTA = ({ headline, cta, isLandscape }) => {
    useEffect(() => {
        if (!headline) {
            dispatchWarning(
                'No headline provided. Headline required for render.'
            );
        }

        if (!cta?.link || !cta?.text) {
            dispatchWarning('No CTA provided. CTA required for render.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (headline && cta?.link && cta?.text) {
        return (
            <div className={cx(styles.cta, isLandscape && styles.landscapeCTA)}>
                <div
                    className={cx(
                        styles.ctaInner,
                        isLandscape && styles.landscapeCTAInner
                    )}
                >
                    <Text className={styles.headline} baseTheme="headingLarge">
                        {headline}
                    </Text>
                    <Button theme="outlinedCream" entry={cta.link}>
                        {cta.text}
                    </Button>
                </div>
            </div>
        );
    }

    return null;
};

CardCTA.propTypes = {
    headline: PropTypes.string.isRequired,
    cta: PropTypes.shape({
        link: PropTypes.object.isRequired,
        text: PropTypes.string.isRequired,
    }).isRequired,
    isLandscape: PropTypes.bool,
};

CardCTA.defaultProps = {
    isLandscape: false,
};

export default CardCTA;
