import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SvgIcon from 'components/ui/SvgIcon';
import Link from 'components/ui/Link';

import styles from './Footer.module.scss';

const SocialLink = ({ href, platform }) => {
    return (
        <Link className={styles.socialLink} href={href}>
            <SvgIcon
                className={cx(styles.socialIcon, styles[platform])}
                iconType={platform}
            />
            <span className="sr-only">{platform}</span>
        </Link>
    );
};

SocialLink.propTypes = {
    href: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
};

export default SocialLink;
