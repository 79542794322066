import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Img from 'components/ui/Img';

import styles from './BlockImage.module.scss';

const BlockImage = ({
    image,
    imageLarge,
    flushTop,
    flushBottom,
    fullWidth,
    embeddedEntry,
    className,
    priority,
}) => {
    return (
        <section
            className={cx(styles.blockImageFlex, className, {
                [styles.embeddedEntry]: embeddedEntry,
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.fullWidth]: fullWidth,
            })}
        >
            <div className={styles.blockImage}>
                {image?.file?.url && imageLarge?.file?.url && (
                    <Img
                        className={styles.image}
                        src={image.file.url}
                        width={image.file?.details?.image?.width}
                        height={image.file?.details?.image?.height}
                        alt={image.description || ''}
                        priority={priority}
                        customSources={[
                            {
                                breakpoint: 1920,
                                src: imageLarge.file.url,
                                imageWidth: 1920 * 0.75,
                            },
                            {
                                breakpoint: 1440,
                                src: imageLarge.file.url,
                                imageWidth: 1440 * 0.75,
                            },
                            {
                                breakpoint: 1024,
                                src: imageLarge.file.url,
                                imageWidth: 1024 * 0.75,
                            },
                            {
                                breakpoint: 768,
                                src: imageLarge.file.url,
                                imageWidth: 768 * 1.5,
                            },
                            {
                                src: image.file.url,
                                imageWidth: 768,
                            },
                        ]}
                    />
                )}
                {image?.file?.url && !imageLarge && (
                    <Img
                        className={styles.image}
                        src={image.file.url}
                        fallbackImageWidth={732}
                        alt={image.description || ''}
                        priority={priority}
                        customSources={[
                            {
                                src: image.file.url,
                                imageWidth: 1280,
                            },
                        ]}
                    />
                )}
            </div>
        </section>
    );
};

BlockImage.propTypes = {
    className: PropTypes.string,
    embeddedEntry: PropTypes.bool,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    fullWidth: PropTypes.bool,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    priority: PropTypes.bool,
};

BlockImage.defaultProps = {
    flushBottom: false,
    flushTop: false,
    fullWidth: true,
    embeddedEntry: false,
    priority: false,
};

export default BlockImage;
