import 'styles/global-css-vars.scss';
import 'styles/normalize.scss';
import 'styles/font-embeds.scss';
import 'styles/theme.scss';

import 'regenerator-runtime/runtime.js';

import * as gtm from 'utils/gtm';
import * as klaviyo from 'utils/klaviyo';

import React, { Fragment, useEffect, useState } from 'react';
import DefaultLayout from 'components/layouts/Default';
import Error from 'next/error';
import Head from 'next/head';
import PageLoader from 'components/globals/PageLoader';
import pkg from 'package.json';
import PropTypes from 'prop-types';
import Script from 'next/script';
import smoothScroll from 'smoothscroll-polyfill';

import { KLAVIYO_ID, GTM_DATA_ID } from 'constants.js';
import { loadKlaviyoScript } from 'utils/klaviyo';
import { PreviousAsPathContext } from 'hooks/usePreviousAsPath';
import { ThemeProvider } from 'hooks/useTheme';
import { LocationProvider } from 'hooks/useLocation';
import SearchProvider from 'components/pageTemplates/Search/SearchContext';

import { useNormalizedContentfulResponse } from 'services/contentful';
import { usePrevious } from 'react-use';
import { useRouter } from 'next/router';

export const ThemeContext = React.createContext('default');

const title = 'Dairy Co-Op - Tillamook';

const metaDescription =
    'Over a 110 years of uncompromising standards, obsessively sourced ingredients, and good old fashioned passion, because every bite deserves our best.';

const OGDescription =
    'Over a 110 years of uncompromising standards, obsessively sourced ingredients, and good old fashioned passion, because every bite deserves our best.';

const OGImage = 'https://www.tillamook.com/images/share.jpg';

const TillamookApp = props => {
    const { pageProps, error, Component } = props;
    const siteConfig =
        useNormalizedContentfulResponse(pageProps.siteConfigRequest)[0] ||
        false;

    const { alerts = [] } = siteConfig;

    const Layout =
        typeof Component.getLayout === 'function'
            ? Component.getLayout(pageProps)
            : DefaultLayout;

    const router = useRouter();
    const previousAsPath = usePrevious(router.asPath);
    const prevTheme = usePrevious(pageProps.theme);
    const [alert, updateAlert] = useState(null);
    const [userLocation, setUserLocation] = useState(null);

    const disallowedQueryParams = [
        'utm_campaign',
        'utm_medium',
        'utm_source',
        '_kx',
        'fbid',
    ];

    const url = new URL(router.asPath, 'https://www.tillamook.com');

    disallowedQueryParams.forEach(param => {
        url.searchParams.delete(param);
    });

    const matchAlerts = () => {
        return alerts?.find(({ routes, regionCategory, regions }) => {
            return routes?.find(route => {
                return (
                    new RegExp(route).test(router?.asPath) &&
                    matchRegion(regionCategory, regions)
                );
            });
        });
    };

    const matchRegion = (regionCategory, regions) => {
        const viewerCountry = userLocation?.viewerCountry || 'Unknown';
        const viewerCountryRegion =
            userLocation?.viewerCountryRegion || 'Unknown';

        // Map against nested regions object
        // Contentful goes crazy if this field is not an object
        const states = regions?.regions || [];

        // Check for required region category before proceeding
        if (!regionCategory) {
            console.warn(`Region category required for alert render.`);
            return false;
        }

        if (regionCategory === 'Global') {
            console.info('Global match', viewerCountry, viewerCountryRegion);
            return true;
        }

        if (regionCategory === 'Outside US' && viewerCountry !== 'US') {
            console.info(`Outside US`, viewerCountry, viewerCountryRegion);
            return true;
        }

        if (
            (regionCategory === 'US' ||
                regionCategory === 'Core' ||
                regionCategory === 'Growth' ||
                regionCategory === 'Frontier') &&
            viewerCountry === 'US'
        ) {
            const match = states.includes(viewerCountryRegion);

            if (match) {
                return match;
            }
        }

        // Nothing matches
        return false;
    };

    const handleRouteChange = url => {
        klaviyo.trackPageView(url);
        gtm.pageView(url);
    };

    const handleAlerts = () => {
        let matchedAlert = matchAlerts() || null;

        if (matchedAlert !== alert) {
            updateAlert(matchedAlert);
        }
    };

    useEffect(() => {
        async function locate() {
            const response = await fetch('https://www.tillamook.com/geo');
            setUserLocation({
                viewerCountry: response.headers.get(
                    'cloudfront-viewer-country'
                ),
                viewerCountryRegion: response.headers.get(
                    'cloudfront-viewer-country-region'
                ),
                viewerCity: response.headers.get('cloudfront-viewer-city'),
                viewerLat: response.headers.get('cloudfront-viewer-latitude'),
                viewerLong: response.headers.get('cloudfront-viewer-longitude'),
            });
        }
        locate();
    }, []);

    useEffect(() => {
        if (userLocation !== null) {
            handleAlerts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocation, router.asPath]);

    useEffect(() => {
        smoothScroll.polyfill();
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router]);

    useEffect(() => {
        console.info(
            `%c  🐮 v${pkg.version}`,
            'font: bold 12px sans-serif; display: block; width: 100%;'
        );
    }, []);

    useEffect(() => {
        const bodyClasses = document.getElementsByTagName('body')[0].classList;
        if (
            prevTheme !== pageProps.theme &&
            !bodyClasses.contains(`theme-${pageProps.theme}`)
        ) {
            bodyClasses.remove(`theme-${prevTheme}`);
            bodyClasses.add(`theme-${pageProps.theme}`);
        }
    }, [pageProps.theme, prevTheme]);

    return (
        <LocationProvider location={userLocation}>
            <ThemeProvider theme={pageProps.theme}>
                <PreviousAsPathContext.Provider value={previousAsPath}>
                    <SearchProvider>
                        <Fragment>
                            <Head>
                                <meta charSet="utf-8" />
                                <meta
                                    name="theme-color"
                                    key="theme-color"
                                    content="#041a48"
                                />
                                <link rel="apple-touch-icon" href="/icon.png" />
                                <title key="title">{title}</title>
                                <meta
                                    key="description"
                                    name="description"
                                    content={metaDescription}
                                />
                                <meta
                                    name="viewport"
                                    content="width=device-width, initial-scale=1, viewport-fit=cover"
                                />
                                <meta
                                    key="og:url"
                                    property="og:url"
                                    content={`https://www.tillamook.com${router.asPath}`}
                                />
                                <meta
                                    key="og:type"
                                    property="og:type"
                                    content="website"
                                />
                                <meta
                                    key="og:title"
                                    property="og:title"
                                    content={title}
                                />
                                <meta
                                    key="og:description"
                                    property="og:description"
                                    content={OGDescription}
                                />
                                <meta
                                    key="og:image"
                                    property="og:image"
                                    content={OGImage}
                                />
                                <meta
                                    key="twitter:card"
                                    name="twitter:card"
                                    content="summary_large_image"
                                />
                                <meta
                                    key="twitter:site"
                                    name="twitter:site"
                                    content="@TillamookDairy"
                                />
                                <meta
                                    key="twitter:title"
                                    name="twitter:title"
                                    content={title}
                                />
                                <meta
                                    key="twitter:description"
                                    name="twitter:description"
                                    content={OGDescription}
                                />
                                <meta
                                    key="twitter:image"
                                    name="twitter:image"
                                    content={OGImage}
                                />
                                <link
                                    key="canonical"
                                    rel="canonical"
                                    href={url.href}
                                />
                            </Head>
                            <Script
                                id="not-supported"
                                dangerouslySetInnerHTML={{
                                    __html: `
                                    var ua = window.navigator.userAgent;
                                    var isIE = /MSIE|Trident/.test(ua);
                                    if (isIE) {
                                        window.location.replace('/not-supported.html');
                                    }
                                `,
                                }}
                            />
                            <Script src="//destinilocators.com/tillamook/pdpwidget/install/" />
                            <PageLoader />
                            <Layout
                                alert={alert}
                                Component={error ? Error : Component}
                                pageProps={error ? error : pageProps}
                            ></Layout>

                            {/* Google Tag Manager */}
                            <Script id="gtag" strategy="afterInteractive">
                                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${GTM_DATA_ID}');`}
                            </Script>
                            {/* End Google Tag Manager */}

                            <Script
                                id="klaviyo-init"
                                strategy="afterInteractive"
                            >
                                {loadKlaviyoScript(KLAVIYO_ID)}
                            </Script>
                            <Script
                                id="klaviyo"
                                dangerouslySetInnerHTML={{
                                    __html: `var _learnq = window._learnq || [];`,
                                }}
                            />
                        </Fragment>
                    </SearchProvider>
                </PreviousAsPathContext.Provider>
            </ThemeProvider>
        </LocationProvider>
    );
};

TillamookApp.propTypes = {
    Component: PropTypes.func.isRequired,
    error: PropTypes.object,
    pageProps: PropTypes.object.isRequired,
};

export function reportWebVitals(metric) {
    metric.label === 'web-vital' && console.log(metric);
}

export default TillamookApp;
