import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import TextEyebrow from 'components/ui/TextEyebrow';
import Icon from 'components/ui/Icon';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';
import Link from 'components/ui/Link';

import styles from './BlockDownloadableResources.module.scss';

const BlockDownloadableResources = ({
    headline,
    resources,
    backgroundColor,
    flushTop,
    flushBottom,
    lastBlock,
}) => {
    if (resources?.length) {
        const backgroundColors = {
            White: 'white',
            Default: 'default',
        };
        return (
            <section
                className={cx(
                    styles.root,

                    styles[
                        `background-${
                            backgroundColors[backgroundColor || 'Default'] ||
                            'default'
                        }`
                    ],
                    {
                        [styles.lastBlock]: lastBlock && !flushBottom,
                        [styles.flushTop]: flushTop,
                        [styles.flushBottom]: flushBottom,
                    }
                )}
            >
                <div className={styles.inner}>
                    {headline && (
                        <div className={styles.headline}>
                            <TextEyebrow headline={headline} theme="display" />
                        </div>
                    )}
                    <div className={styles.resources}>
                        {resources.map((resource, idx) => (
                            <div className={styles.resource} key={idx}>
                                {resource.icon && (
                                    <div className={styles.resourceIconBox}>
                                        <div
                                            className={cx(
                                                styles.resourceIconWrapper
                                            )}
                                        >
                                            <Icon
                                                src={
                                                    resource.icon?.icon?.file
                                                        ?.url
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                <Text
                                    className={styles.resourceTitle}
                                    baseTheme="headingSmall"
                                    as="h4"
                                >
                                    {resource.title}
                                </Text>
                                <ul className={styles.downloadList}>
                                    {resource.downloads.map((download, idx) => (
                                        <li
                                            className={styles.download}
                                            key={idx}
                                        >
                                            <Link
                                                href={
                                                    download.assetDownloadLink
                                                        ?.file?.url
                                                }
                                                className={styles.innerDownload}
                                            >
                                                <Text
                                                    baseTheme="labelMedium"
                                                    themes={{
                                                        large: 'labelLarge',
                                                    }}
                                                    className={
                                                        styles.downloadTitle
                                                    }
                                                >
                                                    {download.text}
                                                </Text>
                                                <div
                                                    className={
                                                        styles.downloadIconWrapper
                                                    }
                                                >
                                                    <SvgIcon iconType="download" />
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }

    return null;
};

BlockDownloadableResources.propTypes = {
    headline: PropTypes.string,
    resources: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            downloads: PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string.isRequired,
                    assetDownloadLink: PropTypes.object.isRequired,
                })
            ).isRequired,
        }).isRequired
    ),
    backgroundColor: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    lastBlock: PropTypes.bool,
};

BlockDownloadableResources.defaultProps = {
    headline: '',
    resources: [],
};

export default BlockDownloadableResources;
