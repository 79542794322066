import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';
import RichText from 'components/ui/RichText';

import Img from 'components/ui/Img';
import Divot from 'components/globals/Divot';

import { useTheme } from 'hooks/useTheme';

import Banner from './layouts/Banner';

import styles from './BlockCallout.module.scss';

const layoutsMap = {
    Square: 'square',
    Banner: Banner,
    'Banner (Simple)': 'bannerSimple',
    Card: 'card',
    Masthead: 'masthead',
    Recommended: 'recommended',
};

const colorThemeMap = {
    'Blue Background': { background: 'blueMedium', color: 'cream' },
    'Cream Background': { background: 'creamDark', color: 'blue' },
};

const BlockCallout = props => {
    const {
        eyebrow,
        richHeadline,
        copy,
        image,
        imageLarge,
        theme,
        colorTheme,
        className,
        icon,
        hasNotch,
        buttonComponentSlot,
    } = props;

    useEffect(() => {
        if (!richHeadline) {
            dispatchWarning(
                'No headline provided. Headline required for render.'
            );
        }
    }, [richHeadline]);

    const [globalTheme] = useTheme();

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    const hasImage = image?.file?.url && imageLarge?.file?.url;
    const isBannerSimple = layoutsMap[theme] === 'bannerSimple';
    const isMasthead = layoutsMap[theme] === 'masthead';

    // gradually breakout these callouts into variant components
    if (typeof layoutsMap[theme] !== 'string') {
        const Variant = layoutsMap[theme];
        return <Variant {...props} globalTheme={globalTheme} />;
    }

    const headlineOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="h2"
                        baseTheme="bodyMedium"
                        themes={{
                            large: 'bodyLarge',
                        }}
                        className={styles.simpleHeadline}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    return (
        <article
            className={cx(
                styles.calloutWrapperFlex,
                styles.calloutWrapper,
                styles[layoutsMap[theme]],
                styles[globalTheme],
                className,
                {
                    [styles.hasNotch]: hasNotch,
                    [styles.withCopy]: copy,
                    [styles.withImage]: hasImage,
                    [styles.banner]: layoutsMap[theme] === 'bannerSimple',
                }
            )}
        >
            <div
                className={styles.calloutWrapper}
                style={{
                    backgroundColor: `var(--colors-${colorThemeMap[colorTheme].background})`,
                }}
            >
                {hasNotch && colorTheme === 'Blue Background' && (
                    <Divot theme="blueMedium" />
                )}
                {hasNotch && colorTheme === 'Cream Background' && (
                    <Divot theme="creamDark" />
                )}
                {hasImage && theme === 'Square' && (
                    <div className={styles.imageContainer}>
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            alt={image.description || ''}
                            width={image.file.details?.image?.width}
                            height={image.file.details?.image?.height}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: imageLarge.file.url,
                                    imageWidth: 1920 * 0.75,
                                },
                                {
                                    breakpoint: 1440,
                                    src: imageLarge.file.url,
                                    imageWidth: 1440 * 0.75,
                                },
                                {
                                    breakpoint: 1024,
                                    src: imageLarge.file.url,
                                    imageWidth: 1024 * 0.75,
                                },
                                {
                                    breakpoint: 768,
                                    src: imageLarge.file.url,
                                    imageWidth: 768 * 1.5,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    </div>
                )}
                <div
                    className={styles.callout}
                    style={{
                        color: `var(--colors-${colorThemeMap[colorTheme].color})`,
                    }}
                >
                    <div className={styles.inner}>
                        <div className={styles.section}>
                            {eyebrow && isBannerSimple && (
                                <Text
                                    as="h3"
                                    baseTheme="labelSmall"
                                    themes={{
                                        large: 'labelLarge',
                                    }}
                                    className={styles.simpleEyebrow}
                                >
                                    {eyebrow}
                                </Text>
                            )}
                            {richHeadline && (
                                <>
                                    {isBannerSimple && (
                                        <RichText
                                            richText={richHeadline}
                                            overrides={headlineOverrides}
                                        />
                                    )}
                                    {isMasthead && (
                                        <TextEyebrow
                                            className={styles.eyebrow}
                                            eyebrow={eyebrow ? eyebrow : ''}
                                            richHeadline={richHeadline}
                                            icon={icon}
                                            theme="masthead"
                                            headlineAs="h1"
                                        />
                                    )}
                                    {!isBannerSimple && !isMasthead && (
                                        <TextEyebrow
                                            className={styles.eyebrow}
                                            eyebrow={eyebrow ? eyebrow : ''}
                                            richHeadline={richHeadline}
                                            icon={
                                                buttonComponentSlot
                                                    ? null
                                                    : icon
                                            }
                                            theme={
                                                theme === 'Square'
                                                    ? 'displaySmall'
                                                    : 'normal'
                                            }
                                        />
                                    )}
                                </>
                            )}
                            {buttonComponentSlot &&
                                layoutsMap[theme] === 'banner' && (
                                    <div className={styles.buttonComponentSlot}>
                                        {buttonComponentSlot}
                                    </div>
                                )}
                        </div>
                        {copy && (
                            <div className={styles.section}>
                                <Text
                                    className={styles.copy}
                                    baseTheme="bodySmall"
                                    themes={{ large: 'bodyMedium' }}
                                >
                                    {copy}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </article>
    );
};

BlockCallout.propTypes = {
    className: PropTypes.string,
    eyebrow: PropTypes.string,
    richHeadline: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    icon: PropTypes.string,
    copy: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    cta: PropTypes.object,
    hasNotch: PropTypes.bool,
    theme: PropTypes.string,
    colorTheme: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    buttonComponentSlot: PropTypes.node,
};

BlockCallout.defaultProps = {
    hasNotch: false,
    theme: 'Card',
    colorTheme: 'Blue Background',
    headline: '',
};

export default BlockCallout;
