import React, {
    useEffect,
    useState,
    useCallback,
    useMemo,
    useRef,
} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import SvgIcon from 'components/ui/SvgIcon';

import { AnimatePresence } from 'framer-motion';
import { fetchProductsLinked } from 'services/contentful';
import { cleanupContentfulData } from 'utils/contentful-resolve-response';

import {
    useSearchBox,
    useHits,
    Configure,
} from 'react-instantsearch-hooks-web';
import { scrollToOffset } from 'utils';

import Search from './Search';
import Breadcrumbs from './Breadcrumbs';
import Card from './Card';

import styles from './ProductsNav.module.scss';

const steps = {
    Cheese: {
        filter: 'cut',
    },
    Butter: {
        filter: false,
    },
    'Ice Cream': {
        filter: 'type',
    },
    Yogurt: {
        filter: 'style',
    },
    'Farmstyle Cream Cheese Spreads': {
        filter: false,
    },
    'Sour Cream': {
        filter: false,
    },
};

const ProductsNav = ({
    productCategories,
    onUpdate,
    additionalCategory,
    tabIndex,
}) => {
    const rootRef = useRef(null);

    // All products
    const [products, setProducts] = useState(null);

    const [showSearch, setShowSearch] = useState(false);
    const [finderState, setFinderState] = useState({});
    const [activeProduct, setActiveProduct] = useState(null);
    const [productSize, setProductSize] = useState(null);

    /*  Handle the "drill-down" state to render the product list (search bypasses this)
    finderState = {
        category: [string | null],
        filter: [string | null],
    }
    */
    const productStep = (() => {
        if (!finderState.category) {
            return 0;
        }
        if (finderState?.category && !finderState?.filter) {
            return 1;
        }
        if (finderState?.category && finderState?.filter) {
            return 2;
        }
        return 0;
    })();

    const { query, refine, clear, isSearchStalled } = useSearchBox({});

    const categoryConfig =
        steps?.[finderState?.category?.title] ||
        steps?.[activeProduct?.category?.fields?.title];

    const renderView = () => {
        if (activeProduct) {
            return renderSizes3();
        }
        if (query.length > 0 && !activeProduct) {
            return renderSearch();
        }
        if (
            !activeProduct &&
            ((categoryConfig?.filter && finderState?.filter) ||
                (!categoryConfig?.filter && finderState?.category))
        ) {
            return renderProducts();
        }
        if (categoryConfig?.filter) {
            return renderCategoryFilters();
        }
        return renderCategories();
    };

    const productsIndexMap = useMemo(
        () =>
            products?.reduce((a, o, i) => {
                a[o.id] = i;
                return a;
            }, {}) || {},
        [products]
    );

    const transformItems = useCallback(
        items => {
            return items.map(item => {
                const index = productsIndexMap[item.objectID];
                return (
                    { objectID: item.objectID, ...products?.[index] } || item
                );
            });
        },
        [products, productsIndexMap]
    );

    const { hits } = useHits({ transformItems });

    const filterByCategory = (list, filter) => {
        return list.filter(item => item?.category?.fields?.title === filter);
    };

    useEffect(() => {
        const fetchProductsList = async () => {
            const products = await fetchProductsLinked({
                showDiscontinued: true,
            });
            setProducts(products?.items.map(cleanupContentfulData));
        };

        fetchProductsList().catch(console.error);
    }, []);

    useEffect(() => {
        if (activeProduct && query.length > 0) {
            setActiveProduct(null);
        }
    }, [query]);

    const handleResetView = e => {
        e.preventDefault();
        scrollToOffset(rootRef?.current, -200, 'smooth');
        clear();
    };

    const handleCategoryClick = (e, item) => {
        handleResetView(e);
        setFinderState({ category: item });
    };

    const handleTypeClick = (e, item) => {
        handleResetView(e);
        setFinderState({ ...finderState, filter: item });
    };

    const handleProductClick = (e, item) => {
        handleResetView(e);
        setActiveProduct(item);
    };

    const filterByType = (list, filter, attr) => {
        return list.filter(item =>
            [...(item?.attributes?.[attr] || [])]
                ?.map(item => parseInt(item, 10))
                .includes(parseInt(filter, 10))
        );
    };

    const setOnlySize = product => {
        clear();
        const parseSize = product?.sizes3?.map(cleanupContentfulData)?.[0];

        setProductSize({
            product,
            size: parseSize,
            sku: parseSize?.sku,
            packagingCodeExample: parseSize?.packagingCodeExample,
        });
    };

    const packagingCodeExample =
        productSize?.packagingCodeExample &&
        cleanupContentfulData(productSize?.packagingCodeExample);

    useEffect(() => {
        if (!productSize) {
            return;
        }

        const handleUpdateForm = packagingCodeExample
            ? {
                  size: productSize?.size,
                  sku: productSize?.sku,
                  product: productSize?.product,
                  packagingCodeExample: packagingCodeExample,
              }
            : {
                  size: productSize?.size,
                  product: productSize?.product,
                  sku: productSize?.sku,
              };

        productSize !== null && onUpdate(handleUpdateForm);
    }, [productSize]);

    const filterProducts = products => {
        const categoryProducts = filterByCategory(
            Object.values(products || []),
            finderState?.category?.title
        );

        if (productStep === 1 && products) {
            return categoryProducts;
        }
        if (productStep === 2 && products) {
            return filterByType(
                categoryProducts,
                finderState?.filter?.id,
                categoryConfig.filter
            );
        }

        return [];
    };

    const renderStepLabel = () => {
        if (activeProduct) {
            return 'Back';
        }
        if (query.length > 0) {
            return 'Clear';
        }
        if (productStep === 1) {
            return 'Products';
        }
        if (productStep === 2) {
            return finderState?.category?.title;
        }
        if (productStep === 3) {
            return finderState?.filter?.title;
        }
    };

    const renderCategories = () => (
        <>
            {productCategories.map((item, i) => {
                return (
                    <Card
                        key={`cat-${i}`}
                        image={item?.thumbnail?.file?.url}
                        onClick={e => handleCategoryClick(e, item)}
                        tabIndex={tabIndex}
                        index={i}
                    >
                        {item.title}
                    </Card>
                );
            })}
            {additionalCategory && additionalCategory()}
        </>
    );

    const renderCategoryFilters = () => {
        const categoryFilters =
            finderState?.category?.attributes?.[categoryConfig.filter]?.options;

        return categoryFilters?.map((item, i) => {
            const hideCategories = item.id !== 5 && item.id !== 8; //hide cheeseboards and cracker cuts

            return (
                hideCategories && (
                    <Card
                        key={`filter-${i}`}
                        image={item?.thumb}
                        onClick={e => handleTypeClick(e, item)}
                        tabIndex={tabIndex}
                        index={i}
                    >
                        {item.label}
                    </Card>
                )
            );
        });
    };

    const renderProducts = () => {
        const productsList = filterProducts(products);

        return productsList.map((item, i) => {
            item = cleanupContentfulData(item);
            const image = cleanupContentfulData(item.image);
            return (
                <Card
                    key={`product-${i}`}
                    image={image.file?.url}
                    index={i}
                    onClick={
                        Object.keys(item?.sizes2 || [])?.length > 1
                            ? e => handleProductClick(e, item)
                            : () => setOnlySize(item)
                    }
                    tabIndex={tabIndex}
                >
                    {item.displayName || item.title}
                </Card>
            );
        });
    };

    const renderSearch = () => {
        const filteredResults = hits?.filter(
            item => item?.contentTypeId === 'product'
        );

        if (isSearchStalled) {
            return (
                <div className={styles.isSearchStalled}>
                    <span>Searching...</span>
                </div>
            );
        }

        if (filteredResults.length === 0) {
            return (
                <div className={styles.noResults}>
                    <span>No Results</span>
                </div>
            );
        }

        const search = filteredResults?.filter(
            item => item?.contentTypeId === 'product'
        );

        return search?.map((item, i) => {
            const image = cleanupContentfulData(item.image);
            return (
                <Card
                    key={`product-${item.slug}`}
                    image={image.file?.url}
                    index={i}
                    onClick={
                        item?.sizes3?.length > 1
                            ? e => handleProductClick(e, item)
                            : () => setOnlySize(item)
                    }
                    tabIndex={tabIndex}
                >
                    {item.displayName || item.title}
                </Card>
            );
        });
    };

    const renderSizes3 = () => {
        return activeProduct?.sizes3?.map((item, i) => {
            item = cleanupContentfulData(item);

            const image = cleanupContentfulData(activeProduct?.image);

            return (
                <Card
                    key={`sizes-${i}`}
                    image={image?.file?.url}
                    index={i}
                    onClick={() =>
                        setProductSize({
                            product: activeProduct,
                            size: item,
                            sku: item.sku,
                            packagingCodeExample: item.packagingCodeExample,
                        })
                    }
                    tabIndex={tabIndex}
                >
                    {activeProduct?.pageTitle}
                    {item.size}
                </Card>
            );
        });
    };

    return (
        <div className={styles.root} ref={rootRef}>
            <div
                className={cx(styles.grid, {
                    [styles.showSearch]: showSearch,
                })}
            >
                <Breadcrumbs
                    step={productStep}
                    label={renderStepLabel()}
                    onClick={() => {
                        clear();
                        if (productStep == 1) {
                            setFinderState({
                                ...finderState,
                                category: null,
                            });
                        }
                        if (productStep == 2 && !activeProduct) {
                            setFinderState({
                                ...finderState,
                                filter: null,
                            });
                        }
                        if (activeProduct) {
                            setActiveProduct(null);
                        }
                    }}
                    isHidden={productStep === 0 && query.length === 0}
                    tabIndex={tabIndex}
                    className={styles.breadcrumbs}
                />
                <Configure
                    hitsPerPage={80}
                    filters={'(sys.contentType.sys.id:product)'}
                />
                <Search
                    query={query}
                    refine={refine}
                    clear={clear}
                    className={styles.search}
                    isActive={!activeProduct && showSearch}
                    setIsActive={setShowSearch}
                    tabIndex={tabIndex}
                />
                <div
                    className={cx(styles.searchButton)}
                    onClick={() => setShowSearch(true)}
                >
                    <SvgIcon iconType="search" />
                </div>
            </div>
            <div
                role="radiogroup"
                aria-label="Products"
                className={cx(styles.products, styles.grid)}
            >
                <AnimatePresence exitBeforeEnter>
                    {renderView()}
                </AnimatePresence>
            </div>
        </div>
    );
};

ProductsNav.propTypes = {
    additionalCategory: PropTypes.func,
    onUpdate: PropTypes.func,
    productCategories: PropTypes.array,
    tabIndex: PropTypes.number,
};

export default ProductsNav;
