export const profiles = [
    {
        id: 'SERIOUSLY_STRAWBERRY',
        priority: 4,
        label: `Seriously \nStrawberry`,
        color: '#FFCECC',
        payoff: {
            copy: `So happy we matched! I’m a sweet, glass half-full kind of spread. The longer you know me, the longer you’ll love me.`,
            image: '/images/cream-cheese-campaign/seriously-strawberry.png',
        },
        detail: {
            image: `/images/cream-cheese-campaign/illo-strawberry.png`,
            distance: `0.9 mi. away`,
            occupation: `Stylist, Cosmetologist`,
            height: `0'4"`,
            bio: `I’m an unexpected delight and fully aware of my contagious upbeat energy. People say I’m as sweet as a strawberry–and I use that ability to spread those vibes along to others because I believe in paying it forward whenever possible.`,
            interests: [
                'Volunteering',
                'Cooking',
                'Spreading Joy',
                'Walking Dogs',
            ],
            ctaLink: `https://www.tillamook.com/tillamaps/buy?category=cream_cheese&variety=strawberry_cream_cheese`,
        },
    },
    {
        id: 'AGED_CHEDDAR',
        priority: 2,
        label: 'Aged Cheddar',
        color: '#FFE0A6',
        payoff: {
            copy: `Out with the old, in with the bold! I’m excited for you to see what I’m all about–hope you like trying new things.`,
            image: '/images/cream-cheese-campaign/aged-cheddar.png',
        },
        detail: {
            image: `/images/cream-cheese-campaign/illo-cheddar.png`,
            distance: `0.1 mi. away`,
            occupation: `Commentator, Radio Personality`,
            height: `0'4"`,
            bio: `I come in strong but leave you wanting more with my unexpected boldness. My biggest achievement? I’m the first of my kind and unafraid to show you who I am and what I’m about.`,
            interests: [
                'Learning New Languages',
                'Debate Association',
                'Wine & Spirits',
                'Karaoke',
            ],
            ctaLink: `https://www.tillamook.com/tillamaps/buy?category=cream_cheese&variety=aged_cheddar_cream_cheese`,
        },
    },
    {
        id: 'VERRY_VEGGIE',
        priority: 3,
        label: 'Verry Veggie',
        color: '#B8EBB2',
        payoff: {
            copy: `I'm the perfect balance of work and play, so my life motto is, "you gotta eat well to be well".`,
            image: '/images/cream-cheese-campaign/very-veggie.png',
            bgImage: '/images/cream-cheese-campaign/bg-veggie.jpg',
        },
        detail: {
            image: `/images/cream-cheese-campaign/illo-veggie.png`,
            distance: `6.5 mi. away`,
            occupation: `Botanist, Nature Lover`,
            height: `0'4"`,
            bio: `You’ll find me gardening or chilling outdoors. Yoga session in the mornings, followed by a well-rounded breakfast and as much sun as possible. I eat well to be well, but sometimes you have to splurge and treat yourself. Life’s only as rich as the experiences you create.`,
            interests: [
                'Health / Exercise',
                'Rock Climbing',
                'Festivals',
                'Home-cooked Meals',
            ],
            ctaLink: `https://www.tillamook.com/tillamaps/buy?category=cream_cheese&variety=veggie_cream_cheese`,
        },
    },
    {
        id: 'CHIVE_ONION',
        priority: 5,
        label: 'Chive & Onion',
        color: '#BFE1D0',
        payoff: {
            copy:
                'Let’s meet up! I’m new around here, but. If you love me, you LOVE me–and I have a feeling you won’t go back to that old stuff. ',
            image: '/images/cream-cheese-campaign/chive-and-onion.png',
        },
        detail: {
            image: `/images/cream-cheese-campaign/illo-chives.png`,
            distance: `4 mi. away`,
            occupation: `Blogger, Influencer`,
            height: `0'4"`,
            bio: `I’m new in town and open to meeting up. People tell me I’m free-spirited. I don’t like drama, I am who I am. I may not be for everyone, but if you love me, you LOVE me. Sometimes you just have to drop everything and go. YOLO!`,
            interests: ['Modeling', 'Entrepreneurship', 'Art', 'Finding Love'],
            ctaLink: `https://www.tillamook.com/tillamaps/buy?category=cream_cheese`,
        },
    },
    {
        id: 'TILLAMOOK_ORIGINAL',
        priority: 1,
        label: 'Original',
        color: '#A1E1FF',
        payoff: {
            copy:
                'We’re just two OGs falling in love. This is what a truly delicious cream cheese relationship looks like.',
            image: '/images/cream-cheese-campaign/tillamook-original.png',
        },
        detail: {
            image: `/images/cream-cheese-campaign/illo-original.png`,
            distance: `4 mi. away`,
            occupation: `History Buff, Librarian`,
            height: `0'4"`,
            bio: `Some would call me traditional, or old-fashioned, and that’s fine. I prefer to call myself a classic. Sure, I don’t rock the boat, but I believe if you’re going to do something, do it right. There’s nothing like an honest day’s work, and staying true to those who love you. Get along to go along. Let’s get together!`,
            interests: [
                'First Edition Books',
                'Traveling',
                'Museum Hopping',
                'Model Ship Building',
            ],
            ctaLink: `https://www.tillamook.com/tillamaps/buy?category=cream_cheese&variety=original_cream_cheese`,
        },
    },
];
