import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CardFanStoryShare from 'components/cards/CardFanStoryShare';

import styles from './BlockFanStoryShare.module.scss';

const BlockFanStoryShare = ({ cards, flushTop, flushBottom }) => {
    useEffect(() => {
        if (!cards?.length) {
            dispatchWarning(
                'No cards provided. Two cards required for render.'
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (cards?.length) {
        return (
            <div
                className={cx(styles.root, {
                    [styles.flushTop]: flushTop,
                    [styles.flushBottom]: flushBottom,
                })}
            >
                <ul className={styles.cards}>
                    {cards.map((card, idx) => (
                        <li className={styles.cardWrapper} key={idx}>
                            <div className={styles.innerCard}>
                                <CardFanStoryShare {...card} />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    return null;
};

BlockFanStoryShare.propTypes = {
    cards: PropTypes.array.isRequired,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

BlockFanStoryShare.defaultProps = {
    flushTop: false,
    flushBottom: false,
};

export default BlockFanStoryShare;
