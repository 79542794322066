import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import TextEyebrow from 'components/ui/TextEyebrow';

import TimelineItem from './TimelineItem';

import styles from './BlockTimeline.module.scss';

const BlockTimeline = ({ headline, eyebrow, items, flushTop, className }) => {
    return (
        <section
            className={cx(styles.blockTimeline, className, {
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.eyebrow}>
                {headline && (
                    <TextEyebrow
                        eyebrow={eyebrow ? eyebrow : ''}
                        headline={headline}
                        theme="display"
                    />
                )}
            </div>
            <div className={styles.items}>
                <div className={styles.timelineLine} role="presentation">
                    <div className={styles.timelineLineInner} />
                </div>
                {items?.map((item, i) => {
                    return <TimelineItem key={i} {...item} />;
                })}
            </div>
        </section>
    );
};

BlockTimeline.propTypes = {
    items: PropTypes.array,
    headline: PropTypes.string,
    eyebrow: PropTypes.string,
    flushTop: PropTypes.bool,
    className: PropTypes.string,
};

BlockTimeline.defaultProps = {
    flushTop: true,
};

export default BlockTimeline;
