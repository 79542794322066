import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CareersGridRow from './CareersGridRow';
import TextEyebrow from 'components/ui/TextEyebrow';
import styles from './BlockCareersGrid.module.scss';

const BlockCareersGrid = ({
    grid,
    eyebrow,
    eyebrowHeadline,
    theme,
    flushTop,
}) => {
    return (
        <div
            className={cx(styles.careersGrid, {
                [styles.miniGrid]: theme === 'mini',
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.innerWrapper}>
                <div className={styles.eyebrow}>
                    {eyebrow && eyebrowHeadline && (
                        <TextEyebrow
                            eyebrow={eyebrow}
                            headline={eyebrowHeadline}
                            theme="display"
                        />
                    )}
                </div>
                <div className={styles.gridContent}>
                    {grid &&
                        grid.map((gridRow, id) => {
                            return (
                                <CareersGridRow
                                    gridRow={gridRow}
                                    theme={theme}
                                    key={id}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

BlockCareersGrid.propTypes = {
    grid: PropTypes.array,
    eyebrow: PropTypes.string,
    theme: PropTypes.string,
    eyebrowHeadline: PropTypes.string,
    flushTop: PropTypes.bool,
};

BlockCareersGrid.defaultProps = {
    theme: 'default',
    flushTop: false,
};

export default BlockCareersGrid;
