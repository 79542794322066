import PropTypes from 'prop-types';
import React from 'react';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Img from 'components/ui/Img';

import styles from './FallbackError.module.scss';

const FallbackError = ({ title, message }) => {
    return (
        <div className={styles.container}>
            <Text
                className={styles.headline}
                as="h1"
                baseTheme="displaySmall"
                themes={{ medium: 'displayLarge' }}
            >
                {title}
            </Text>
            <Text
                className={styles.copy}
                as="p"
                baseTheme="bodyMedium"
                themes={{ medium: 'bodyLarge' }}
            >
                {message}
            </Text>
            <Button theme="filledBlue" href="/">
                Home
            </Button>
            <figure className={styles.imageWrapper}>
                <Img
                    src="https://images.ctfassets.net/j8tkpy1gjhi5/8n6vklgPpDZxQGHnrU21x/3a65023ab6bdf4a351a7da522a1e888c/aging_story_-_CHEESE_KO_2_1.png"
                    fallbackImageWidth={1820}
                    alt="chopped cheddar"
                />
            </figure>
        </div>
    );
};

FallbackError.propTypes = {
    message: PropTypes.string,
    title: PropTypes.string,
};

FallbackError.defaultProps = {
    title: 'Cheese Excuse Us',
    message:
        'We’re experiencing an internal server problem. Please try again later.',
};

export default FallbackError;
