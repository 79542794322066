import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Stagger from 'components/ui/Stagger';
import Icon from 'components/ui/Icon';

import styles from './TextLockup.module.scss';

const TextLockup = ({
    eyebrow,
    headline,
    richHeadline,
    bodyCopy,
    cta,
    ctaButton,
    className,
    theme,
    hasArrowIcon,
    icon,
}) => {
    return (
        <Stagger
            className={cx(styles.textLockup, className, {
                [styles.hasRichHeadline]: richHeadline,
            })}
        >
            {icon?.icon?.file?.url && (
                <Stagger.Child order={0}>
                    <Icon src={icon?.icon?.file?.url} contained />
                </Stagger.Child>
            )}
            {eyebrow && (
                <Stagger.Child order={0} className={styles.eyebrow}>
                    <Text
                        baseTheme="labelSmall"
                        themes={{
                            xLarge: 'labelLarge',
                        }}
                    >
                        {eyebrow}
                    </Text>
                </Stagger.Child>
            )}
            {headline && !richHeadline && (
                <Stagger.Child order={1} className={styles.headline}>
                    <Text
                        baseTheme="displayXSmall"
                        themes={{
                            large: 'displayMedium',
                            xxLarge: 'displayLarge',
                        }}
                    >
                        {headline}
                    </Text>
                </Stagger.Child>
            )}
            {richHeadline && (
                <Stagger.Child order={1} className={styles.headline}>
                    <Text
                        baseTheme="displayXSmall"
                        themes={{
                            large: 'displayMedium',
                            xLarge: 'displayLarge',
                        }}
                    >
                        {richHeadline.content.map((line, i) => {
                            if (line.content[0].value === '') {
                                return null;
                            }
                            return (
                                <span className={styles.line} key={i}>
                                    {line.content[0].value}
                                </span>
                            );
                        })}
                    </Text>
                </Stagger.Child>
            )}
            {bodyCopy && (
                <Stagger.Child order={2} className={styles.copy}>
                    <Text
                        baseTheme="bodySmall"
                        themes={{
                            xLarge: 'bodyMedium',
                        }}
                    >
                        {bodyCopy}
                    </Text>
                </Stagger.Child>
            )}
            {hasArrowIcon && (
                <Stagger.ArrowRight
                    order={3}
                    className={styles.arrowIconWrapper}
                />
            )}
            {cta?.text && cta?.link && !ctaButton && (
                <Stagger.Child
                    order={hasArrowIcon ? 4 : 3}
                    className={styles.buttonWrapper}
                >
                    <Button
                        className={styles.cta}
                        theme={
                            theme !== 'Default'
                                ? 'outlinedCream'
                                : 'outlinedBlue'
                        }
                        entry={cta.link}
                    >
                        {cta.text}
                    </Button>
                </Stagger.Child>
            )}
            {(ctaButton?.cta?.link || ctaButton?.cta?.assetDownloadLink) && (
                <Stagger.Child
                    order={hasArrowIcon ? 4 : 3}
                    className={styles.buttonWrapper}
                >
                    <Button
                        className={styles.cta}
                        theme={ctaButton.theme}
                        entry={ctaButton.cta.link}
                        iconLeading={ctaButton.iconType}
                    >
                        {ctaButton.cta.text}
                    </Button>
                </Stagger.Child>
            )}
        </Stagger>
    );
};

TextLockup.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    richHeadline: PropTypes.object,
    bodyCopy: PropTypes.string,
    cta: PropTypes.object,
    ctaButton: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.oneOf([
        'Default',
        'Holiday Blue',
        'Special Holiday Blue',
        'Special Holiday Rust',
        'Special Holiday Squash',
    ]),
    hasArrowIcon: PropTypes.bool,
    icon: PropTypes.object,
};

TextLockup.defaultProps = {
    className: null,
    theme: 'Default',
    hasArrowIcon: true,
};

export default TextLockup;
