import React, { memo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Img from 'components/ui/Img';
import { useIntersection } from 'react-use';

import { BLOCKS } from '@contentful/rich-text-types';

import Text from 'components/ui/Text';
import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';

import Icon from 'components/ui/Icon';

import CTA from 'components/ui/CTA';
import CardTestimonial from 'components/cards/CardTestimonial';

import styles from './GridRow.module.scss';
import CountUp from 'react-countup';

const GridRow = ({
    className,
    headline,
    richHeadline,
    number,
    unit,
    bodyCopy,
    richText,
    leftImage,
    iconType,
    icon,
    blueBackground,
    image,
    imageLarge,
    theme,
    cta,
    testimonial,
}) => {
    const ref = useRef(null);
    const [isInView, setIsInView] = useState(false);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    useEffect(() => {
        setIsInView(!!intersection?.isIntersecting);
    }, [intersection]);

    const animateCount = number => {
        return (
            <CountUp
                end={number}
                duration={5}
                startOnMount={false}
                start={isInView ? 0 : null}
            >
                {({ countUpRef }) => (
                    <div className={styles.countUpHeadline}>
                        <span ref={countUpRef} />
                        <span>{unit}</span>
                    </div>
                )}
            </CountUp>
        );
    };
    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        className={styles.paragraph}
                        as="p"
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyMedium' }}
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return (
                    <Text
                        className={styles.richTextH5}
                        as="p"
                        baseTheme="bodyMedium"
                        themes={{ large: 'bodyLarge' }}
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.HEADING_5]: (node, children) => {
                return (
                    <Text
                        className={styles.richTextEyebrow}
                        as="p"
                        baseTheme="labelSmall"
                        themes={{ large: 'labelLarge' }}
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.HEADING_6]: (node, children) => {
                return (
                    <Text
                        className={styles.listHeadline}
                        as="h6"
                        baseTheme="bodyMedium"
                        themes={{ large: 'bodyLarge' }}
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.LIST_ITEM]: (node, children) => {
                return <li className={styles.listItem}>{children}</li>;
            },
        },
    };

    const renderCTA = () => {
        return (
            <CTA
                className={styles.cta}
                theme={
                    theme === 'Blue Background' || blueBackground
                        ? 'outlinedCream'
                        : 'outlinedBlue'
                }
                cta={cta}
            >
                {cta?.text}
            </CTA>
        );
    };

    return (
        <div
            ref={ref}
            className={cx(styles.gridRow, className, {
                [styles.gridRowReversed]: leftImage,
                [styles.themeBlueBackground]: theme === 'Blue Background',
                [styles.themeCreamBackground]: theme === 'Cream Background',
                [styles.themeGridRowBlue]: theme === 'Cream Background',
            })}
        >
            <div className={styles.column}>
                {image?.file?.url &&
                    image?.file?.details?.image &&
                    imageLarge?.file?.url &&
                    imageLarge?.file?.details?.image && (
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    breakpoint: 1440,
                                    src: imageLarge.file.url,
                                    imageWidth: 1024,
                                },
                                {
                                    breakpoint: 1024,
                                    src: imageLarge.file.url,
                                    imageWidth: 768,
                                },
                                {
                                    breakpoint: 768,
                                    src: imageLarge.file.url,
                                    imageWidth: 580,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
            </div>
            <div
                className={cx(styles.column, {
                    [styles.blueBackground]: blueBackground,
                })}
            >
                {testimonial ? (
                    <>
                        <CardTestimonial
                            {...testimonial}
                            backgroundColor={
                                blueBackground || theme === 'Blue Background'
                                    ? 'blueSolidIcon'
                                    : 'white'
                            }
                        />
                        {cta && (
                            <div className={styles.testimonialCta}>
                                {renderCTA()}
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className={styles.lockup}>
                            {headline && (
                                <Text
                                    className={cx(styles.headline, {
                                        [styles.capitalize]:
                                            iconType === 'arrowRight',
                                        [styles.headlineSecond]:
                                            iconType !== 'arrowRight',
                                    })}
                                    baseTheme={
                                        iconType === 'arrowRight'
                                            ? number
                                                ? 'displayMedium'
                                                : 'displaySmall'
                                            : 'headingMedium'
                                    }
                                    themes={
                                        iconType === 'arrowRight'
                                            ? {
                                                  large: 'displayLarge1',
                                              }
                                            : {
                                                  large: 'headingLarge',
                                              }
                                    }
                                >
                                    {number && animateCount(number)}
                                    {headline &&
                                        !richHeadline &&
                                        !number &&
                                        headline}
                                    {richHeadline?.content.map((line, i) => {
                                        if (line.content[0].value === '') {
                                            return null;
                                        }
                                        return (
                                            <span
                                                className={styles.line}
                                                key={i}
                                            >
                                                {line.content[0].value}
                                            </span>
                                        );
                                    })}
                                </Text>
                            )}
                            {icon && (
                                <div className={cx(styles.iconWrapper, 'icon')}>
                                    <Icon
                                        className={styles.icon}
                                        src={
                                            icon.icon?.file?.url
                                                ? `https:${icon.icon?.file?.url}`
                                                : ''
                                        }
                                    />
                                </div>
                            )}
                            {!icon && iconType && (
                                <div
                                    className={cx(
                                        styles.iconWrapper,
                                        styles[iconType],
                                        {
                                            [styles.creamIcon]:
                                                blueBackground ||
                                                theme === 'Blue Background',
                                            [styles.iconFirst]:
                                                iconType !== 'arrowRight',
                                        }
                                    )}
                                >
                                    <SvgIcon
                                        className={styles.icon}
                                        iconType={iconType}
                                    />
                                </div>
                            )}
                            <section className={styles.copy}>
                                {bodyCopy && !richText && (
                                    <Text
                                        baseTheme={
                                            number ? 'bodyMedium' : 'bodySmall'
                                        }
                                        themes={
                                            number
                                                ? { large: 'bodyLarge' }
                                                : { large: 'bodyMedium' }
                                        }
                                    >
                                        {bodyCopy}
                                    </Text>
                                )}
                                {richText && !bodyCopy && (
                                    <RichText
                                        richText={richText}
                                        overrides={richTextOverrides}
                                    />
                                )}
                            </section>
                        </div>
                        {cta && (
                            <div className={styles.buttonWrapper}>
                                {renderCTA()}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

GridRow.propTypes = {
    blueBackground: PropTypes.bool,
    bodyCopy: PropTypes.string,
    richText: PropTypes.object,
    className: PropTypes.string,
    cta: PropTypes.shape({
        assetDownloadLink: PropTypes.object,
        link: PropTypes.object,
        text: PropTypes.string,
    }),
    headline: PropTypes.string,
    richHeadline: PropTypes.object,
    iconType: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    icon: PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.shape({
            file: PropTypes.shape({
                details: PropTypes.shape({
                    image: PropTypes.shape({
                        height: PropTypes.number,
                        width: PropTypes.number,
                    }),
                }),
                url: PropTypes.string,
            }),
        }),
    }),
    leftImage: PropTypes.bool,
    number: PropTypes.number,
    unit: PropTypes.oneOf(['%', '+']),
    theme: PropTypes.oneOf(['Default', 'Blue Background', 'Cream Background']),
    testimonial: PropTypes.object,
};

GridRow.defaultProps = {
    blueBackground: false,
    leftImage: false,
    theme: 'Default',
    cta: null,
};

export default memo(GridRow);
