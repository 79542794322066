import React from 'react';
import PropTypes from 'prop-types';

import SharpnessStory from 'components/overlays/SharpnessStory';

const BlockSharpnessStoryProxy = ({ sharpnessStory }) => {
    return <SharpnessStory {...sharpnessStory} isPage />;
};

BlockSharpnessStoryProxy.propTypes = {
    sharpnessStory: PropTypes.object,
};

export default BlockSharpnessStoryProxy;
