import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import Bar from './Bar';
import { useIntersection } from 'react-use';

import styles from './Graph.module.scss';

const Graph = ({ headline, bodyCopy }) => {
    const blockRef = useRef(null);
    const chartBars = [
        { year: 2017, percentage: 2.0 },
        { year: 2018, percentage: 2.7 },
        { year: 2019, percentage: 4.0 },
    ];
    const [inView, setInview] = useState(false);

    const intersection = useIntersection(blockRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    useEffect(() => {
        setInview(!!intersection?.isIntersecting);
    }, [intersection]);

    return (
        <div className={cx(styles.chartRow)} ref={blockRef}>
            <div className={styles.innerChart}>
                <div className={styles.lockup}>
                    <div className={cx(styles.iconWrapper)}>
                        <SvgIcon
                            className={styles.icon}
                            iconType={'volunteer'}
                        />
                    </div>
                    {headline && (
                        <div className={styles.headlineWrapper}>
                            <Text
                                className={cx(
                                    styles.headline,
                                    styles.standardsHeadline
                                )}
                                baseTheme={'headingLarge'}
                                themes={{
                                    large: 'headingLarge',
                                }}
                            >
                                {headline}
                            </Text>
                        </div>
                    )}

                    {bodyCopy && (
                        <div className={styles.copyWrapper}>
                            <Text
                                className={styles.bodyCopy}
                                baseTheme={'bodySmall'}
                                themes={{ large: 'bodyMedium' }}
                            >
                                {bodyCopy}
                            </Text>
                        </div>
                    )}
                </div>

                <div className={styles.chartInner}>
                    {chartBars.map((chartItem, i) => {
                        return (
                            <div className={styles.barColumn} key={i}>
                                <div className={styles.yearLabelWrapper}>
                                    <Text
                                        className={styles.yearLabel}
                                        baseTheme={'labelMedium'}
                                        themes={{ large: 'labelLarge' }}
                                    >
                                        {chartItem.year}
                                    </Text>
                                </div>
                                <Bar
                                    i={i}
                                    inView={inView}
                                    chartItem={chartItem}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

Graph.propTypes = {
    bodyCopy: PropTypes.string,
    headline: PropTypes.string,
    theme: PropTypes.string,
};

Graph.propTypes = {
    theme: PropTypes.oneOf(['Graph1', 'Graph2']),
};

export default Graph;
