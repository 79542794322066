import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import breakpoints from './ContentfulImg.module.scss';

// This component requires Contentful's Image API
// Inspiration: https://www.contentful.com/blog/2019/10/31/webp-source-sets-images-api/

function getDefaultCustomSources(ogSrc) {
    const breakpointSources = Object.values(breakpoints).map(breakpoint => {
        return {
            breakpoint: parseFloat(breakpoint),
            src: ogSrc,
            imageWidth: parseFloat(breakpoint) * 1.5,
        };
    });

    return [...breakpointSources, { src: ogSrc, imageWidth: 768 }];
}

// detects webp in accept headers and redirects to webp
function negotiatedFallback(ogSrc) {
    return ogSrc.replace(`//images.ctfassets.net`, `/api/images/cdn`);
}

const ContentfulImg = ({
    alt,
    className,
    customSources,
    decoding,
    draggable,
    fallbackImageWidth,
    loading,
    priority,
    src: ogSrc,
    ...rest
}) => {
    const hasNoFallbackCustomSource =
        customSources?.length > 0 &&
        customSources.every(source => source.breakpoint);

    hasNoFallbackCustomSource &&
        console.warn(
            'ContentfulImg.js: For optimization purposes, it is *highly recommended* that you include a fallback custom source with no breakpoint.'
        );

    // Round with to nearest integer to keep Contentful's Image API happy
    const fallbackWidth = Math.round(fallbackImageWidth);
    const isGif = /.gif$/i.test(ogSrc);
    const fallbackQuality = isGif ? 100 : 90;
    const quality = isGif ? 100 : 80;
    const sources =
        customSources?.length > 0
            ? customSources
            : getDefaultCustomSources(ogSrc);

    return (
        <picture>
            {sources.map(
                (
                    { breakpoint, orientation, imageWidth, src: breakpointSrc },
                    i
                ) => {
                    // Max image values at 2560px and round width to nearest integer to keep Contentful's Image API happy
                    const w = Math.round(Math.min(imageWidth, 2560));

                    return (
                        <Fragment key={i}>
                            <source
                                media={
                                    orientation
                                        ? `(orientation: ${orientation})`
                                        : breakpoint &&
                                          `(min-width: ${breakpoint}px)`
                                }
                                srcSet={`${
                                    breakpointSrc ?? ogSrc
                                }?w=${w}&fm=webp&q=${quality}`}
                                type="image/webp"
                            />
                            <source
                                media={
                                    orientation
                                        ? `(orientation: ${orientation})`
                                        : breakpoint &&
                                          `(min-width: ${breakpoint}px)`
                                }
                                srcSet={`${
                                    breakpointSrc ?? ogSrc
                                }?w=${w}&q=${quality}`}
                            />
                        </Fragment>
                    );
                }
            )}
            <img
                alt={alt}
                src={negotiatedFallback(
                    `${ogSrc}?w=${fallbackWidth}&q=${fallbackQuality}`
                )}
                className={className}
                draggable={draggable}
                loading={priority ? 'eager' : loading}
                decoding={priority ? 'sync' : decoding}
                fetchpriority={priority ? 'high' : 'low'}
                {...rest}
            />
        </picture>
    );
};

ContentfulImg.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    customSources: PropTypes.array,
    decoding: PropTypes.oneOf(['async', 'sync', 'auto']),
    draggable: PropTypes.bool,
    fallbackImageWidth: PropTypes.number,
    loading: PropTypes.oneOf(['eager', 'lazy']),
    priority: PropTypes.bool,
    src: PropTypes.string.isRequired,
};

ContentfulImg.defaultProps = {
    alt: '',
    className: null,
    customSources: [],
    draggable: false,
    fallbackImageWidth: 1280,
    loading: 'lazy',
    priority: false,
};

export default memo(ContentfulImg);
