import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import { cleanupContentfulData } from 'utils/contentful-resolve-response';

import styles from './ProductCode.module.scss';

const ProductCode = ({ setActiveStep, formState, onUpdate, tabIndex }) => {
    const [inputValue, updateInput] = useState(initialInputValue);
    const ref = useRef();

    const initialInputValue = '';

    const handleInput = evt => {
        updateInput(evt.target.value);
    };

    const handleSubmit = submit => {
        setActiveStep(3);

        onUpdate({ productCode: submit ? inputValue : '' });
    };
    useEffect(() => {
        updateInput(initialInputValue);
    }, [initialInputValue]);

    const codeIllustration =
        formState?.packagingCodeExample &&
        cleanupContentfulData(
            formState?.packagingCodeExample?.illustration || {}
        );

    const codeExamples = formState?.packagingCodeExample?.codeExamples;

    return (
        <div ref={ref} className={styles.root}>
            <div className={styles.inner}>
                <div className={styles.header}>
                    <div className={styles.headerText}>
                        <Text
                            baseTheme="displayXSmall"
                            themes={{ medium: 'displaySmall' }}
                            as="h1"
                        >
                            Find your product code
                        </Text>
                        <Text
                            baseTheme="bodyMedium"
                            themes={{ medium: 'bodyLarge' }}
                            as="h2"
                        >
                            Please enter the product code located here on the
                            packaging:
                        </Text>
                    </div>
                    {codeIllustration && (
                        <Icon src={codeIllustration?.file?.url} />
                    )}
                </div>
                <div className={styles.examplesContainer}>
                    <div className={styles.examplesHeadline}>
                        <SvgIcon iconType="caution" />

                        <Text
                            baseTheme="bodyMedium"
                            themes={{ medium: 'bodyLarge' }}
                            as="p"
                        >
                            <b> NOTE: </b> The product code includes the stamped
                            best-by date and the alphanumeric characters below
                            it. Please enter both in the field below.
                        </Text>
                    </div>

                    <div className={styles.examplesList}>
                        <Text
                            baseTheme="labelMedium"
                            themes={{ medium: 'labelLarge' }}
                            as="p"
                        >
                            product code examples:
                        </Text>
                        {codeExamples?.map((item, i) => (
                            <div key={i} className={styles.examplesItem}>
                                {item.map((line, i) => (
                                    <Text key={i}>{line}</Text>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.submitContainer}>
                    <Text
                        baseTheme="labelMedium"
                        themes={{ medium: 'labelLarge' }}
                        as="p"
                    >
                        enter product code
                    </Text>
                    <Text
                        as="label"
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyMedium' }}
                    >
                        <textarea
                            placeholder={'xxx-xxx-xxxx'}
                            rows="5"
                            cols="30"
                            ref={ref}
                            name="search"
                            type="text"
                            onChange={handleInput}
                            value={inputValue}
                            autoComplete="off"
                            tabIndex={tabIndex}
                        />
                    </Text>
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        onClick={() => handleSubmit(true)}
                        theme="filledBlueHoverCream"
                        tabIndex={tabIndex}
                    >
                        submit code
                    </Button>
                    <Button
                        onClick={() => handleSubmit(false)}
                        theme="outlinedBlue"
                        tabIndex={tabIndex}
                    >
                        skip this step
                    </Button>
                </div>
            </div>
        </div>
    );
};

ProductCode.propTypes = {
    formState: PropTypes.shape({
        packagingCodeExample: PropTypes.shape({
            codeExamples: PropTypes.array,
            illustration: PropTypes.object,
        }),
    }),
    onUpdate: PropTypes.func,
    setActiveStep: PropTypes.func,
    tabIndex: PropTypes.number,
};

export default ProductCode;
