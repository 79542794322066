import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import SvgIcon from 'components/ui/SvgIcon';
import Banner from './Banner';

import useEmailSignUp from './useEmailSignUp';
import { useClickAway } from 'react-use';

import { event } from 'utils/gtm';

import styles from './Card.module.scss';

const AlertBanner = forwardRef((props, ref) => {
    return <Banner innerRef={ref} {...props} />;
});

const Card = ({
    icon,
    title,
    copy,
    successIcon,
    successTitle,
    successCopy,
    isVisible: isVisibleParent,
    onClose,

    source,
}) => {
    const {
        onSubmit,
        isSubmitting,
        isSuccess,
        register,
        watch,
        isSubscribed,
        errors,
    } = useEmailSignUp(source);

    const selectValues = watch(['state']); // you can also target specific fields by their names

    const watchedValues = {
        state: 0,
    };

    const bannerRef = useRef(null);

    const [isVisible, setVisible] = useState(isVisibleParent);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            event('new_subscriber', { formLocation: 'card', source: source });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const handleClose = () => {
        setVisible(false);
    };

    const handleClick = () => {
        setIsExpanded(true);
    };

    useClickAway(bannerRef, () => {
        if (isExpanded) {
            handleClose();
        }
    });

    return (
        <div
            onAnimationEnd={e => {
                if (/^Card_close/i.test(e.animationName)) {
                    onClose();
                }
            }}
            className={cx(styles.root, {
                [styles.isClosing]: !isVisible && !isExpanded,
                [styles.isClosingExpanded]: !isVisible && isExpanded,
                [styles.isExpanded]: isExpanded,
                [styles.isSuccess]: isSuccess,
            })}
        >
            <div className={styles.card}>
                <div className={styles.cardInner}>
                    {isSuccess ? successIcon : icon}
                    <div className={styles.headlineWrapper}>
                        <Text
                            baseTheme={'displayXSmall'}
                            themes={{
                                medium: 'displaySmall',
                            }}
                            fixWidows
                        >
                            {isSuccess ? successTitle : title}
                        </Text>
                    </div>
                    <div className={styles.bodyCopyWrapper}>
                        <Text
                            baseTheme={'bodyXSmall'}
                            themes={{ medium: 'bodySmall' }}
                            fixWidows
                        >
                            {isSuccess ? successCopy : copy}
                        </Text>
                    </div>
                    {!isSuccess && (
                        <Button
                            theme="filledBlueHoverWhite-ultrabold"
                            onClick={handleClick}
                            className={styles.expandButton}
                            id="expand"
                        >
                            Sign Up
                        </Button>
                    )}
                </div>

                <Button
                    theme=""
                    onClick={handleClose}
                    className={styles.closeButton}
                >
                    <div className={styles.closeIconBuffer}>
                        <div className={styles.closeIconWrapper}>
                            <SvgIcon iconType="close" />
                        </div>
                    </div>
                </Button>
            </div>
            <AlertBanner
                ref={bannerRef}
                icon={icon}
                title={title}
                copy={
                    <>
                        Join our email newsletter and we’ll send you a buy one
                        get one free coupon good for any 8oz Tillamook Cheese.
                        (We’ll send the coupon via snail mail so you don’t have
                        to print it!) We’ll also send you our best recipes,
                        offers and more.
                    </>
                }
                copy2={<>Limit one per household. While supplies last.</>}
                isExpanded={isExpanded}
                isSuccess={isSuccess}
                onSubmit={onSubmit}
                onClose={handleClose}
                isClosing={!isVisible}
                isSubmitting={isSubmitting}
                errors={errors}
                isSubscribed={isSubscribed}
                register={register}
                selectValues={selectValues}
                watchedValues={watchedValues}
            />
        </div>
    );
};

Card.propTypes = {
    copy: PropTypes.any,
    icon: PropTypes.any,
    isSuccess: PropTypes.any,
    isVisible: PropTypes.any,
    onClose: PropTypes.any,
    source: PropTypes.any,
    successCopy: PropTypes.any,
    successIcon: PropTypes.any,
    successTitle: PropTypes.any,
    title: PropTypes.any,
};

export default Card;
