import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CardProductCategory from 'components/cards/CardProductCategory';
import Link from 'components/ui/Link';
import { getColor } from 'utils';

import styles from './BlockCardTrioInline.module.scss';

const BlockCardTrioInline = ({ cards, flushTop, className }) => {
    useEffect(() => {
        if (!cards) {
            dispatchWarning(
                'No cards provided. Three cards required for render.'
            );
        } else {
            if (cards.length !== 3) {
                if (cards.length < 3) {
                    dispatchWarning('Three cards required for render.');
                } else {
                    dispatchWarning(
                        `${cards.length} cards provided. Only three will be displayed at render.`
                    );
                }
            }
        }
    }, [cards]);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };
    const renderCard = card => {
        return (
            <div className={styles.productCard}>
                <Link.Entry entry={card.cta?.link}>
                    <CardProductCategory
                        backgroundColor={getColor(
                            card.productCategoryCard?.productBackgroundColor
                        )}
                        image={card.productCategoryCard?.productImage}
                        title={card.cta?.text}
                        theme="card"
                    />
                </Link.Entry>
            </div>
        );
    };

    return (
        <section
            className={cx(styles.blockCardTrioInline, className, {
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.inner}>
                <ul className={styles.cards}>
                    {cards?.map((card, i) => {
                        if (i < cards.length - 1) {
                            return (
                                <Fragment key={i}>
                                    <li className={styles.listItem}>
                                        {renderCard(card)}
                                    </li>
                                    <li
                                        className={styles.listItemGap}
                                        role="presentation"
                                    />
                                </Fragment>
                            );
                        }
                        return (
                            <li key={i} className={styles.listItem}>
                                {renderCard(card)}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
};

BlockCardTrioInline.propTypes = {
    cards: PropTypes.array,
    className: PropTypes.string,
    flushTop: PropTypes.bool,
};

BlockCardTrioInline.defaultProps = {
    flushTop: false,
};

export default BlockCardTrioInline;
