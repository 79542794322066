import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Link from 'components/ui/Link';
import ButtonCircle from 'components/ui/ButtonCircle';
import TextEyebrow from 'components/ui/TextEyebrow';

import styles from './BlockLinkList.module.scss';

const BlockLinkList = ({ eyebrow, headline, links, flushTop, className }) => {
    return (
        <section
            className={cx(styles.blockLinkList, className, {
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.column}>
                    {headline && (
                        <TextEyebrow
                            className={styles.eyebrow}
                            eyebrow={eyebrow ? eyebrow : ''}
                            headline={headline}
                            theme="displaySmall"
                        />
                    )}
                </div>
                <div className={styles.column}>
                    {links && (
                        <ul className={styles.links}>
                            {links.map((link, i) => {
                                return (
                                    <li className={styles.listItem} key={i}>
                                        <Link.Entry
                                            className={styles.link}
                                            entry={link?.link}
                                        >
                                            <Text
                                                baseTheme="bodySmall"
                                                themes={{
                                                    large: 'bodyMedium',
                                                }}
                                                className={styles.linkText}
                                            >
                                                {link?.text}
                                            </Text>
                                            <ButtonCircle theme="chevronRightBlue" />
                                        </Link.Entry>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </section>
    );
};

BlockLinkList.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    links: PropTypes.array,
    className: PropTypes.string,
    flushTop: PropTypes.bool,
};
BlockLinkList.defaultProps = {
    flushTop: false,
};

export default BlockLinkList;
