import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Grid.module.scss';

const Grid = ({ children, tag, className }) => {
    const Tag = tag;
    return (
        <Tag className={cx(styles.gridContainer, className)}>{children}</Tag>
    );
};

Grid.propTypes = {
    children: PropTypes.node.isRequired,
    tag: PropTypes.string,
    className: PropTypes.string,
};

Grid.defaultProps = {
    tag: 'div',
    className: '',
};

export default Grid;
