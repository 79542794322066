import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Img from 'components/ui/Img';

import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Button from 'components/ui/Button';

import styles from './BlockShopPromotional.module.scss';

const iconMap = {
    'New Indicator': 'newSun',
    Featured: 'featuredShopProduct',
};

const priceIconMap = {
    Sale: 'sale',
};

function formatPrice(num) {
    return num.toFixed(2);
}

const BlockShopPromotional = ({
    headline,
    price,
    ctaButton,
    image,
    icon,
    priceIcon,
}) => {
    if (headline && ctaButton?.cta && image?.file?.url) {
        return (
            <div className={styles.root}>
                <ul className={styles.cards}>
                    <li className={styles.cardWrapper}>
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            fallbackImageWidth={375}
                            alt={image.description || ''}
                            customSources={[
                                { breakpoint: 1024, imageWidth: 624 },
                            ]}
                        />
                    </li>
                    <li className={styles.cardWrapper}>
                        <div className={styles.innerCard}>
                            <div className={styles.content}>
                                {icon && iconMap[icon] && (
                                    <div
                                        className={cx(
                                            styles.iconWrapper,
                                            styles[iconMap[icon]]
                                        )}
                                    >
                                        <SvgIcon iconType={iconMap[icon]} />
                                    </div>
                                )}
                                <Text
                                    className={styles.headline}
                                    baseTheme="displayXSmall"
                                    themes={{ xxLarge: 'displayMedium' }}
                                >
                                    {headline}
                                </Text>
                                {price && (
                                    <div className={styles.priceWrapper}>
                                        {priceIcon && (
                                            <div
                                                className={
                                                    styles.priceIconWrapper
                                                }
                                            >
                                                <SvgIcon
                                                    iconType={
                                                        priceIconMap[priceIcon]
                                                    }
                                                />
                                            </div>
                                        )}
                                        <Text
                                            baseTheme="bodyMedium"
                                            themes={{ large: 'bodyLarge' }}
                                        >
                                            {`$${formatPrice(price)}`}
                                        </Text>
                                    </div>
                                )}
                                {ctaButton?.cta?.link && (
                                    <Button
                                        className={styles.ctaButton}
                                        theme={ctaButton.theme}
                                        entry={ctaButton.cta.link}
                                    >
                                        {ctaButton.cta.text}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }

    return null;
};

BlockShopPromotional.propTypes = {
    headline: PropTypes.string.isRequired,
    price: PropTypes.number,
    ctaButton: PropTypes.shape({
        cta: PropTypes.shape({
            assetDownloadLink: PropTypes.object,
            link: PropTypes.shape({
                contentTypeId: PropTypes.string,
            }),
            text: PropTypes.string,
        }),
        iconType: PropTypes.string,
        theme: PropTypes.string,
    }),
    image: PropTypes.object.isRequired,
    icon: PropTypes.string,
    priceIcon: PropTypes.string,
};

BlockShopPromotional.defaultProps = {
    price: 0,
    icon: null,
    priceIcon: null,
};

export default BlockShopPromotional;
