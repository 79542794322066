import React from 'react';
import PropTypes from 'prop-types';

const FlavorFinderIcon = ({ type }) => {
    return (
        <>
            {type === 'heart' && (
                <>
                    <svg viewBox="0 0 58 47" fill="none">
                        <path
                            d="M29 47C15.8629 40.1513 -6.72052 24.6558 1.91825 8.2339C8.90912 -5.05538 25.1892 0.45375 29 5.98252C32.8108 0.45375 49.0909 -5.05538 56.0817 8.2339C64.7205 24.6558 42.1371 40.1513 29 47Z"
                            fill="currentColor"
                        />
                    </svg>
                    <span className="sr-only">Yes</span>
                </>
            )}
            {type === 'x' && (
                <>
                    <svg viewBox="0 0 39 39" fill="none">
                        <path
                            d="M25.5022 19.8867L36.9263 31.3037C38.4894 32.8658 38.4894 35.3993 36.9263 36.9614L36.1822 37.7051C34.6201 39.2662 32.0882 39.2662 30.5262 37.7051L19.1015 26.2875L7.68697 37.7025C6.12487 39.2646 3.59216 39.2646 2.03007 37.7025L1.28619 36.9586C-0.275852 35.3965 -0.275852 32.8639 1.28619 31.3018L12.6988 19.8888L1.27389 8.47091C-0.289211 6.90878 -0.289211 4.37531 1.27388 2.81318L2.01801 2.06951C3.58008 0.508409 6.11192 0.508401 7.67399 2.0695L19.0995 13.4879L30.5287 2.05833C32.0908 0.496186 34.6235 0.496178 36.1856 2.05833L36.9295 2.80223C38.4915 4.36432 38.4915 6.89694 36.9295 8.45904L25.5022 19.8867Z"
                            fill="currentColor"
                        />
                    </svg>
                    <span className="sr-only">No</span>
                </>
            )}
        </>
    );
};

FlavorFinderIcon.propTypes = {
    type: PropTypes.oneOf(['heart', 'x']),
};

FlavorFinderIcon.defaultProps = {
    type: 'x',
};

export default FlavorFinderIcon;
