import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import Stagger from 'components/ui/Stagger';
import Icon from 'components/ui/Icon';
import SvgIcon from 'components/ui/SvgIcon';
import Video from 'components/ui/Video';
import VideoPlayerYT from 'components/ui/VideoPlayerYT';

import Img from 'components/ui/Img';

import styles from './BlockSmallMediaWithText.module.scss';

const richTextBodyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text as="p" baseTheme="bodyMedium">
                    {children}
                </Text>
            );
        },
    },
};

const richTextHeadlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h3"
                    baseTheme="displaySmall"
                    themes={{ xLarge: 'displayMedium' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockSmallMediaWithText = ({
    eyebrow,
    headline,
    body,
    cta,
    image,
    mediaPosition,
    videoTextureMp4,
    videoTextureWebm,
    videoPoster,
    icon,
    displayArrowIcon,
    youtubeVideoId,
    textAlignment,
}) => {
    const hasImage = image?.file?.url;
    const hasVideo = youtubeVideoId;
    const hasTexturalVideo =
        videoTextureMp4?.file?.url && videoTextureWebm?.file?.url && !hasVideo;

    return (
        <div
            className={cx(styles.root, {
                [styles.imageRight]: mediaPosition.toLowerCase() === 'right',
                [styles.textAlignLeft]: textAlignment?.toLowerCase() === 'left',
            })}
        >
            <div className={cx(styles.media, {})}>
                {hasImage && (
                    <Img className={styles.image} src={image?.file?.url} />
                )}
                {hasVideo && (
                    <div className={styles.videoPlayerAspect}>
                        <div className={styles.videoPlayer}>
                            <VideoPlayerYT
                                centerPlayButton
                                heroVideo={true}
                                youtubeVideoId={youtubeVideoId}
                                videoTexturePoster={videoPoster || null}
                                videoTextureMp4={videoTextureMp4 || null}
                                videoTextureWebm={videoTextureWebm || null}
                                threshold={0.25}
                            />
                        </div>
                    </div>
                )}
                {hasTexturalVideo && (
                    <Video
                        poster={videoPoster?.file?.url}
                        sources={[
                            videoTextureMp4.file.url,
                            videoTextureWebm.file.url,
                        ]}
                    />
                )}
            </div>
            <Stagger className={styles.card}>
                {icon && (
                    <Stagger.Child className={styles.icon} order={0}>
                        <Icon src={icon.icon.file.url} />
                    </Stagger.Child>
                )}
                {eyebrow && (
                    <Stagger.Child className={styles.eyebrow} order={0}>
                        <Text as="p" baseTheme="labelLarge">
                            {eyebrow}
                        </Text>
                    </Stagger.Child>
                )}
                {headline && (
                    <Stagger.Child className={styles.headline} order={0}>
                        <RichText
                            overrides={richTextHeadlineOverrides}
                            richText={headline}
                        />
                    </Stagger.Child>
                )}
                {displayArrowIcon && (
                    <div className={styles.arrowIcon}>
                        <SvgIcon iconType="arrowRight" />
                    </div>
                )}
                {body && (
                    <Stagger.Child className={styles.body} order={1}>
                        <RichText
                            overrides={richTextBodyOverrides}
                            richText={body}
                        />
                    </Stagger.Child>
                )}
                {cta?.text && cta?.link && (
                    <Stagger.Child className={styles.cta} order={1}>
                        <span className={styles.buttonWrapper}>
                            <Button
                                theme="filledBlueHoverWhite-ultrabold"
                                entry={cta.link}
                                className={styles.ctaLink}
                            >
                                {cta.text}
                            </Button>
                        </span>
                    </Stagger.Child>
                )}
            </Stagger>
        </div>
    );
};

BlockSmallMediaWithText.propTypes = {
    body: PropTypes.object,
    cta: PropTypes.object,
    eyebrow: PropTypes.string,
    headline: PropTypes.object,
    image: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    youtubeVideoId: PropTypes.string,
    videoTextureMp4: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    videoTextureWebm: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    videoPoster: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    icon: PropTypes.object,
    mediaPosition: PropTypes.oneOf(['Left', 'Right']),
    textAlignment: PropTypes.oneOf(['Left', 'Center']),
    displayArrowIcon: PropTypes.bool,
};

BlockSmallMediaWithText.defaultProps = {
    mediaPosition: 'left',
    textAlignment: 'left',
    videoTextureMp4: null,
    videoTextureWebm: null,
    videoPoster: null,
    youtubeVideoId: null,
    body: null,
    cta: null,
    eyebrow: '',
    image: null,
    headline: null,
    icon: null,
    displayArrowIcon: false,
};

export default BlockSmallMediaWithText;
