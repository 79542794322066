import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import cx from 'classnames';
import copy from 'clipboard-copy';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './FlavorFinderShare.module.scss';

const shareText = `You have a cream cheese choice! Meet Tillamook’s most eligible flavor profiles and take the Flavor Match Quiz to see which spread loves you back.`;

const FlavorFinderShare = ({ show }) => {
    const copylinkRef = useRef(null);
    const [linkCopied, setLinkCopied] = useState(false);

    const url = 'https://www.tillamook.com/products/cream-cheese/break-up#quiz';

    const handleCopyLink = () => {
        copy(url);
        setLinkCopied(true);
    };

    const handleOutsideClick = event => {
        if (
            copylinkRef?.current &&
            !copylinkRef?.current.contains(event.target)
        ) {
            setLinkCopied(false);
        }
    };

    const toggleEventListener = useCallback(method => {
        document[`${method}EventListener`]('click', handleOutsideClick);
    }, []);

    useEffect(() => {
        toggleEventListener('add');

        return () => {
            toggleEventListener('remove');
        };
    }, [toggleEventListener]);

    return (
        <div
            className={cx(styles.root, {
                [styles.show]: show,
                [styles.linkCopied]: linkCopied,
            })}
        >
            <Text
                className={styles.label}
                baseTheme="labelSmall"
                themes={{ large: 'labelLarge' }}
            >
                SHARE:
            </Text>
            <div className={styles.icon}>
                <FacebookShareButton url={url} quote={shareText}>
                    <SvgIcon iconType="facebook" />
                    <span className="sr-only">share on facebook</span>
                </FacebookShareButton>
            </div>
            <div className={styles.icon}>
                <TwitterShareButton
                    url={url}
                    title={shareText}
                    via="TillamookDairy"
                >
                    <SvgIcon iconType="twitter" />
                    <span className="sr-only">share on twitter</span>
                </TwitterShareButton>
            </div>
            <div
                ref={copylinkRef}
                className={cx(styles.icon, styles.copylink)}
                onClick={handleCopyLink}
            >
                <div className={styles.tooltip}>
                    <svg width="130" height="51" viewBox="0 0 130 51">
                        <rect
                            x="53"
                            y="39"
                            width="16.9707"
                            height="16.9707"
                            transform="rotate(-45 53 39)"
                            fill="currentColor"
                        />
                        <rect
                            width="130"
                            height="42"
                            rx="4"
                            fill="currentColor"
                        />
                    </svg>
                    <Text
                        baseTheme="headingXSmall"
                        className={styles.tooltipText}
                    >
                        Link copied!
                    </Text>
                </div>
                <SvgIcon iconType="link" />
                <span className="sr-only">copy link</span>
            </div>
        </div>
    );
};

FlavorFinderShare.propTypes = {
    show: PropTypes.bool,
    shareImage: PropTypes.string,
};

export default FlavorFinderShare;
