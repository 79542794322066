import React, { useState, useEffect } from 'react';
import { createTeleporter } from 'react-teleporter';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import Link from 'components/ui/Link';
import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Logo from 'components/globals/Logo';
import SearchBar from 'components/globals/SearchBar';

import useScrollDirection from 'hooks/useScrollDirection';

import styles from './Header.module.scss';

export const AlertBanner = createTeleporter();
export const HeaderBottom = createTeleporter();

const Header = ({ menuRoutes, menuRoutesSecondary }) => {
    const navRef = React.createRef();
    const [menuVisible, setMenuVisibility] = useState(false);

    const scrollDirection = useScrollDirection();

    const router = useRouter();

    useEffect(() => {
        setMenuVisibility(false);
    }, [router.query]);

    useEffect(() => {
        menuVisible
            ? disableBodyScroll(navRef.current)
            : enableBodyScroll(navRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuVisible]);

    return (
        <div
            className={cx(styles.headerContainer, {
                [styles.isHidden]: scrollDirection === 'down',
                [styles.isLanding]: router.pathname === '/',
            })}
        >
            <AlertBanner.Target />
            <header className={styles.header}>
                <Link
                    href="https://www.tillamook.com/tillamaps"
                    className={styles.tillamapsCTA}
                    target="_self"
                >
                    <SvgIcon
                        className={styles.tillamapsIcon}
                        iconType="location"
                    />
                    <span className="sr-only">Tillamaps</span>
                </Link>
                <Link className={styles.logoContainer} href="/">
                    <span className="sr-only">Home Page</span>
                    <Logo />
                </Link>
                <nav
                    ref={navRef}
                    className={cx(styles.nav, {
                        [styles['nav--visible']]: menuVisible,
                    })}
                >
                    <section className={styles.inner}>
                        <div className={styles.logoWrapper}>
                            <div className={styles.logoWrapperInner}>
                                <Link href="/" className={styles.logo}>
                                    <Logo withMorningStar />
                                </Link>
                            </div>
                        </div>
                        <ul className={styles.links}>
                            {menuRoutes?.map(route => {
                                if (route.contentTypeId === 'cta') {
                                    return (
                                        <li
                                            key={route.id}
                                            className={cx(styles.link)}
                                        >
                                            <Link.Entry entry={route.link}>
                                                <Text
                                                    baseTheme="headingSmall"
                                                    themes={{
                                                        medium: 'headingMedium',
                                                        large: 'headingLarge',
                                                        xLarge: 'bodyMedium',
                                                    }}
                                                >
                                                    {route.text}
                                                </Text>
                                            </Link.Entry>
                                        </li>
                                    );
                                }
                                const current =
                                    router.asPath === `/${route.slug}`;
                                return (
                                    <li
                                        className={cx(styles.link, {
                                            [styles.linkCurrent]: current,
                                        })}
                                        key={route.id}
                                    >
                                        <Link href={`/${route.slug}`}>
                                            <Text
                                                baseTheme="menuSmall"
                                                themes={{
                                                    large: 'menuLarge',
                                                }}
                                            >
                                                {route.title}
                                            </Text>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>

                        <ul className={styles.linksSecondary}>
                            {menuRoutesSecondary?.map(route => {
                                if (route.contentTypeId === 'cta') {
                                    return (
                                        <li
                                            key={route.id}
                                            className={cx(styles.link)}
                                        >
                                            <Link.Entry entry={route.link}>
                                                <Text
                                                    baseTheme="headingSmall"
                                                    themes={{
                                                        medium: 'headingMedium',
                                                        large: 'headingLarge',
                                                        xLarge: 'bodyMedium',
                                                    }}
                                                >
                                                    {route.text}
                                                </Text>
                                            </Link.Entry>
                                        </li>
                                    );
                                }
                                const current =
                                    router.asPath === `/${route.slug}`;
                                return (
                                    <li
                                        className={cx(styles.link, {
                                            [styles.linkCurrent]: current,
                                        })}
                                        key={route.id}
                                    >
                                        <Link href={`/${route.slug}`}>
                                            <Text
                                                baseTheme="headingSmall"
                                                themes={{
                                                    medium: 'headingMedium',
                                                    large: 'headingLarge',
                                                    xLarge: 'bodyMedium',
                                                }}
                                            >
                                                {route.title}
                                            </Text>
                                        </Link>
                                    </li>
                                );
                            })}
                            <li
                                className={cx(styles.link, styles.cart, {
                                    [styles.linkCurrent]:
                                        router.asPath === `/shop`,
                                })}
                            >
                                <Link
                                    href="https://shop.tillamook.com"
                                    target="_self"
                                >
                                    <Text
                                        baseTheme="headingSmall"
                                        themes={{
                                            medium: 'headingMedium',
                                            large: 'headingLarge',
                                            xLarge: 'bodyMedium',
                                        }}
                                    >
                                        Online Shop
                                    </Text>
                                </Link>
                            </li>
                            <SearchBar
                                className={styles.link}
                                isHidden={scrollDirection === 'down'}
                                isLanding={router.asPath === '/'}
                                searchPlaceholder="Type to search"
                            />
                        </ul>
                    </section>
                </nav>
                <button
                    aria-label="Open the menu"
                    onClick={() => setMenuVisibility(!menuVisible)}
                    className={cx(styles.hamburgerIcon, {
                        [styles.open]: menuVisible,
                    })}
                >
                    <span role="presentation"></span>
                    <span role="presentation"></span>
                    <span role="presentation"></span>
                    <span role="presentation"></span>
                </button>
            </header>
            <HeaderBottom.Target />
        </div>
    );
};

Header.propTypes = {
    menuRoutes: PropTypes.array,
    menuRoutesSecondary: PropTypes.array,
};

Header.defaultProps = {
    menuRoutes: [],
    menuRoutesSecondary: [],
};

export default Header;
