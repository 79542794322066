import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import TextEyebrow from 'components/ui/TextEyebrow';
import CTA from 'components/ui/CTA';
import RichText from 'components/ui/RichText';
import Stagger from 'components/ui/Stagger';

import Img from 'components/ui/Img';

import styles from './CardColumnContent.module.scss';

const CardColumnContent = ({
    eyebrow,
    headline,
    hasArrowIcon,
    richBodyCopy,
    cta,
    cta2,
    image,
    imageLarge,
    backgroundTheme,
}) => {
    return (
        <Stagger
            className={cx(styles.columnContent, {
                [styles.creamBackground]: backgroundTheme === 'cream',
            })}
        >
            <div className={styles.columnContentInner}>
                {headline && (
                    <Stagger.Child order={0} className={styles.eyebrowWrapper}>
                        <TextEyebrow
                            eyebrow={eyebrow ? eyebrow : ''}
                            headline={headline}
                            icon={hasArrowIcon ? 'arrowRight' : 'none'}
                        />
                    </Stagger.Child>
                )}
                {richBodyCopy && (
                    <Stagger.Child order={1} className={styles.textWrapper}>
                        <RichText richText={richBodyCopy} />
                    </Stagger.Child>
                )}
                {cta && (
                    <Stagger.Child
                        order={2}
                        className={cx(styles.buttonWrapper, {
                            [styles.ctaDuo]: cta?.text && cta2?.text,
                        })}
                    >
                        {cta?.text && (
                            <CTA
                                className={styles.cta}
                                theme="outlinedBlue"
                                iconLeading=""
                                cta={cta}
                            >
                                {cta.text}
                            </CTA>
                        )}
                        {cta2?.text && (
                            <CTA
                                className={cx(styles.cta, styles.cta2)}
                                theme="outlinedBlue"
                                iconLeading=""
                                cta={cta2}
                            >
                                {cta2.text}
                            </CTA>
                        )}
                    </Stagger.Child>
                )}
            </div>
            {image?.file?.url &&
                image?.file?.details &&
                imageLarge?.file?.url &&
                imageLarge?.file?.details && (
                    <Stagger.Child order={3} className={styles.imgWrapper}>
                        <Img
                            className={styles.image}
                            src={image.file.url}
                            fallbackImageWidth={500}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: imageLarge.file.url,
                                    imageWidth:
                                        imageLarge.file.details.image.width,
                                },
                                {
                                    src: image.file.url,
                                    imageWidth: image.file.details.image.width,
                                },
                            ]}
                        />
                    </Stagger.Child>
                )}
        </Stagger>
    );
};

CardColumnContent.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string.isRequired,
    hasArrowIcon: PropTypes.bool,
    richBodyCopy: PropTypes.object,
    cta: PropTypes.object,
    cta2: PropTypes.object,
    backgroundTheme: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
};

CardColumnContent.defaultProps = {
    backgroundTheme: 'white',
    hasArrowIcon: true,
};

export default CardColumnContent;
