import React from 'react';

const CarouselContext = React.createContext({
    activeIndex: 0,
    prevIndex: 0,
    totalSlides: 0,
    mode: '',
    seconds: 0,
    slides: null,
    timerDuration: 0,
    isContinuous: false,
    whileInterrupt: false,
    nextSlide: () => {},
    prevSlide: () => {},
});

export default CarouselContext;
