import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SvgIcon from 'components/ui/SvgIcon';

import styles from './ScrollIndicator.module.scss';

const ScrollIndicator = ({ duration, className, onClick }) => {
    const svg = useRef();
    const rotation = useRef(0);
    const time = useRef();

    useEffect(() => {
        let rafId;
        const rotate = ts => {
            const timeDelta = time.current == null ? 0 : ts - time.current;
            const rotationDelta = (360 * timeDelta) / (duration * 1000);
            rotation.current = (rotation.current + rotationDelta) % 360;
            if (svg?.current) {
                svg.current.style.transform = `translateZ(0) rotate(${-rotation.current}deg)`;
            }
            time.current = ts;
            rafId = requestAnimationFrame(rotate);
        };
        rafId = requestAnimationFrame(rotate);
        return () => {
            cancelAnimationFrame(rafId);
        };
    }, [duration]);

    const x = 112;
    const y = 112;
    const r = 112 - 14;
    const start = 11;
    const circumfence = 2 * Math.PI * r - start;
    const path = `
        M ${x - r}, ${y}
        a ${r},${r} 0 1,1 ${r * 2},0
        a ${r},${r} 0 1,1 ${-r * 2},0
    `;
    return (
        <div
            onClick={onClick}
            className={cx(styles.scrollIndicator, className)}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 224 224"
                fill="currentColor"
                className={styles.textRing}
                ref={svg}
            >
                <path
                    id="__scrollIndicatorPath"
                    className={styles.circle}
                    d={path}
                />
                <text className={styles.text} textLength={circumfence}>
                    <textPath
                        textLength={circumfence}
                        startOffset={start}
                        xlinkHref="#__scrollIndicatorPath"
                    >
                        Scroll down for deliciousness • Scroll down for
                        deliciousness •
                    </textPath>
                </text>
            </svg>
            <SvgIcon className={styles.arrow} iconType="arrowRight" />
        </div>
    );
};

ScrollIndicator.propTypes = {
    duration: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

ScrollIndicator.defaultProps = {
    duration: 15,
};

export default ScrollIndicator;
