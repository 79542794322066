/* eslint-disable @next/next/no-img-element */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Button from 'components/ui/Button';
import Link from 'components/ui/Link';

import styles from './BlockLocation.module.scss';

const logoMap = {
    Market: 'market',
    Creamery: 'creamery',
};

const Location = ({
    location,
    address,
    linkToGoogleMaps,
    linkToReservation,
    hours,
    descriptionLine1,
    descriptionLine2,
    descriptionLine3,
    contactName,
    contactEmail,
    contactNumber,
    customLink,
    contactName2,
    contactNumber2,
    logo,
}) => {
    return (
        <article
            className={cx(styles.locationCard, {
                [styles.market]: location === 'Market',
            })}
        >
            <div className={styles.column}>
                {location && !logo && (
                    <div className={styles.logoWrapper}>
                        <SvgIcon iconType={logoMap[location]} />
                    </div>
                )}
                {logo?.file?.url && (
                    <div className={styles.logoWrapper}>
                        <img src={logo.file.url} />
                    </div>
                )}
                <Link className={styles.address} href={linkToGoogleMaps}>
                    <Text
                        baseTheme="bodySmall"
                        themes={{
                            large: 'bodyMedium',
                            xxLarge: 'bodyLarge',
                        }}
                    >
                        {address}
                    </Text>
                </Link>
                <div className={styles.iconWrapper}>
                    <div className={styles.icon}>
                        <SvgIcon iconType="arrowRight" styles={styles.icon} />
                    </div>
                </div>
                {linkToReservation && (
                    <Button
                        className={styles.reservationButton}
                        theme="outlinedBlue"
                        href={linkToReservation}
                    >
                        Make A Reservation
                    </Button>
                )}
            </div>
            <div className={styles.column}>
                <div className={styles.columnInner}>
                    <div className={styles.hoursWrapper}>
                        <Text
                            className={styles.hoursLabel}
                            baseTheme="labelLarge"
                        >
                            Hours
                        </Text>
                        <ul className={styles.hoursList}>
                            {hours?.map((timeSpan, idx) => (
                                <li key={idx}>
                                    <div className={styles.timeSpan}>
                                        <Text baseTheme="headingXSmall">
                                            {timeSpan.days}
                                        </Text>
                                        <Text baseTheme="headingXSmall">
                                            {timeSpan.hours}
                                        </Text>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <Text baseTheme="bodyXSmall">
                            {descriptionLine1}
                            {descriptionLine2 && (
                                <>
                                    <br />
                                    {descriptionLine2}
                                </>
                            )}
                            {descriptionLine3 && (
                                <>
                                    <br />
                                    {descriptionLine3}
                                </>
                            )}
                        </Text>
                    </div>
                    <div className={styles.contactWrapper}>
                        <Text
                            className={styles.contactLabel}
                            baseTheme="labelLarge"
                        >
                            Contact
                        </Text>
                        <Text
                            className={styles.contactName}
                            baseTheme="headingXSmall"
                        >
                            {contactName}
                        </Text>
                        {contactEmail && (
                            <Link
                                className={styles.contactEmail}
                                href={`mailto:${contactEmail}`}
                            >
                                <Text baseTheme="headingXSmall">
                                    {contactEmail}
                                </Text>
                            </Link>
                        )}
                        <Link
                            className={styles.contactNumber}
                            href={`tel:${contactNumber}`}
                        >
                            <Text baseTheme="headingXSmall">
                                {contactNumber}
                            </Text>
                        </Link>
                        {customLink?.text && customLink?.link && (
                            <div className={styles.buttonWrapper}>
                                <Link.Entry
                                    className={styles.customLink}
                                    entry={customLink.link}
                                >
                                    <Text baseTheme="headingXSmall">
                                        {customLink.text}
                                    </Text>
                                </Link.Entry>
                            </div>
                        )}
                        {contactName2 && (
                            <Text
                                className={styles.contactName}
                                baseTheme="headingXSmall"
                            >
                                {contactName2}
                            </Text>
                        )}
                        {contactNumber2 && (
                            <Link
                                className={styles.contactNumber}
                                href={`tel:${contactNumber2}`}
                            >
                                <Text baseTheme="headingXSmall">
                                    {contactNumber2}
                                </Text>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </article>
    );
};

Location.propTypes = {
    location: PropTypes.oneOf(['Market', 'Creamery']),
    logo: PropTypes.object,
    address: PropTypes.string,
    linkToGoogleMaps: PropTypes.string,
    linkToReservation: PropTypes.string,
    hours: PropTypes.array,
    descriptionLine1: PropTypes.string,
    descriptionLine2: PropTypes.string,
    descriptionLine3: PropTypes.string,
    linkToMenus: PropTypes.string,
    contactName: PropTypes.string,
    contactEmail: PropTypes.string,
    contactNumber: PropTypes.string,
    customLink: PropTypes.object,
    contactName2: PropTypes.string,
    contactNumber2: PropTypes.string,
    className: PropTypes.string,
};

export default Location;
