import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import Text from 'components/ui/Text';
import RichText from 'components/ui/RichText';
import Button from 'components/ui/Button';

import styles from './Card.module.scss';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text as="h3" baseTheme="displaySmall">
                    {children}
                </Text>
            );
        },
    },
};

const bodyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text as="p" baseTheme="bodySmall">
                    {children}
                </Text>
            );
        },
    },
};

const Card = ({
    isActive,
    isPrev,
    headline,
    eyebrow,
    bodyCopy,
    cta,
    dirNext,
}) => {
    return (
        <div
            className={cx(styles.card, {
                [styles.isActive]: isActive,
                [styles.isPrev]: isPrev,
                [styles.dirNext]: dirNext,
            })}
        >
            {eyebrow && (
                <div className={styles.eyebrow}>
                    <Text as="p" baseTheme="labelLarge">
                        {eyebrow}
                    </Text>
                </div>
            )}
            {headline && (
                <div className={styles.headline}>
                    <RichText
                        richText={headline}
                        overrides={headlineOverrides}
                    />
                </div>
            )}
            {bodyCopy && (
                <div className={styles.bodyCopy}>
                    <RichText richText={bodyCopy} overrides={bodyOverrides} />
                </div>
            )}
            {cta?.text && cta?.link && (
                <Button className={styles.cta} entry={cta.link}>
                    {cta.text}
                </Button>
            )}
        </div>
    );
};

Card.propTypes = {
    isActive: PropTypes.bool,
    isPrev: PropTypes.bool,
    timerDuration: PropTypes.number,
    eyebrow: PropTypes.string,
    headline: PropTypes.object.isRequired,
    bodyCopy: PropTypes.object.isRequired,
    cta: PropTypes.object.isRequired,
    dirNext: PropTypes.bool,
};

export default Card;
