import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import carouselContext from 'components/ui/CarouselProvider/CarouselContext';
import { MODE } from 'components/ui/CarouselProvider/carouselConstants';

import Text from 'components/ui/Text';
import ButtonCircle from 'components/ui/ButtonCircle';

import Slide from './Slide';
import Card from './Card';

import styles from './BlockCarouselShowcaseWithCard.module.scss';

const { PAUSED, PLAYING, STOPPED } = MODE;

const CarouselShowcaseWithCard = ({ flushTop, flushBottom }) => {
    const cc = useContext(carouselContext);
    const [dir, setDir] = useState('');

    const handleNextSlide = () => {
        setDir('next');
        cc.nextSlide();
    };

    const handlePrevSlide = () => {
        setDir('prev');
        cc.prevSlide();
    };

    return (
        <section
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.isContinuous]: cc.isContinuous,
                [styles[PLAYING]]: cc.mode === PLAYING,
                [styles[PAUSED]]: cc.mode === PAUSED,
                [styles.isSwitching]: cc.isContinuous
                    ? cc.seconds === 0 && !cc.whileInterrupt
                    : cc.seconds === 0 && cc.mode !== STOPPED,
            })}
        >
            <div className={styles.inner}>
                {cc.slides?.map((slide, i) => (
                    <Slide
                        key={i}
                        {...slide}
                        isActive={cc.activeIndex === i}
                        isPrev={cc.prevIndex === i}
                        timerDuration={cc.timerDuration}
                    />
                ))}
                <div className={styles.column}>
                    <div className={styles.cardWrapper}>
                        {cc.slides?.map((slide, i) => (
                            <Card
                                key={i}
                                {...slide}
                                dirNext={dir === 'next'}
                                isActive={cc.activeIndex === i}
                                isPrev={cc.prevIndex === i}
                                timerDuration={cc.timerDuration}
                            />
                        ))}
                    </div>
                </div>
                <div className={styles.navWrapper}>
                    <div className={styles.nav}>
                        <ButtonCircle
                            onClick={handlePrevSlide}
                            className={styles.navItem}
                            theme="chevronLeftBlue"
                        >
                            <span className="sr-only">Previous Slide</span>
                        </ButtonCircle>
                        <Text
                            baseTheme="labelSmall"
                            className={styles.navCount}
                        >
                            {`${cc.activeIndex + 1}/${cc.totalSlides}`}
                        </Text>
                        <ButtonCircle
                            onClick={handleNextSlide}
                            className={styles.navItem}
                            theme="chevronRightBlue"
                        >
                            <span className="sr-only">Next Slide</span>
                        </ButtonCircle>
                    </div>
                </div>
            </div>
        </section>
    );
};

CarouselShowcaseWithCard.propTypes = {
    className: PropTypes.string,
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
    isContinuous: PropTypes.bool,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

CarouselShowcaseWithCard.defaultProps = {
    timerDuration: 7,
    isContinuous: false,
};

export default CarouselShowcaseWithCard;
