import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useClickAway, useKey } from 'react-use';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';
import ButtonCircle from 'components/ui/ButtonCircle';
import { SearchContext } from 'components/pageTemplates/Search/SearchContext';

import styles from './SearchBar.module.scss';

const RETURN_KEYCODE = 13;

const overlayStates = {
    OPENING: 'OPENING',
    OPENED: 'OPENED',
    CLOSING: 'CLOSING',
    CLOSED: 'CLOSED',
};

const DivotSvg = () => {
    return (
        <svg
            x="0px"
            y="0px"
            width="19px"
            height="10px"
            viewBox="0 0 19 10"
            xmlSpace="preserve"
        >
            <path d="m9.5.5 8.8 8.8-8.8.1-8.8-.1z" fill="currentColor" />
        </svg>
    );
};

const SearchBar = ({ className, searchPlaceholder, isHidden, isLanding }) => {
    const {
        searchTerm: [searchTerm, setSearchTerm],
    } = useContext(SearchContext);
    const { OPENING, OPENED, CLOSING, CLOSED } = overlayStates;
    const [mobileSearchClicked, setMobileSearchClicked] = useState(false);
    const [overlayState, setOverlayState] = useState(CLOSED);
    const [escPressed, setEscPressed] = useState(false);

    const ref = useRef(null);
    const inputRef = useRef(null);
    const router = useRouter();

    const handleSearch = () => {
        if (searchTerm.length === 0) return;
        router
            .push({
                pathname: '/search/[slug]',
                query: { slug: searchTerm },
            })
            .then(() => setOverlayState(CLOSING));
    };

    const handleInputKeyDown = e => {
        if (e.keyCode === RETURN_KEYCODE) {
            handleSearch();
        }
    };

    const handleAnimationEnd = () => {
        overlayState === CLOSING && setOverlayState(CLOSED);
        overlayState === OPENING &&
            setOverlayState(() => {
                inputRef.current.focus();
                return OPENED;
            });

        setEscPressed(false);
    };

    const handleOpen = () => {
        setOverlayState(OPENING);
    };

    const toggleOpen = () => {
        overlayState === CLOSED ? handleOpen() : setOverlayState(CLOSING);
    };

    useKey('Escape', () => setEscPressed(true));
    useClickAway(ref, () => setEscPressed(true));

    useEffect(() => {
        escPressed && overlayState !== CLOSED
            ? setOverlayState(CLOSING)
            : setEscPressed(false);
    }, [escPressed, overlayState, CLOSED, CLOSING]);

    useEffect(() => {
        setMobileSearchClicked(
            [OPENED, OPENING, CLOSING].includes(overlayState)
        );
    }, [overlayState, OPENED, OPENING, CLOSING]);

    return (
        <li ref={ref} className={cx(styles.root, className)}>
            <div
                className={cx(styles.rootInner, {
                    [styles.isClosing]: mobileSearchClicked,
                })}
            >
                <Text
                    as="label"
                    baseTheme="bodyMedium"
                    className={cx(styles.searchIconWrapper, {
                        [styles.isClosing]: mobileSearchClicked,
                    })}
                    onClick={toggleOpen}
                >
                    <SvgIcon className={styles.searchIcon} iconType="search" />
                </Text>

                {overlayState !== CLOSED && (
                    <aside
                        className={cx(styles.overlay, {
                            [styles.isClosing]: overlayState === CLOSING,
                            [styles.isHidden]: isHidden,
                            [styles.isLanding]: isLanding,
                        })}
                        onAnimationEnd={handleAnimationEnd}
                    >
                        <div className={styles.divotWrapper}>
                            <div className={styles.divot}>
                                <DivotSvg />
                            </div>
                        </div>

                        <div className={styles.searchWrapper}>
                            <div className={styles.searchInput}>
                                <SvgIcon
                                    className={styles.searchIcon}
                                    iconType="search"
                                />
                                <Text as="label" baseTheme="bodyMedium">
                                    <input
                                        ref={inputRef}
                                        name="search"
                                        placeholder={searchPlaceholder}
                                        autoComplete="off"
                                        type="text"
                                        onChange={e =>
                                            setSearchTerm(e.target.value)
                                        }
                                        onKeyDown={handleInputKeyDown}
                                        value={searchTerm}
                                        className={styles.input}
                                        enterKeyHint="search"
                                    />
                                </Text>
                                <div className={styles.submitButtonWrapper}>
                                    <ButtonCircle
                                        type="submit"
                                        theme="chevronRightCreamDark"
                                        onClick={handleSearch}
                                    >
                                        <span className="sr-only">Submit</span>
                                    </ButtonCircle>
                                </div>
                            </div>
                        </div>
                    </aside>
                )}
            </div>
        </li>
    );
};

SearchBar.propTypes = {
    searchPlaceholder: PropTypes.string,
    className: PropTypes.string,
    isHidden: PropTypes.bool,
    isLanding: PropTypes.bool,
};

SearchBar.defaultProps = {
    searchPlaceholder: '',
    isHidden: false,
    isLanding: false,
    className: '',
};
export default SearchBar;
