import React from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';

import styles from './FlavorFinderBackground.module.scss';

// Framer Motion has a bug with animating css var colors
// https://github.com/framer/motion/issues/546

const spring = {
    type: 'spring',
    damping: 60,
    stiffness: 160,
};

const FlavorFinderBackground = ({ color }) => {
    return (
        <motion.div
            className={styles.root}
            animate={{
                backgroundColor: color,
            }}
            transition={spring}
        />
    );
};

FlavorFinderBackground.propTypes = {
    color: PropTypes.string,
};

export default FlavorFinderBackground;
