import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useInterval } from 'react-use';

import Text from 'components/ui/Text';
import styles from './SwirlTimer.module.scss';

const totalSeconds = 15;

const SwirlTimer = ({ show, start, ended, sendMessage }) => {
    const [displaySeconds, setDisplaySeconds] = useState(totalSeconds);
    const [seconds, setSeconds] = useState(totalSeconds);
    const [isRunning, setIsRunning] = useState(false);

    const thirdOfTotal = Math.round(totalSeconds / 3);
    const firstMessage = totalSeconds - thirdOfTotal;
    const secondMessage = totalSeconds - thirdOfTotal - thirdOfTotal;

    const delay = 1000;

    const dashLength = 289;
    const increment = dashLength / totalSeconds;

    const strokeDashoffset =
        dashLength - Math.abs(seconds - totalSeconds) * increment;

    useInterval(
        () => {
            if (seconds === 0) {
                setSeconds(seconds - 1);
                setDisplaySeconds(displaySeconds - 1);
                ended();
                setIsRunning(false);
            } else {
                if (seconds === firstMessage) {
                    sendMessage();
                }
                if (seconds === secondMessage) {
                    sendMessage();
                }
                setSeconds(seconds - 1);
                setDisplaySeconds(displaySeconds - 1);
            }
        },
        isRunning ? delay : null
    );

    useEffect(() => {
        if (start) {
            setIsRunning(true);
            setSeconds(seconds - 1); // only on `start` change, do not include `seconds` in deps.
        } else {
            setSeconds(totalSeconds);
            setDisplaySeconds(totalSeconds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start]);

    return (
        <div
            className={cx(styles.root, {
                [styles.show]: show,
                [styles.isRunning]: isRunning,
            })}
        >
            <div className={styles.timer}>
                <div className={styles.timerCircle}>
                    <svg width="100px" height="100px" viewBox="0 0 100 100">
                        <circle
                            fill="none"
                            strokeOpacity="0.2"
                            stroke="var(--colors-white)"
                            strokeWidth="8"
                            cx="50"
                            cy="50"
                            r="46"
                        />
                    </svg>
                    <svg width="100px" height="100px" viewBox="0 0 100 100">
                        <circle
                            style={{
                                strokeDashoffset,
                            }}
                            className={styles.timerCircleStroke}
                            fill="none"
                            stroke="var(--colors-white)"
                            strokeWidth="8"
                            strokeLinecap="round"
                            cx="50"
                            cy="50"
                            r="46"
                            strokeDasharray={dashLength}
                            strokeDashoffset={dashLength}
                            strokeMiterlimit="0"
                        />
                    </svg>
                </div>
                <div className={styles.seconds}>
                    <Text baseTheme="headingMedium2">{displaySeconds}</Text>
                </div>
            </div>
        </div>
    );
};

SwirlTimer.propTypes = {
    show: PropTypes.bool,
    start: PropTypes.bool,
    className: PropTypes.string,
    ended: PropTypes.func,
    sendMessage: PropTypes.func,
};

export default SwirlTimer;
