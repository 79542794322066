import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import icons from './icons';

const SvgIcon = ({ className, iconType, height, tag, width }) => {
    const Tag = tag;
    return (
        <Tag
            className={cx('svg-icon', className)}
            dangerouslySetInnerHTML={{ __html: icons[iconType] }}
            style={{
                width,
                height,
            }}
        />
    );
};

SvgIcon.defaultProps = {
    tag: 'span',
};

SvgIcon.propTypes = {
    className: PropTypes.string,
    height: PropTypes.string,
    iconType: PropTypes.string,
    tag: PropTypes.string,
    width: PropTypes.string,
};

export default SvgIcon;
