import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import carouselContext from 'components/ui/CarouselProvider/CarouselContext';
import { MODE } from 'components/ui/CarouselProvider/carouselConstants';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import ButtonCircle from 'components/ui/ButtonCircle';
import Stagger from 'components/ui/Stagger';
import CarouselShowcaseSlide from './CarouselShowcaseSlide';
import GradientPanel from './GradientPanel';

import styles from './BlockCarouselShowcase.module.scss';

const { PAUSED, PLAYING, STOPPED } = MODE;

const CarouselShowcase = () => {
    const cc = useContext(carouselContext);

    const dashLength = 90;
    const increment = dashLength / cc.timerDuration;
    const strokeDashoffset = dashLength - cc.seconds * increment;

    return (
        <section
            className={cx(styles.root, {
                [styles.isContinuous]: cc.isContinuous,
                [styles[PLAYING]]: cc.mode === PLAYING,
                [styles[PAUSED]]: cc.mode === PAUSED,
                [styles.isSwitching]: cc.isContinuous
                    ? cc.seconds === 0 && !cc.whileInterrupt
                    : cc.seconds === 0 && cc.mode !== STOPPED,
            })}
        >
            <div className={styles.bg} role="presentation">
                {cc.slides?.map((slide, i) => (
                    <GradientPanel
                        key={i}
                        {...slide}
                        isActive={cc.activeIndex === i}
                        isPrev={cc.prevIndex === i}
                    />
                ))}
            </div>
            <div className={styles.inner}>
                {cc.slides?.map((slide, i) => (
                    <CarouselShowcaseSlide
                        key={i}
                        {...slide}
                        isActive={cc.activeIndex === i}
                        isPrev={cc.prevIndex === i}
                        timerDuration={cc.timerDuration}
                    />
                ))}
                <div className={styles.column}>
                    <Stagger className={styles.ctaLockup}>
                        <Stagger.ArrowRight
                            order={2}
                            className={styles.iconWrapper}
                        />
                        <Stagger.Child
                            order={3}
                            className={styles.buttonWrapper}
                        >
                            {cc.slides?.[cc.activeIndex]?.cta?.text &&
                                cc.slides?.[cc.activeIndex]?.cta?.link && (
                                    <Button
                                        className={styles.cta}
                                        theme="outlinedCream"
                                        entry={
                                            cc.slides[cc.activeIndex].cta.link
                                        }
                                    >
                                        {cc.slides[cc.activeIndex].cta.text}
                                    </Button>
                                )}
                        </Stagger.Child>
                        <div className={styles.nav}>
                            <ButtonCircle
                                onClick={cc.prevSlide}
                                className={styles.navItem}
                                theme="chevronLeftWhite"
                            >
                                <span className="sr-only">Previous Slide</span>
                            </ButtonCircle>
                            <Text
                                baseTheme="labelSmall"
                                className={styles.navCount}
                            >
                                {`${cc.activeIndex + 1}/${cc.totalSlides}`}
                            </Text>
                            <ButtonCircle
                                onClick={cc.nextSlide}
                                className={styles.navItem}
                                theme="chevronRightWhite"
                            >
                                <span className="sr-only">Next Slide</span>
                                <span className={styles.navItemAnimation}>
                                    <svg
                                        width="31px"
                                        height="31px"
                                        viewBox="0 0 31 31"
                                    >
                                        <circle
                                            style={{
                                                strokeDashoffset,
                                            }}
                                            className={styles.strokeCircle}
                                            fill="none"
                                            stroke="var(--colors-blue)"
                                            strokeWidth="2"
                                            cx="15.5"
                                            cy="15.5"
                                            r="14.5"
                                            strokeDasharray="90"
                                            strokeDashoffset="90"
                                            strokeMiterlimit="0"
                                        />
                                    </svg>
                                </span>
                            </ButtonCircle>
                        </div>
                    </Stagger>
                </div>
            </div>
        </section>
    );
};

CarouselShowcase.propTypes = {
    className: PropTypes.string,
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
    isContinuous: PropTypes.bool,
};

CarouselShowcase.defaultProps = {
    timerDuration: 7,
    isContinuous: false,
};

export default CarouselShowcase;
