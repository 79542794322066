import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import { useIntersection } from 'react-use';

import CardTestimonial from 'components/cards/CardTestimonial';

import CreameryDiningHours from './CreameryDiningHours';
import CardColumnContent from 'components/cards/CardColumnContent';
import CardLocation from 'components/cards/CardLocation';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import SlideShow from './SlideShow';
import styles from './BlockSlideShow.module.scss';

const CardMissing = ({ contentTypeId }) => {
    return <p>No Card{contentTypeId}</p>;
};

CardMissing.propTypes = {
    contentTypeId: PropTypes.string,
};

const cardMap = {
    cardLocation: CardLocation,
    cardColumnContent: CardColumnContent,
    default: CardMissing,
};

const richTextOverrides = {
    renderNode: {
        [BLOCKS.HEADING_4]: (node, children) => {
            return (
                <Text
                    as="h4"
                    baseTheme="headingSmall"
                    themes={{ large: 'headingMedium' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockImageOffsetContent = ({
    slideshow,
    richBodyCopy,
    offsetContent,
    testimonial,
    offsetLeft,
    creameryDiningHours,
    backgroundTheme,
    className,
}) => {
    const [isInView, setIsInView] = useState(false);

    const Card =
        cardMap[offsetContent?.contentTypeId || 'default'] ||
        cardMap['default'];

    const ref = useRef(null);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    useEffect(() => {
        if (intersection?.isIntersecting) {
            setIsInView(true);
        }
    }, [intersection]);

    return (
        <section
            className={cx(styles.imageOffsetContent, className, {
                [styles.offsetLeft]: offsetLeft,
                [styles.hasTestimonial]: testimonial,
                [styles.whiteBackground]: backgroundTheme === 'white',
                [styles.hasTestimonialWithoutImage]:
                    testimonial && !testimonial.authorImage,
            })}
        >
            {slideshow?.slides && (
                <SlideShow {...slideshow} dotsOnLeft={!offsetLeft} />
            )}
            <div className={styles.columns}>
                <div
                    className={cx(styles.column, styles.columnContent, {
                        [styles.noContent]:
                            !richBodyCopy &&
                            !testimonial &&
                            !creameryDiningHours,
                    })}
                >
                    {richBodyCopy && !testimonial && !creameryDiningHours && (
                        <RichText
                            richText={richBodyCopy}
                            overrides={richTextOverrides}
                        />
                    )}
                    {testimonial && !richBodyCopy && !creameryDiningHours && (
                        <CardTestimonial {...testimonial} />
                    )}
                    {creameryDiningHours && !testimonial && !richBodyCopy && (
                        <CreameryDiningHours {...creameryDiningHours} />
                    )}
                </div>
                {offsetContent && (
                    <div
                        ref={ref}
                        className={cx(
                            styles.column,
                            styles.columnOffsetContent,
                            {
                                [styles.isInView]: isInView,
                            }
                        )}
                    >
                        <Card {...offsetContent} />
                    </div>
                )}
            </div>
        </section>
    );
};

BlockImageOffsetContent.propTypes = {
    slideshow: PropTypes.object,
    richBodyCopy: PropTypes.object,
    offsetContent: PropTypes.object,
    testimonial: PropTypes.object,
    offsetLeft: PropTypes.bool,
    backgroundTheme: PropTypes.string,
    creameryDiningHours: PropTypes.object,
    className: PropTypes.string,
};

BlockImageOffsetContent.defaultProps = {
    offsetLeft: false,
    backgroundTheme: 'default',
};

export default BlockImageOffsetContent;
