import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { useBoolean, useMedia, useWindowSize } from 'react-use';

import { wait } from 'utils';

import FlavorFinderBackground from './FlavorFinderBackground';
import FlavorFinderPrompt from './FlavorFinderPrompt';
import FlavorFinderCardSwiper from './FlavorFinderCardSwiper';
import FlavorFinderPayoff from './FlavorFinderPayoff';

import { profiles } from 'components/blocks/BlockCreamCheeseFlavorFinder/cream-cheese-profiles';
import { data } from './cream-cheese-finder-data';

import styles from './FlavorFinder.module.scss';

const FlavorFinder = ({ sendProductId }) => {
    const bodyLockRef = useRef(null);

    const { height } = useWindowSize();

    const isMediumQuery = useMedia('(min-width: 768px)');
    const [isMedium, setIsMedium] = useBoolean(false);

    useEffect(() => {
        setIsMedium(isMediumQuery);
    }, [isMediumQuery, setIsMedium]);

    const [isPrompt, setIsPrompt] = useState(false);
    const [isInteractive, setIsInteractive] = useState(false);
    const [isSwitching, setIsSwitching] = useState(false);
    const [isPayoff, setIsPayoff] = useState(false);

    const [payoffProfileId, setPayoffProfileId] = useState(
        'SERIOUSLY_STRAWBERRY'
    );
    const [cardSet, setCardSet] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState('#FFCECC');

    // starts with one point each in case user clicks no for all
    const [flavors, setFlavorsArray] = useState([
        'Strawberry',
        'Cheddar',
        'Veggie',
        'Chive',
        'Original',
    ]);

    const handleTryAgain = () => {
        setIsSwitching(true);
        setIsInteractive(false);
        setIsPayoff(false);
        setCardSet(0);

        // isSwitching
        wait(400)
            .then(() => {
                setIsPrompt(false);
                setIsSwitching(false);
            })
            .then(() => {
                wait(50).then(() => {
                    showQuestion();
                });
            });
    };

    const handleLearnMore = () => {
        sendProductId(payoffProfileId);
    };

    const showQuestion = () => {
        setIsPrompt(true);

        wait(3250).then(() => {
            setIsInteractive(true);
        });
    };

    useEffect(() => {
        showQuestion();
    }, []);

    useEffect(() => {
        if (isPayoff) {
            const activeProfile = profiles.find(
                item => item.id === payoffProfileId
            );
            setBackgroundColor(activeProfile.color);
        } else {
            setBackgroundColor(data[cardSet].color);
        }
    }, [cardSet, isPayoff, payoffProfileId]);

    const determineFavoriteFlavor = () => {
        // count how many of each flavor
        let counts = {};

        for (let i = 0; i < flavors.length; i++) {
            let num = flavors[i];
            counts[num] = (counts[num] || 0) + 1;
        }

        // create array of each flavor score
        const scores = [
            counts['Strawberry'],
            counts['Cheddar'],
            counts['Veggie'],
            counts['Chive'],
            counts['Original'],
        ];

        // get highest score
        const highestScore = Math.max(...scores);

        // check for ties
        // returns which indeces have the same highest score
        const indices = scores.reduce(function (r, v, i) {
            return r.concat(v === highestScore ? i : []);
        }, []);

        // if there is only one highest score then set id
        if (indices.length === 1) {
            const profileId = profiles[indices].id;
            setPayoffProfileId(profileId);
        } else {
            // determine id based on highest priority

            // create an array of the priority numbers (in profiles data)
            // based on indeces with the same highest score
            const priorities = [];
            for (let i = 0; i < indices.length; i++) {
                priorities.push(profiles[indices[i]].priority);
            }

            // get the highest priority number
            const highestPriority = Math.max(...priorities);

            // find id from highest priority number
            const profileId = profiles.find(
                item => item.priority === highestPriority
            ).id;

            setPayoffProfileId(profileId);
        }
    };

    const handleCardSetComplete = () => {
        setIsSwitching(true);
        setIsInteractive(false);

        if (cardSet === data.length - 1) {
            determineFavoriteFlavor();
            setIsPayoff(true);
            return;
        }

        // isSwitching
        wait(400)
            .then(() => {
                setIsPrompt(false);
                setIsSwitching(false);
            })
            .then(() => {
                wait(50).then(() => {
                    setCardSet(cardSet + 1);
                    showQuestion();
                });
            });
    };

    const collectFlavorPoint = flavor => {
        setFlavorsArray(flavors => [...flavors, flavor]);
    };

    useEffect(() => {
        disableBodyScroll(bodyLockRef.current);
        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    return (
        <div
            ref={bodyLockRef}
            className={cx(styles.root, {
                [styles.isPrompt]: isPrompt,
                [styles.isInteractive]: isInteractive,
                [styles.isSwitching]: isSwitching,
                [styles.isPayoff]: isPayoff,
            })}
            style={{
                height: `${height}px`,
            }}
        >
            <FlavorFinderBackground color={backgroundColor} />
            <FlavorFinderPrompt
                isPrompt={isPrompt}
                isInteractive={isInteractive}
                isSwitching={isSwitching}
                isPayoff={isPayoff}
                cardSet={cardSet}
            />
            <FlavorFinderCardSwiper
                isPrompt={isPrompt}
                isInteractive={isInteractive}
                isSwitching={isSwitching}
                isPayoff={isPayoff}
                cardSet={cardSet}
                cardSetComplete={handleCardSetComplete}
                sendFlavorPoint={collectFlavorPoint}
                isMedium={isMedium}
            />
            <FlavorFinderPayoff
                show={isPayoff}
                profileId={payoffProfileId}
                tryAgain={handleTryAgain}
                learnMore={handleLearnMore}
            />
        </div>
    );
};

FlavorFinder.propTypes = {
    sendProductId: PropTypes.func,
};

export default FlavorFinder;
