import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ selector, children }) => {
    const [element, setElement] = useState(null);

    useEffect(() => {
        const element = document.querySelector(selector);
        setElement(element);
    }, [selector]);

    if (element) {
        return ReactDOM.createPortal(children, element);
    }

    return null;
};

Portal.propTypes = {
    selector: PropTypes.string,
    children: PropTypes.node.isRequired,
};

Portal.defaultProps = {
    selector: '#__portal__',
};

export default Portal;
