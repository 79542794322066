import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'components/ui/Button';
import RichText, { BLOCKS } from 'components/ui/RichText';
import TextEyebrow from 'components/ui/TextEyebrow';

import styles from './BlockEyebrow.module.scss';
import Text from 'components/ui/Text';

const BlockEyebrow = ({
    eyebrow,
    headline,
    richHeadline,
    richText,
    flushTop,
    flushBottom,
    hasArrowIcon,
    theme,
    className,
    cta,
    backgroundColor,
    firstBlock,
}) => {
    const backgroundColors = {
        White: 'white',
        Default: 'default',
    };

    const richTextBodyOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        baseTheme="bodyMedium"
                        themes={{ medium: 'bodyLarge' }}
                        className={styles.paragraph}
                        fixWidows
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    return (
        <section
            className={cx(
                styles.root,
                className,
                styles[
                    `background-${
                        backgroundColors[backgroundColor || 'Default'] ||
                        'default'
                    }`
                ],
                styles[theme],
                {
                    [styles.flushTop]: flushTop,
                    [styles.flushBottom]: flushBottom,
                }
            )}
        >
            <div className={styles.inner}>
                <div className={styles.eyebrow}>
                    {(headline || richHeadline) && (
                        <TextEyebrow
                            eyebrow={eyebrow ? eyebrow : ''}
                            className={styles.headline}
                            headline={headline}
                            richHeadline={richHeadline ? richHeadline : null}
                            icon={hasArrowIcon ? 'arrowRight' : 'none'}
                            disableAnimation={firstBlock}
                            theme={
                                theme === 'displayLarge'
                                    ? 'displayLarge'
                                    : 'display'
                            }
                        />
                    )}
                    {richText && (
                        <div className={styles.richText}>
                            <RichText
                                richText={richText}
                                overrides={
                                    theme === 'displayLarge'
                                        ? richTextBodyOverrides
                                        : null
                                }
                            />
                        </div>
                    )}
                    {cta && (
                        <Button
                            className={styles.cta}
                            theme={'outlinedBlue'}
                            entry={cta.link}
                        >
                            {cta.text}
                        </Button>
                    )}
                </div>
            </div>
        </section>
    );
};

BlockEyebrow.propTypes = {
    backgroundColor: PropTypes.string,
    className: PropTypes.string,
    cta: PropTypes.object,
    eyebrow: PropTypes.string,
    firstBlock: PropTypes.bool,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    hasArrowIcon: PropTypes.bool,
    headline: PropTypes.string,
    richHeadline: PropTypes.object,
    richText: PropTypes.object,
    theme: PropTypes.oneOf(['default', 'displayLarge']),
};

BlockEyebrow.defaultProps = {
    flushTop: false,
    flushBottom: false,
    hasArrowIcon: true,
    theme: 'default',
};

export default BlockEyebrow;
