import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CardSmall from 'components/cards/CardSmall';
import TextEyebrow from 'components/ui/TextEyebrow';

import styles from './BlockCardTrioSmall.module.scss';

const BlockCardTrioSmall = ({
    headline,
    eyebrow,
    cards,
    flushTop,
    className,
}) => {
    useEffect(() => {
        if (!cards) {
            dispatchWarning(
                'No cards provided. Three cards required for render.'
            );
        } else {
            if (cards.length !== 3) {
                if (cards.length < 3) {
                    dispatchWarning('Three cards required for render.');
                } else {
                    dispatchWarning(
                        `${cards.length} cards provided. Only three will be displayed at render.`
                    );
                }
            }
        }
    }, [cards]);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    const renderCard = card => {
        return (
            <div className={styles.cardSmall}>
                <CardSmall {...card} />
            </div>
        );
    };

    return (
        <section
            className={cx(styles.blockCardTrioSmall, className, {
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.eyebrow}>
                    {headline && (
                        <TextEyebrow
                            eyebrow={eyebrow ? eyebrow : ''}
                            headline={headline}
                            theme="display"
                        />
                    )}
                </div>
                <ul className={styles.cards}>
                    {cards?.map((card, i) => {
                        if (i < cards.length - 1) {
                            return (
                                <Fragment key={i}>
                                    <li className={styles.listItem}>
                                        {renderCard(card)}
                                    </li>
                                    <li
                                        className={styles.listItemGap}
                                        role="presentation"
                                    />
                                </Fragment>
                            );
                        }
                        return (
                            <li key={i} className={styles.listItem}>
                                {renderCard(card)}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
};

BlockCardTrioSmall.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    cards: PropTypes.array,
    className: PropTypes.string,
    flushTop: PropTypes.bool,
};

BlockCardTrioSmall.defaultProps = {
    flushTop: false,
};

export default BlockCardTrioSmall;
