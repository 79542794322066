import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import SvgIcon from 'components/ui/SvgIcon';

import Img from 'components/ui/Img';

import styles from './BlockHighlight.module.scss';

const BlockHighlight = ({
    image,
    imageLarge,
    description,
    cta,
    headline,
    imageOnRight,
}) => {
    useEffect(() => {
        if (!headline) {
            dispatchWarning(
                'No Headline provided. Headline required for render.'
            );
        }

        if (!image) {
            dispatchWarning(
                'No default image provided. Image required for render.'
            );
        }

        if (!imageLarge) {
            dispatchWarning(
                'No large image provided. Image required for render.'
            );
        }

        if (!description) {
            dispatchWarning(
                'No description provided. Description required for render.'
            );
        }

        if (!cta) {
            dispatchWarning('No CTA provided. CTA required for render.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (
        headline &&
        image &&
        imageLarge &&
        description &&
        cta?.link &&
        cta?.text
    ) {
        const { height, width } = image.file.details.image;

        return (
            <div
                className={cx(
                    styles.highlight,
                    imageOnRight && styles.imageOnRight
                )}
            >
                {/* Aspect ratio should not change between images. */}
                <div className={styles.imageWrapper}>
                    <div
                        style={{
                            paddingBottom: `${(height / width) * 100}%`,
                        }}
                        className={styles.imageBoxOuter}
                    >
                        <div className={styles.imageBoxInner}>
                            <Img
                                className={styles.image}
                                src={image.file.url}
                                fallbackImageWidth={768}
                                alt={image.description || ''}
                                customSources={[
                                    {
                                        breakpoint: 768,
                                        src: imageLarge.file.url,
                                        imageWidth:
                                            imageLarge.file.details.image.width,
                                    },
                                    {
                                        src: image.file.url,
                                        imageWidth: width,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.cowIcon}>
                        <SvgIcon iconType="cow" />
                    </div>
                    <Text
                        className={styles.headline}
                        baseTheme="headingMedium"
                        themes={{ large: 'headingLarge' }}
                    >
                        {headline}
                    </Text>
                    <div className={styles.arrowIcon}>
                        <SvgIcon iconType="arrowRight" />
                    </div>
                    <Text
                        className={styles.description}
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyMedium' }}
                    >
                        {description}
                    </Text>
                    <Button theme="outlinedBlue" entry={cta.link}>
                        {cta.text}
                    </Button>
                </div>
            </div>
        );
    }

    return null;
};

BlockHighlight.propTypes = {
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                }).isRequired,
            }),
            url: PropTypes.string.isRequired,
        }).isRequired,
    }),
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    cta: PropTypes.shape({
        text: PropTypes.string.isRequired,
        link: PropTypes.object.isRequired,
    }).isRequired,
    imageOnRight: PropTypes.bool,
};

BlockHighlight.defaultProps = {
    imageOnRight: false,
};

export default BlockHighlight;
