import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import raspberry1 from './images/raspberry1.png';
import raspberry2 from './images/raspberry2.png';

import blueberry1 from './images/blueberry1.png';
import blueberry2 from './images/blueberry2.png';
import blueberry3 from './images/blueberry3.png';

import strawberry1 from './images/strawberry1.png';
import strawberry2 from './images/strawberry2.png';

import blackberry1 from './images/blackberry1.png';
import blackberry2 from './images/blackberry2.png';

import styles from './FallingFruit.module.scss';

const MIN_DURATION = 7;
const MAX_DURATION = 10;

const MIN_SCALE = 0.5;
const MAX_SCALE = 1;

const LAYERS = 2;

const sets = {
    RASPBERRY_BLACKBERRY: {
        fruits: [raspberry1, blackberry1, raspberry2, blackberry2],
    },
    STRAWBERRY_PLAIN: {
        fruits: [strawberry1, strawberry2],
    },
    BLUEBERRY_VANILLA: {
        fruits: [blueberry1, blueberry2, blueberry3],
    },
};

const Fruit = ({ i, current, count }) => {
    const [seed1] = useState(Math.random()); // !important, set random seed value to initial state so they don't change on each render
    const [seed2] = useState(Math.random());

    const type = current.fruits[i % current.fruits.length];
    const [currentType, setCurrentType] = useState(type);

    const onAnimationIteration = () => {
        if (currentType !== type) {
            setCurrentType(type);
        }
    };

    const animationDelay = `${(seed2 * MAX_DURATION).toFixed(2)}s`;
    const animationDuration = `${(
        MIN_DURATION +
        seed2 * (MAX_DURATION - MIN_DURATION)
    ).toFixed(2)}s`;

    const style = {
        animationDelay,
        animationDuration,
        left: `${((i / (count / LAYERS)) % 1) * 100}%`,
    };

    const rotation = `${(seed1 * 360).toFixed(2)}deg`;
    const scale = `${(
        MIN_SCALE +
        (1 - seed2) * (MAX_SCALE - MIN_SCALE)
    ).toFixed(2)}`;

    const imageStyle = {
        transform: `rotate(${rotation}) scale(${scale})`,
        backgroundImage: `url(${currentType})`,
    };

    return (
        <div
            onAnimationIteration={onAnimationIteration}
            className={cx(styles.fruit, styles[type])}
            style={style}
        >
            <div style={imageStyle} />
        </div>
    );
};

Fruit.propTypes = {
    i: PropTypes.number.isRequired,
    current: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
};

const FallingFruit = ({ current: currentKey, count }) => {
    const current = sets[currentKey];
    return (
        <div className={styles.container}>
            {[...Array(count)].map((v, i) => {
                return <Fruit key={i} i={i} count={count} current={current} />;
            })}
        </div>
    );
};

FallingFruit.defaultProps = {
    current: 'RASPBERRY_BLACKBERRY',
    count: 100,
};

FallingFruit.propTypes = {
    current: PropTypes.string,
    count: PropTypes.number,
};

export default FallingFruit;
