import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import StoryList from './StoryList';
import TextEyebrow from 'components/ui/TextEyebrow';
import VideoPlayerYT from 'components/ui/VideoPlayerYT';
import styles from './BlockNews.module.scss';

const BlockNews = ({
    headline,
    eyebrow,
    video,
    stories,
    flushTop,
    hasArrowIcon,
}) => {
    return (
        <div
            className={cx(styles.newsBlock, {
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.eyebrowBlock}>
                {headline && (
                    <TextEyebrow
                        eyebrow={eyebrow ? eyebrow : ''}
                        headline={headline}
                        theme="display"
                        icon={hasArrowIcon ? 'arrowRight' : 'none'}
                    />
                )}
            </div>
            {video && (
                <div className={styles.videoBlock}>
                    <VideoPlayerYT {...video} />
                </div>
            )}
            {stories?.length && (
                <div className={styles.storyBlock}>
                    <StoryList stories={stories} />
                </div>
            )}
        </div>
    );
};

BlockNews.propTypes = {
    headline: PropTypes.string,
    eyebrow: PropTypes.string,
    hasArrowIcon: PropTypes.bool,
    video: PropTypes.object,
    stories: PropTypes.array.isRequired,
    flushTop: PropTypes.bool,
};

BlockNews.defaultProps = {
    hasArrowIcon: true,
};

export default BlockNews;
