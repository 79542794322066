import React from 'react';
import Button from 'components/ui/Button';
import Xola from './Xola';

import PropTypes from 'prop-types';

const CTA = ({ cta, ...rest }) => {
    if (cta?.link?.contentTypeId === 'xolaReservationOverlay') {
        return <Xola {...rest} cta={cta} />;
    }

    return (
        <Button.Entry
            iconLeading={cta.assetDownloadLink && 'download'} // sensible default
            {...rest}
            entry={cta.assetDownloadLink || cta.link}
        />
    );
};

CTA.propTypes = {
    cta: PropTypes.object,
};

export default CTA;
