import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import RichText from 'components/ui/RichText';
import TextEyebrow from 'components/ui/TextEyebrow';
import CTA from 'components/ui/CTA';

import styles from './BlockCTAGroup.module.scss';

const CTAButton = ({ cta, theme, iconType }) => (
    <CTA className={styles.cta} theme={theme} iconLeading={iconType} cta={cta}>
        {cta.text}
    </CTA>
);

CTAButton.propTypes = {
    cta: PropTypes.shape({
        assetDownloadLink: PropTypes.object,
        link: PropTypes.shape({
            contentTypeId: PropTypes.string,
        }),
        text: PropTypes.string,
    }),
    iconType: PropTypes.string,
    theme: PropTypes.string,
};

CTAButton.defaultProps = {
    iconType: null,
};

const BlockCTAGroup = ({ headline, eyebrow, copy, ctaButtons, className }) => {
    return (
        <section className={cx(styles.blockCTAGroup, className)}>
            <div className={styles.inner}>
                <div className={styles.eyebrow}>
                    {headline && (
                        <TextEyebrow
                            eyebrow={eyebrow ? eyebrow : ''}
                            richHeadline={headline}
                            theme="display"
                        />
                    )}
                    {copy && (
                        <div className={styles.copy}>
                            <RichText richText={copy} />
                        </div>
                    )}
                    <div className={styles.button}>
                        {ctaButtons?.map(
                            (button, i) =>
                                button.cta && <CTAButton {...button} key={i} />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockCTAGroup.propTypes = {
    className: PropTypes.string,
    copy: PropTypes.object,
    ctaButtons: PropTypes.array,
    eyebrow: PropTypes.string,
    flushTop: PropTypes.bool,
    headline: PropTypes.object,
};

BlockCTAGroup.defaultProps = {
    flushTop: false,
};

export default BlockCTAGroup;
