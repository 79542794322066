import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Link from 'components/ui/Link';

import Img from 'components/ui/Img';

import styles from './CardRecipe.module.scss';

const CardRecipe = ({
    cardImage,
    cardImageLarge,
    category,
    cookTime,
    creator,
    prepTime,
    slug,
    textAlignLeft,
    title,
}) => {
    return (
        <article
            className={cx(styles.recipe, {
                [styles.textAlignLeft]: textAlignLeft,
            })}
        >
            <Link href={`/recipes/${slug}`} className={styles.inner}>
                <div className={styles.aspectBox}>
                    {cardImage?.file?.url && (
                        <Img
                            className={styles.image}
                            src={cardImage.file.url}
                            alt={cardImage.description || ''}
                            customSources={[
                                {
                                    breakpoint: 1440,
                                    src: cardImageLarge?.file?.url
                                        ? cardImageLarge.file.url
                                        : cardImage.file.url,
                                    imageWidth: 1440 * 0.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: cardImageLarge?.file?.url
                                        ? cardImageLarge.file.url
                                        : cardImage.file.url,
                                    imageWidth: 1024 * 0.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: cardImageLarge?.file?.url
                                        ? cardImageLarge.file.url
                                        : cardImage.file.url,
                                    imageWidth: 768 * 0.75,
                                },
                                {
                                    src: cardImage.file.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </div>
                <div className={styles.infoWrapper}>
                    <div className={styles.infoLockup}>
                        <SvgIcon className={styles.timeIcon} iconType="clock" />
                        <Text
                            className={styles.timeLabel}
                            baseTheme="labelMedium"
                            themes={{ large: 'labelLarge' }}
                        >
                            {prepTime + cookTime}MIN
                        </Text>
                        <Text
                            className={styles.separator}
                            baseTheme="labelMedium"
                        >
                            |
                        </Text>
                        <Text
                            className={styles.category}
                            baseTheme="labelMedium"
                            themes={{ large: 'labelLarge' }}
                        >
                            {category.title}
                        </Text>
                    </div>
                    <div className={styles.headlineWrapper}>
                        <Text
                            className={styles.headline}
                            baseTheme="headingSmall"
                            themes={{ xxLarge: 'headingMedium' }}
                            as="h5"
                        >
                            {title}
                        </Text>
                    </div>
                    <Text
                        className={styles.creator}
                        baseTheme="bodySmall"
                        themes={{ xxLarge: 'bodyMedium' }}
                        as="h6"
                    >
                        {creator}
                    </Text>
                </div>
            </Link>
        </article>
    );
};

CardRecipe.propTypes = {
    cardImage: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    cardImageLarge: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    category: PropTypes.shape({
        title: PropTypes.string,
    }),
    cookTime: PropTypes.number,
    creator: PropTypes.string,
    prepTime: PropTypes.number,
    slug: PropTypes.string.isRequired,
    textAlignLeft: PropTypes.bool,
    title: PropTypes.string,
};

CardRecipe.defaultProps = {
    cardImage: {},
    cardImageLarge: {},
    category: {
        title: '',
    },
    cookTime: 0,
    creator: '',
    prepTime: 0,
    textAlignLeft: false,
    title: '',
};

export default CardRecipe;
