import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';
import Stagger from 'components/ui/Stagger';

import styles from './CreameryDiningHours.module.scss';

const iconTypeMap = {
    'Yogurt Cup': 'yogurtCup',
    Utensils: 'utensils',
    'Ice Cream Cone': 'iceCreamCone',
    'Coffee Cup': 'coffeeCup',
};

const CreameryDiningHours = ({ eyebrow, richHeadline, list }) => {
    return (
        <div className={styles.creameryDiningHoursFlex}>
            <div className={styles.creameryDiningHours}>
                <Stagger className={styles.richEyebrow}>
                    {eyebrow && (
                        <Stagger.Child order={0} className={styles.eyebrow}>
                            <Text
                                baseTheme={'labelSmall'}
                                themes={{ medium: 'labelLarge' }}
                                as="h3"
                            >
                                {eyebrow}
                            </Text>
                        </Stagger.Child>
                    )}
                    {richHeadline && (
                        <>
                            <Stagger.Child
                                order={1}
                                className={styles.headline}
                            >
                                {richHeadline.content.map((line, i) => {
                                    if (line.content[0].value === '') {
                                        return null;
                                    }
                                    return (
                                        <Text
                                            key={i}
                                            as="h3"
                                            baseTheme="displayXSmall"
                                            themes={{
                                                medium: 'displayLarge',
                                            }}
                                        >
                                            {line.content[0].value}
                                        </Text>
                                    );
                                })}
                            </Stagger.Child>
                            <Stagger.ArrowRight
                                theme="smallMobile"
                                order={2}
                                className={styles.iconWrapper}
                            />
                        </>
                    )}
                </Stagger>
                {list && (
                    <ul className={styles.list}>
                        {list.map((item, i) => {
                            return (
                                <li key={i} className={styles.listItem}>
                                    <div className={styles.listItemLabel}>
                                        <div
                                            className={styles.listItemLabelIcon}
                                        >
                                            <div
                                                className={cx(
                                                    styles.listItemLabelIconInner,
                                                    styles[
                                                        iconTypeMap[item.icon]
                                                    ]
                                                )}
                                            >
                                                <SvgIcon
                                                    iconType={
                                                        iconTypeMap[item.icon]
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <Text
                                            as="div"
                                            baseTheme="labelSmall"
                                            themes={{
                                                xxLarge: 'labelLarge',
                                            }}
                                            className={styles.listItemLabelText}
                                        >
                                            {item.label}
                                        </Text>
                                    </div>
                                    <Text
                                        as="div"
                                        baseTheme="bodySmall"
                                        className={styles.listItemValue}
                                    >
                                        {item.value}
                                    </Text>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
};

CreameryDiningHours.propTypes = {
    eyebrow: PropTypes.string,
    richHeadline: PropTypes.object,
    list: PropTypes.array,
    className: PropTypes.string,
};

CreameryDiningHours.defaultProps = {
    className: null,
};

export default CreameryDiningHours;
