import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { wrap } from 'popmotion';
import { useTheme } from 'hooks/useTheme';

import ButtonCircle from 'components/ui/ButtonCircle';
import Text from 'components/ui/Text';

import CoverSlide from './CoverSlide';
import FlavorPairings from '../shared/FlavorPairings';

import styles from './BlockProductPairingsSlides.module.scss';

const Controls = ({ slideIndex, onPaginate, total }) => {
    const [globalTheme] = useTheme();

    return (
        <div
            className={cx(
                styles.paginationControls,
                styles[`theme--${globalTheme}`],
                {
                    [styles[`paginationControls--show`]]: slideIndex !== 0,
                }
            )}
        >
            <div className={styles.paginationControlsInner}>
                <ButtonCircle
                    theme="chevronLeftWhite"
                    onClick={() => {
                        onPaginate(-1);
                    }}
                    className={cx(styles.paginationButton, styles.buttonPrev)}
                />
                <Text
                    as="div"
                    baseTheme="labelSmallAlt"
                    className={styles.paginationCount}
                >
                    {`${slideIndex + 1} / ${total}`}
                </Text>
                <ButtonCircle
                    theme="chevronRightWhite"
                    onClick={() => {
                        onPaginate(1);
                    }}
                    className={cx(styles.paginationButton, styles.buttonNext)}
                />
            </div>
        </div>
    );
};

Controls.propTypes = {
    slideIndex: PropTypes.number.isRequired,
    onPaginate: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
};

const BlockProductPairingsSlides = ({
    slides = [],
    flushTop,
    flushBottom,
    textColor,
    ...props
}) => {
    const [[page, direction], setPage] = useState([0, 0]);
    const slideIndex = wrap(0, slides.length + 1, page);

    const paginate = newDirection => {
        setPage([page + newDirection, newDirection]);
    };

    return (
        <div
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.slides}>
                    <div
                        className={cx(styles.slide, {
                            [styles['slide-active']]: slideIndex === 0,
                            [styles['slide-prev']]:
                                direction > 0 && slideIndex === 1,
                        })}
                    >
                        <CoverSlide
                            {...props}
                            onClickStart={() => {
                                paginate(1);
                            }}
                            textColor={textColor}
                        />
                    </div>
                    {slides.map((slide, i) => {
                        return (
                            <div
                                key={i}
                                className={cx(styles.slide, {
                                    [styles['slide-active']]:
                                        slideIndex === i + 1,
                                    [styles['slide-prev']]:
                                        direction > 0
                                            ? slideIndex === i + 2
                                            : slideIndex === i,
                                })}
                            >
                                <FlavorPairings
                                    {...slides[i]}
                                    textColor={textColor}
                                    controls={
                                        <Controls
                                            slideIndex={slideIndex}
                                            onPaginate={paginate}
                                            total={slides.length + 1}
                                        />
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

BlockProductPairingsSlides.propTypes = {
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    slides: PropTypes.array,
    textColor: PropTypes.string,
};

BlockProductPairingsSlides.defaultProps = {
    slides: [],
    textColor: 'cream',
};

export default BlockProductPairingsSlides;
