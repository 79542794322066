import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import FlavorFinderCard from './FlavorFinderCard';
import FlavorFinderVoteButton from './FlavorFinderVoteButton';

import { data } from '../cream-cheese-finder-data';

import styles from './FlavorFinderCardSwiper.module.scss';

const FlavorFinderCardSwiper = ({
    isPrompt,
    isInteractive,
    cardSet,
    cardSetComplete,
    sendFlavorPoint,
    isMedium,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [prevIndex, setPrevIndex] = useState(activeIndex);
    const [isForceYes, setIsForceYes] = useState(false);
    const [isForceNo, setIsForceNo] = useState(false);
    const [hintYes, setHintYes] = useState(false);
    const [hintNo, setHintNo] = useState(false);
    const [willBeYes, setWillBeYes] = useState(false);
    const [willBeNo, setWillBeNo] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const totalCards = data?.[cardSet]?.cards.length - 1;

    const advance = () => {
        if (activeIndex === totalCards) {
            cardSetComplete();
        }
        setActiveIndex(activeIndex + 1);
        setPrevIndex(activeIndex);

        setHintYes(false);
        setHintNo(false);
    };

    const handleYes = () => {
        // check active card and store point
        const flavor = data[cardSet].cards[activeIndex].flavor;
        sendFlavorPoint(flavor);
        advance();
    };

    const handleNo = () => {
        advance();
    };

    const forceYes = () => {
        handleYes();
        setIsForceYes(true);
        setIsForceNo(false);
    };

    const forceNo = () => {
        handleNo();
        setIsForceNo(true);
        setIsForceYes(false);
    };

    const handleDrag = () => {
        setIsForceYes(false);
        setIsForceNo(false);

        setIsDragging(true);
    };

    const handleDragEnd = () => {
        setIsDragging(false);
    };

    const handleHintYes = isHintYes => {
        setHintYes(isHintYes);
    };

    const handleHintNo = isHintNo => {
        setHintNo(isHintNo);
    };

    const handleOnYes = isYes => {
        setWillBeYes(isYes);

        if (isYes) {
            setHintNo(false);
        }
    };

    const handleOnNo = isNo => {
        setWillBeNo(isNo);

        if (isNo) {
            setHintYes(false);
        }
    };

    useEffect(() => {
        // reset to zero during next question
        isPrompt && setActiveIndex(0);
    }, [isPrompt]);

    const handleVote = vote => {
        vote === 'No' ? handleNo() : handleYes();
    };

    return (
        <div
            className={cx(styles.root, {
                [styles.isPrompt]: isPrompt,
                [styles.isInteractive]: isInteractive,
                [styles.isDragging]: isDragging,
            })}
        >
            <div className={styles.inner}>
                <FlavorFinderVoteButton
                    type="No"
                    hint={hintNo}
                    willBePicked={willBeNo}
                    willNotBePicked={willBeYes}
                    onClick={forceNo}
                />
                <div className={styles.cards}>
                    {data?.[cardSet]?.cards.map((card, i) => {
                        return (
                            <FlavorFinderCard
                                key={i}
                                isTop={activeIndex === i}
                                isMiddle={activeIndex === i - 1}
                                isBottom={activeIndex === i - 2}
                                isInteractive={isInteractive}
                                onVote={handleVote}
                                onDrag={activeIndex === i ? handleDrag : null}
                                onDragEnd={
                                    activeIndex === i ? handleDragEnd : null
                                }
                                onHintYes={
                                    activeIndex === i ? handleHintYes : null
                                }
                                onHintNo={
                                    activeIndex === i ? handleHintNo : null
                                }
                                onYes={activeIndex === i ? handleOnYes : null}
                                onNo={activeIndex === i ? handleOnNo : null}
                                forceYes={prevIndex === i && isForceYes}
                                forceNo={prevIndex === i && isForceNo}
                                isMedium={isMedium}
                                {...card}
                            />
                        );
                    })}
                    <div
                        className={styles.cardOpacityCover}
                        style={{ backgroundColor: data?.[cardSet]?.color }}
                        role="presentation"
                    />
                </div>
                <FlavorFinderVoteButton
                    type="Yes"
                    hint={hintYes}
                    willBePicked={willBeYes}
                    willNotBePicked={willBeNo}
                    onClick={forceYes}
                />
            </div>
        </div>
    );
};

FlavorFinderCardSwiper.propTypes = {
    isPrompt: PropTypes.bool,
    isInteractive: PropTypes.bool,
    cardSet: PropTypes.number,
    cardSetComplete: PropTypes.func,
    sendFlavorPoint: PropTypes.func,
    isMedium: PropTypes.bool,
};

export default FlavorFinderCardSwiper;
