import { useState } from 'react';
import { useUnmount } from 'react-use';

export const useWait = () => {
    const [timeoutIds, setTimeoutIds] = useState([]);

    useUnmount(() => {
        timeoutIds.map(clearTimeout);
    });

    return function wait(ms) {
        let timeoutId;
        const promise = new Promise(resolve => {
            timeoutId = setTimeout(resolve, ms);
        });
        setTimeoutIds([...timeoutIds, timeoutId]);
        return promise;
    };
};

export default useWait;
