import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useMeasure } from 'react-use';
import { useTheme } from 'hooks/useTheme';
import { AnimatePresence, motion } from 'framer-motion';

import Text from 'components/ui/Text';

import { easing, timing } from '../animations';

import styles from './Accordion.module.scss';

const Accordion = ({
    title,
    children,
    activeStep,
    setActive,
    index,
    pagination,
}) => {
    const [isOpen, setOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const [isDecreasing, setIsDecreasing] = useState(false);
    const [theme] = useTheme();

    const [heightRef, { height: measureHeight }] = useMeasure();

    const isActive = activeStep === index;
    useEffect(() => {
        getHeight();
    }, [measureHeight]);

    const getHeight = () => {
        setHeight(measureHeight);
    };

    const handleToggleClick = i => {
        setIsDecreasing(activeStep > i);
        setActive(i);
    };

    useEffect(() => {
        setOpen(isActive);
    }, [isActive]);

    const rootVariants = {
        initial: {
            opacity: index < activeStep ? 1 : 0,
            y: index < activeStep ? 0 : 100,
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: timing.base,
                delay: timing.base * 0.8,
                ease: easing.opacity,
            },
        },
        exit: {
            opacity: 0,
            y: index >= activeStep ? 100 : 0,
            transition: {
                duration: timing.base,
                ease: easing.scale,
            },
        },
    };

    const panelVariants = {
        initial: {
            opacity: 0,
            height: height,
        },
        openAccordion: {
            height: height,
            transition: { duration: timing.base, ease: easing.height },
        },
        closeAccordion: {
            height: 0,
            transition: { duration: timing.base, ease: easing.height },
        },
        fadeIn: {
            opacity: 1,
            transition: { duration: timing.base, delay: timing.base },
        },
        exit: {
            opacity: 0,
            height: activeStep > index ? 0 : height,
            transition: {
                duration: timing.base,
                ease: easing.height,
            },
        },
    };

    const buttonVariants = {
        initial: { opacity: 0 },
        animate: {
            opacity: 1,
            transition: {
                duration: timing.base,
                delay: isDecreasing
                    ? timing.base * 0.25
                    : isActive
                    ? timing.base + index * 0.25
                    : timing.base * 0.125,
            },
        },
        exit: {
            opacity: 0,
            transition: { duration: timing.base },
        },
    };

    return (
        <motion.div
            className={cx(styles.accordion, styles[`theme--${theme}`])}
            variants={rootVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <div className={styles.accordionInner}>
                <AnimatePresence exitBeforeEnter>
                    <motion.button
                        key={title}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={buttonVariants}
                        onClick={() => handleToggleClick(index)}
                        className={cx(styles.accordionToggle, {
                            [styles.isClickable]: !isOpen,
                        })}
                    >
                        {pagination && (
                            <Text.Theme
                                className={styles.pagination}
                                config={{
                                    default: {
                                        baseTheme: 'labelMedium',
                                        themes: { large: 'labelLarge' },
                                    },
                                }}
                            >
                                {pagination}
                            </Text.Theme>
                        )}
                        <Text.Theme
                            className={styles.label}
                            config={{
                                default: {
                                    baseTheme: 'labelMedium',
                                    themes: { large: 'labelLarge' },
                                },
                            }}
                        >
                            {title}
                        </Text.Theme>
                    </motion.button>
                </AnimatePresence>
                <motion.div
                    className={styles.panel}
                    variants={panelVariants}
                    initial="initial"
                    animate={
                        isOpen ? ['openAccordion', 'fadeIn'] : 'closeAccordion'
                    }
                    exit="exit"
                >
                    <div className={styles.panelInner}>
                        <div ref={heightRef} className={styles.panelContent}>
                            {children}
                        </div>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
};

Accordion.propTypes = {
    accordionInfo: PropTypes.array,
    activeStep: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    index: PropTypes.number,
    isActive: PropTypes.bool,
    pagination: PropTypes.string,
    setActive: PropTypes.func,
    title: PropTypes.string,
};

Accordion.defaultProps = { accordionInfo: [] };

export default Accordion;
