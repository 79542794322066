import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './BlockHeader.module.scss';

const BlockHeader = ({ headline, subheadline }) => {
    useEffect(() => {
        if (!headline) {
            dispatchWarning(
                'No headline provided. Headline required for render.'
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (headline) {
        return (
            <header className={styles.blockHeader}>
                <Text
                    as="h1"
                    baseTheme="displayXSmall"
                    themes={{ large: 'displayLarge' }}
                    className={styles.headline}
                >
                    {headline}
                </Text>
                <div className={styles.iconWrapper}>
                    <SvgIcon iconType="arrowRight" />
                </div>
                {subheadline && (
                    <Text
                        as="h2"
                        baseTheme="headingXSmall"
                        themes={{ large: 'headingMedium' }}
                        className={styles.subheadline}
                    >
                        {subheadline}
                    </Text>
                )}
            </header>
        );
    }

    return null;
};

BlockHeader.propTypes = {
    headline: PropTypes.string.isRequired,
    subheadline: PropTypes.string,
};

BlockHeader.defaultProps = {
    subheadline: '',
};

export default BlockHeader;
