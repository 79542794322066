import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TouchSwipe extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
        onTap: PropTypes.func,
        onSwipedUp: PropTypes.func,
        onSwipedDown: PropTypes.func,
        onSwipedLeft: PropTypes.func,
        onSwipedRight: PropTypes.func,
        mouse: PropTypes.bool,
    };

    touchSwipe = {
        fingerCount: 0,
        startX: 0,
        startY: 0,
        curX: 0,
        curY: 0,
        minLength: 52,
        swipeLength: 0,
        swipeAngle: 0,
        swipeDirection: 'none',
    };

    state = {
        mouseDown: false,
    };

    handleMouseDown = event => {
        const { touchSwipe } = this;

        this.setState({
            mouseDown: true,
        });

        touchSwipe.fingerCount = 1;
        touchSwipe.startX = event.pageX;
        touchSwipe.startY = event.pageY;
    };

    handleMouseMove = event => {
        const { touchSwipe } = this;

        if (this.state.mouseDown) {
            touchSwipe.curX = event.pageX;
            touchSwipe.curY = event.pageY;
        }
    };

    handleMouseLeave = () => {
        if (this.state.mouseDown) {
            this.handleTouchEnd();
        }
    };

    handleTouchStart = event => {
        const { touchSwipe } = this;

        touchSwipe.fingerCount = event.touches.length;
        if (touchSwipe.fingerCount === 1) {
            touchSwipe.startX = event.touches[0].pageX;
            touchSwipe.startY = event.touches[0].pageY;
        } else {
            this.handleTouchCancel(event);
        }
    };

    handleTouchMove = event => {
        const { touchSwipe } = this;

        if (event.touches.length === 1) {
            touchSwipe.curX = event.touches[0].pageX;
            touchSwipe.curY = event.touches[0].pageY;
        } else {
            this.handleTouchCancel(event);
        }
    };

    handleTouchEnd = event => {
        const { mouse } = this.props;
        const { touchSwipe } = this;

        if (mouse) {
            this.setState({
                mouseDown: false,
            });
        }

        // if one finger
        if (touchSwipe.fingerCount === 1 && touchSwipe.curX !== 0) {
            // distance formula
            touchSwipe.swipeLength = Math.round(
                Math.sqrt(
                    Math.pow(touchSwipe.curX - touchSwipe.startX, 2) +
                        Math.pow(touchSwipe.curY - touchSwipe.startY, 2)
                )
            );
            if (touchSwipe.swipeLength >= touchSwipe.minLength) {
                this.caluculateAngle();
                this.determineSwipeDirection();
                this.processingRoutine();
                this.handleTouchCancel(event); // reset the variables
            } else if (touchSwipe.swipeLength <= 5) {
                // lazy tap
                if (this.props.onTap) {
                    this.props.onTap();
                }
                this.handleTouchCancel(event);
            } else {
                this.handleTouchCancel(event);
            }
        } else {
            if (this.props.onTap) {
                this.props.onTap();
            }
            event?.preventDefault(); // fix for double on touch end events
            this.handleTouchCancel(event);
        }
    };

    handleTouchCancel = () => {
        const { touchSwipe } = this;
        touchSwipe.fingerCount = 0;
        touchSwipe.startX = 0;
        touchSwipe.startY = 0;
        touchSwipe.curX = 0;
        touchSwipe.curY = 0;
        touchSwipe.swipeLength = 0;
        touchSwipe.swipeAngle = 0;
        touchSwipe.swipeDirection = 'none';
    };

    caluculateAngle = () => {
        const { touchSwipe } = this;

        const X = touchSwipe.startX - touchSwipe.curX;
        const Y = touchSwipe.curY - touchSwipe.startY;
        const r = Math.atan2(Y, X); // angle in radians (Cartesian system)
        touchSwipe.swipeAngle = Math.round((r * 180) / Math.PI); // angle in degrees
        if (touchSwipe.swipeAngle < 0) {
            touchSwipe.swipeAngle = 360 - Math.abs(touchSwipe.swipeAngle);
        }
    };

    determineSwipeDirection = () => {
        const { touchSwipe } = this;

        if (touchSwipe.swipeAngle <= 45 && touchSwipe.swipeAngle >= 0) {
            touchSwipe.swipeDirection = 'left';
        } else if (
            touchSwipe.swipeAngle <= 360 &&
            touchSwipe.swipeAngle >= 315
        ) {
            touchSwipe.swipeDirection = 'left';
        } else if (
            touchSwipe.swipeAngle >= 135 &&
            touchSwipe.swipeAngle <= 225
        ) {
            touchSwipe.swipeDirection = 'right';
        } else if (touchSwipe.swipeAngle > 45 && touchSwipe.swipeAngle < 135) {
            touchSwipe.swipeDirection = 'down';
        } else {
            touchSwipe.swipeDirection = 'up';
        }
    };

    processingRoutine = () => {
        const { touchSwipe } = this;
        switch (touchSwipe.swipeDirection) {
            case 'up':
                if (this.props.onSwipedUp) {
                    this.props.onSwipedUp();
                }
                break;
            case 'down':
                if (this.props.onSwipedDown) {
                    this.props.onSwipedDown();
                }
                break;
            case 'left':
                if (this.props.onSwipedLeft) {
                    this.props.onSwipedLeft();
                }
                break;
            case 'right':
                if (this.props.onSwipedRight) {
                    this.props.onSwipedRight();
                }
                break;
        }
    };

    render() {
        const { mouseDown } = this.state;
        const { mouse } = this.props;

        const cleanProps = { ...this.props };

        delete cleanProps.onTap;
        delete cleanProps.onSwipedUp;
        delete cleanProps.onSwipedDown;
        delete cleanProps.onSwipedLeft;
        delete cleanProps.onSwipedRight;
        delete cleanProps.mouse;

        return (
            <div
                className={this.props.className ? this.props.className : null}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onTouchEnd={this.handleTouchEnd}
                onTouchCancel={this.handleTouchCancel}
                onMouseDown={mouse ? this.handleMouseDown : null}
                onMouseUp={mouse ? this.handleTouchEnd : null}
                onMouseLeave={mouse ? this.handleMouseLeave : null}
                onMouseMove={mouse && mouseDown ? this.handleMouseMove : null}
                style={{
                    touchAction: 'manipulation',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
                {...cleanProps}
            >
                {this.props.children}
            </div>
        );
    }
}

export default TouchSwipe;
