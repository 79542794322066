import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CardCTA from 'components/cards/CardCTA';

import GridRow from './GridRow';

import useMediaQuery from 'hooks/useMediaQuery';

import styles from './BlockGrid.module.scss';

const BlockGrid = ({
    gridRow,
    flushTop,
    flushBottom,
    flushBottomMobile,
    fullWidth,
    ctaCard,
}) => {
    const isMedium = useMediaQuery('(min-width: 768px)');

    return (
        <div
            className={cx(styles.blockGrid, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.flushBottomMobile]: flushBottomMobile,
                [styles.fullWidth]: fullWidth,
            })}
        >
            <div className={styles.blockGridInner}>
                {gridRow &&
                    gridRow.map((gridItem, key) => {
                        return <GridRow {...gridItem} key={key} />;
                    })}
                <div className={styles.ctaCardWrapper}>
                    {ctaCard && <CardCTA isLandscape={isMedium} {...ctaCard} />}
                </div>
            </div>
        </div>
    );
};
BlockGrid.propTypes = {
    gridRow: PropTypes.array,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    flushBottomMobile: PropTypes.bool,
    fullWidth: PropTypes.bool,
    ctaCard: PropTypes.object,
};
BlockGrid.defaultProps = {
    flushTop: true,
    flushBottom: false,
    flushBottomMobile: false,
    fullWidth: false,
    ctaCard: null,
};

export default BlockGrid;
