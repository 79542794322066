import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import Img from 'components/ui/Img';

import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';
import RichText from 'components/ui/RichText';

import styles from './BlockFanStory.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    className={styles.bodyCopy}
                    as="p"
                    baseTheme="bodySmall"
                    themes={{ xxLarge: 'bodyMedium' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockFanStory = ({
    blockEyebrow,
    blockHeadline,
    title,
    richText,
    author,
    image,
    imageOnLeft,
    flushTop,
    flushBottom,
}) => {
    useEffect(() => {
        if (!title) {
            dispatchWarning('No title provided. Title required for render.');
        }

        if (!image) {
            dispatchWarning('No image provided. Image required for render.');
        }

        if (!richText) {
            dispatchWarning(
                'No rich text provided. Rich text required for render.'
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (title && richText && image?.file?.url) {
        return (
            <div
                className={cx(styles.root, {
                    [styles.flushTop]: flushTop,
                    [styles.flushBottom]: flushBottom,
                })}
            >
                {blockEyebrow && blockHeadline && (
                    <div className={styles.eyebrow}>
                        <TextEyebrow
                            headline={blockHeadline}
                            eyebrow={blockEyebrow}
                            theme="display"
                        />
                    </div>
                )}
                <div
                    className={cx(
                        styles.content,
                        imageOnLeft && styles.reverse
                    )}
                >
                    <div className={styles.copyWrapper}>
                        <Text
                            baseTheme="headingMedium"
                            themes={{ xxLarge: 'headingLarge' }}
                        >
                            {title}
                        </Text>
                        <RichText
                            overrides={richTextOverrides}
                            richText={richText}
                        />
                        {author && (
                            <>
                                <div className={styles.divider}></div>
                                <Text
                                    className={styles.author}
                                    baseTheme="headingSmall"
                                >
                                    {author}
                                </Text>
                            </>
                        )}
                    </div>
                    <div className={styles.imgWrapper}>
                        <div className={styles.imgBox}>
                            <Img
                                className={styles.image}
                                src={image.file.url}
                                fallbackImageWidth={257}
                                alt={image.description || ''}
                                customSources={[
                                    { breakpoint: 1024, imageWidth: 624 },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

BlockFanStory.propTypes = {
    blockEyebrow: PropTypes.string,
    blockHeadline: PropTypes.string,
    title: PropTypes.string.isRequired,
    richText: PropTypes.object.isRequired,
    author: PropTypes.string,
    image: PropTypes.object.isRequired,
    imageOnLeft: PropTypes.bool,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

BlockFanStory.defaultProps = {
    blockEyebrow: '',
    blockHeadline: '',
    author: '',
    imageOnLeft: false,
    flushTop: false,
    flushBottom: false,
};

export default BlockFanStory;
