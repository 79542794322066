import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'components/ui/Link';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './Button.module.scss';

const Button = ({
    children,
    circleIcon,
    circleTheme,
    className,
    disabled,
    entry,
    href,
    iconLeading,
    iconTrailing,
    onClick,
    theme: buttonThemeProp,
    themeOverride,
    ...props
}) => {
    const font = buttonThemeProp.split('-')?.[1]
        ? buttonThemeProp.split('-')[1]
        : 'default';

    const buttonTheme = buttonThemeProp.split('-')[0];

    const defaultFonts = {
        ultrabold: {
            baseTheme: 'headingXSmall2',
            themes: {
                large: 'headingSmall2',
            },
        },
        default: {
            baseTheme: { listItem: 'bodyMedium' }[buttonTheme] || 'labelSmall',
            themes: {
                large: { listItem: '' }[buttonTheme] || 'labelLarge',
            },
        },
    };

    const renderInner = () => {
        if (buttonThemeProp == 'none') {
            return children;
        }
        return !circleTheme ? (
            <Text.Theme
                themeOverride={themeOverride}
                config={{
                    'makers-reserve': {
                        baseTheme: 'labelSmallAlt',
                        themes: {
                            large: 'labelMediumAlt',
                        },
                    },
                    default: defaultFonts[font],
                }}
                className={styles.text}
            >
                {iconLeading && typeof iconLeading === 'string' && (
                    <span className={styles.iconComponent}>
                        <SvgIcon iconType={iconLeading} />
                    </span>
                )}
                {iconLeading &&
                    isValidElement(iconLeading) &&
                    typeof iconTrailing !== 'string' && (
                        <span className={styles.iconComponent}>
                            {iconLeading}
                        </span>
                    )}
                <span className={styles.inner}>{children}</span>
                {iconTrailing && typeof iconTrailing === 'string' && (
                    <span
                        className={cx(
                            styles.iconComponent,
                            styles.iconComponentTrailing
                        )}
                    >
                        <SvgIcon iconType={iconTrailing} />
                    </span>
                )}
                {iconLeading &&
                    isValidElement(iconTrailing) &&
                    typeof iconTrailing !== 'string' && (
                        <span
                            className={cx(
                                styles.iconComponent,
                                styles.iconComponentTrailing
                            )}
                        >
                            {iconTrailing}
                        </span>
                    )}
            </Text.Theme>
        ) : (
            <>
                <SvgIcon
                    className={styles[`iconCircle--${circleIcon}`]}
                    iconType={circleIcon}
                />
                <span className="sr-only">
                    {!children && `Button ${circleTheme}`}
                </span>
                {children}
            </>
        );
    };

    const containsLink = entry || href;
    const Tag = containsLink ? 'span' : 'button';

    const renderShell = () => (
        <Tag
            gtm-id={props['gtm-id']}
            gtm-label={props['gtm-label']}
            data-button-id={props['data-button-id']}
            tabIndex={props['tabIndex']}
            onClick={onClick}
            disabled={disabled}
            className={cx(
                styles.buttonResets,
                styles.base,
                styles[circleTheme ? `circle--${circleTheme}` : buttonTheme],
                styles[font ? `font-${font}` : null],
                {
                    [className]: className && !href && !entry,
                    [styles[`hasIconLeading`]]: iconLeading,
                    [styles[`hasIconTrailing`]]: iconTrailing,
                    [styles.disabled]: disabled,
                }
            )}
        >
            {renderInner()}
        </Tag>
    );

    const LinkComponent = entry ? Link?.Entry || Link : Link;

    return containsLink ? (
        <LinkComponent
            {...props}
            href={href}
            entry={entry}
            className={cx(styles.isLink, className && className, {
                [styles.disabled]: disabled,
            })}
        >
            {renderShell()}
        </LinkComponent>
    ) : (
        renderShell()
    );
};

Button.propTypes = {
    as: PropTypes.string,
    children: PropTypes.node,
    circleIcon: PropTypes.string,
    circleTheme: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    entry: PropTypes.object,
    href: PropTypes.string,
    iconLeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    iconTrailing: PropTypes.string,
    onClick: PropTypes.func,
    theme: PropTypes.oneOf([
        '',
        'none',
        'close',
        'filledBlue',
        'filledBlueHoverCream',
        'filledBlueHoverCream-ultrabold',
        'filledBlueHoverStrawberry',
        'filledBlueHoverStrawberry-ultrabold',
        'filledBlueHoverWhite',
        'filledBlueHoverWhite-ultrabold',
        'filledCream',
        'filledCreamReserve',
        'filledCreamHoverWhite',
        'filledCreamHoverWhite-ultrabold',
        'filledGoldReserveCream',
        'filledGoldReserveCreamWithBlackHover',
        'filledCreamDark',
        'filledCreamInverted',
        'filledOrange',
        'filledOrangeCreamText',
        'filledOrangeCreamText-ultrabold',
        'filledOrangeHoverCream-ultrabold',
        'outlinedBlue',
        'outlinedCream',
        'outlinedWhite',
        'outlinedReserve',
        'outlinedCreamReserve',
    ]),
    themeOverride: PropTypes.string,
    'gtm-id': PropTypes.string,
    'gtm-label': PropTypes.string,
    'data-button-id': PropTypes.string,
    tabIndex: PropTypes.number,
};

Button.defaultProps = {
    children: null,
    disabled: false,
    onClick: () => {},
    theme: 'outlinedBlue',
};

Button.Entry = props => {
    return <Button {...props}></Button>;
};

Button.Entry.propTypes = {
    entry: PropTypes.object,
};

export default Button;
