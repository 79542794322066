import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Button from 'components/ui/Button';
import FlavorFinderShare from '../FlavorFinderShare';
import FlavorFinderBgSpinner from '../FlavorFinderBgSpinner';

import { wait } from 'utils';

import Img from 'components/ui/Img';

import { profiles } from 'components/blocks/BlockCreamCheeseFlavorFinder/cream-cheese-profiles';

import styles from './FlavorFinderPayoff.module.scss';

const FlavorFinderPayoff = ({ show, profileId, tryAgain, learnMore }) => {
    const [isLoading, setIsLoading] = useState(false);

    const activeProfile = profiles.find(item => item.id === profileId);

    useEffect(() => {
        show &&
            wait(4000).then(() => {
                setIsLoading(false);
            });
    }, [show]);

    useEffect(() => {
        profileId && setIsLoading(true);
    }, [profileId]);

    const handleTryAgain = () => {
        tryAgain();
    };

    const handleLearnMore = () => {
        learnMore();
    };

    return (
        <div
            className={cx(styles.root, {
                [styles.show]: show,
                [styles.showContent]: !isLoading,
            })}
        >
            <div className={styles.spinnerShift}>
                <FlavorFinderBgSpinner
                    isSpinning={!isLoading}
                    renderSvgs={false}
                    type={activeProfile?.id}
                />
            </div>
            <div className={styles.inner}>
                <div className={styles.content}>
                    <Text
                        className={styles.headline}
                        baseTheme="displaySmall"
                        themes={{ large: 'displayMedium' }}
                    >
                        {activeProfile?.label}
                    </Text>
                    <Text
                        className={styles.subheadline}
                        baseTheme="bodyMedium"
                        themes={{ large: 'bodyLarge' }}
                    >
                        {activeProfile?.payoff?.copy}
                    </Text>
                    <Button
                        onClick={handleLearnMore}
                        className={styles.learnMore}
                        theme="filledBlueHoverCream-ultrabold"
                        gtm-label={`Check My Profile (${activeProfile?.label})`}
                    >
                        Check My Profile
                    </Button>
                    <Button
                        href="https://share.promotionpod.com/share/d83a5ad41ecc76daeb9210f0247938b744975d15/"
                        target="_blank"
                        className={styles.coupon}
                        theme="filledCream"
                    >
                        Get your $1 off coupon
                    </Button>
                    <Button
                        onClick={handleTryAgain}
                        className={styles.tryAgain}
                        theme=""
                    >
                        <span className={styles.tryAgainSvg}>
                            <svg viewBox="0 0 25 22">
                                <path
                                    d="M24.5 9.42855C24.5 14.6274 20.624 18.8572 15.86 18.8572H7.13744L8.37872 20.2118C8.7536 20.6209 8.7536 21.2841 8.37872 21.6932C8.00432 22.1023 7.39568 22.1023 7.02128 21.6932L4.14128 18.5503C3.7664 18.1412 3.7664 17.478 4.14128 17.0689L7.02128 13.926C7.39568 13.5169 8.00432 13.5169 8.37872 13.926C8.7536 14.3351 8.7536 14.9983 8.37872 15.4074L7.13744 16.762H15.86C19.5661 16.762 22.58 13.4729 22.58 9.42855C22.58 8.85026 23.0101 8.38092 23.54 8.38092C24.0699 8.38092 24.5 8.85026 24.5 9.42855ZM9.14 5.23803H17.8626L16.6213 6.59261C16.2464 7.00171 16.2464 7.66487 16.6213 8.07397C16.9933 8.48045 17.6024 8.48568 17.9787 8.07397L20.8587 4.93107C21.2336 4.52197 21.2336 3.85882 20.8587 3.44972L17.9787 0.306825C17.6043 -0.102275 16.9957 -0.102275 16.6213 0.306825C16.2464 0.715925 16.2464 1.37908 16.6213 1.78818L17.8626 3.14276H9.14C4.376 3.14276 0.5 7.37258 0.5 12.5714C0.5 13.1497 0.93008 13.6191 1.46 13.6191C1.98992 13.6191 2.42 13.1497 2.42 12.5714C2.42 8.52759 5.4344 5.23803 9.14 5.23803Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </span>
                        <span className={styles.tryAgainText}>Try Again</span>
                    </Button>
                    <FlavorFinderShare show={show} />
                </div>
                <div className={styles.imageWrap}>
                    <div className={styles.imageWrapInner}>
                        <div className={styles.textSvg} />
                        <div className={styles.heartSvg} />
                        <Img
                            className={styles.image}
                            src={activeProfile?.payoff?.image}
                            fallbackImageWidth={768}
                            alt={''}
                            customSources={[
                                {
                                    breakpoint: 1024,
                                    src: activeProfile?.payoff?.image,
                                    imageWidth: 700 * 1.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: activeProfile?.payoff?.image,
                                    imageWidth: 600 * 1.5,
                                },
                                {
                                    src: activeProfile?.payoff?.image,
                                    imageWidth: 375 * 1.5,
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
            <FlavorFinderBgSpinner isSpinning={isLoading} />
        </div>
    );
};

FlavorFinderPayoff.propTypes = {
    show: PropTypes.bool,
    profileId: PropTypes.string,
    tryAgain: PropTypes.func,
    learnMore: PropTypes.func,
};

export default FlavorFinderPayoff;
