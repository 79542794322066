import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Link from 'components/ui/Link';
import SvgIcon from 'components/ui/SvgIcon';
import RichText from 'components/ui/RichText';

import { BLOCKS } from '@contentful/rich-text-types';

import styles from './CardIconStat.module.scss';

const iconMap = {
    Ribbon: 'cardRibbon',
    Crops: 'cardCrops',
    Air: 'cardAir',
    Car: 'cardCar',
    'The Creamery': 'cardTheCreamery',
};

const CardIconStat = ({ icon, stat, copy, cta }) => {
    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        className={styles.paragraph}
                        baseTheme="bodyMedium"
                        themes={{ medium: 'bodyLarge' }}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    return (
        <Link.Entry className={styles.cardLinkWrap} entry={cta?.link}>
            <article className={styles.cardIconStat}>
                <div className={styles.inner}>
                    {icon && (
                        <div className={cx(styles.icon, styles[iconMap[icon]])}>
                            <SvgIcon iconType={iconMap[icon]} />
                        </div>
                    )}
                    {stat && (
                        <Text
                            className={styles.stat}
                            baseTheme="displayMedium"
                            themes={{ medium: 'displayLarge' }}
                            as="h3"
                        >
                            {stat}
                        </Text>
                    )}
                    {copy && (
                        <div className={styles.copy}>
                            <RichText
                                richText={copy}
                                overrides={richTextOverrides}
                            />
                        </div>
                    )}
                </div>
            </article>
        </Link.Entry>
    );
};

CardIconStat.propTypes = {
    icon: PropTypes.string,
    stat: PropTypes.string,
    copy: PropTypes.object,
    cta: PropTypes.object,
};

CardIconStat.defaultProps = {
    cta: null,
};

export default CardIconStat;
