import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useMedia } from 'react-use';
import { useTheme } from 'hooks/useTheme';

import AnimatedPolyLine from './AnimatedPolyLine';
import styles from './FlavorProfileGraph.module.scss';

const toFixedNumber =
    (toFixTo = 2, base = 10) =>
    num => {
        const pow = Math.pow(base, toFixTo);
        return +(Math.round(num * pow) / pow);
    };

const FlavorProfileGraph = ({
    values: originalValues,
    className,
    hasInViewAnimation,
    animateIn,
    isDark,
}) => {
    const [globalTheme] = useTheme();

    const labels = [
        'Bright/Acid',
        'Buttery',
        'Sweet',
        'Salty',
        'Umami',
        'Fruity',
        'Nutty',
        'Herbal',
        'Floral',
        'Earthy',
        'Caramel',
        'Eggy',
        'Mineral',
        'Citrus Pith',
        'Roasted',
        'Crystalline',
    ].reverse();

    const isXLarge = useMedia('(min-width: 1280px)');

    const width = 480;
    const steps = 4;
    const stepsGradiation = 0.5;
    const minValueLength = 43;
    const maxValueLength = 221;
    const distance = maxValueLength - minValueLength;
    const radians = 2 * Math.PI;
    const textSizeGap = isXLarge ? 13 : 15;
    // curd values
    const values = [...originalValues].reverse(); // clockwise

    const getCords = (value, i) => {
        return [
            value *
                Math.sin(
                    (radians / values.length) * i -
                        radians / 2 +
                        radians / values.length // offset first item for clockwise
                ),
            value *
                Math.cos(
                    (radians / values.length) * i -
                        radians / 2 +
                        radians / values.length // offset first item for clockwise
                ),
        ].map(v => toFixedNumber(5)(v));
    };

    const graphPoints = values.map((value, i) => {
        const [x, y] = getCords(minValueLength + value * (distance / steps), i);
        return `${x.toFixed(5)},${y.toFixed(5)}`;
    });

    graphPoints.push(graphPoints[0]);

    return (
        <div
            className={cx(
                styles.graph,
                styles[`theme--${globalTheme}`],
                className,
                {
                    [styles.hasInViewAnimation]: hasInViewAnimation,
                    [styles.animateIn]: animateIn,
                    [styles.isDark]: isDark,
                }
            )}
        >
            <div className={cx(styles.graphInner)}>
                <div className={cx(styles.labelsPadding)}>
                    {labels.map((label, i) => {
                        const [x, y] = getCords(50, i);
                        return (
                            <div
                                key={i}
                                className={cx(
                                    styles.label,
                                    x < 2 && x > -2
                                        ? styles['middle']
                                        : x > 0
                                        ? styles['start']
                                        : styles['end']
                                )}
                                style={{
                                    left: `${x + 50}%`,
                                    top: `${y + 50}%`,

                                    '--label-transition-delay': Math.abs(
                                        i - labels.length
                                    ),
                                }}
                            >
                                <div className={cx(styles.labelInner)}>
                                    {label}
                                </div>
                            </div>
                        );
                    })}
                    <svg viewBox={`0 0 ${width} ${width}`} fill="none">
                        <defs>
                            <clipPath id="cut-out-text">
                                <rect
                                    x={-width / 2}
                                    y={-width / 2}
                                    width={width / 2 - textSizeGap}
                                    height={width}
                                />
                                <rect
                                    x={textSizeGap}
                                    y={-width / 2}
                                    width={width / 2}
                                    height={width}
                                />
                            </clipPath>
                        </defs>
                        <g
                            id="chart-centered"
                            transform={`translate(${width / 2} ${width / 2})`}
                        >
                            {Array(steps * (1 / stepsGradiation) + 1)
                                .fill()
                                .map((v, i) => (
                                    <g
                                        key={i}
                                        className={styles.circleGroup}
                                        style={{
                                            '--circle-transition-delay': i + 1,
                                        }}
                                    >
                                        <text
                                            x="0"
                                            y={
                                                minValueLength +
                                                ((distance * stepsGradiation) /
                                                    steps) *
                                                    i +
                                                4
                                            }
                                            className={styles.numbers}
                                            textAnchor="middle"
                                        >
                                            {i * stepsGradiation}
                                        </text>
                                        <text
                                            x="0"
                                            y={
                                                (minValueLength +
                                                    ((distance *
                                                        stepsGradiation) /
                                                        steps) *
                                                        i -
                                                    4) *
                                                -1
                                            }
                                            className={styles.numbers}
                                            textAnchor="middle"
                                        >
                                            {i * stepsGradiation}
                                        </text>
                                        <polyline
                                            className={styles.circle}
                                            clipPath="url(#cut-out-text)"
                                            points={Array(labels.length + 1)
                                                .fill()
                                                .map((label, i2) => {
                                                    const [x, y] = getCords(
                                                        minValueLength +
                                                            i *
                                                                stepsGradiation *
                                                                (distance /
                                                                    steps),
                                                        i2
                                                    );
                                                    return `${x.toFixed(
                                                        5
                                                    )},${y.toFixed(5)}`;
                                                })
                                                .join(' ')}
                                            vectorEffect="non-scaling-stroke"
                                        />
                                    </g>
                                ))}
                            <g className={styles.chartPolyGroup}>
                                <AnimatedPolyLine
                                    stroke={'rgba(255, 252, 230'}
                                    className={styles.chartPolyline}
                                    points={graphPoints.join(' ')}
                                />
                                {values.map((value, i) => {
                                    const [x, y] = getCords(
                                        minValueLength +
                                            value * (distance / steps),
                                        i
                                    );

                                    return (
                                        <circle
                                            key={`point-${i}`}
                                            cx={0}
                                            cy={0}
                                            className={styles.points}
                                            transform={`translate(${x.toFixed(
                                                5
                                            )} ${y.toFixed(5)})`}
                                            r={7.5}
                                            fill={
                                                isDark
                                                    ? 'var(--colors-blueDark)'
                                                    : 'var(--colors-cream)'
                                            }
                                        />
                                    );
                                })}
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    );
};

FlavorProfileGraph.defaultProps = {
    values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    hasInViewAnimation: false,
    animateIn: false,
    isDark: false,
};

FlavorProfileGraph.propTypes = {
    isDark: PropTypes.bool,
    values: PropTypes.array,
    className: PropTypes.string,
    hasInViewAnimation: PropTypes.bool,
    animateIn: PropTypes.bool,
};

export default FlavorProfileGraph;
