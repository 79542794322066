import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Header from 'components/globals/Header';

import Footer from 'components/globals/Footer';
import Alerts from 'components/globals/Alerts';

import NotFound from 'components/pages/errors/NotFound';
import FallbackError from 'components/pages/errors/FallbackError';
import { useNormalizedContentfulResponse } from 'services/contentful';

import styles from './Default.module.scss';

const Error = ({ error }) => {
    switch (error?.name) {
        case 'ResourceNotFoundException':
            return <NotFound />;
        default:
            return <FallbackError statusCode={error.statusCode} />;
    }
};

Error.propTypes = {
    error: PropTypes.object,
};

const DefaultLayout = ({ Component, pageProps, user, alert }) => {
    const siteConfig = useNormalizedContentfulResponse(
        pageProps.siteConfigRequest
    )[0];

    const renderBody = () => {
        if (pageProps?.error) {
            return <Error error={pageProps.error} />;
        }
        try {
            return (
                <Fragment>
                    {alert && <Alerts {...alert} />}
                    <Component {...pageProps} user={user} />
                </Fragment>
            );
        } catch (e) {
            return <Error error={pageProps.error} />;
        }
    };

    return (
        <main className={styles.root}>
            <Header user={user} {...siteConfig} />
            <article className={styles.page}>{renderBody()}</article>
            <Footer {...siteConfig} />
        </main>
    );
};

DefaultLayout.propTypes = {
    Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
    pageProps: PropTypes.object.isRequired,
    user: PropTypes.object,
    error: PropTypes.object,
    siteConfig: PropTypes.object,
    alert: PropTypes.object,
};

DefaultLayout.defaultProps = {
    alert: null,
};

export default DefaultLayout;
