import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import EntryLink from './EntryLink';
import { isExternalURL, isUtilityURL } from 'utils';
import * as klaviyo from 'utils/klaviyo';
import kebabCase from 'lodash/kebabCase';

import { BlockContext } from 'components/blocks';

import styles from './Link.module.scss';

const Link = ({
    as,
    children,
    className,
    draggable,
    href,
    scroll,
    target,
    onClick,
    ...props
}) => {
    const isExternalHref = isExternalURL(href);
    const blockContext = useContext(BlockContext);
    const router = useRouter();

    const targetProps = {
        target: target ? target : isExternalHref ? '_blank' : null,
        rel:
            isExternalHref && target !== '_self' ? 'noreferrer noopener' : null,
    };

    const trackingPathId = kebabCase(router.asPath.split('?')[0]);
    const hrefId = kebabCase(as || href);

    const handleOnClick = (e, isExternal) => {
        if (isExternal) {
            klaviyo.trackExternalLink(href);
        }

        onClick && onClick(e);
    };

    const renderAnchorElement = isExternal => (
        <a
            gtm-id={`${trackingPathId}-${
                blockContext && blockContext.substring(0, 6)
            }-${hrefId}`}
            {...props}
            {...targetProps}
            className={cx(styles.link, className)}
            href={as ? as : href}
            draggable={draggable}
            onDragStart={e => (draggable ? null : e.preventDefault())}
            onClick={e => handleOnClick(e, isExternal)}
        >
            {children}
        </a>
    );

    return isExternalHref || isUtilityURL(href) ? (
        renderAnchorElement(true)
    ) : (
        <NextLink href={href} as={as} scroll={scroll}>
            {renderAnchorElement()}
        </NextLink>
    );
};

Link.propTypes = {
    as: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    draggable: PropTypes.bool,
    href: PropTypes.string,
    scroll: PropTypes.bool,
    target: PropTypes.string,
    onClick: PropTypes.func,
};

Link.defaultProps = {
    as: null,
    className: '',
    draggable: false,
    href: '',
    scroll: true,
    target: '',
    onClick: null,
};

Link.Entry = EntryLink;

export default Link;
