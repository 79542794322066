const DEFAULT_IMAGES = [
    {
        entryTitle: 'Where to buy 1',
        image: {
            file: {
                url: 'https://images.ctfassets.net/j8tkpy1gjhi5/6m33UApxAERoQuDC4vwxnX/122f0a70838c64b61ad574dd31fd6659/default-where-to-buy-1.png',
                details: {
                    image: {
                        width: 458,
                        height: 529,
                    },
                },
            },
        },
        scale: 0.7,
    },
    {
        entryTitle: 'Where to buy 2 ',
        image: {
            file: {
                url: 'https://images.ctfassets.net/j8tkpy1gjhi5/7hEfeOb7Lxt49yASebHSXe/17486c12c66d4ae40ea5099edb71e86d/default-where-to-buy-2_NEW.png',
                details: {
                    image: {
                        width: 423,
                        height: 718,
                    },
                },
            },
        },
        scale: 0.9,
    },
    {
        entryTitle: 'Where to buy 3',
        image: {
            file: {
                url: 'https://images.ctfassets.net/j8tkpy1gjhi5/2jwprMlDGtZanJM12MTNZB/a868b1f4aafbfe649b8dfbbf3e6300e0/default-where-to-buy-3.png',
                details: {
                    image: {
                        width: 470,
                        height: 240,
                    },
                },
            },
        },
        scale: 0.6,
    },
    {
        entryTitle: 'Where to buy 4',
        image: {
            file: {
                url: 'https://images.ctfassets.net/j8tkpy1gjhi5/1Qy40DCApkn5icTZ0XNlOL/a5ab3ad1d599a8f37bf8818b89b6a0ff/default-where-to-buy-4.png',
                details: {
                    image: {
                        width: 492,
                        height: 702,
                    },
                },
            },
        },
        scale: 0.8,
    },
    {
        entryTitle: 'Where to buy 5',
        image: {
            file: {
                url: 'https://images.ctfassets.net/j8tkpy1gjhi5/2Sh3D9HYDFVzNSNz63he1K/90ef84d593ee3381d915d58dadea6c4b/default-where-to-buy-5.png',
                details: {
                    image: {
                        width: 648,
                        height: 252,
                    },
                },
            },
        },
        scale: 0.8,
    },
    {
        entryTitle: 'Where to buy 6',
        image: {
            file: {
                url: 'https://images.ctfassets.net/j8tkpy1gjhi5/a5bEeGvrNOBc0w70rRkgO/a70e19722015ec367fba7fbbb047cccc/default-where-to-buy-6.png',
                details: {
                    image: {
                        width: 352,
                        height: 406,
                    },
                },
            },
        },
        scale: 0.6,
    },
    {
        entryTitle: 'Where to buy 7',
        image: {
            file: {
                url: 'https://images.ctfassets.net/j8tkpy1gjhi5/3EbOXGbT97kI2MCgSoX44y/0fbf9f8df2353d97cb2434565ef9f0a2/default-where-to-buy-7.png',
                details: {
                    image: {
                        width: 370,
                        height: 178,
                    },
                },
            },
        },
        scale: 0.7,
    },
    {
        entryTitle: 'Where to buy 8',
        image: {
            file: {
                url: 'https://images.ctfassets.net/j8tkpy1gjhi5/0YPhJZ1vAF3c2OPyGHUEp/76f82fe393a8e5dd33b7d14754b9216b/default-where-to-buy-8.png',
                details: {
                    image: {
                        width: 320,
                        height: 298,
                    },
                },
            },
        },
        scale: 0.5,
    },
];

export default DEFAULT_IMAGES;
