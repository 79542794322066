import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from 'components/ui/Text';
import Img from 'components/ui/Img';
import Stagger from 'components/ui/Stagger';
import Button from 'components/ui/Button';

import styles from './BlockTwoColumnCarousel.module.scss';

const BlockTwoColumnCarousel = ({
    eyebrow,
    headline,
    copy,
    images,
    cta,
    lastBlock,
    flushTop,
    flushBottom,
    imageOnLeft,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const needsNav = images?.length > 1;

    const handleImageClick = () => {
        if (needsNav) {
            setActiveIndex(prevIndex => {
                return prevIndex < images.length - 1 ? prevIndex + 1 : 0;
            });
        }
    };

    return (
        <div
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.lastBlock]: lastBlock,
                [styles.imageOnLeft]: imageOnLeft,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.column}>
                    <div
                        className={styles.imageWrapper}
                        onClick={handleImageClick}
                    >
                        {images?.map((image, idx) => {
                            return (
                                <Img
                                    key={idx}
                                    className={cx(styles.image, {
                                        [styles['image-active']]:
                                            activeIndex === idx,
                                    })}
                                    src={image?.file?.url}
                                    fallbackImageWidth={768}
                                    alt={image?.description || ''}
                                    width={image?.file?.details?.image?.width}
                                    height={image?.file?.details?.image?.height}
                                    customSources={[
                                        {
                                            breakpoint: 1920,
                                            src: image?.file.url,
                                            imageWidth: 1920 * 0.75,
                                        },
                                        {
                                            breakpoint: 1440,
                                            src: image?.file.url,
                                            imageWidth: 1440 * 0.75,
                                        },
                                        {
                                            breakpoint: 1024,
                                            src: image?.file.url,
                                            imageWidth: 1024 * 0.75,
                                        },
                                        {
                                            breakpoint: 768,
                                            src: image?.file?.url,
                                            imageWidth: 768 * 0.75,
                                        },
                                        {
                                            src: image?.file?.url,
                                            imageWidth: 768,
                                        },
                                    ]}
                                    priority
                                />
                            );
                        })}
                    </div>
                    {needsNav && (
                        <nav className={styles.nav}>
                            <div className={styles.dots}>
                                {images?.map((image, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className={cx(styles.dot, {
                                                [styles.active]:
                                                    activeIndex === idx,
                                            })}
                                            onClick={() => setActiveIndex(idx)}
                                        />
                                    );
                                })}
                            </div>
                        </nav>
                    )}
                </div>
                <div className={styles.column}>
                    <Stagger className={styles.lockup}>
                        {eyebrow && (
                            <Stagger.Child order={0} className={styles.eyebrow}>
                                <Text
                                    baseTheme="labelSmall"
                                    themes={{
                                        large: 'labelMedium',
                                    }}
                                >
                                    {eyebrow}
                                </Text>
                            </Stagger.Child>
                        )}
                        {headline && (
                            <Stagger.Child
                                order={1}
                                className={styles.headline}
                            >
                                <Text
                                    as="h2"
                                    baseTheme="displayXSmall"
                                    themes={{
                                        large: 'displaySmall',
                                    }}
                                >
                                    {headline}
                                </Text>
                            </Stagger.Child>
                        )}
                        {copy && (
                            <Stagger.Child order={2} className={styles.copy}>
                                <Text
                                    baseTheme="bodySmall"
                                    themes={{
                                        large: 'bodyMedium',
                                    }}
                                    fixWidows
                                >
                                    {copy}
                                </Text>
                            </Stagger.Child>
                        )}
                        {cta && (
                            <Stagger.Child order={3} className={styles.copy}>
                                <Button
                                    className={styles.cta}
                                    entry={cta.link}
                                    theme="filledBlueHoverCream-ultrabold"
                                >
                                    {cta.text}
                                </Button>
                            </Stagger.Child>
                        )}
                    </Stagger>
                </div>
            </div>
        </div>
    );
};

BlockTwoColumnCarousel.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    copy: PropTypes.string,
    images: PropTypes.array,
    lastBlock: PropTypes.bool,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    cta: PropTypes.object,
    imageOnLeft: PropTypes.bool,
};

BlockTwoColumnCarousel.defaultProps = {
    eyebrow: '',
    headline: '',
    copy: '',
    images: [],
    lastBlock: false,
    flushTop: false,
    flushBottom: false,
    cta: null,
    imageOnLeft: false,
};

export default BlockTwoColumnCarousel;
