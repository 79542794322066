import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Carousel from 'components/ui/Carousel';
import VideoPlayerYT from 'components/ui/VideoPlayerYT';

import styles from './BlockVideoCarousel.module.scss';

const BlockVideoCarousel = ({ videos = [], flushTop, flushBottom }) => {
    return (
        <div
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <Carousel slideClassName={styles.slide} className={styles.carousel}>
                {videos.map((video, index) => {
                    return (
                        <div key={index} className={styles.item}>
                            <VideoPlayerYT {...video} index={index} />
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

BlockVideoCarousel.propTypes = {
    className: PropTypes.string,
    videos: PropTypes.arrayOf(PropTypes.shape(VideoPlayerYT.PropTypes)),
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default BlockVideoCarousel;
