import { useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';

import styles from './PageLoader.module.scss';

const PageLoader = () => {
    const router = useRouter();

    useEffect(() => {
        NProgress.configure({
            speed: 500,
            minimum: 0.2,
            trickle: true,
            trickleSpeed: 100,
            showSpinner: false,
            template: `<div class="${styles.loader}" role="bar"></div>`,
        });

        const handleStart = () => {
            NProgress.start();
        };
        const handleStop = () => {
            NProgress.done();
        };

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleStop);
        router.events.on('routeChangeError', handleStop);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleStop);
            router.events.off('routeChangeError', handleStop);
        };
    }, [router]);
    return null;
};

export default PageLoader;
