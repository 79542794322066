import React from 'react';
import PropTypes from 'prop-types';

const fill = '#FFFCE6';

const LessThanMonth = () => (
    <>
        <path
            d="M24.5372 10.671L23.8631 5.17047L27.2263 4.75834L27.3515 5.77987L25.2613 6.03601L25.4144 7.28541L27.5046 7.02927L27.6269 8.02722L25.5367 8.28336L25.685 9.49348L27.7752 9.23734L27.9004 10.2589L24.5372 10.671Z"
            fill={fill}
        />
        <path
            d="M18.6595 6.79725L20.5738 11.9978L23.7089 10.8437L23.3151 9.77391L21.3835 10.4849L19.863 6.35422L18.6595 6.79725Z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6601 16.318L10.9483 12.2031L12.5002 10.8032C13.139 10.227 13.827 9.96536 14.5641 10.0183C15.3013 10.0712 15.9315 10.3876 16.4547 10.9676C16.9779 11.5476 17.228 12.207 17.2049 12.9457C17.1857 13.6809 16.8547 14.3384 16.212 14.9181L14.6601 16.318ZM14.8913 14.6595L15.4027 14.1982C15.7868 13.8518 15.9731 13.4634 15.9616 13.033C15.9505 12.5953 15.7876 12.202 15.473 11.8532C15.1584 11.5044 14.7857 11.304 14.3549 11.2519C13.9245 11.1924 13.5172 11.3358 13.1332 11.6822L12.6218 12.1436L14.8913 14.6595Z"
            fill={fill}
        />
        <path
            d="M7.32163 17.1898L11.9841 20.1851L13.8155 17.3343L12.9496 16.778L11.8114 18.5498L10.7856 17.8908L11.9238 16.1191L11.0779 15.5757L9.93974 17.3474L8.8807 16.667L10.0189 14.8953L9.15301 14.339L7.32163 17.1898Z"
            fill={fill}
        />
        <path
            d="M8.69432 20.0463L9.62025 20.321L9.47161 20.822C10.0737 21.1492 10.4827 21.5982 10.6988 22.1688C10.9113 22.7328 10.9094 23.3791 10.6932 24.1077C10.444 24.9476 9.98147 25.5508 9.30567 25.9174C8.62632 26.2773 7.88262 26.3346 7.07456 26.0894C6.27007 25.8506 5.67294 25.3954 5.28319 24.7238C4.89494 24.047 4.83294 23.2634 5.09719 22.3729C5.30588 21.6696 5.64804 21.138 6.12366 20.7782C6.60079 20.4133 7.10669 20.2854 7.64135 20.3945L7.24274 21.7378C7.00004 21.7209 6.77707 21.7951 6.57384 21.9605C6.37211 22.1208 6.22471 22.3579 6.13162 22.6716C5.97698 23.1927 6.02281 23.6412 6.26912 24.0171C6.51692 24.3879 6.90468 24.6489 7.4324 24.8C7.93687 24.9552 8.39558 24.9399 8.80854 24.7541C9.21794 24.5618 9.49246 24.2304 9.63209 23.7598C9.85279 23.016 9.67393 22.4592 9.09552 22.0894L8.81401 23.0381L7.88808 22.7634L8.69432 20.0463Z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.92193 28.2462L9.25254 28.4482L9.28779 30.4112L9.96401 30.5891L9.98831 31.9427L4.41385 30.1662L4.39068 28.876L9.89763 26.8926L9.92193 28.2462ZM8.27742 30.1443L8.2524 28.7512L5.80322 29.492L8.27742 30.1443Z"
            fill={fill}
        />
        <path
            d="M29.167 9.97853C29.5193 10.3554 30.0344 10.575 30.7124 10.6372C31.322 10.6932 31.825 10.5831 32.2214 10.3068C32.6231 10.031 32.8475 9.63556 32.8948 9.12051C32.9634 8.3742 32.6404 7.82249 31.9259 7.46536L31.0448 7.01871C30.7816 6.88324 30.597 6.75764 30.4911 6.6419C30.3856 6.52091 30.3398 6.38421 30.3538 6.2318C30.3693 6.06362 30.4392 5.93754 30.5635 5.85356C30.6935 5.7648 30.861 5.72984 31.066 5.74867C31.313 5.77136 31.496 5.85706 31.6148 6.00578C31.7336 6.15449 31.7812 6.35761 31.7576 6.61514L33.0347 6.73245C33.0835 6.20163 32.9384 5.76166 32.5994 5.41252C32.261 5.05814 31.7869 4.85294 31.1773 4.79694C30.5781 4.7419 30.0864 4.84513 29.7019 5.10662C29.3228 5.36859 29.1107 5.74396 29.0659 6.23274C28.9992 6.95802 29.3263 7.49421 30.047 7.84131L31.0048 8.31885C31.2479 8.44188 31.412 8.56029 31.4969 8.6741C31.5876 8.78313 31.6252 8.92173 31.6097 9.08991C31.5919 9.28437 31.5124 9.42812 31.3714 9.52117C31.2356 9.61469 31.0494 9.65059 30.8129 9.62887C30.5344 9.60328 30.3214 9.49892 30.1739 9.31577C30.0317 9.13311 29.9743 8.89199 30.0018 8.59242L28.7247 8.4751C28.6678 9.09527 28.8152 9.59641 29.167 9.97853Z"
            fill={fill}
        />
        <path
            d="M35.0746 11.7609C34.4455 11.5008 34.0184 11.1387 33.7934 10.6744C33.5704 10.2053 33.5778 9.68303 33.8158 9.10749L35.001 9.59744C34.8861 9.87545 34.8695 10.1227 34.9513 10.3393C35.038 10.5579 35.2106 10.7206 35.4691 10.8275C35.6886 10.9182 35.877 10.939 36.0344 10.8898C36.1967 10.8427 36.3151 10.7289 36.3897 10.5484C36.4542 10.3923 36.4593 10.2488 36.4049 10.1178C36.3575 9.98399 36.2358 9.82234 36.04 9.63289L35.2664 8.89333C34.6806 8.34851 34.5269 7.73956 34.8051 7.06647C34.9926 6.61286 35.3062 6.31704 35.7459 6.17899C36.1905 6.04296 36.6908 6.08987 37.2469 6.31972C37.8126 6.55361 38.2047 6.88988 38.4232 7.32855C38.6436 7.76233 38.652 8.22554 38.4484 8.71816L37.2632 8.22821C37.362 7.98922 37.3766 7.78111 37.3071 7.60389C37.2376 7.42667 37.0882 7.29067 36.859 7.19591C36.6687 7.11728 36.4984 7.10111 36.3479 7.1474C36.2043 7.19084 36.1002 7.2906 36.0357 7.44668C35.9772 7.58812 35.9805 7.73224 36.0454 7.87902C36.1124 8.02093 36.2516 8.19552 36.4628 8.40279L37.1724 9.09017C37.7492 9.64272 37.8945 10.2653 37.6082 10.9579C37.4106 11.4359 37.0792 11.7472 36.6139 11.8918C36.1535 12.0384 35.6404 11.9948 35.0746 11.7609Z"
            fill={fill}
        />
        <path
            d="M43.5477 11.6895L40.5383 14.9016L41.4684 15.7731L44.4779 12.5609L45.5929 13.6056L46.3723 12.7736L43.2179 9.81833L42.4385 10.6503L43.5477 11.6895Z"
            fill={fill}
        />
        <path
            d="M42.8549 17.1756L47.571 14.2657L48.2445 15.3571L46.3378 16.5336L47.3438 18.164L49.2505 16.9876L49.9239 18.079L45.2077 20.989L44.5343 19.8975L46.4073 18.7418L45.4013 17.1114L43.5283 18.267L42.8549 17.1756Z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.0514 24.9887L46.4516 25.3481L46.8056 26.6547L51.6617 23.3869L51.3243 22.1414L45.4848 21.7797L45.8388 23.0863L46.538 23.0937L47.0514 24.9887ZM47.9473 24.4507L47.583 23.1059L50.1416 23.1344L47.9473 24.4507Z"
            fill={fill}
        />
        <path
            d="M46.8521 27.3299L52.3926 27.4429L52.3658 28.7568L48.7411 30.607L52.3266 30.6801L52.3016 31.9069L46.761 31.7939L46.7878 30.48L50.4126 28.6298L46.8271 28.5567L46.8521 27.3299Z"
            fill={fill}
        />
        <path
            d="M16.0528 41.0369L12.7854 45.5129L13.7766 46.2364L15.9097 43.3142L14.7932 46.9786L15.5606 47.5387L18.6708 45.3591L16.5517 48.2622L17.5428 48.9856L20.8101 44.5096L19.6336 43.6508L16.1244 45.9606L17.2549 41.9144L16.0528 41.0369Z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.6334 51.2949C23.9706 51.7064 23.2049 51.7996 22.3364 51.5744C21.4679 51.3493 20.844 50.8958 20.4646 50.214C20.0865 49.5272 20.0002 48.7878 20.2055 47.9959C20.4108 47.2041 20.8449 46.6023 21.5077 46.1908C22.1719 45.7742 22.9382 45.6784 23.8067 45.9036C24.6752 46.1288 25.2985 46.5848 25.6765 47.2717C26.0559 47.9534 26.1429 48.6902 25.9376 49.4821C25.7323 50.274 25.2976 50.8782 24.6334 51.2949ZM22.6245 50.4633C23.115 50.5904 23.5405 50.5263 23.9012 50.2708C24.2682 50.0116 24.5154 49.6368 24.6425 49.1463C24.7697 48.6559 24.7352 48.2107 24.5389 47.8109C24.3492 47.4073 24.009 47.1419 23.5186 47.0148C23.023 46.8863 22.5917 46.9517 22.2246 47.2109C21.864 47.4663 21.6226 47.8399 21.5006 48.3317C21.3683 48.8208 21.3971 49.2672 21.5868 49.6708C21.7831 50.0706 22.1289 50.3348 22.6245 50.4633Z"
            fill={fill}
        />
        <path
            d="M27.3498 46.5192V52.0608H28.5769V48.4746L30.5006 52.0608H31.8148V46.5192H30.5877V50.1054L28.6639 46.5192H27.3498Z"
            fill={fill}
        />
        <path
            d="M35.9974 51.0547L34.4414 46.9373L33.0195 47.4746L32.6165 46.4082L36.6599 44.8802L37.0629 45.9466L35.6337 46.4867L37.1897 50.6041L35.9974 51.0547Z"
            fill={fill}
        />
        <path
            d="M37.6195 44.3625L40.7051 48.9657L41.7704 48.2517L40.545 46.4235L42.1364 45.3568L43.3618 47.185L44.4271 46.4709L41.3416 41.8677L40.2763 42.5817L41.5237 44.4427L39.9323 45.5095L38.6849 43.6484L37.6195 44.3625Z"
            fill={fill}
        />
        <path
            d="M10.4297 38.7316C10.5757 38.5106 10.6528 38.2513 10.6513 37.9864C10.6492 37.6331 10.5073 37.2949 10.2567 37.0458C10.0061 36.7968 9.66717 36.6569 9.31383 36.6569C9.04898 36.6569 8.79008 36.7356 8.56998 36.8829C8.34987 37.0302 8.17845 37.2396 8.07746 37.4844C7.97648 37.7293 7.95046 37.9986 8.00271 38.2582C8.05497 38.5179 8.18314 38.7562 8.37098 38.9429C8.55882 39.1296 8.79787 39.2564 9.05783 39.3071C9.31779 39.3578 9.58694 39.3301 9.83119 39.2277C10.0754 39.1252 10.2837 38.9526 10.4297 38.7316Z"
            fill={fill}
        />
        <path
            d="M46.3479 37.9864C46.3464 38.2513 46.4234 38.5106 46.5694 38.7316C46.7154 38.9526 46.9238 39.1252 47.168 39.2277C47.4122 39.3301 47.6814 39.3578 47.9414 39.3071C48.2013 39.2564 48.4404 39.1296 48.6282 38.9429C48.8161 38.7562 48.9442 38.5179 48.9965 38.2582C49.0487 37.9986 49.0227 37.7293 48.9217 37.4844C48.8207 37.2396 48.6493 37.0302 48.4292 36.8829C48.2091 36.7356 47.9502 36.6569 47.6854 36.6569C47.332 36.6569 46.993 36.7968 46.7425 37.0458C46.4919 37.2949 46.35 37.6331 46.3479 37.9864Z"
            fill={fill}
        />
    </>
);

const OverMonths = () => (
    <>
        <path
            d="M20.7061 48.2749C21.1714 47.228 21.0551 46.0647 20.0082 45.5994C18.9612 45.0178 17.9143 45.5994 17.3327 46.53C16.751 47.577 16.9837 48.7402 18.0306 49.2055C19.1939 49.7872 20.1245 49.3219 20.7061 48.2749ZM15.9367 45.8321C16.751 44.2035 18.6122 43.3892 20.5898 44.3198C22.5674 45.3668 22.9163 47.3443 22.102 48.9729C21.2878 50.6015 19.4265 51.4157 17.449 50.4851C15.4714 49.4382 15.1225 47.4606 15.9367 45.8321Z"
            fill={fill}
        />
        <path
            d="M12.4466 36.7598L13.4935 38.1557L10.818 42.3434L15.5874 40.9475L16.6344 42.2271L11.516 46.1822L10.5854 45.0189L13.9589 42.4598L9.7711 43.972L9.07314 43.0414L11.516 39.3189L8.14253 41.8781L7.21191 40.7149L12.4466 36.7598Z"
            fill={fill}
        />
        <path
            d="M24.6616 46.2988H26.1738L28.1514 50.7192L28.384 46.5315L29.8963 46.6478L29.431 53.0458L27.9187 52.9294L25.9412 48.6254L25.7085 52.8131L24.1963 52.6968L24.6616 46.2988Z"
            fill={fill}
        />
        <path
            d="M33.0363 47.2286L31.2914 47.8103L30.9424 46.5307L35.8281 45.1348L36.1771 46.4144L34.4322 46.8797L35.8281 51.7654L34.4322 52.2307L33.0363 47.2286Z"
            fill={fill}
        />
        <path
            d="M36.6426 44.6701L37.9222 43.7395L39.4344 45.8334L41.2956 44.5538L39.6671 42.4599L40.9467 41.5293L44.7854 46.764L43.5058 47.6946L41.9936 45.6007L40.2487 46.8803L41.7609 48.9742L40.4814 49.9048L36.6426 44.6701Z"
            fill={fill}
        />
        <path
            d="M45.3673 43.1575L46.1816 41.8779C46.7632 42.2269 47.3448 42.2269 47.6938 41.6452C48.0428 41.1799 47.9265 40.831 47.5775 40.482C47.2285 40.2493 46.9959 40.3657 46.4142 40.7146L45.3673 41.4126C44.553 41.9942 43.855 41.9942 43.1571 41.5289C42.2265 40.9473 42.1101 39.6677 42.9244 38.5044C43.7387 37.3412 44.902 36.9922 45.9489 37.6901L45.1346 38.9697C44.6693 38.6208 44.204 38.7371 43.855 39.2024C43.6224 39.5514 43.6224 40.0167 43.9714 40.2493C44.3203 40.482 44.553 40.3657 45.1346 40.0167L46.0652 39.3187C46.8795 38.7371 47.5775 38.7371 48.2754 39.3187C49.3224 40.0167 49.4387 41.1799 48.6244 42.4595C47.8101 43.6228 46.5305 43.9718 45.3673 43.1575Z"
            fill={fill}
        />
        <path
            d="M8.72422 32.4554C8.72422 33.1533 8.14259 33.735 7.44463 33.735C6.74667 33.735 6.16504 33.1533 6.16504 32.4554C6.16504 31.7574 6.74667 31.1758 7.44463 31.1758C8.14259 31.1758 8.72422 31.7574 8.72422 32.4554Z"
            fill={fill}
        />
        <path
            d="M48.3916 32.4554C48.3916 33.1533 48.9732 33.735 49.6712 33.735C50.3692 33.735 50.9508 33.1533 50.9508 32.4554C50.9508 31.7574 50.3692 31.1758 49.6712 31.1758C48.9732 31.1758 48.3916 31.7574 48.3916 32.4554Z"
            fill={fill}
        />
        <path
            d="M9.42282 23.7304L6.39833 23.963L9.07384 25.3589L9.42282 23.7304ZM10.5861 23.614L10.1208 25.8242L10.8187 26.1732L10.4698 27.6855L4.53711 24.1957L4.88609 22.6834L11.633 21.8691L11.284 23.3814L10.5861 23.614Z"
            fill={fill}
        />
        <path
            d="M14.0755 14.0757L13.7265 14.541C14.7735 15.7042 14.8898 17.2165 13.8429 18.6124C12.6796 20.3573 10.702 20.59 9.07347 19.543C7.56122 18.4961 6.97959 16.5185 8.25918 14.6573C9.18979 13.2614 10.702 12.7961 11.749 13.3777L10.8184 14.7736C10.3531 14.541 9.6551 14.7736 9.18979 15.3553C8.49183 16.4022 8.84081 17.5655 9.88775 18.1471C10.9347 18.8451 12.098 18.7287 12.6796 17.7981C13.1449 17.1002 13.1449 16.4022 12.6796 15.7042L11.9816 16.6349L11.051 15.9369L12.9122 13.2614L14.0755 14.0757Z"
            fill={fill}
        />
        <path
            d="M13.1445 9.65552L16.4017 7.44531L17.0996 8.37592L15.1221 9.77184L15.9364 11.0514L18.0302 9.65552L18.6119 10.5861L16.6343 11.982L17.4486 13.1453L19.4262 11.7494L20.1241 12.7963L16.867 15.0065L13.1445 9.65552Z"
            fill={fill}
        />
        <path
            d="M23.731 10.1198C25.0106 9.77082 25.3596 8.72388 25.1269 7.79327C24.8943 6.74633 24.08 6.04837 22.8004 6.28102L22.1024 6.51367L23.033 10.3524L23.731 10.1198ZM20.2412 5.58306L22.5677 5.00143C24.5453 4.53612 26.1739 5.69939 26.5228 7.44429C26.8718 9.18918 26.0575 10.9341 24.08 11.3994L21.7535 11.981L20.2412 5.58306Z"
            fill={fill}
        />
        <path
            d="M37.6893 9.53951C38.0383 8.49257 37.8056 7.32931 36.6424 6.864C35.4791 6.3987 34.5485 7.09666 34.0832 8.14359C33.7342 9.30686 34.0832 10.4701 35.2464 10.8191C36.4097 11.2844 37.3403 10.5865 37.6893 9.53951ZM32.6873 7.67829C33.3852 5.93339 35.1301 4.88645 37.1077 5.70074C39.0852 6.51502 39.7832 8.37625 39.0852 10.1211C38.3873 11.866 36.6424 12.913 34.6648 12.0987C32.6873 11.2844 32.1056 9.42319 32.6873 7.67829Z"
            fill={fill}
        />
        <path
            d="M42.3425 8.375L43.5058 9.42194L41.5282 13.726L45.4833 11.2832L46.7629 12.3301L40.8303 15.7036L39.667 14.6566L42.3425 8.375Z"
            fill={fill}
        />
        <path
            d="M48.0425 14.0762L50.1364 17.4496L49.0895 18.1476L47.8099 16.0537L46.5303 16.868L47.9262 18.9619L46.8793 19.5435L45.5997 17.4496L44.3201 18.1476L45.716 20.2415L44.6691 20.9394L42.5752 17.566L48.0425 14.0762Z"
            fill={fill}
        />
        <path
            d="M48.6243 23.4982L48.857 24.5451C48.9733 25.1267 49.5549 25.4757 50.1366 25.3594C50.7182 25.2431 51.1835 24.6614 51.0672 24.0798L50.8345 23.0329L48.6243 23.4982ZM48.159 26.0574L46.4141 27.5696L46.0651 25.941L47.5774 24.5451L47.4611 23.7308L45.7162 24.0798L45.3672 22.6839L51.7651 21.4043L52.3468 24.0798C52.5794 25.3594 51.8815 26.5227 50.4856 26.8716C49.5549 26.988 48.7407 26.639 48.159 26.0574Z"
            fill={fill}
        />
    </>
);

const OverYears = () => (
    <>
        <path
            d="M19.774 44.4219L13.1824 47.4311L11.8848 46.3723L14.3686 45.2339L15.1328 40.6484L16.4782 41.747L15.9369 44.605L18.4843 43.3711L19.774 44.4219Z"
            fill={fill}
        />
        <path
            d="M20.554 44.6758L24.2717 46.0689L23.8498 47.1994L21.5412 46.3316L21.0237 47.7168L23.3324 48.5766L22.9184 49.6832L20.6097 48.8154L20.1162 50.1529L22.4248 51.0127L22.0029 52.1431L18.2852 50.7579L20.554 44.6758Z"
            fill={fill}
        />
        <path
            d="M28.993 50.9975L28.1969 48.0997L27.3371 50.9656L28.993 50.9975ZM29.3114 52.1757H27.0107L26.7719 52.9718H25.1797L27.4883 46.5234H28.993L31.0867 53.0434H29.4945L29.3114 52.1757Z"
            fill={fill}
        />
        <path
            d="M34.7652 48.9914L35.6966 48.6411C35.96 48.5288 36.1705 48.3202 36.2851 48.0578C36.3997 47.7955 36.4097 47.4992 36.3131 47.2297C36.2165 46.9602 36.0205 46.7378 35.7653 46.608C35.5102 46.4782 35.215 46.4508 34.9403 46.5315L33.9691 46.8738L34.7652 48.9914ZM37.3206 49.0949L39.0959 50.6871L37.5037 51.2602L35.9115 49.9149L35.1712 50.1776L35.7762 51.8732L34.3592 52.3827L32.1699 46.2688L34.7731 45.3373C35.2548 45.2082 35.7649 45.2331 36.2317 45.4085C36.6985 45.5839 37.0988 45.9011 37.3762 46.3155C37.6536 46.7299 37.7944 47.2208 37.7787 47.7192C37.763 48.2177 37.5917 48.6987 37.2888 49.0949"
            fill={fill}
        />
        <path
            d="M40.4095 47.4639L41.5718 46.5165C41.6499 46.6413 41.7545 46.7474 41.8782 46.8272C42.0018 46.9071 42.1416 46.9587 42.2875 46.9785C42.4334 46.9982 42.5818 46.9856 42.7223 46.9416C42.8627 46.8975 42.9918 46.8231 43.1003 46.7235C43.5301 46.3653 43.6098 45.9274 43.3232 45.617C43.0366 45.3065 42.7898 45.2746 42.1848 45.4657L40.9986 45.8478C40.6354 46.0039 40.2307 46.0355 39.8476 45.9376C39.4646 45.8397 39.1247 45.6178 38.881 45.3065C38.1565 44.4228 38.3795 43.2605 39.4701 42.3769C40.5607 41.4932 41.8584 41.5012 42.6146 42.4724L41.4444 43.4198C41.3841 43.3076 41.2979 43.2116 41.1928 43.1397C41.0878 43.0679 40.9671 43.0222 40.8407 43.0066C40.7144 42.991 40.5862 43.0059 40.4668 43.0501C40.3475 43.0943 40.2404 43.1665 40.1547 43.2605C39.7806 43.571 39.7089 43.9452 39.9557 44.2477C40.2025 44.5502 40.5209 44.5422 41.1817 44.3432L42.2644 44.0327C42.6388 43.866 43.0585 43.8303 43.4557 43.9314C43.8529 44.0325 44.2045 44.2645 44.4536 44.59C45.2497 45.5294 44.979 46.7394 43.8645 47.647C42.75 48.5545 41.3489 48.5943 40.4334 47.4639"
            fill={fill}
        />
        <path
            d="M10.6514 37.0424C10.653 37.3072 10.5759 37.5666 10.4299 37.7876C10.2839 38.0085 10.0756 38.1812 9.83132 38.2836C9.58708 38.3861 9.31792 38.4137 9.05796 38.363C8.79801 38.3123 8.55895 38.1856 8.37111 37.9988C8.18327 37.8121 8.0551 37.5738 8.00285 37.3142C7.9506 37.0545 7.97661 36.7852 8.0776 36.5404C8.17859 36.2955 8.35 36.0862 8.57011 35.9389C8.79022 35.7915 9.04911 35.7129 9.31397 35.7129C9.6673 35.7129 10.0063 35.8527 10.2569 36.1018C10.5075 36.3509 10.6493 36.689 10.6514 37.0424"
            fill={fill}
        />
        <path
            d="M46.3477 37.0424C46.3461 37.3072 46.4232 37.5666 46.5692 37.7876C46.7152 38.0085 46.9235 38.1812 47.1678 38.2836C47.412 38.3861 47.6812 38.4137 47.9411 38.363C48.2011 38.3123 48.4401 38.1856 48.628 37.9988C48.8158 37.8121 48.944 37.5738 48.9962 37.3142C49.0485 37.0545 49.0225 36.7852 48.9215 36.5404C48.8205 36.2955 48.6491 36.0862 48.429 35.9389C48.2089 35.7915 47.95 35.7129 47.6851 35.7129C47.3318 35.7129 46.9928 35.8527 46.7422 36.1018C46.4916 36.3509 46.3498 36.689 46.3477 37.0424Z"
            fill={fill}
        />
        <path
            d="M9.42497 23.6683L6.43964 23.8833L9.09858 25.2685L9.42497 23.6683ZM10.651 23.5808L10.1813 25.8337L10.9137 26.2158L10.5873 27.7682L4.64844 24.2972L4.95891 22.8165L11.7575 21.9727L11.4311 23.525L10.651 23.5808Z"
            fill={fill}
        />
        <path
            d="M14.1144 14.1316L13.7641 14.6331C14.3 15.1713 14.615 15.8904 14.6475 16.6491C14.6799 17.4078 14.4272 18.1511 13.9393 18.733C13.7025 19.1262 13.3853 19.4649 13.0084 19.7268C12.6316 19.9888 12.2036 20.1681 11.7526 20.2531C11.3015 20.338 10.8377 20.3267 10.3913 20.2197C9.94499 20.1128 9.52631 19.9127 9.16274 19.6326C8.76939 19.3854 8.43215 19.0587 8.17272 18.6733C7.91329 18.2879 7.73742 17.8525 7.65643 17.3951C7.57544 16.9376 7.59113 16.4683 7.70248 16.0173C7.81383 15.5663 8.01838 15.1436 8.30297 14.7764C9.32196 13.3435 10.7788 12.8738 11.8854 13.4947L10.9539 14.8481C10.6562 14.7476 10.3323 14.7565 10.0405 14.8732C9.74873 14.9899 9.50808 15.2068 9.36176 15.485C9.198 15.7097 9.08504 15.9673 9.0307 16.24C8.97636 16.5127 8.98194 16.7939 9.04706 17.0642C9.11218 17.3346 9.23528 17.5875 9.40784 17.8055C9.58039 18.0236 9.79827 18.2015 10.0464 18.327C10.2504 18.5017 10.4889 18.6317 10.7464 18.7084C11.0038 18.7851 11.2745 18.8069 11.5409 18.7724C11.8073 18.7379 12.0635 18.6478 12.2929 18.508C12.5223 18.3682 12.7198 18.1818 12.8725 17.9608C13.115 17.6735 13.248 17.3097 13.248 16.9338C13.248 16.5579 13.115 16.1941 12.8725 15.9069L12.2197 16.8622L11.2883 16.2253L13.1671 13.4868L14.1144 14.1316Z"
            fill={fill}
        />
        <path
            d="M13.1914 9.65708L16.4554 7.41211L17.14 8.40722L15.11 9.80038L15.9459 11.0184L17.9759 9.62524L18.6367 10.5885L16.6066 11.9896L17.4186 13.1678L19.4487 11.7667L20.1333 12.7618L16.8614 15.0068L13.1914 9.65708Z"
            fill={fill}
        />
        <path
            d="M23.7558 10.127C24.012 10.0761 24.2544 9.97121 24.4669 9.81929C24.6794 9.66737 24.8571 9.47192 24.9882 9.24596C25.1192 9.02 25.2007 8.76871 25.227 8.50883C25.2534 8.24894 25.224 7.98642 25.141 7.73876C25.1019 7.48258 25.009 7.23756 24.8685 7.01978C24.7281 6.802 24.5432 6.61637 24.3259 6.47505C24.1087 6.33374 23.864 6.23993 23.608 6.19979C23.352 6.15964 23.0904 6.17407 22.8403 6.24211L22.0442 6.43317L22.9597 10.3101L23.7558 10.127ZM20.3008 5.57339L22.689 5.01613C23.1075 4.89006 23.5477 4.85284 23.9814 4.90686C24.4152 4.96088 24.8328 5.10494 25.2076 5.3298C25.5823 5.55466 25.906 5.85537 26.1577 6.21263C26.4095 6.56989 26.5838 6.97583 26.6695 7.4044C26.786 7.8239 26.8134 8.26313 26.75 8.69386C26.6866 9.1246 26.5338 9.5373 26.3014 9.90544C26.0689 10.2736 25.762 10.589 25.4004 10.8315C25.0388 11.0739 24.6305 11.238 24.2016 11.3132L21.8134 11.8784L20.3008 5.57339Z"
            fill={fill}
        />
        <path
            d="M37.7259 9.58492C37.8442 9.34658 37.9112 9.08607 37.9226 8.82021C37.934 8.55436 37.8895 8.28908 37.7919 8.0415C37.6944 7.79393 37.546 7.56956 37.3564 7.3829C37.1668 7.19623 36.9401 7.05142 36.691 6.95782C36.4447 6.85988 36.1806 6.81492 35.9158 6.82585C35.6511 6.83679 35.3915 6.90339 35.1542 7.0213C34.9169 7.13922 34.7071 7.3058 34.5384 7.51022C34.3697 7.71463 34.246 7.95228 34.1753 8.20768C34.0545 8.44499 33.9855 8.70528 33.9729 8.97128C33.9603 9.23728 34.0044 9.50292 34.1023 9.75059C34.2001 9.99826 34.3495 10.2223 34.5405 10.4078C34.7316 10.5934 34.9598 10.7362 35.2103 10.8268C35.4578 10.922 35.7224 10.9643 35.9873 10.9512C36.2521 10.938 36.5112 10.8697 36.7481 10.7505C36.985 10.6313 37.1943 10.4639 37.3627 10.2591C37.5311 10.0543 37.6548 9.81648 37.7259 9.56103V9.58492ZM32.7105 7.6743C32.8586 7.24096 33.0947 6.84293 33.4039 6.50524C33.7132 6.16755 34.0891 5.8975 34.5078 5.71207C34.9265 5.52664 35.379 5.42985 35.8369 5.42777C36.2949 5.42569 36.7483 5.51838 37.1686 5.7C37.6035 5.8443 38.0039 6.07685 38.3448 6.3831C38.6856 6.68934 38.9595 7.06268 39.1493 7.47971C39.3392 7.89674 39.4408 8.34848 39.4479 8.80662C39.4551 9.26477 39.3675 9.71945 39.1907 10.1422C39.0418 10.5748 38.8052 10.972 38.4955 11.3088C38.1859 11.6457 37.8099 11.9148 37.3913 12.0995C36.9727 12.2841 36.5204 12.3802 36.0629 12.3818C35.6054 12.3833 35.1525 12.2903 34.7326 12.1085C34.298 11.9651 33.8977 11.7334 33.557 11.4279C33.2162 11.1224 32.9422 10.7498 32.7524 10.3334C32.5625 9.91698 32.4607 9.46581 32.4535 9.00821C32.4463 8.55062 32.5338 8.09647 32.7105 7.6743"
            fill={fill}
        />
        <path
            d="M42.28 8.39844L43.4901 9.4652L41.468 13.78L45.5042 11.2405L46.7063 12.3072L40.7913 15.7225L39.6211 14.6955L42.28 8.39844Z"
            fill={fill}
        />
        <path
            d="M48.0597 14.1309L50.1455 17.4983L49.1265 18.1352L47.8289 16.0415L46.579 16.8137L47.8766 18.9074L46.8735 19.5283L45.5759 17.4346L44.3659 18.183L45.6635 20.2767L44.6365 20.9135L42.5508 17.5461L48.0597 14.1309Z"
            fill={fill}
        />
        <path
            d="M48.6486 23.5165L48.8556 24.5275C48.9376 24.7938 49.1159 25.0201 49.3555 25.1623C49.5952 25.3044 49.8792 25.3524 50.1522 25.2968C50.4253 25.2412 50.6679 25.086 50.8329 24.8614C50.9979 24.6368 51.0734 24.3589 51.0448 24.0817L50.8219 23.0786L48.6486 23.5165ZM48.1709 26.0321L46.3877 27.5606L46.0454 25.8888L47.6375 24.5355L47.4783 23.7394L45.6871 24.1295L45.3926 22.6647L51.7613 21.375L52.3106 24.0817C52.3864 24.5877 52.2977 25.1048 52.0576 25.5566C51.8174 26.0084 51.4385 26.3712 50.9766 26.5914C50.5148 26.8117 49.9944 26.8777 49.4921 26.7799C48.9899 26.6821 48.5323 26.4255 48.1868 26.048"
            fill={fill}
        />
    </>
);

const types = {
    Months: OverMonths,
    Years: OverYears,
    'Less than Month': LessThanMonth,
};

const AgeBug = ({ duration, agedUnit }) => {
    const Copy = types[agedUnit] || types['Less than Month'];
    return (
        <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.5 1.27959C13.4939 1.27959 1.27959 13.4939 1.27959 28.5C1.27959 43.5061 13.4939 55.7204 28.5 55.7204C43.5061 55.7204 55.7204 43.5061 55.7204 28.5C55.7204 13.4939 43.5061 1.27959 28.5 1.27959ZM28.5 57C12.7959 57 0 44.2041 0 28.5C0 12.7959 12.7959 0 28.5 0C44.2041 0 57 12.7959 57 28.5C57 44.2041 44.2041 57 28.5 57Z"
                fill={fill}
            />
            <Copy />
            <text
                fill={fill}
                fontFamily="GT Walsheim Pro"
                fontSize="22"
                fontWeight="bold"
                letterSpacing="0px"
                textAnchor="middle"
                x="28"
                y="36.414"
            >
                {duration}
            </text>
        </svg>
    );
};

AgeBug.defaultProps = {
    duration: 0,
    agedUnit: 'Less than Month',
};

AgeBug.propTypes = {
    duration: PropTypes.number.isRequired,
    agedUnit: PropTypes.text,
};

export default AgeBug;
