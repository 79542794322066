import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
    motion,
    useTransform,
    useMotionValue,
    useElementScroll,
} from 'framer-motion';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useWindowSize } from 'react-use';

import Block from 'components/blocks/';

import useMediaQuery from 'hooks/useMediaQuery';
import useScrollDirection from 'hooks/useScrollDirection';

import FlavorProfileGraph from 'components/ui/FlavorProfileGraph';

import SharpnessAgeBugs from './SharpnessAgeBugs';
import SharpnessStoryItem from './SharpnessStoryItem';
import SharpnessCloseButton from './SharpnessCloseButton';
import SharpnessBackground from './SharpnessBackground';
import SharpnessSideBarLines from './SharpnessSideBarLines';

import styles from './SharpnessStory.module.scss';

const SharpnessStory = ({ masthead, items, isPage, closeLink }) => {
    const scrollRef = useRef(null);
    const constraintsRef = useRef(null);
    const mastheadAreaRef = useRef(null);
    const stickyAreaRef = useRef(null);

    const scrollDirection = useScrollDirection();
    const { scrollY } = useElementScroll(scrollRef);
    const { width, height } = useWindowSize();

    const isMedium = useMediaQuery('(min-width: 768px)');

    const [isInertia, setIsInertia] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isFrameCounting, setIsFrameCounting] = useState(false);
    const [transformScrollRange, setTransformScrollRange] = useState([0, 0]);
    const [animateIn, setAnimateIn] = useState(false);
    const [activeProductId, setActiveProductId] = useState(false);

    const activeProduct = items?.find(item => item.id === activeProductId);

    const indicatorTrackRange = [0, 550];

    const updateDimensions = useCallback(() => {
        const mastheadAreaCurrent =
            mastheadAreaRef && mastheadAreaRef.current
                ? mastheadAreaRef.current
                : null;

        const stickyAreaCurrent =
            stickyAreaRef && stickyAreaRef.current
                ? stickyAreaRef.current
                : null;

        const mastheadHeight = mastheadAreaCurrent.offsetHeight;

        const stickyTopScrollClearance = 10;
        const stickyAreaTop = mastheadHeight + stickyTopScrollClearance;

        // magic offset number
        const offset = isPage ? 287 : 132;

        setTransformScrollRange([
            stickyAreaTop,
            stickyAreaCurrent.offsetHeight + offset,
        ]);
    }, [isPage]);

    useEffect(() => {
        updateDimensions();
    }, [width, height, updateDimensions]);

    const useMotionScrollY = useTransform(
        scrollY,
        transformScrollRange,
        indicatorTrackRange,
        {
            clamp: true,
        }
    );

    const scrollTop =
        scrollRef && scrollRef.current ? scrollRef.current.scrollTop : 0;

    const dragMv = useMotionValue(scrollTop);

    const dragMvTransform = useTransform(
        dragMv,
        indicatorTrackRange,
        transformScrollRange,
        {
            clamp: true,
        }
    );

    let transformValue = scrollTop;
    const diffThreshold = 30;
    const frameCountThreshold = 10;
    let frameCounter =
        isInertia || isFrameCounting ? frameCountThreshold + 1 : 0;

    // dragTransform is never called
    /* eslint-disable-next-line no-unused-vars */
    const dragTransform = useTransform(dragMvTransform, value => {
        if (scrollRef && scrollRef.current && isDragging) {
            // NOTE: For when user switches from native scrolling to dragging:
            // Problem: Initial value on drag start is wrong and causes page scroll jump on first drag
            // Solution: Skip the initial bad value frames (Seeking better solution)
            const diff = Math.abs(transformValue - value);
            frameCounter = frameCounter + 1;
            // Firstly, if we're over [frameCountThreshold] frames into dragging we can be sure the value is correct
            if (frameCounter > frameCountThreshold) {
                transformValue = value;
            } else {
                // If we're under [frameCountThreshold] frames we check the difference between the real scrollTop and the bad values
                // If the we're under [diffThreshold] pixel difference then we know the value isn't bad, or that bad.
                if (diff < diffThreshold) {
                    transformValue = value;
                }
            }
            scrollRef.current.scrollTop = transformValue;
        }
    });

    useEffect(() => {
        // if overlay version is refreshed we switch to the isPage version
        // but the scrollTop value persists in some cases
        // so force page to top if isPage and not top
        if (isPage && window.scrollY > 0) {
            window.scrollTo(0, 0);
        }
    });

    useEffect(() => {
        disableBodyScroll(scrollRef.current);
        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const handleInView = id => {
        if (!activeProductId) {
            updateDimensions();
            setAnimateIn(true);
            setTimeout(() => {
                setActiveProductId(id);
            }, 300);
        } else {
            setActiveProductId(id);
        }
    };

    return (
        <div
            ref={scrollRef}
            className={cx(styles.scrollRoot, {
                [styles.isPage]: isPage,
            })}
            onWheel={() => {
                if (isDragging) {
                    setIsInertia(false);
                    setIsFrameCounting(false);
                    setIsDragging(false);
                }
            }}
        >
            <SharpnessBackground activeProduct={activeProduct} />
            <div className={styles.root}>
                {isPage && (
                    <SharpnessCloseButton
                        scrollDirection={scrollDirection}
                        closeLink={closeLink}
                    />
                )}
                <div ref={mastheadAreaRef} className={styles.masthead}>
                    <Block
                        key={`masthead-block`}
                        contentTypeId="blockFullWidthMedia"
                        {...masthead}
                        theme="Sharpness Story"
                    />
                </div>
                <div ref={stickyAreaRef} className={styles.inner}>
                    {isMedium && (
                        <div className={styles.chartWrapper}>
                            <FlavorProfileGraph
                                hasInViewAnimation
                                animateIn={animateIn}
                                className={styles.chart}
                                values={activeProduct?.flavorProfileData}
                            />
                        </div>
                    )}
                    <div className={styles.items}>
                        {items?.map((item, i) => {
                            return (
                                <SharpnessStoryItem
                                    key={i}
                                    {...item}
                                    isInView={handleInView}
                                    isMedium={isMedium}
                                    isPage={isPage}
                                    onChange={updateDimensions}
                                >
                                    {!isMedium && (
                                        <div className={styles.chartWrapper}>
                                            <FlavorProfileGraph
                                                hasInViewAnimation={i === 0}
                                                animateIn={animateIn}
                                                className={styles.chart}
                                                values={item.flavorProfileData}
                                            />
                                        </div>
                                    )}
                                </SharpnessStoryItem>
                            );
                        })}
                    </div>
                    <div className={styles.sideBarLinesWrap}>
                        <div
                            className={styles.sideBarLines}
                            ref={constraintsRef}
                        >
                            <SharpnessSideBarLines items={items} />
                            <motion.div
                                drag="y"
                                dragElastic={0.01}
                                dragConstraints={constraintsRef}
                                onDragStart={() => {
                                    transformValue = scrollTop;
                                    setIsDragging(true);
                                    setIsInertia(false);

                                    // timer here gives drag 250ms to correct itself
                                    // see dragTransform function
                                    let timer;
                                    clearTimeout(timer);
                                    timer = setTimeout(() => {
                                        setIsFrameCounting(true);
                                    }, 250);
                                }}
                                onDragEnd={() => {
                                    setIsInertia(true);
                                }}
                                className={styles.ageBugDrag}
                                style={{
                                    y: isDragging ? dragMv : useMotionScrollY,
                                }}
                            >
                                <SharpnessAgeBugs
                                    activeProduct={activeProduct}
                                    items={items}
                                />
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SharpnessStory.propTypes = {
    masthead: PropTypes.object,
    closeLink: PropTypes.object,
    items: PropTypes.array,
    isPage: PropTypes.bool,
};

SharpnessStory.defaultProps = {
    isPage: false,
};

export default SharpnessStory;
