import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';
import Link from 'components/ui/Link';
import Img from 'components/ui/Img';

import styles from './CardStory.module.scss';

const CardStory = ({ title, category, slug, cardImage }) => {
    return (
        <li className={styles.listItem}>
            <Link href={`/news/${slug}`}>
                <figure className={styles.aspectBox}>
                    {cardImage?.file?.url && (
                        <Img
                            className={styles.image}
                            src={cardImage.file.url}
                            fallbackImageWidth={1030}
                            alt={cardImage.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: cardImage.file.url,
                                    imageWidth: 1030,
                                },
                                {
                                    src: cardImage.file.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </figure>
                <figcaption className={styles.textBlock}>
                    <Text
                        baseTheme="labelSmall"
                        themes={{ medium: 'labelLarge' }}
                        className={styles.storySubHeadline}
                        as="h3"
                    >
                        {category}
                    </Text>
                    <div className={styles.storyHeadlineWrapper}>
                        <Text
                            baseTheme="headingSmall"
                            themes={{ medium: 'headingLarge' }}
                            className={styles.storyHeadline}
                            as="h1"
                        >
                            {title}
                        </Text>
                    </div>
                    <Text
                        baseTheme="bodySmall"
                        themes={{ medium: 'bodyMedium' }}
                        className={styles.link}
                        as="h3"
                    >
                        View Story →
                    </Text>
                </figcaption>
            </Link>
        </li>
    );
};

CardStory.propTypes = {
    cardImage: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    category: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default CardStory;
