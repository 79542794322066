import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import UltraBoldHeading from 'components/ui/UltraBoldHeading';
import Button from 'components/ui/Button';
import Text from 'components/ui/Text';
import Video from 'components/ui/Video';
import RichText from 'components/ui/RichText';
import DecorativeBorder from 'components/globals/DecorativeBorder';

import useMediaQuery from 'hooks/useMediaQuery';
import { useTheme } from 'hooks/useTheme';

import Block from 'components/blocks/';

import Img from 'components/ui/Img';

import styles from './BlockFullWidthMedia.module.scss';

const BlockFullWidthMedia = ({
    callout,
    className,
    flushBottom,
    flushTop,
    headline,
    richHeadline,
    headlineSize,
    headlineColor,
    headlineIcon,
    ctaButton,
    headlinePortraitAlignment,
    headlineLandscapeAlignment,
    landscapeImage,
    loading,
    portraitImage,
    theme,
    videoTextureMp4,
    videoTextureWebm,
    videoPoster,
    videoTextureMp4Large,
    videoTextureWebmLarge,
    videoPosterLarge,
    svgLockup,
    svgPortraitAlignment,
    svgLandscapeAlignment,
    imageLargeVerticalAlignment,
    description,
    firstBlock,
}) => {
    const isMedium = useMediaQuery('(min-width: 768px)');
    const [globalTheme] = useTheme();

    const isMakersReserve = globalTheme === 'makers-reserve';
    const isSharpnessStory = theme === 'Sharpness Story';

    useEffect(() => {
        if (
            !portraitImage &&
            !landscapeImage &&
            !videoTextureMp4 &&
            !videoTextureWebm
        ) {
            dispatchWarning(
                'No background media provided. Image or video texture required for render.'
            );
        }

        if (
            (portraitImage && !landscapeImage) ||
            (landscapeImage && !portraitImage)
        ) {
            dispatchWarning(
                'Both portrait image and landscape image required for render.'
            );
        }

        if (
            (videoTextureMp4 && !videoTextureWebm) ||
            (videoTextureWebm && !videoTextureMp4)
        ) {
            dispatchWarning(
                'Both mp4 and webm video textures required for render.'
            );
        }
    }, [portraitImage, landscapeImage, videoTextureMp4, videoTextureWebm]);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    const descriptionOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text.Theme
                        config={{
                            default: {
                                baseTheme: 'headingXSmall',
                                themes: {
                                    medium: 'headingSmall',
                                },
                            },
                        }}
                    >
                        {children}
                    </Text.Theme>
                );
            },
        },
    };

    const richTextOverrides = {
        // This headline will always come in as a paragraph from the CMS.
        // It is not possible to restrict input to H2.

        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text.Theme
                        config={{
                            'makers-reserve': {
                                baseTheme: 'parkinsonXLarge',
                                themes: {
                                    large: 'parkinsonXXLarge',
                                },
                            },
                            default: {
                                baseTheme: 'displayMedium',
                                themes: {
                                    large: 'displayXXLarge',
                                },
                            },
                        }}
                        as="h2"
                    >
                        {children}
                    </Text.Theme>
                );
            },
        },
    };

    const headlineSizes = {
        small: {
            baseTheme: 'displaySmall',
            themes: {
                medium: 'displayLarge',
                large: 'displayXLarge',
                xxxLarge: 'displayXXLarge',
            },
        },
        medium: {
            baseTheme: 'displayMedium',
            themes: {
                medium: 'displayLarge',
                large: 'displayXLarge',
                xxxLarge: 'displayXXLarge',
            },
        },
    };

    return (
        <>
            <div
                className={cx(
                    styles.root,
                    styles[`theme--${globalTheme}`],
                    styles[
                        `headlinePortraitAlignment-${headlinePortraitAlignment}`
                    ],
                    styles[
                        `headlineLandscapeAlignment-${headlineLandscapeAlignment}`
                    ],
                    styles[`svgPortraitAlignment-${svgPortraitAlignment}`],
                    styles[`svgLandscapeAlignment-${svgLandscapeAlignment}`],
                    styles[
                        `imageLargeVerticalAlignment-${imageLargeVerticalAlignment}`
                    ],
                    className,
                    {
                        [styles[`theme--midpage`]]: theme === 'Midpage',
                        [styles[`theme--fullHeight`]]: theme === 'Full Height',
                        [styles[`theme--sharpnessStory`]]: isSharpnessStory,
                        [styles[`theme--headlineAlignLeft`]]:
                            theme === 'Headline Align Left',
                    }
                )}
                style={{ color: `var(--colors-${headlineColor})` }}
            >
                {(headline || richHeadline) &&
                    !svgLockup &&
                    globalTheme !== 'makers-reserve' && (
                        <div className={styles.headline}>
                            <UltraBoldHeading
                                className={styles.headlineSandwich}
                                baseTheme={
                                    headlineSizes[headlineSize].baseTheme
                                }
                                themes={{
                                    medium: headlineSizes[headlineSize].themes
                                        .medium,
                                    large: headlineSizes[headlineSize].themes
                                        .large,
                                    xxxLarge:
                                        headlineSizes[headlineSize].themes
                                            .xxxLarge,
                                }}
                                icon={
                                    headlineIcon === 'none'
                                        ? null
                                        : headlineIcon
                                }
                                headline={richHeadline}
                            />
                            {description && (
                                <div className={styles.description}>
                                    <RichText
                                        richText={description}
                                        overrides={descriptionOverrides}
                                    />
                                </div>
                            )}
                            {(ctaButton?.cta?.link ||
                                ctaButton?.cta?.assetDownloadLink) && (
                                <div className={styles.buttonWrapper}>
                                    <Button
                                        className={styles.cta}
                                        theme={ctaButton.theme}
                                        entry={ctaButton.cta.link}
                                        iconLeading={ctaButton.iconType}
                                    >
                                        {ctaButton.cta.text}
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                {svgLockup?.file?.url && (
                    <div
                        className={styles.svgLockup}
                        style={{
                            backgroundImage: `url(${svgLockup.file.url})`,
                        }}
                    >
                        {headline && (
                            <span className="sr-only">{headline}</span>
                        )}
                    </div>
                )}
                {isMakersReserve && (
                    <div className={cx(styles.headline)}>
                        {richHeadline && (
                            <RichText
                                richText={richHeadline}
                                overrides={richTextOverrides}
                            />
                        )}
                    </div>
                )}
                <div className={styles.aspect}>
                    {portraitImage?.file?.url &&
                        landscapeImage?.file?.url &&
                        !videoTextureMp4 &&
                        !videoTextureWebm && (
                            <Img
                                className={styles.image}
                                src={portraitImage.file.url}
                                fallbackImageWidth={768}
                                loading={loading}
                                alt={portraitImage.description || ''}
                                priority={firstBlock}
                                customSources={[
                                    {
                                        breakpoint: 1920,
                                        src: landscapeImage.file.url,
                                        imageWidth: 1920 * 1.5,
                                    },
                                    {
                                        breakpoint: 1440,
                                        src: landscapeImage.file.url,
                                        imageWidth: 1440 * 1.5,
                                    },
                                    {
                                        breakpoint: 1024,
                                        src: landscapeImage.file.url,
                                        imageWidth: 1024 * 1.5,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: landscapeImage.file.url,
                                        imageWidth: 768 * 1.5,
                                    },
                                    {
                                        src: portraitImage.file.url,
                                        imageWidth: 768,
                                    },
                                ]}
                            />
                        )}
                    {videoTextureMp4?.file?.url && videoTextureWebm?.file?.url && (
                        <Video
                            className={cx(styles.videoSmall, {
                                [styles['large-hide']]:
                                    videoTextureMp4Large?.file.url,
                            })}
                            poster={videoPoster?.file?.url}
                            sources={[
                                videoTextureMp4.file.url,
                                videoTextureWebm.file.url,
                            ]}
                        />
                    )}
                    {videoTextureMp4Large?.file?.url &&
                        videoTextureWebmLarge?.file?.url && (
                            <Video
                                className={cx(styles.videoLarge, {
                                    [styles['small-hide']]:
                                        videoTextureMp4?.file.url,
                                })}
                                poster={videoPosterLarge?.file?.url}
                                sources={[
                                    videoTextureMp4Large.file.url,
                                    videoTextureWebmLarge.file.url,
                                ]}
                            />
                        )}
                    {isMakersReserve && !isSharpnessStory && (
                        <div
                            className={styles.makersReserveBorder}
                            role="presentation"
                        >
                            <div className={styles.makersReserveBorderInner}>
                                <DecorativeBorder
                                    borderWidth={isMedium ? 2 : 1}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {callout && (
                <div
                    className={cx(styles.calloutWrapper, {
                        [styles.flushBottom]: flushBottom,
                        [styles.flushTop]: flushTop,
                    })}
                >
                    <Block {...callout} contentTypeId={'blockCallout'} />
                </div>
            )}
        </>
    );
};

BlockFullWidthMedia.propTypes = {
    callout: PropTypes.object,
    className: PropTypes.string,
    eyebrow: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    richHeadline: PropTypes.object,
    headline: PropTypes.string,
    headlineSize: PropTypes.string,
    headlineColor: PropTypes.string,
    headlineIcon: PropTypes.string,
    ctaButton: PropTypes.object,
    headlinePortraitAlignment: PropTypes.string,
    headlineLandscapeAlignment: PropTypes.string,
    landscapeImage: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }).isRequired,
            }),
            url: PropTypes.string,
        }),
    }),
    svgPortraitAlignment: PropTypes.string,
    svgLandscapeAlignment: PropTypes.string,
    svgLockup: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    loading: PropTypes.string,
    portraitImage: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    theme: PropTypes.oneOf([
        'Default',
        'Midpage',
        'Full Height',
        'Headline Align Left',
        "Maker's Reserve",
        "Maker's Reserve w/ Border",
    ]),
    videoTextureMp4: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    videoTextureWebm: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    videoPoster: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    videoTextureMp4Large: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    videoTextureWebmLarge: PropTypes.shape({
        file: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    videoPosterLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    height: PropTypes.number,
                    width: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLargeVerticalAlignment: PropTypes.string,
    description: PropTypes.object,
    firstBlock: PropTypes.bool,
};

BlockFullWidthMedia.defaultProps = {
    flushBottom: false,
    flushTop: false,
    landscapeImage: null,
    loading: 'auto',
    portraitImage: null,
    theme: 'Default',
    videoTextureMp4: null,
    videoTextureWebm: null,
    headlineSize: 'medium',
    headlineColor: 'cream',
    headlineIcon: 'morningStar',
    description: null,
};

export default BlockFullWidthMedia;
