import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Block from 'components/blocks';

import styles from './BlockTabs.module.scss';

const Tab = ({ blocks, isActive, isPrevious, className }) => {
    return (
        <section
            className={cx(styles.tab, className, {
                [styles.isActive]: isActive,
                [styles.isPrevious]: isPrevious,
            })}
        >
            {blocks &&
                blocks.map((blockProps, i) => (
                    <Block key={`tab-block-${i}`} {...blockProps} />
                ))}
        </section>
    );
};

Tab.propTypes = {
    isActive: PropTypes.bool,
    isPrevious: PropTypes.bool,
    blocks: PropTypes.arrayOf(
        PropTypes.shape({
            contentTypeId: PropTypes.string.isRequired,
        }).isRequired
    ),
    className: PropTypes.string,
};

export default Tab;
