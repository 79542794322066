import { useEffect } from 'react';
import PropTypes from 'prop-types';

const BlockMissing = ({ contentTypeId }) => {
    useEffect(() => {
        console.warn(`Block Missing: ${contentTypeId}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

BlockMissing.propTypes = {
    contentTypeId: PropTypes.string,
};

BlockMissing.MissingBlockProps = {
    contentTypeId: '',
};

export default BlockMissing;
