import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Img from 'components/ui/Img';

import styles from './Slide.module.scss';

const Slide = ({ slot, image, scale, animationState }) => {
    return (
        <div
            className={cx(
                styles.slide,
                [styles[`slot-${slot}`]],
                [styles[`${animationState}`]],
                {
                    [styles['no-transition']]: slot > 4 || slot < 1,
                }
            )}
        >
            <Img
                src={image?.file.url}
                fallbackImageWidth={800}
                alt={image?.description || ''}
                style={{ width: `${scale * 100}%`, height: `${scale * 100}%` }}
                width={image?.file?.details?.image?.width}
                height={image?.file?.details?.image?.height}
                customSources={[
                    {
                        breakpoint: 1920,
                        src: image?.file.url,
                        imageWidth: 1920 / 3,
                    },
                    {
                        breakpoint: 1440,
                        src: image?.file.url,
                        imageWidth: 1440 / 3,
                    },
                    {
                        breakpoint: 1024,
                        src: image?.file.url,
                        imageWidth: Math.round(1024 / 3),
                    },
                    {
                        breakpoint: 768,
                        src: image?.file.url,
                        imageWidth: 768 / 2,
                    },
                    {
                        src: image?.file.url,
                        imageWidth: 400,
                    },
                ]}
                priority
            />
        </div>
    );
};

Slide.propTypes = {
    slot: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    scale: PropTypes.number,
    animationState: PropTypes.string.isRequired,
};

Slide.propTypes = {
    scale: 0.8,
};

export default Slide;
